import crud from "../store/modules/crud";
import i18n from "@/i18n";
export default {
    data() {
        return {
            hasCompany: true,
            key: 0,
            company: {
                id: null,
                city: null,
                country: null,
                created_at: null,
                deleted_at: null,
                district: null,
                name: null,
                postal_code: null,
                reg_number: null,
                status: null,
                street: null,
                tax_id: null,
                updated_at: null,
                vat_id: null,
            },
        }
    },
    methods: {
        getCompany() {
            crud.get("companies/my-company")
                .then((resp) => {
                    if(resp.data === null) {
                        this.hasCompany = false
                    } else {
                        this.company = resp.data;
                    }
                }).finally(() => {
                    this.key++
                })
        },
        updateCompany() {
            crud.put(`companies/${this.company.id}`, this.company)
                .then(() => {
                    this.$notify({
                        type: "success",
                        text: i18n.t("company.successfully-updated"),
                    });
                })
                .catch((reason) => {

                    console.log(reason);

                    this.$notify({
                        type: "danger",
                        text: i18n.t(reason.data.message),
                    });
                });
        },
    }
}