import moment from "moment";
import store from "@/store";
export default {
    methods: {
        getOnlyExistingAttributes(obj, attributes)
        {
            Object.keys(obj).forEach(key => {
                if(key in obj){
                    if(typeof obj[key] === 'boolean'){
                        obj[key] = Boolean(attributes[key])
                    } else {
                        obj[key] = attributes[key]
                    }
                }
            })
        },

        getDate(date, format='ll')
        {
            return moment(date).locale(store.getters["auth/getLocale"]).format(format);
        }

    }
}