<template>
  <div>
    <div class="ml-3 mb-4">
      <h1 class="text-left">
        <strong>{{ $t("menu.active-offers") }}</strong>
      </h1>
      <p>{{ $t("auctions.please_use_advanced_search") }}</p>
    </div>

    <div class="row col-12">
      <div class="col-md-8 d-md-flex">
        <div class="ad-input-seach position-relative">
          <font-awesome-icon
            icon="fa-solid fa-magnifying-glass"
            class="position-absolute"
            id="font-awesome-icon"
          />

          <input
            type="text"
            class="ad-input-seach mr-3"
            :placeholder="$t('system.search_in_article_title')"
            v-model="searchAuctionName"
            @keyup.enter="searchByName()"
          />
        </div>

        <button
          @click="tableFilterEnabled = true"
          class="px-3 ad-btn-advanced-search"
        >
          <font-awesome-icon icon="fa-solid fa-sliders" class="mr-2" />
          {{ $t("system.advanced-search") }}
        </button>
      </div>

      <div class="col-md-4 md-text-right btn-didnt-find">
        <!-- @click="report.modal = true" -->
        <router-link
          to="/advertiser/wishlist/create"
          class="ad-btn-custom-requirement mx-1 px-4 py-3"
        >
          {{ $t("modal.didnt_find_what_you_were_looking_for") }}
        </router-link>
      </div>
    </div>

    <div class="row ml-3 mt-3" style="margin-bottom: -3rem;">
      <!-- name -->
      <p
        v-if="this.selectedFilterOptions.search"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.search") }}

        <font-awesome-icon
          @click="resetCustomParam('search')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <!-- max_price -->
      <p
        v-if="this.selectedFilterOptions.max_price"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.max_price") }}

        <font-awesome-icon
          @click="resetCustomParam('max_price')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <!-- min_estimated_traffic -->
<!--      <p-->
<!--        v-if="this.selectedFilterOptions.min_estimated_traffic"-->
<!--        class="ad-filter-btn mb-0 position-relative"-->
<!--      >-->
<!--        {{ $t("filter.min_estimated_traffic") }}-->

<!--        <font-awesome-icon-->
<!--          @click="resetCustomParam('min_estimated_traffic')"-->
<!--          class="close-btn position-absolute ad-pointer"-->
<!--          icon="fa-solid fa-xmark"-->
<!--        />-->
<!--      </p>-->
<!--      &lt;!&ndash; published &ndash;&gt;-->
      <p
        v-if="this.selectedFilterOptions.published"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.published") }}

        <font-awesome-icon
          @click="resetCustomParam('published')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <!-- tag -->
      <p
          v-if="this.selectedFilterOptions.tag.length > 1"
          class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.hashtag") }}

        <font-awesome-icon
            @click="resetCustomParam('tag')"
            class="close-btn position-absolute ad-pointer"
            icon="fa-solid fa-xmark"
        />
      </p>

      <p
          v-for="(tag, index) in this.selectedFilterOptions.tag"
          v-bind:key="index"
          class="ad-filter-btn mb-0 position-relative"
      >
        {{ tag.word }}

        <font-awesome-icon
            @click="resetCustomParam('tag', tag.id)"
            class="close-btn position-absolute ad-pointer"
            icon="fa-solid fa-xmark"
        />
      </p>

      <!-- segment -->
      <p
        v-if="this.selectedFilterOptions.segment.length > 1"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.segment") }}

        <font-awesome-icon
          @click="resetCustomParam('segment')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <p
        v-for="(segment, index) in this.selectedFilterOptions.segment"
        v-bind:key="index"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t(segment.name) }}

        <font-awesome-icon
          @click="resetCustomParam('segment', segment.id)"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <!-- url -->
<!--      <p-->
<!--        v-if="this.selectedFilterOptions.url.length"-->
<!--        class="ad-filter-btn mb-0 position-relative"-->
<!--      >-->
<!--        {{ $t("filter.url") }}-->

<!--        <font-awesome-icon-->
<!--          @click="resetCustomParam('url')"-->
<!--          class="close-btn position-absolute ad-pointer"-->
<!--          icon="fa-solid fa-xmark"-->
<!--        />-->
<!--      </p>-->
    </div>

    <!-- v-on:params-updated="processEmit" -->
    <TableFilter
      v-show="tableFilterEnabled"
      v-if="!loading"
      v-on:close-action="tableFilterEnabled = false"
      v-on:filter-updated="processFilterOptions"

      :max_price="selectedFilterOptions.max_price"
      :segment="selectedFilterOptions.segment"
      :tag="selectedFilterOptions.tag"
      :url="selectedFilterOptions.url"
    />

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :meta="this.table.meta"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>
    <div class="btn-didnt-find-mobile col-12 md-text-right">
      <!-- @click="report.modal = true" -->
      <router-link
          to="/advertiser/wishlist/create"
          class="ad-btn-custom-requirement mx-1 px-4 py-3"
      >
        {{ $t("modal.didnt_find_what_you_were_looking_for") }}
      </router-link>
    </div>
    <Modal
      v-if="report.modal"
      @close="report.modal = false"
      @save="submitReportModal()"
      color="bg-secondary"
      :title="$t('modal.didnt_find_what_you_were_looking_for')"
      :button-text="$t('system.send')"
      class="text-left"
    >
      <div class="input-box bg-invert">
        <div class="row col-12 mt-4 mb-3">
          <div class="col-12 mb-5 text-center">
            {{ $t("modal.description") }}
          </div>
          <!-- Topic type -->
          <div class="col-md-6">
            <input
              type="text"
              v-model="report.details.what_topic_are_you_interested"
              class="input-1"
            />
            <label class="input-label">
              {{ $t("modal.what_topic_are_you_interested") }}
              <font-awesome-icon
                icon="fa-solid fa-star-of-life"
                style="color: red;"
              />
            </label>
          </div>

          <!-- Minimum traffic -->
          <div class="col-md-6 mt-5 mt-md-0">
            <input
              type="text"
              v-model="report.details.what_minimum_traffic_do_you_require"
              class="input-1"
            />
            <label class="input-label">
              {{ $t("modal.what_minimum_traffic_do_you_require") }}
              <font-awesome-icon
                icon="fa-solid fa-star-of-life"
                style="color: red;"
              />
            </label>
          </div>

          <!-- Price for opening article -->
          <div class="col-md-6 mt-5">
            <input
              type="text"
              v-model="report.details.price_for_opening_article"
              class="input-1"
            />
            <label class="input-label">
              {{ $t("modal.price_for_opening_article") }}
            </label>
          </div>

          <!-- Max price -->
          <div class="col-md-6 mt-5">
            <input
              type="text"
              v-model="report.details.maximum_purchase_price"
              class="input-1"
            />
            <label class="input-label">
              {{ $t("modal.maximum_purchase_price") }}
              <font-awesome-icon
                icon="fa-solid fa-star-of-life"
                style="color: red;"
              />
            </label>
          </div>

          <!-- Other requirements -->
          <div class="col-md-12 mt-5">
            <textarea
              class="input-1"
              v-model="report.details.other_requirements"
              rows="5"
            >
            </textarea>
            <label class="input-label">
              {{ $t("modal.other_requirements") }}
            </label>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/ui/Modal.vue";
import TableFilter from "@/components/ui/v_2/TableFilter.vue";
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import DataViewer from "@/components/ui/DataViewer.vue";
import PreLoader from "@/components/ui/PreLoader.vue";
import rules from "@/mixins/rules.js";
import moment from 'moment'

export default {
  components: {
    Modal,
    DataViewer,
    PreLoader,
    TableFilter
  },

  mixins: [rules],

  data() {
    return {
      loading: true,
      selectedFilterOptions: {
        search: null,
        max_price: null,
        min_estimated_traffic: null,
        url: [],
        segment: [],
        tag: [],
      },
      searchAuctionQuery: null,
      searchAuctionName: null,
      report: {
        modal: false,
        details: {
          what_topic_are_you_interested: "",
          what_minimum_traffic_do_you_require: "",
          price_for_opening_article: "",
          maximum_purchase_price: "",
          other_requirements: ""
        }
      },
      tableFilterEnabled: false,
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "auction_name",
          translate: i18n.t("advertiser_table.article_name"),
          sorter: true,
          style: "width: 150px !important;",
          hint: null,
          href: {
            link: "/advertiser/offer-detail/",
            identifier: "id"
          },
          mobile: {
            class: "col-12",
            sorter: false,
          }
        },{
          key: "price",
          translate: i18n.t("auctions.mention_price"),
          sorter: true,
          cell_style: "justify-content: right !important;",
          style: "text-align:right;",
          hint: null,
          mobile: {
            sorter: false,
            class: "col-12",
            display: "inline"
          }
        }
      ]
    };
  },

  mounted() {
    let params = this.$route.query;

    if( params['filter[max_price]'] !== undefined){
      this.selectedFilterOptions.max_price = Number.parseFloat(params['filter[max_price]']);
    }

    if (params['filter[min_estimated_traffic]'] !== undefined) {
      this.selectedFilterOptions.min_estimated_traffic = Number.parseFloat(params['filter[min_estimated_traffic]']);
    }

    if (params['filter[url]'] !== undefined) {
      let urls = params['filter[url]'].split(',');
      urls.forEach( (url, index) => { urls[index] = Number.parseInt(url)})

      this.selectedFilterOptions.url = urls;
    }

    if (params['filter[segment]'] !== undefined) {
      let segments = params['filter[segment]'].split(',');
      segments.forEach( (segment, index) => { segments[index] = Number.parseInt(segment); })

      this.selectedFilterOptions.segment = segments;
    }

    // if (params['filter[published]'] !== undefined) {
    //   this.selectedFilterOptions.published = Number.parseInt(params['filter[published]']);
    // }

    if (params['filter[tag]'] !== undefined) {
      let tags = params['filter[tag]'].split(',');
      tags.forEach((tag, index) => { tags[index] = Number.parseInt(tag); })

      this.selectedFilterOptions.tag = tags;
    }

    if (params['filter[search]'] !== undefined){
      this.selectedFilterOptions.search = params['filter[search]'];
    }

    this.processFilterOptions(this.selectedFilterOptions);
  },

  methods: {
    ...mapActions({
      addNotification: "notifications/addNotification"
    }),
    submitReportModal() {
      if (
        this.report.details.what_topic_are_you_interested &&
        this.report.details.what_minimum_traffic_do_you_require &&
        this.report.details.what_minimum_traffic_do_you_require
      ) {
        let self = this;

        let obj = {
          topic: self.report.details.what_topic_are_you_interested,
          minimum_traffic: self.report.details.what_minimum_traffic_do_you_require,
          price_per_click: self.report.details.price_for_opening_article,
          price: self.report.details.maximum_purchase_price,
          other: self.report.details.other_requirements
        };

        crud.post("wishlist", obj)
            .then(function() {
            self.report.modal = false;

            // Reset data
            self.report.details = {
              what_topic_are_you_interested: "",
              what_minimum_traffic_do_you_require: "",
              price_for_opening_article: "",
              maximum_purchase_price: "",
              other_requirements: ""
            };

            self.addNotification({
              type: "success",
              message: i18n.t("success.record-add")
            });
          })
          .catch(() => {
            self.addNotification({
              type: "danger",
              message: i18n.t("error.oops")
            });
          });
      }
    },
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "?";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud
        .get("auction/advertiser/list" + page + this.query.filter + sort)
        .then(function(response) {

          response.data.items.forEach(item => {

            let min_price = 0;
            item.min_price ? (min_price = Intl.NumberFormat("sk-SK").format(item.min_price)+ "€")  : "";

            let max_price = 0;
            item.max_price ? (max_price = Intl.NumberFormat("sk-SK").format(item.max_price)+ "€")  : "";

            if(max_price == min_price){
              item.price = min_price;
            }else {
              item.price = min_price + ' ' + i18n.t('offer.to') + ' ' + max_price;
            }
            // item.domain_name
            //   ? (item.domain_name = item.domain_name
            //       .split("//")[1]
            //       .replace("www.", ""))
            //   : "";
            // item.price_per_click
            //   ?
            // (item.price_per_click = Intl.NumberFormat("sk-SK", { style: 'currency', currency: 'EUR' }).format(item.price_per_click))
            //   :
            // "";
            //
            // item.estimated_traffic = Intl.NumberFormat("sk-SK").format(item.estimated_traffic)

            // HTML title
            let shortedTitle =
              item.auction_name.length > 56
                ? item.auction_name.substring(0, 56) + " ..."
                : item.auction_name;

            let auctionName =
              '<div class="routerLinkRedesign" style="display: inline-flex;">' +
              '<div style="font-size: 1.25rem;" class="pr-3"><i class="fa-regular fa-file-lines"></i></div>' +
              '<div><span style="font-weight:600;">' +
              shortedTitle +
              "</span><br>" +
              item.domain_name +
              "</div>" +
              "</div>";

            item.auction_name = auctionName;

            // HTML date
            item.time_left[1]
              ? (item.time_left =
                  moment(item.time_left[0]).locale('sk').format('ll') +
                  '<br><span style="color:rgba(230, 81, 0, 1);font-weight:500;">' +
                  moment(item.time_left[0]).locale('sk').fromNow() +
                  "</span>")
              : item.time_left = i18n.t("filter.published");

            // HTML company
            item.company = '<span><b> ' + item.user + ', </b><br class="ad-hide-mobile-table"> ' + item.company + ' </span>';
          });

          self.table = response.data;
          self.performance.loading = false;
          self.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },

    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }

      // Emited action
      if (data.action) {
        let self = this;

        self.performance.loading = true;

        crud.post(data.action).then(() => {
          self.loadTable();
        });
      }
    },
    searchByName() {
      if (!this.searchAuctionName) return;

      this.selectedFilterOptions.search = this.searchAuctionName;

      this.processFilterOptions(this.selectedFilterOptions);
    },

    processFilterOptions(data) {
      this.query.filter = "";

      if (data.max_price > 0) {
        this.query.filter += "&filter[max_price]=" + data.max_price;
        this.selectedFilterOptions.max_price = data.max_price;
      }

      // if (data.min_estimated_traffic > 0) {
      //   this.query.filter += "&filter[min_estimated_traffic]=" + data.min_estimated_traffic;
      //   this.selectedFilterOptions.min_estimated_traffic = data.min_estimated_traffic;
      // }
      //
      // if (Array.isArray(data.url) && data.url.length > 0) {
      //   this.query.filter += "&filter[url]=" + data.url.toString();
      //   this.selectedFilterOptions.url = data.url;
      // }

      if (Array.isArray(data.segment) && data.segment.length > 0) {
        this.query.filter += "&filter[segment]=" + data.segment.map( (value) => value.id).toString();
        this.selectedFilterOptions.segment = data.segment;
      }

      if (Array.isArray(data.tag) && data.tag.length > 0) {
        this.query.filter += "&filter[tag]=" + data.tag.map((value) => value.id).toString();
        this.selectedFilterOptions.tag = data.tag;
      }

      // if (data.published !== null) {
      //   this.query.filter += "&filter[published]=" + data.published;
      //   this.selectedFilterOptions.published = data.published;
      // }

      if (this.selectedFilterOptions.search !== undefined && this.selectedFilterOptions.search !== null) {
        this.query.filter += "&filter[search]=" + this.selectedFilterOptions.search;
      }

      this.loadTable();
    },

    resetCustomParam(data, id = null) {
      if(Array.isArray(this.selectedFilterOptions[data])){
        if(id === null) {
          this.selectedFilterOptions[data] = [];
        }else{
          this.selectedFilterOptions[data].forEach((option, index)=> {
            if(option.id === id){
              this.selectedFilterOptions[data].splice(index,1);
            }
          });
        }
      }
      else{
        this.selectedFilterOptions[data] = null;
      }

      this.processFilterOptions(this.selectedFilterOptions);
    },

    generateFilterQuery() {
      this.query.page = 1;
      let routeQuery = this.$route.query
      const queryKeys = Object.keys(this.$route.query)
      console.log(this.selectedFilterOptions)
      queryKeys.forEach(queryKey => {
        if(queryKey.includes('filter') && !this.selectedFilterOptions.segment.includes(routeQuery[queryKey]))
        {
          this.selectedFilterOptions.segment.push( routeQuery[queryKey])
        }
      });
      let filter = Object.values(this.selectedFilterOptions)
        .join("")
        .toString();

      return filter;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-didnt-find-mobile{
  @media (max-width: 768px) {
    display: inline;
  }
  @media (min-width: 769px) {
    display: none;
  }
}

.btn-didnt-find{
  @media (max-width: 768px) {
    display: none;
  }
  @media (min-width: 769px) {
    display: inline;
  }
}

</style>
