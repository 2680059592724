<template>
  <div>
    <div class="row">
      <div class="col-sm-8">
        <h1 class="text-left mb-4">
          <strong>{{ $t("menu.invoice") }}</strong>
        </h1>
        <p v-html="$t('hints.invoice-introduction')"></p>
      </div>
<!--      <div class="col-sm-4 text-sm-right">-->
<!--        <router-link-->
<!--          class="button bg-light mr-0 my-2 d-inline-block"-->
<!--          to="/finance/dashboard"-->
<!--        >-->
<!--          {{ $t("system.cancel") }}-->
<!--        </router-link>-->
<!--      </div>-->
    </div>

    <dynamic-filter
      :inputs="dynamicFilterInputs"
      v-on:params-updated="processEmit"
    />

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :meta="this.table.meta"
        :tableConfig="{
          col: 500
        }"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import DataViewer from "@/components/ui/DataViewer";
import PreLoader from "@/components/ui/PreLoader";
import DynamicFilter from "@/components/ui/DynamicFilter";
import i18n from "@/i18n";

export default {
  data() {
    return {
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "user",
          translate: i18n.t("users.user"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "type",
          translate: i18n.t("finance.type"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "status",
          translate: i18n.t("system.status"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "value",
          translate: i18n.t("system.value"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "actions",
          translate: i18n.t("system.actions"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          },
          buttons: [
            {
              key: "proforma",
              link: "proforma_pdf",
              identifier: null,
              href: true,
              translate: i18n.t("finance.proforma-invoice"),
              class: "button bg-warning text-white mx-1 px-3 px-4 py-2"
            },
            {
              key: "regular_pdf",
              link: "regular_pdf",
              identifier: null,
              href: true,
              translate: i18n.t("finance.regular-invoice"),
              class: "button bg-success text-white mx-1 px-3 px-4 py-2"
            }
          ]
        }
      ],
      dynamicFilterInputs: [
        {
          name: "user",
          type: "text",
          label: i18n.t("users.user"),
          value: null,
          adminOnly: true
        },
        {
          name: "proforma_id",
          type: "text",
          label: i18n.t("finance.proforma_invoice_id"),
          value: null,
          adminOnly: true
        },
        {
          name: "regular_id",
          type: "text",
          label: i18n.t("finance.invoice_id"),
          value: null,
          adminOnly: true
        }
      ]
    };
  },

  components: {
    DataViewer,
    PreLoader,
    DynamicFilter
  },

  mounted() {
    this.loadTable();

    if (this.$options.filters.isSuperAdmin(this.$store.state.auth.user.roles)) {
      this.tableHeaders.splice(
        this.tableHeaders.length - 1,
        0,
        {
          key: "proforma_invoice_id",
          translate: i18n.t("finance.proforma_invoice_id"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "regular_invoice_id",
          translate: i18n.t("finance.invoice_id"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        }
      );
    }
  },

  methods: {
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "";
      let filter = this.query.filter ? this.query.filter : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      let api = null;

      if (
        this.$options.filters.isSuperAdmin(this.$store.state.auth.user.roles)
      ) {
        api = "transaction/administration";
      } else {
        api = "transaction/get-deposits";
      }

      crud
        .get(api + page + filter + sort)
        .then(function(response) {
          // console.log(  Object.keys(response.data.items[0])  );

          response.data.items.forEach(item => {
            if (Number(item.value)) {
              item.value = item.value.split(".")[0] + "€";
            }

            item.type
              ? (item.type = i18n.t(
                  "enum." +
                    self.$store.state.enumerators.enumerators[
                      "transaction-type"
                    ][item.type]
                ))
              : "";

            item.status
              ? (item.status = i18n.t(
                  "enum." +
                    self.$store.state.enumerators.enumerators[
                      "transaction-status"
                    ][item.status]
                ))
              : "";
          });

          self.table = response.data;
          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },
    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      if (data.filter) {
        this.query.filter = data.filter;

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
