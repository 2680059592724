<template>
  <div class="container text-center">
    <div class="loader" v-if="loading">Loading...</div>
    <h3>Verifying...</h3>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import store from "@/store";
import {mapActions} from "vuex";
import i18n from "@/i18n";

export default {
    data() {
      return {
        loading: true
      }
    },
    mounted() {
       crud.get(this.$route.query.verifyLink).then(resp => {
        if(resp.status === 200 && store.getters["auth/authenticated"]) {
          this.loading = false
          this.$store.dispatch("auth/verifyUser").then(() => { this.$router.push({path: '/advertiser/dashboard'}) })
        }else {
          this.addNotification({
            type: "success",
            message: i18n.t("verification.verified")
          });
          this.$router.push({name: 'login'})
        }
        console.log(resp.status)
       })
    },
  methods: {
    ...mapActions({
      addNotification: "notifications/addNotification"
    }),
  }
}
</script>

<style>

</style>