<template>
  <div class="ad-input-wrapper">
    <!-- <input  :type="type" @input="emitValue" :value="value" id="ad-input" class="ad-input" :class="{'bordered': bordered}" placeholder=" "> -->
    <textarea
      id="ad-text-area"
      placeholder=" "
      @input="emitValue"
      class="ad-text-area"
      cols="30"
      rows="10"
      :class="{
        bordered: bordered,
        'ad-valid': validationTriggered && !error,
        'ad-error': validationTriggered && error
      }"
      :value="value"
    ></textarea>
    <label for="ad-text-area">
      <span v-show="placeholder !== null">{{ placeholder }}</span>
    </label>
  </div>
</template>

<script>
import validation from "../../../mixins/validation";
export default {
  name: "AdTextArea",
  mixins: [validation],
  props: {
    value: {
      default: null
    },
    placeholder: {
      default: null
    },
    bordered: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    emitValue(e) {
      if (this.validations.length > 0) {
        this.validate(e.target.value);
      }
      this.$emit("input", e.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.ad-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  .ad-text-area {
    width: 100%;
    height: 15em;
    // line-height: 0.3em;
    padding: 8em 0.8em 1em 0.8em;
    font-size: 1em;
    border: none;
    transition: border-color 0.3s;
  }

  @media only screen and (min-width: 200px) {
    .ad-text-area {
      padding: 8em 0.8em 1em 0.8em;
    }
  }

  @media only screen and (min-width: 300px) {
    .ad-text-area {
      padding: 6em 0.8em 1em 0.8em;
    }
  }

  @media only screen and (min-width: 400px) {
    .ad-text-area {
      padding: 5em 0.8em 1em 0.8em;
    }
  }

  @media only screen and (min-width: 600px) {
    .ad-text-area {
      padding: 4em 0.8em 1em 0.8em;
    }
  }

  @media only screen and (min-width: 2178px) {
    .ad-text-area {
      padding: 2em 0.8em 1em 0.8em;
    }
  }


  .bordered {
    border: 1px solid #ced4da;
    border-radius: 4px;
  }

  .ad-valid {
    border-color: #00a490 !important;
  }
  .ad-error {
    border-color: #dc3545d9 !important;
    background-color: rgba(220, 53, 69, 5%);
  }

  label {
    position: absolute;
    left: 1em;
    top: 1em;
    margin: 0;
    color: #000000a6;
    transition: transform 0.3s;
  }

  .ad-text-area:focus ~ label,
  .ad-text-area:not(:placeholder-shown).ad-text-area:not(:focus) ~ label {
    transform: translateY(-0.8em) scale(0.7) translateX(-0.5em);
  }
}
</style>
