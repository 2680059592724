<template>
  <div class="row pt-4">
    <div class="col-sm-6" v-if="totalCount > 0">
      <strong>{{ $t("system.total-count") }}</strong
      >: {{ totalCount }} ({{ pageCount }})
    </div>
    <div class="col-sm-6">
      <Pagination
        v-if="pages > 0"
        :activePage.sync="page"
        :pages="pages"
        align="end"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/ui/Pagination";
/**
 *  In parent file
 *
 *  <Pagination :totalCount="this.page.totalCount" :perPage="this.page.perPage" />
 *
 *   import Pagination from "@/components/ui/Pagination";
 *
 *   components: {Pagination},
 *   data() {
 *   return {
 *     page: {
 *         totalCount: 0,
 *         perPage: 0
 *         pageCount: 0
 *     }
 *   };
 *
 *   methods: {
 *     pagination() {
 *       some functions ... call after click
 *    },
 *   }
 *
 *   !!!!
 *   set  totalCount, perPage, pageCount
 *   self.page.totalCount = response.data.meta.totalCount;
 *    self.page.perPage = response.data.meta.perPage;
 *    self.page.pageCount = response.data.items.length;
 *
 */

export default {
  name: "DataTableInfo",
  components: {
    Pagination
  },
  props: {
    totalCount: { type: Number, default: 0 },
    perPage: Number,
    pageCount: Number,
    currentPage: Number,
    paginationType: String,
    paginationSection: String
  },
  data() {
    return {
      page:
        this.$route.query.page && this.$route.query.page !== "NaN"
          ? parseInt(this.$route.query.page)
          : 1
    };
  },
  mounted() {
    this.setCurrentPage();
  },
  watch: {
    currentPage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      }
    },
    page: function() {
      window.scrollTo(0, 0);

      if (this.paginationType === "api") {
        this.$parent.$parent.pagination({
          section: this.paginationSection,
          current: this.page
        });
      } else {
        // eslint-disable-next-line no-unused-vars
        let filter = false;
        for (let key in this.$route.query) {
          if (key.includes("filter") || key === "sort") {
            filter = true;
          }
        }

        if (filter) {
          let query = Object.assign(
            this.$route.query,
            JSON.parse('{ "page": ' + this.page + "}")
          );
          this.$router.push({ path: this.$route.path });
          this.$router.push({ query: query });
        } else {
          if (!this.page) {
            this.$router.push({ path: this.$route.path });
          } else if (Number(this.$route.query.page) !== Number(this.page)) {
            this.$router.push({
              path: this.$route.path,
              query: { page: this.page }
            });
          }
        }

        this.$parent.$parent.pagination();
      }
    },
    "$route.query.page"() {
      //set pagination to [1]
      if (this.paginationType === "default")
        this.page = Number(this.$route.query.page ? this.$route.query.page : 1);
    }
  },
  methods: {
    setCurrentPage() {
      this.page = Number(this.page ? this.page : 1);
    }
  },
  computed: {
    pages: function() {
      return Math.ceil(this.totalCount / this.perPage);
    }
  }
};
</script>
