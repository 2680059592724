<template>
  <div class="col-xxl-6 col-md-9 col-xl-7">
    <router-link
      :to="'/advertiser/offer-detail/' + this.offerId"
    >
      <font-awesome-icon icon="fa-solid fa-arrow-left" class="mr-2" />
      {{ $t("offer.back_to_offer_detail") }}
    </router-link>

    <h2 class="mt-4 font-weight-bold">
      {{ $t("order.order") }}
    </h2>
    <ad-form @submit="createOffer">

    <!-- Timeline start -->
    <div class="timeline">
      <div class="timeline-box position-relative">
        <div class="timeline-dot timeline-dot-active"></div>

        <div class="timeline-dot-line ml-auto"></div>

        <div class="timeline-text mt-md-3">
          <span
            style="font-family: Roboto; font-size: 16px; line-height: 24px;"
            class="d-block font-weight-bold"
          >
            {{ $t("order.order") }}
          </span>

          <span
            style="font-family: Roboto; font-size: 14px; font-weight: 400; line-height: 16px;"
            class="d-block"
          >
            {{ $t("order.pay_first") }}
          </span>
        </div>

        <div class="timeline-dot-line-mobile"></div>
      </div>

      <div class="timeline-box position-relative">
        <div class="timeline-dot"></div>

        <div class="timeline-dot-line ml-auto"></div>

        <div class="timeline-text mt-md-3">
          <span
            style="font-family: Roboto; font-size: 16px; line-height: 24px;"
            class="d-block font-weight-bold"
          >
            {{ $t("order.processing") }}
          </span>

          <span
            style="font-family: Roboto; font-size: 14px; font-weight: 400; line-height: 16px;"
            class="d-block"
          >
            {{ $t("order.communication_with_the_editors") }}
          </span>
        </div>

        <div class="timeline-dot-line-mobile"></div>
      </div>

      <div class="timeline-box">
        <div class="timeline-dot"></div>

        <div class="timeline-text mt-md-3">
          <span
            style="font-family: Roboto; font-size: 16px; line-height: 24px;"
            class="d-block font-weight-bold mt-custom-5"
          >
            {{ $t("auctions.publication") }}
          </span>

          <span
            style="font-family: Roboto; font-size: 14px; font-weight: 400; line-height: 16px;"
            class="d-block"
          >
            {{ $t("order.publishing_content") }}
          </span>
        </div>
      </div>
    </div>
    <!-- Timeline end -->

    <div class="mt-4">
      <h3>
        {{ $t("products."+selectedItem.name) }}
      </h3>
<!--      <router-link to="#" class="mt-2">-->
<!--        {{ this.item.auction_name }}-->
<!--      </router-link>-->
    </div>

    <div class="mt-4 ad-smaller-text-responsive">
      <!-- row 1 -->
      <div
        class="row py-2"
        style="border-top: 1px solid rgba(233, 237, 239, 1);"
      >
<!--        <div-->
<!--          class="col-4 col-md-3"-->
<!--          style="border-right: 1px solid rgba(233, 237, 239, 1);"-->
<!--        >-->
<!--          <font-awesome-icon icon="fa-regular fa-file-lines" class="mr-3" />-->
<!--          {{ $t("advertiser_table.article") }}-->
<!--        </div>-->
        <div class="col-8 col-md-9">
          <font-awesome-icon icon="fa-solid fa-display" class="mr-3 ml-md-1" />
          {{ this.item.domain_url }}
        </div>
      </div>
      <div
          class="row py-2"
          style="border-top: 1px solid rgba(233, 237, 239, 1);"
      ></div>
    </div>

<!--      &lt;!&ndash; row 2 &ndash;&gt;-->
<!--&lt;!&ndash;      <div&ndash;&gt;-->
<!--&lt;!&ndash;        class="row py-2"&ndash;&gt;-->
<!--&lt;!&ndash;        style="border-top: 1px solid rgba(233, 237, 239, 1);"&ndash;&gt;-->
<!--&lt;!&ndash;      >&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="col-6" v-if="item.end_date !== null">&ndash;&gt;-->
<!--&lt;!&ndash;          <font-awesome-icon icon="fa-regular fa-clock" class="mr-3" />&ndash;&gt;-->
<!--&lt;!&ndash;          <span class="mr-3">&ndash;&gt;-->
<!--&lt;!&ndash;            {{ $t("offer.end_offer") }}&ndash;&gt;-->
<!--&lt;!&ndash;          </span>&ndash;&gt;-->
<!--&lt;!&ndash;          {{ endDate }} ({{endDateFromNow}})&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->

<!--&lt;!&ndash;        <div class="col-6" v-else >&ndash;&gt;-->
<!--&lt;!&ndash;          <font-awesome-icon icon="fa-regular fa-clock" class="mr-3" />&ndash;&gt;-->
<!--&lt;!&ndash;            {{ $t("offer.end_date_empty") }}&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->


<!--&lt;!&ndash;        <div class="col-6">&ndash;&gt;-->
<!--&lt;!&ndash;          <font-awesome-icon icon="fa-regular fa-calendar" class="mr-3" />&ndash;&gt;-->
<!--&lt;!&ndash;          <span class="mr-3">&ndash;&gt;-->
<!--&lt;!&ndash;            {{ $t("offer.article_publication") }}&ndash;&gt;-->
<!--&lt;!&ndash;          </span>&ndash;&gt;-->
<!--&lt;!&ndash;          {{ publishDate }}&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->

<!--      &lt;!&ndash; row 3 &ndash;&gt;-->
<!--      <div-->
<!--        class="row py-2"-->
<!--        style="border-top: 1px solid rgba(233, 237, 239, 1); border-bottom: 1px solid rgba(233, 237, 239, 1);"-->
<!--      >-->
<!--        <div class="col-12">-->
<!--          <font-awesome-icon icon="fa-solid fa-chart-column" class="mr-3" />-->
<!--          <span class="mr-3">-->
<!--            {{ $t("offer.number_of_views") }}-->
<!--          </span>-->
<!--            {{ this.item.estimated_traffic }}-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

      <div class="mt-4">
        <h3>
          {{ $t("order.publishing_content") }}
        </h3>

        <div class="mt-3">
          <ad-text-area
            v-model="required.statementText.value"
            :rules="[rules.required]"
            :placeholder="selectedItem && selectedItem.type !== 1 ? $t('auctions.statement_text') : $t('order.mention-description')"
          />
        </div>

        <div v-if="selectedItem && selectedItem.type !== 1" class="mt-3">
          <ad-text-area
            v-model="required.expectationText.value"
            :rules="[rules.required]"
            :placeholder="$t('auctions.expectation_text')"
          />
        </div>

        <div class="mt-3 url-link">
          <ad-input
            v-model="required.urlLink.value"
            :rules="[rules.url, rules.required]"
            :placeholder="$t('auctions.url-address')"
          />
        </div>

        <div v-if="selectedItem && selectedItem.type == 4" class="mt-3">
          <ad-input
            v-model="videoUrl.value"
            :rules="[rules.url]"
            :placeholder="$t('order.video-url')"
          />
        </div>
      </div>

      <div
          v-if="selectedItem && selectedItem.type == 3"
          class="mt-4" id="attachments">
        <div class="section-header mt-4">
          <h3 class="mb-0">{{ $t("order.image") }}</h3>
        </div>
        <div class="row">
          <div class="col mt-4">
            <base-uploader
                :value="offer.main.attachments"
                @uploaded="attachment = $event"
                @remove="attachment = null"
                accept="image/png, image/jpeg"
                class="w-100"
                api-url="attachment"
                type="grid"
                :number-of-files="1"
                :maxFileSize="6000000"
            />
          </div>
        </div>
      </div>

      <div class="mt-4">
        <p style="font-weight: 500; font-size: 18px;">
          {{ $t("system.price") }}
        </p>

        <div
          class="pt-3 d-flex"
          style="border-top: 1px solid rgba(233, 237, 239, 1); color: rgba(0, 164, 144, 1); justify-content: space-between;"
        >
          <div>
            {{ $t("products."+selectedItem.name) }}
          </div>

          <div>
            {{  parseFloat(selectedItem.price).toFixed(2) }} €
          </div>
        </div>

        <hr style="border-color: rgba(233, 237, 239, 1)" />
        <div class="d-flex">
          <ad-btn
            :title="$t('offer.order_now')"
            type="submit"
            :loading="loading"
          />

          <div v-if="not_enough_credit" style="color: red; align-content: center" class=" mt-1 ml-2 w-75">
            {{$t('order.not_enough_credit')}}
            <router-link to="/finance/add-credit">{{$t('order.add_credit')}}</router-link>
          </div>
        </div>
      </div>
    </ad-form>
  </div>
</template>

<script>
import AdForm from "@/components/ui/v_2/AdForm.vue";
import AdBtn from "@/components/ui/v_2/AdBtn.vue";
import AdInput from "@/components/ui/v_2/AdInput.vue";
import AdTextArea from "@/components/ui/v_2/AdTextArea.vue";
import rules from "@/mixins/rules.js";
import offer from "@/mixins/offer.js";
import crud from "@/store/modules/crud.js";
import i18n from "@/i18n";
import moment from 'moment'
import store from "@/store";
import BaseUploader from "@/components/attachments/BaseUploader.vue";

export default {
  mixins: [rules, offer],

  components: {
    BaseUploader,
    AdInput,
    AdTextArea,
    AdForm,
    AdBtn
  },

  data() {
    return {
      item: [],
      productTypes: this.$store.state.enumerators.enumerators["product-type"],
      loading: false,
      not_enough_credit: false,
      required: {
        statementText: {
          value: null,
          error: false
        },
        expectationText: {
          value: null,
          error: false
        },
        urlLink: {
          value: 'https://',
          error: false
        },
      },
      videoUrl: {
        value: null,
        error: false
      },
      selectedItem: {
        id: null,
        name: null,
        price: null,
        type: null
      },
      attachment: null,
      offerId: this.$route.params.id,
      productId: this.$route.params.product_id
    };
  },

  mounted () {
    this.getOrder();
  },

  computed: {
    selectedProduct: function() {
      return this.item.products.find(element => element.id == this.productId);
    },

    endDate: function(){
      return moment(this.item.end_date).locale(store.getters['auth/getLocale']).format('l');
    },
    endDateFromNow: function(){
      return moment(this.item.end_date).locale(store.getters['auth/getLocale']).fromNow();
    },
    publishDate: function(){
      return moment(this.item.publish_date).locale(store.getters['auth/getLocale']).format('l');
    }


  },

  methods: {
    createOffer(validated) {
      if (!validated) return;

      this.loading = true;

      let obj = {
        info: this.required.statementText.value,
        expectation: this.required.expectationText.value,
        url: this.required.urlLink.value,
        product: this.productId,
        product_type: this.selectedItem.type,
      }

      if(this.videoUrl.value != '' && this.videoUrl.error == false){
        obj.video_url = this.videoUrl.value;
      }

      if(this.attachment != null && this.attachment.length > 0){
        obj.attachment = this.attachment[0].id;
      }

      crud.post('/bid/advertiser/' + this.offerId, obj).then((resp) => {
        this.$notify({ type: "success", text: i18n.t("success.record-add") });
        // this.$router.push({path: '/advertiser/orders'})
        this.$router.push({path:"/advertiser/order/comments/" + resp.data.id});
      })
      .catch((error) => {
        this.loading = false;

        if(error.response.data == 'Not enough credit'){
          this.not_enough_credit = true;
        }
        this.$notify({ type: "error", text: i18n.t("error."+error.response.data) });
      });
    },
    getOrder() {
      crud.get('/auction/advertiser/' + this.offerId).then(response => {
        this.item = response.data.items;

        this.selectedItem = {
          id: this.productId,
          name: this.productTypes[this.selectedProduct.type],
          price: this.selectedProduct.price,
          type: this.selectedProduct.type
        };
      });
    }
  }
};
</script>
<style lang="scss" scoped>

.url-link::v-deep .ad-input-main .ad-input {
  @media (max-width: 768px) {
    padding-top: 2.5em;
  }
}

.timeline {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.timeline-box {
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    margin-bottom: 1.5rem;

    .timeline-dot {
      margin-right: 1rem;
    }
  }
}

.timeline-dot {
  z-index: 9999 !important;
  width: 1.3rem;
  height: 1.3rem;
  background-color: white;
  border-radius: 50%;
  border: 3px solid rgba(0, 164, 144, 0.12);
}

.timeline-dot-active {
  border: 3px solid rgba(0, 164, 144, 1);
}

.timeline-dot-line {
  z-index: 1 !important;

  height: 4px;
  bottom: 0.75rem;
  background: rgba(0, 164, 144, 0.12);
  width: calc(100% - 1.3rem);
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
}

.timeline-dot-line-mobile {
  position: absolute;
  left: 0.525rem;
  top: 1.3rem;
  background: rgba(0, 164, 144, 0.12);
  width: 4px;
  height: 110%;

  @media (min-width: 768px) {
    display: none;
  }
}

.mt-custom-5 {
  //

  @media (min-width: 768px) {
    margin-top: 1.25rem !important;
  }
}

.ad-smaller-text-responsive {
  font-size: 15px;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
}

.ad-input-error {
  border: 1px solid red !important;
  border-radius: 6px;
}
</style>
