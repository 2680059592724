export default {
  namespaced: true,

  state: {
    status: null,
    //status : this.$store.state.enumerators.enumerators['system-view'][1],
    checked: true
  },
  actions: {
    setUserView({ commit, state }, data = null) {
      let defaultRole = localStorage.default_role;
      if (localStorage.view) {
        let checked = localStorage.view === "advertiser";
        commit("SET_VIEW", { status: localStorage.view, checked: checked });
      }
      if (state.status === null) {
        let checked = localStorage.defaultRole === "advertiser";
        commit("SET_VIEW", { status: defaultRole, checked: checked });
      }
      if (data !== null) {
        commit("SET_VIEW", { status: data.status, checked: data.checked });
      }
    }
  },
  getters: {
    status(state) {
      return state.status;
    },
  },
  mutations: {
    SET_VIEW(state, data) {
      localStorage.view = data.status;
      state.status = data.status;
      state.checked = data.checked;
    }
  }
};
