<template>
  <div class="avatar">
    <div
      class="avatar-wrapper d-flex justify-content-center text-center align-items-center"
      :style="wrapperStyle"
    >
      <div class="avatar-text">
        {{ firstLettersOfName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object
    },
    size: {
      type: Number,
      default: 32
    },
    colors: {
      type: Array,
      default: () => {
        return [
          {
            background: "#00A490",
            color: "white"
          },
          {
            background: "#3473AC",
            color: "white"
          }
        ];
      }
    }
  },
  computed: {
    firstLettersOfName() {
      return this.user.first_name.charAt(0) + this.user.last_name.charAt(0);
    },
    wrapperStyle() {
      const randomColor = this.colors[
        Math.floor(Math.random() * this.colors.length)
      ];
      return {
        width: this.size + "px",
        height: this.size + "px",
        "background-color": randomColor.background,
        color: randomColor.color
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar-wrapper {
  position: relative;
  border-radius: 50%;
  .avatar-text {
    font-weight: 700;
  }
}
</style>
