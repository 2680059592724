<template>
  <div>
    <h3>{{ $t('interests.interests')}}</h3>

    <p> {{ $t('interests.title')}}</p>

    <div>
      <form @submit.prevent="addKeywords">

        <div class="import-form">

        <div class="mt-2 pr-3 error-header" v-if="!isFormValid">
          <div v-for="(error, index) in errors" v-bind:key="index" >
            {{error}}
          </div>
        </div>

        <textarea
                id="ad-text-area"
                placeholder=" "
                class="text-area keywords-input mt-2"
                rows="1"
                cols="50"
                ref="textarea"
                @focus="resize"
                @keyup="resize"
                :class=" {
                  'ad-valid': !isFormValid,
                  'ad-error': isFormValid
                }"
                :value="keywordsInput"
                @input="checkAfterKeyUp"
        ></textarea>
        <ad-btn
            class="mt-2"
            :title="$t('interests.import')"
            type="submit"
            :loading="loading"
        />
        </div>
      </form>
    </div>


    <div class="mt-4 row">

      <div class="ml-3 w-100"><h4>{{ $t("interests.table_header", { count: keywords.length})}}</h4></div>

       <table class="keywords-table mx-4 col-sm-12 col-md-8 col-xxl-5">
         <tr v-for="(keyword, index) in keywords" v-bind:key="index" class="keyword-row">
           <td>{{ keyword.word }}</td>
           <td >
             <ad-btn
               flat
               prepend-icon="fa-solid fa-trash"
               @click="deleteKeyword(keyword.id)"
               :title="$t('interests.delete-word')"
               class="ml-auto delete-column"
               style="max-width: 150px"
           /></td>
           <td style="text-align: right;">
             <div class="toggle-frequency">
             {{ $t('interests.now')}}
             <ad-toggle
                 :value="getFrequencyValue(keyword.frequency)"
                 toggle-type="default"
                 :with-text="false"
                 @input="toggleKeywordFrequency(keyword)"
             />
             {{ $t('interests.daily')}}
             </div>
           </td>
         </tr>

       </table>


    </div>

  </div>
</template>

<script>
import AdBtn from "@/components/ui/v_2/AdBtn.vue";
// import crud from "@/store/modules/crud";
import keyword from "@/mixins/keyword.js";
import i18n from "@/i18n";
import crud from "@/store/modules/crud";
import AdToggle from "@/components/ui/v_2/AdToggle.vue";

export default {
  name: 'InterestsNew',
  components: {AdToggle, AdBtn},
  mixins: [keyword],
  data() {
    return {
      keywords: [],
      keywordsInput: '',
      keywordsValidated: [],
      errors: [],
      isFormValid: true,
      loading: false,
      timeout: null
    }
  },
  created() {
    this.getKeywords();
  },
  methods: {
    addKeywords() {
      this.loading = true;

      this.validate(this.keywordsInput);

      if (!this.isFormValid){
        this.loading = false;
        return;
      }

      crud.post('keywords', {
        words: this.keywordsValidated
      }).then((response) => {

        if(response.status == 200) {
          this.keywords = [...this.keywords, ...response.data.items];

          this.keywordsValidated = [];
          this.keywordsInput = '';
        }
      }).finally(() => this.loading = false)
    },

    validate(val){
      val = val.replace(/^\s*[\r\n]/gm,'');
      this.isFormValid = true;
      this.errors = [];

      if(val == ''){
        this.errors.push(i18n.t('system.is-required'))
        this.isFormValid = false;
        return;
      }

      this.keywordsValidated = val.split(/\r\n|\r|\n/g);

      this.keywordsValidated.forEach((word, index, keywordsArray) => {

        if(word == ''){
          keywordsArray.splice(index,1);
          return;
        }
        if(word.length > 50){
          this.isFormValid = false;
          this.errors.push(i18n.t('interests.keyword-too-long', {word: word}));
        }
        if(word.length < 3){
          this.isFormValid = false;

          this.errors.push(i18n.t('interests.keyword-too-short', {word: word}));
        }
      });
      return true;
    },

    toggleKeywordFrequency(keyword) {

      crud.post(`keywords/${keyword.id}/frequency`, {frequency: this.getToggleValue(keyword.frequency)}).then((response) => {
        this.keywords.forEach((word, index) => {
         if(word.id == keyword.id) {
            this.keywords[index].frequency = response.data.frequency
         }
        });

        this.$notify({
          type: "success",
          text: i18n.t("success.success"),
        });
      });
    },

    checkAfterKeyUp(input) {
      clearTimeout(this.timeout);
      let self = this;
      this.timeout = setTimeout(function() {
        self.emitValue(input);
      }, 800);
    },

    emitValue(e) {
      this.keywordsInput = e.target.value;
      this.validate(this.keywordsInput);
    },
    resize(){
      const { textarea } = this.$refs;
      let value = textarea.value.split(/\r\n|\r|\n/g);
      let length = value.length <= 10 ? value.length : 10

      textarea.style.height = (2 + (length -1) * 1.5).toString() + 'em';
    },

    getFrequencyValue(val){
      if(val == 2){
        return true;
      }
      return false;
    },

    getToggleValue(val){
     if(val == 2){
       return 1;
     }
     return 2;
    }
  }
}


</script>
<style>
  .keywords-input{
    height: 2em;
  }

  .import-form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

  }

  .toggle-frequency{
    display: inline-flex;
  }

  .keyword-row:hover{

    .delete-column{
      visibility: visible;
    }
  }

  .delete-column{
    visibility: hidden;
  }

  .keywords-table tr{
    border-bottom: 1px solid #e9edef;

  }


</style>