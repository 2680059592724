//Views Keywords
import Keywords from "../views/keyword/KeywordIndex.vue";
import KeywordCreate from "../views/keyword/KeywordCreate.vue";
import KeywordEdit from "../views/keyword/KeywordEdit.vue";
import KeywordView from "../views/keyword/KeywordView.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/keywords",
          meta: {
            label: "menu.keywords"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/keywords",
              name: "menu.keywords" + ": " + "crud.list",
              component: Keywords,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/keywords/create",
              name: "menu.keywords" + ": " + "crud.create",
              component: KeywordCreate,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/keywords/edit/:id",
              name: "menu.keywords" + ": " + "crud.edit",
              component: KeywordEdit,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/keywords/view/:id",
              name: "menu.keywords" + ": " + "crud.view",
              component: KeywordView,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
