<template>
  <div>
    <div style="text-align:center;" class="py-3 top-bar">
        <img
            class="addmention-logo-sidebar"
            src="/svg/logo_addmention.svg"
            alt="AddMention"
          />

    </div>
    <router-view />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.top-bar {
    border-bottom: 2px solid #F2F2F2;
}
</style>