<template>
  <div>
    <div class="col-lg-12">
      <div class="loader" v-if="loading">Loading...</div>
      <form v-else>
        <div class="form-row mb-3 input-box bg-invert-white">
          <div class="col-md-6">
            <input
              type="text"
              id="url"
              class="input-1"
              v-model="form.url"
              required
            />
            <label class="input-label" for="url"
              >URL <small>(https://www)</small></label
            >
          </div>

          <div class="col-md-3">
            <select
              v-model="form.status"
              class="input-1"
              id="status"
              :disabled="
                !$options.filters.isSuperAdmin(
                  this.$store.state.auth.user.roles
                )
              "
            >
              <option
                :value="index"
                v-for="(value, index) in $store.state.enumerators.enumerators[
                  'website-status'
                ]"
                :key="value"
                >{{ value }}</option
              >
            </select>
            <label for="status" class="input-label">{{
              $t("register.status")
            }}</label>
          </div>

          <div class="col-md-3 text-right">
            <button
              v-if="isEdit && !isSubmitting"
              type="button"
              class="button ml-0"
              @click="validate"
            >
              {{ $t("system.update") }}
            </button>
            <button
              v-if="!isEdit && !isSubmitting"
              @click="validate"
              type="button"
              class="button color-green-dark"
            >
              {{ $t("crud.create") }}
            </button>
            <ButtonSpinner v-if="isSubmitting" class="ml-0" />

            <router-link
              class="button bg-light mr-0 my-2 d-inline-block"
              to="/websites"
            >
              {{ $t("system.cancel") }}
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import crud from "@/store/modules/crud";
import { mapActions } from "vuex";
import i18n from "@/i18n";

export default {
  name: "WebsiteCreate",
  props: ["isEdit", "isMyWebsite"],
  data() {
    return {
      loading: true,
      isSubmitting: false,
      form: {
        id: null,
        url: null,
        company_id: null,
        status: "2",
        has_error: []
      }
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    getWebsites() {
      let self = this;

      crud
        .get("websites/" + Number(this.$route.params.id))
        .then(function(response) {
          let data = response.data;

          self.form.id = data.id;
          self.form.url = data.url;
          self.form.status = String(data.status);
          self.form.company_id = data.company_id;

          self.loading = false;
        });
    },

    getCompany() {
      let self = this;

      crud.get("companies").then(function(response) {
        let data = response.data.items[0];
        self.form.company_id = data.id;
        self.loading = false;
      });
    },

    validate() {
      this.isSubmitting = true;

      this.form.has_error = [];

      var expression = /(https?:\/\/)((?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      var pattern = new RegExp(expression);

      if (
        this.form.url === "" ||
        this.form.url === null ||
        (this.form.url !== "" && !this.form.url.match(pattern))
      )
        this.form.has_error.push("URL");

      if (this.form.has_error.length === 0) {
        this.submit();
      } else {
        const error = this.form.has_error.map(x => i18n.t(x));
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-field") + ":<br>" + error
        });
      }
      this.isSubmitting = false;
    },

    submit() {
      this.isSubmitting = true;

      let objWebsite = {
        id: this.form.id,
        url: this.form.url,
        company_id: this.form.company_id
      };

      //role superAdmin = can change status
      if (
        this.$options.filters.isSuperAdmin(this.$store.state.auth.user.roles)
      ) {
        objWebsite.status = this.form.status;
      }

      if (this.isEdit) {
        crud
          .put("websites/" + this.form.id, objWebsite)
          .then(() => {
            this.isSubmitting = false;
            if (!this.isMyWebsite) this.$router.push("/websites");
            this.addNotification({
              type: "success",
              message: i18n.t("success.record-updated")
            });
          })
          .catch(error => {
            this.isSubmitting = false;
            this.addNotification({
              type: "danger",
              message: error.response.data[0].message
            });
          });
      } else {
        delete objWebsite["id"];

        crud
          .post("websites", objWebsite)
          .then(() => {
            this.isSubmitting = false;
            this.$router.push("/websites");
            this.addNotification(
              {
                type: "success",
                message: i18n.t("success.record-add")
              },
              { root: true }
            );
          })
          .catch(error => {
            this.isSubmitting = false;

            this.addNotification({
              type: "danger",
              message: error.response.data[0].message
            });
          });
      }
    }
  },
  created() {
    this.isEdit ? this.getWebsites() : this.getCompany();
  }
};
</script>
