import Vue from "vue";
import store from "@/store";

export default {};

/*
 * call
 * this.$options.filters.capitalize(value)
 * {{ string | capitalize }}
 */
Vue.filter("capitalize", function(value) {
  if (!value) {
    return "";
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("arrayValue", function(value, array) {
  return array[value];
});

/**
 * $options.filters.enumerator(<enumerator_name>, <value to be formatted>, <return type>);
 * example:
 * $options.filters.enumerator('user-status', user.status, 'plain');
 */
Vue.filter("enumerator", function(enumerator, value, returnAs = "plain") {
  if (!store.state.enumerators[enumerator]) {
    return null;
  }

  let result = null;
  let _returnAs = !returnAs ? "plain" : returnAs;

  switch (_returnAs) {
    case "color":
      // @TODO: create some universal coloring map
      break;

    case "plain":
      result = store.state.enumerators[enumerator][value];
      break;

    case "html":
      result =
        "<span>" + store.state.enumerators[enumerator][value] + "</span>";
      break;
  }

  return result;
});

/**
 * Will convert given object into an object suitable for CoreUI's CSelect component.
 * e.g.
 * {a: 1, b: 2}
 * will become:
 * [{value:a, label:1}, {value:b, label:2}]
 */
Vue.filter("objectToSelectBox", function(object) {
  if (object === undefined) {
    return [];
  }

  let response = [];

  for (const [key, value] of Object.entries(object)) {
    response.push({
      value: key,
      label: value
    });
  }

  return response;
});

// usage: {{ file.size | prettyBytes }}
Vue.filter("prettyBytes", function(num) {
  if (typeof num !== "number" || isNaN(num)) {
    throw new TypeError("Expected a number");
  }

  var exponent;
  var unit;
  var neg = num < 0;
  var units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? "-" : "") + num + " B";
  }

  exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1
  );
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? "-" : "") + num + " " + unit;
});

Vue.filter("parseUrlQueryFilter", function(object) {
  if (object === undefined) {
    return [];
  }

  let filter = false;
  for (let q in object) {
    if (q.includes("filter")) {
      filter = true;
    }
  }

  return filter;
});

//truncate text {{ data.content | truncate(300, '...') }}
Vue.filter("truncate", function(text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});

//filter word status
Vue.filter("wordColorStatus", function(status) {
  if (status === 1) {
    return "red";
  } else if (status === 2) {
    return "orange";
  } else {
    return "default";
  }
});

//parse URL
Vue.filter("parseUrl", function(url) {
  let u = new URL(url);

  let arr = [];
  arr[0] = u.host;

  let path = u.pathname.split("/").filter(Boolean);
  arr.push(...path);

  return arr;
});

//filter clear URL
Vue.filter("clearUrl", function(url) {
  return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];
});

//filter valid URL
Vue.filter("validURL", function(str) {
  let pattern = new RegExp(
    "^(http[s]?:\\/\\/)" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
});

//check isSuperAdmin
Vue.filter("isSuperAdmin", function(array) {
  let arr = [];
  let str = "superadmin"; //role

  arr = array.map(key => {
    return key.toLowerCase();
  });

  if (arr.includes(str.toLowerCase())) return true;
  return false;
});

//check is in array
Vue.filter("isInArray", function(array, str, key = false) {
  let arr = array;

  //get keys 0,1,2
  if (key) {
    arr = Object.keys(array).map(key => {
      return key;
    });
  }
  if (arr.includes(str)) return true;
  return false;
});

//ISO Lang convert to lang
Vue.filter("langFromIso", function(str) {
  let lang = str.split("-");
  return lang[0];
});

/* remove accent */
Vue.filter("slug", function(string) {
  if (!string) {
    return "";
  }

  let removeAccent = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  return removeAccent
    .toString()
    .toLowerCase()
    .trim()
    .replace(/&/g, "-and-")
    .replace(/[\s\W]/g, "-")
    .replace(/--+/g, "-")
    .replace(/^-+|-+$/g, "-");
});

Vue.filter("toLocaleDateString", function(date) {
  return new Date(date).toLocaleDateString(localStorage.getItem("locale"));
});

Vue.filter("changeDateToMysql", function(date) {
  return new Date(date)
    .toISOString()
    .slice(0, 10)
    .replace("T", " ");
});
