<template>
  <div>
    <div class="col-lg-12">
      <div class="loader" v-if="loading">Loading...</div>
      <form v-else>
        <div class="form-row mb-3 input-box bg-invert-white">
          <div class="col-md-6">
            <input
              type="text"
              id="keyword"
              class="input-1"
              v-model="form.keyword"
              required
            />
            <label class="input-label" for="keyword">{{
              $t("my-interests.keyword")
            }}</label>
          </div>
          <div class="col-md-3" v-if="!isEdit">
            <select v-model="form.language" class="input-1" id="language">
              <option
                v-for="(value, index) in $store.state.enumerators.enumerators[
                  'language'
                ]"
                :key="value"
                :value="index"
                >{{ value }}</option
              >
            </select>
            <label for="language" class="input-label">{{
              $t("system.language")
            }}</label>
          </div>
          <div class="col-md-2 text-right">
            <button
              v-if="isEdit && !isSubmitting"
              type="button"
              class="button ml-0"
              @click="validate"
            >
              {{ $t("system.update") }}
            </button>
            <button
              v-if="!isEdit && !isSubmitting"
              @click="validate"
              type="button"
              class="button color-green-dark"
            >
              {{ $t("crud.create") }}
            </button>
            <ButtonSpinner v-if="isSubmitting" class="ml-0" />

            <router-link
              class="button bg-light mr-0 my-2 d-inline-block"
              to="/keywords"
            >
              {{ $t("system.cancel") }}
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import crud from "@/store/modules/crud";
import { mapActions } from "vuex";
import i18n from "@/i18n";

export default {
  name: "KeywordCreate",
  props: ["isEdit"],
  data() {
    return {
      loading: true,
      isSubmitting: false,
      form: {
        id: null,
        keyword: null,
        language: null,
        has_error: []
      }
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    getKeywords() {
      let self = this;

      crud
        .get("keywords/" + Number(this.$route.params.id))
        .then(function(response) {
          let data = response.data;

          self.form.id = data.id;
          self.form.keyword = data.word;
          self.form.language = data.language;

          self.loading = false;
        });
    },

    validate() {
      this.isSubmitting = true;

      this.form.has_error = [];
      console.log(this.form.keyword.trim);
      if (this.form.keyword === "" || this.form.keyword.trim() === "")
        this.form.has_error.push("keyword");

      if (this.form.has_error.length === 0) {
        this.submit();
      } else {
        const error = this.form.has_error.map(x => i18n.t(x));
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-field") + ":<br>" + error
        });
      }
      this.isSubmitting = false;
    },

    submit() {
      this.isSubmitting = true;

      let objKeyword = {
        language: this.form.language,
        word: this.form.keyword
      };

      if (this.isEdit) {
        crud
          .put("keywords/" + this.form.id, objKeyword)
          .then(() => {
            this.isSubmitting = false;
            this.$router.push("/keywords");
            this.addNotification({
              type: "success",
              message: i18n.t("success.record-updated")
            });
          })
          .catch(error => {
            this.isSubmitting = false;
            this.addNotification({
              type: "danger",
              message: error.response.data[0].message
            });
          });
      } else {
        crud
          .post("keywords", objKeyword)
          .then(() => {
            this.isSubmitting = false;
            this.$router.push("/keywords");
            this.addNotification(
              {
                type: "success",
                message: i18n.t("success.record-add")
              },
              { root: true }
            );
          })
          .catch(error => {
            this.isSubmitting = false;

            this.addNotification({
              type: "danger",
              message: error.response.data[0].message
            });
          });
      }
    }
  },
  created() {
    this.isEdit ? this.getKeywords() : (this.loading = false);
    this.form["language"] = this.$store.state.auth.user.language;
  }
};
</script>
