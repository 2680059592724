//import i18n from "@/i18n";

//Views Publisher Action
import PublisherDashboard from "../views/dashboard/DashboardPublisher.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/publisher/dashboard",
          meta: {
            label: "publisher.status"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/publisher/dashboard",
              component: PublisherDashboard,
              meta: {
                requiresAuth: true
              }
            },
          ]
        }
      ]
    }
  }
];
