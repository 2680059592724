<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <h1 class="text-left mb-0">
          <strong>{{ $t("register.company") }}</strong>
        </h1>
        <div class="small text-muted pl-1">{{ $t("crud.view") }}</div>
      </div>
      <div class="col-sm-6 text-sm-right">
        <router-link
          class="button bg-warning text-white mx-0 my-2 d-inline-block"
          :to="'/companies/edit/' + this.$route.params.id"
        >
          <font-awesome-icon icon="fa-solid fa-pencil" />
          {{ $t("system.update") + " " + " " + $t("register.company") }}
        </router-link>
        <router-link
          class="button bg-light mr-0 my-2 d-inline-block"
          to="/companies"
        >
          {{ $t("system.cancel") }}
        </router-link>
      </div>
    </div>

    <DataTable
      :items="items"
      :fields="fields"
      :rows="rows"
      :loading="loading"
      :pagination="false"
      view="view"
    ></DataTable>

    <h1 class="text-left mt-5 mb-0">
      <strong>{{ $t("menu.users") }}</strong>
    </h1>
    <div class="small text-muted pl-1">{{ $t("crud.view") }}</div>

    <DataTable
      :items="itemsUsers"
      :fields="fieldsUsers"
      :loading="loading"
      :pagination="false"
    >
      <template #status="{item}">
        <td>
          <dot :color="getBadge(item.status)">
            {{
              $t(
                "enum." +
                  $store.state.enumerators.enumerators["user-status"][
                    item.status
                  ]
              )
            }}
          </dot>
        </td>
      </template>
      <template #button="{item}">
        <td class="py-2 text-right">
          <router-link
            class="button bg-secondary text-white mx-1"
            :to="{ path: '/users/view/' + item.id }"
            :title="$t('system.show')"
            ><font-awesome-icon icon="fa-solid fa-eye"
          /></router-link>
        </td>
      </template>
    </DataTable>

    <br /><br />

    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 id="segments" class="card-title mb-0">
              {{ $t("menu.segments") }}
            </h4>
            <div class="small text-muted">{{ $t("crud.view") }}</div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <br />

            <span v-if="itemsSegments.length === 0"
              ><CIcon size="xl" class="text-danger" name="cil-ban"
            /></span>
            <CTabs variant="tabs" :active-tab="0" v-else>
              <CTab
                v-for="(value, index) in itemsSegments"
                :key="index"
                :title="$t('lang.' + index)"
              >
                <br />

                <ul>
                  <li v-for="i in value" :key="i">
                    <small>(ID: {{ i }})</small> {{ treeName(index, i) }}
                  </li>
                </ul>
              </CTab>
            </CTabs>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import DataTable from "@/components/ui/DataTable";
import Dot from "@/components/ui/Dot";
import crud from "@/store/modules/crud.js";
import i18n from "@/i18n";
import Vue from "vue";

export default {
  name: "CompanyView",
  components: {
    DataTable,
    Dot
  },
  data() {
    return {
      loading: true,
      items: [],
      itemsUsers: [],
      itemsSegments: [],
      fields: [
        {
          key: "data",
          label: i18n.t("system.label"),
          sorter: false
        },
        {
          key: "value",
          label: i18n.t("system.value"),
          sorter: false
        }
      ],
      rows: [
        {
          key: "status",
          label: i18n.t("register.status"),
          value: this.$store.state.enumerators.enumerators["company-status"],
          valueType: "enumerator"
        },
        {
          key: "reg_number",
          label: i18n.t("register.reg_number")
        },
        {
          key: "vat_id",
          label: i18n.t("register.vat")
        },
        {
          key: "name",
          label: i18n.t("register.company_name")
        },
        {
          key: "street",
          label: i18n.t("register.street")
        },
        {
          key: "district",
          label: i18n.t("register.district")
        },
        {
          key: "city",
          label: i18n.t("register.city")
        },
        {
          key: "postal_code",
          label: i18n.t("register.zip")
        },
        {
          key: "country",
          label: i18n.t("register.country"),
          value: this.$store.state.enumerators.enumerators["country"],
          valueType: "enumerator"
        },
        {
          key: "users",
          label: i18n.t("menu.users"),
          valueType: "count"
        }
      ],
      fieldsUsers: [
        {
          key: "id",
          label: "ID",
          sorter: false
        },
        {
          key: "first_name",
          label: i18n.t("system.first_name"),
          sorter: false
        },
        {
          key: "last_name",
          label: i18n.t("system.last_name"),
          sorter: false
        },
        {
          key: "email",
          label: "E-mail",
          sorter: false
        },
        {
          key: "status",
          label: i18n.t("register.status"),
          sorter: false
        },
        {
          key: "button",
          label: "",
          sorter: false,
          _style: "min-width:200px;"
        }
      ],
      status: [],
      selectLanguage: [],
      treeItems: {},
      treeFlatItem: {},
      treeItemsName: {},
      treeIds: []
    };
  },
  methods: {
    getCompanyInfo() {
      let self = this;
      crud
        .get(
          "companies/" +
            this.$route.params.id +
            "?expand=users,googleCategories"
        )
        .then(function(response) {
          self.items.push(response.data);
          self.itemsUsers = response.data.users;
          self.itemsSegments.push(response.data.googleCategories);

          if (response.data.googleCategories) {
            self.treeItems = response.data.googleCategories;
            self.getTree();
          }

          self.loading = false;
        });
    },

    //set fletItem
    printObj(obj, lang) {
      for (let key in obj) {
        this.treeFlatItem[lang].push({
          id: obj[key].id,
          name: obj[key].text
        });

        if (obj[key].nodes) {
          this.printObj(obj[key].nodes, lang);
        }
      }
    },

    setTreeNames(lang) {
      let obj = this.treeItems[lang];

      for (let key in obj) {
        let search = this.searchInTree(this.treeFlatItem[lang], obj[key]);
        if (search) {
          this.treeItemsName[lang].push({ id: obj[key], name: search.name });
        }
      }
    },

    treeName(lang, id) {
      if (this.treeItemsName[lang])
        return this.treeItemsName[lang].find(el => el.id === id).name;
    },

    searchInTree(data, matchingTitle) {
      for (let i in data) {
        let element = data[i];
        if (element.id === matchingTitle) {
          return element;
        } else if (element.nodes) {
          let i;
          let result = null;
          for (i = 0; result == null && i < element.nodes.length; i++) {
            result = this.searchInTree(element.nodes[i], matchingTitle);
          }
          return result;
        }
      }
      return null;
    },

    getTree() {
      let self = this;

      //let langs = Object.keys(this.$store.state.enumerators.enumerators["language"]) //all items
      let langs = Object.keys(this.itemsSegments); //only user items

      for (let lang in langs) {
        crud
          .get("google-categories/tree?maxDepth=2&language=" + langs[lang])
          .then(function(response) {
            Vue.set(self.treeFlatItem, langs[lang], []);
            Vue.set(self.treeIds, langs[lang], response.data);
            Vue.set(self.treeItemsName, langs[lang], []);

            //create flat items with names
            self.printObj(response.data, langs[lang]);

            self.setTreeNames(langs[lang]);
          });
      }
    },

    getBadge(status) {
      switch (status) {
        case 10:
          return "success";
        case 8:
          return "info";
        case 9:
          return "warning";
        case 0:
          return "danger";
        default:
          return;
      }
    }
  },
  created() {
    this.getCompanyInfo();
  }
};
</script>

<style></style>
