import crud from "../store/modules/crud";
import i18n from "@/i18n";
import moment from 'moment'

export default {
  data() {
    return {
      wishlist_id: null,
      channel_id: null,
      item: {
        name: null,
        price: 0,
        channel: null,
        url: 'https://',
        detail: null,
        expectation: null,
        expiration: null
      },
      // comments: [],
      data: [],
      links: {},
      key: 0,
      meta: {},
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      tableHeaders: [
        {
          key: "name",
          translate: i18n.t("wishlist.name"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "url",
          translate: i18n.t("wishlist.url"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "price",
          translate: i18n.t("wishlist.price"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "expiration",
          translate: i18n.t("wishlist.expiration"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        }
      ]
    };
  },
  methods: {
    momentDate(date)
    {
        return moment(date).locale('sk').format('ll')
    },
    getChannel() {
      return new Promise((resolve, reject) => {
        crud.get(`wishlist/channel/${this.$route.params.id}`).then(resp => {
          resolve(resp.data)
        }).catch(e => {
          reject(e)
        })
      })
    },
    getWishlist(type, id = null) {
      id = (id !== null) ? id : this.$route.params.id
      return new Promise((resolve, reject) => {
        crud
          .get(`wishlist/${type}/${id}`)
          .then(resp => {
            this.item = resp.data;
            resolve(resp.data);
          })
          .catch(e => {
            console.log(e);
            reject(e);
          });
      });
    },
    update(event, type) {
      if (!event) {
        return;
      }
      crud
        .put(`wishlist/${type}/${this.$route.params.id}`, this.item)
        .then(resp => {
          this.item = resp.data;
          this.$router.push({path: `/${type}/wishlist/detail/${this.$route.params.id}`})
        })
        .catch(e => {
          console.log(e);
        });
    },
    deactivate() {
      this.item.active = false;
      crud
        .put(`wishlist/advertiser/${this.$route.params.id}`, this.item)
        .then(resp => {
          this.item = resp.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    getAll(type) {
      let page = this.query.page ? "?page=" + this.query.page : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";
      crud
        .get(`wishlist/${type}${page}${sort}`)
        .then(response => {
          this.data = response.data.items;
          this.links = response.data.links;
          this.meta = response.data.meta;
        })
        .catch(err => {
          console.log(err);
        });
    },
    create(event, type) {
      if (!event) {
        return;
      }

      crud
        .post(`wishlist/${type}`, this.item)
        .then(resp => {
          console.log(resp);
          this.$notify({
            type: "success",
            text: i18n.t("success.success")
          })
          this.$router.push({path: `/${type}/wishlist`})
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
