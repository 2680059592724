<template>
  <ad-form @submit="register">
    <div class="container py-5 ">
      <div class="row text-center">
        <div class="col">
          <h2>{{$t('register.registration')}}</h2>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-12 col-sm-8 col-md-6 col-lg-5">
          <p class="lead">
            {{ $t('register.lead_text') }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-12 col-sm-8 col-md-6 col-lg-5">
            <div class="row">
                <div class="col-12 col-md-6 mt-4">
                    <ad-input :rules="[rules.required]" v-model="registerUser.first_name" :placeholder="$t('system.first_name')" />
                </div>
                <div class="col-12 col-md-6 mt-4">
                    <ad-input :rules="[rules.required]" v-model="registerUser.last_name" :placeholder="$t('system.last_name')" />
                </div>
            </div>
            <div class="row mt-4">
                <div class="col">
                    <ad-input :rules="[rules.email, rules.required]" type="email" v-model="registerUser.email" :hasError="registerErrors.email.length > 0" :errorMessage="registerErrors.email[0]" :placeholder="$t('system.email')" />
                </div>
            </div>
            <div class="row mt-4">
                <div class="col">
                    <ad-input :rules="[rules.required, rules.password]" :placeholder="$t('register.password')" type="password" v-model="registerUser.password" class="mb-2" />
                    <div class="row" style="padding-left: 1em; display:block;">
                      <PasswordStrength
                        ref="passwordStrength"
                        :password-input="registerUser.password"
                        :password-confirm-input="registerAdditionalData.password_repeat"
                      ></PasswordStrength>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col">
                    <ad-input type="password" :rules="[() => registerAdditionalData.password_repeat == registerUser.password || $t('error.password-repeat-check'), rules.required]" v-model="registerAdditionalData.password_repeat" :placeholder="$t('register.password_confirmation')" />
                </div>
            </div>
            <div class="row mt-4">
              <div class="col"  >
                <input type="checkbox" v-model="registerUser.tos" class="mr-1" >
                <small v-html="$t('register.terms_text')"></small>
                <div v-if="registerErrors.tos === true" :class="{'ad-error-text' : registerErrors.tos === true}" style="font-size: 0.8em; padding-left: 1em;">{{$t('system.is-required')}}</div>
              </div>
            </div>
            <div class="row mt-4">
                <div class="col">
                    <ad-btn style="width:100%;" :title="$t('register.register')" type="submit" />
                </div>
            </div>
            <div class="row mt-4 text-center">
              <div class="col">
                <router-link to="/auth/sign-in">{{$t('login.login')}}</router-link> | 
                <router-link to="/auth/forgot-password">{{$t('login.forgot-password')}}</router-link> 
              </div>
            </div>
        </div>
      </div>
    </div>
  </ad-form>  
</template>

<script>
import AdInput from '../../components/ui/v_2/AdInput.vue';
import AdBtn from '../../components/ui/v_2/AdBtn.vue';
import AdForm from '../../components/ui/v_2/AdForm.vue'
import rules from '@/mixins/rules.js'
import auth from '@/mixins/auth.js'
import PasswordStrength from "@/components/ui/PasswordStrength";

// import crud from "@/store/modules/crud.js";
export default {
  mixins: [rules, auth],
  components: {
      AdInput,
      AdBtn,
      AdForm,
      PasswordStrength
  },
}
</script>

<style lang="scss" scoped>
h2 {
  font-weight: bold;
}
</style>