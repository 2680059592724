<template>
  <form @submit.prevent="submit">
    <slot name="error-header" v-if="!isFormValid">
      <div class="error-header col-12">{{$t('system.form_error')}}</div>
    </slot>

    <slot name="default">
    </slot>
  </form>
</template>

<script>
export default {
  name: "AdForm",
  props: {
    method: {
      type: String
    }
  },
  data() {
    return {
      componentsToUpdate: [],
      isFormValid: true
    };
  },
  updated() {
    this.componentsToUpdate = [];
  },
  methods: {
    validate() {
      // validate all found component to validate and returns validation state of whole form (true/false)
      var isScrolled = false;
      this.isFormValid = true;
      this.nestedComponentsValidate();
      this.componentsToUpdate.forEach(componentToValidate => {
        if (!componentToValidate.validate(componentToValidate.value)) {
          this.isFormValid = false;
          if (!isScrolled) {
            // scrolling to error inputs
            const headerOffset = 100;
            const elementPosition = componentToValidate.$parent.$el.getBoundingClientRect()
              .top;
            const offsetPosition =
              elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth"
            });
            isScrolled = true;
          }
        }
      });
      return this.isFormValid;
    },
    nestedComponentsValidate(
      components = [] // get all children components that can be validated recursively
    ) {
      if (components.length === 0) {
        components = this.$children;
      }

      components.forEach(component => {
        if (
          typeof component.validate === "function" &&
          component.validations != undefined
        ) {
          this.componentsToUpdate.push(component);
        }
        if (
          component.$children !== undefined &&
          component.$children.length > 0
        ) {
          this.nestedComponentsValidate(component.$children);
        }
      });
    },
    submit() {
      this.$emit("submit", this.validate());
    }
  }
};
</script>

<style>
.error-header{
  background-color: #F8D7DA;
  margin: 0.5em 0em 0.5em 0em;
  padding: 0.5em 0 0.5em 1em;
  border-radius: 4px;
}


</style>
