<template>
  <div>
    <div v-if="this.$store.state.auth.user" class="support-modal">
      <img
        class="support-modal-icon"
        @click="modal.visible = true"
        src="/svg/support_icon.svg"
        alt="AddMention"
      />
    </div>

    <Modal
      v-if="modal.visible"
      @close="modal.visible = false"
      @save="submitSupportModal()"
      color="bg-secondary"
      :title="$t('support.contact-us-button')"
      :button-text="$t('system.send')"
      class="text-left mt-5"
    >
      <div class="input-box bg-invert">
        <div class="row col-12 mt-4 mb-3">
          <div class="col-12 mb-5 text-center">
            {{ $t("support.subtitle") }}
          </div>

          <!-- Other requirements -->
          <div class="col-md-12">
            <textarea
              class="input-1"
              v-model="modal.message"
              rows="5"
              style="height: 5rem;"
            >
            </textarea>
            <label class="input-label">
              {{ $t("modal.type_your_message") }}
            </label>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/ui/Modal";
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";

export default {
  components: {
    Modal
  },

  data() {
    return {
      modal: {
        visible: false,
        message: null
      }
    };
  },

  mounted() {
    // console.log(
    //   this.$store.state.auth.user
    // );
  },

  methods: {
    ...mapActions({
      addNotification: "notifications/addNotification"
    }),
    submitSupportModal() {
      let self = this;

      let obj = {
        message: self.modal.message
      };

      if (this.modal.message) {
        crud
          .post("users/contact-form", obj)
          .then(function() {
            self.modal.visible = false;

            // Reset data
            self.modal.message = null;

            self.addNotification({
              type: "success",
              message: i18n.t("success.record-add")
            });
          })
          .catch(() => {
            self.addNotification({
              type: "danger",
              message: i18n.t("error.oops")
            });
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.support-modal {
  //position: fixed;
  z-index: 99999 !important;
  margin-bottom: 0.3rem;
  margin-left: 1.25rem;
  font-size: 3.75rem;
}

.support-modal-icon {
  color: #00a99d;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
}
</style>
