<template>
  <div class="col" :class="{'mt-4': !(contains_uppercase && contains_eight_characters) }">
    <div
      class=""
      style="flex:auto;"
      v-if="passwordInput.length > 0"
    >
    <div>
      <small>
          {{ $t("password.headline") }}:

      </small>

    </div>
    <div class="d-flex" style="justify-content:space-around; align-items: center; ">
      <div class="validation" :class="{'is_valid': contains_eight_characters}">
        <small>
          <font-awesome-icon icon="fa-solid fa-check" v-if="contains_eight_characters"></font-awesome-icon>
          <font-awesome-icon icon="fa-solid fa-xmark" v-else></font-awesome-icon>
          {{ $t("password.contains_eight") }}
        </small>
      </div>
      <div class="validation" :class="{'is_valid': contains_uppercase}">
        <small>
          <font-awesome-icon icon="fa-solid fa-check" v-if="contains_uppercase"></font-awesome-icon>
          <font-awesome-icon icon="fa-solid fa-xmark" v-else></font-awesome-icon>
          {{ $t("password.contains_upper") }}
        </small>
      </div>
      <div class="validation" :class="{'is_valid': contains_number}">
        <small>
          <font-awesome-icon icon="fa-solid fa-check" v-if="contains_number"></font-awesome-icon>
          <font-awesome-icon icon="fa-solid fa-xmark" v-else></font-awesome-icon>
          {{ $t("password.contains_number") }}
        </small>
      </div>

    </div>
    <!-- <ul>
        <li v-bind:class="{ is_valid: contains_eight_characters }">
          {{ $t("password.contains_eight") }}
        </li>
        <li v-bind:class="{ is_valid: contains_uppercase }">
          {{ $t("password.contains_upper") }}
        </li>
      </ul> -->

      <!-- <div
        class="checkmark_container"
        v-bind:class="{ show_checkmark: valid_password }"
      >
        <svg width="50%" height="50%" viewBox="0 0 140 100">
          <path
            class="checkmark"
            v-bind:class="{ checked: valid_password }"
            d="M10,50 l25,40 l95,-70"
          />
        </svg>
      </div> -->
    </div>
  </div>
</template>
<script>
import store from "@/store";

export default {
  props: {
    passwordInput: String,
    passwordConfirmInput: String
  },
  data() {
    return {
      password: null,
      password_confirm: null,
      password_length: 0,
      password_warning: false,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      //contains_special_character: false,
      contains_matched: false,
      valid_password: false
    };
  },
  mounted() {
    this.setPassInStore();

    if (!localStorage.getItem("isPasswordValid") == "true") {
      localStorage.setItem("isPasswordValid", this.valid_password);
    }
  },
  methods: {
    setPassInStore() {
      if (this.$store.state.validate.validated.password) {
        store.commit("add", { password: true });
      } else {
        store.commit("add", { password: false });
      }
    },
    checkPassword() {
      this.password_length = this.password.length;
      //eslint-disable-next-line
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.password_length >= 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      //this.contains_special_character = format.test(this.password);
      this.contains_matched =
        this.password === this.password_confirm ? true : false;

      if (
        this.contains_eight_characters === true &&
        //this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true &&
        this.contains_matched
      ) {
        store.commit("add", { password: true });
        this.valid_password = true;
      } else {
        store.commit("add", { password: false });
        this.valid_password = false;
      }
    }
  },
  watch: {
    passwordInput: function(val) {
      this.password = val;
      this.checkPassword();
    },
    passwordConfirmInput: function(val) {
      this.password_confirm = val;
      this.checkPassword();
    },
    valid_password: function() {
      this.$emit("valid-password", this.valid_password);
      localStorage.setItem("isPasswordValid", this.valid_password);
    }
  }
};
</script>
<style scoped>
.input_container {
  position: relative;
}

.input_container ul {
  /* flex-direction: row; */
  /* align-items: flex-start; */
  padding: 0 0 0 18px;
  margin: 0;
}

.validation {
  margin: 0 30px 0 0;
  margin-bottom: 0;
  font-size: 1rem;
  position: relative;
  display: inline-block;
  color: red;
}



/* Checkmark & Strikethrough --------- */

.is_valid {
  color: #00a99d;
}
/* .is_valid:before { */
  /* width: 100%; */
/* } */

/* .input_container .checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -7px;
  right: 0;
  background: #2ecc71;
  width: 30px;
  height: 30px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
} */

.input_container .checkmark_container.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.input_container .checkmark_container .checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.input_container .checkmark_container .checkmark.checked {
  animation: draw 0.5s ease forwards;
}

ul {
  list-style-type: none;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
