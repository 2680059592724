import OrderIndex from "../views/order/Index.vue";
import OrderComments from "../views/order/OrderComments";
import OrderDetail from "../views/order/OrderDetail";
import OrderStats from "../views/order/OrderStats";
//import i18n from "@/i18n";

export default [
  {
    data: {
      admin: [
        {
          path: "/:type/order/",
          // name:
          //   i18n.t("bid.bid") +
          //   ": " +
          //   i18n.t("crud.view") +
          //   ": " +
          //   i18n.t("bid.comments"),
          component: OrderIndex,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: "comments/:id",
              // name:
              //   i18n.t("bid.bid") +
              //   ": " +
              //   i18n.t("crud.view") +
              //   ": " +
              //   i18n.t("bid.comments"),
              component: OrderComments,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "detail/:id",
              // name:
              //   i18n.t("bid.bid") +
              //   ": " +
              //   i18n.t("crud.view") +
              //   ": " +
              //   i18n.t("bid.comments"),
              component: OrderDetail,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "stats/:id",
              // name:
              //   i18n.t("bid.bid") +
              //   ": " +
              //   i18n.t("crud.view") +
              //   ": " +
              //   i18n.t("bid.comments"),
              component: OrderStats,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
