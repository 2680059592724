<template>
  <div id="filter_overlay">
    <div id="filter_text">
      <div class="position-absolute" style="right: 2rem;">
        <font-awesome-icon
          icon="fa-solid fa-xmark"
          style="cursor: pointer;"
          @click="this.emitCloseAction"
        />
      </div>

      <h2>
        {{ $t("system.advanced-search") }}
      </h2>

      <p style="color: rgba(0, 0, 0, 0.75);">
        {{ $t("system.advanced-search-description") }}
      </p>

      <hr />

      <div>
        <p class="filter_title">
          {{ $t("modal.maximum_purchase_price") }}
        </p>

        <div class="row">
          <div class="col-12 col-md-9 my-auto">
            <range-slider
              class="filter_slider"
              :min="this.rangeSlider.maximum_purchase_price.minValue"
              :max="this.rangeSlider.maximum_purchase_price.maxValue"
              :step="this.rangeSlider.maximum_purchase_price.stepBy"
              v-model="rangeSlider.maximum_purchase_price.defaultValue"
            >
            </range-slider>

            <div
              class="mt-2"
              style="display: flex; justify-content: space-between; font-size: 15px; font-weight: 500;"
            >
              <div>
                {{ this.rangeSlider.maximum_purchase_price.minValue }} €
              </div>

              <div>
                {{ this.rangeSlider.maximum_purchase_price.maxValue }} €
              </div>
            </div>
          </div>

          <div class="col-12 col-md-3 mt-3 mt-md-0">
            <input
              type="text"
              v-model="this.rangeSlider.maximum_purchase_price.defaultValue"
              class="filter_input"
              disabled
            />
          </div>
        </div>
      </div>

<!--      <div class="mt-4">-->
<!--        <p class="filter_title">-->
<!--          {{ $t("modal.what_minimum_traffic_do_you_require") }}-->
<!--        </p>-->

<!--        <div class="row">-->
<!--          <div class="col-12 col-md-9 my-auto">-->
<!--            <range-slider-->
<!--              class="filter_slider"-->
<!--              :min="this.rangeSlider.what_minimum_traffic_do_you_require.minValue"-->
<!--              :max="this.rangeSlider.what_minimum_traffic_do_you_require.maxValue"-->
<!--              :step="this.rangeSlider.what_minimum_traffic_do_you_require.stepBy"-->
<!--              v-model="rangeSlider.what_minimum_traffic_do_you_require.defaultValue"-->
<!--            >-->
<!--            </range-slider>-->

<!--            <div-->
<!--              class="mt-2"-->
<!--              style="display: flex; justify-content: space-between; font-size: 15px; font-weight: 500;"-->
<!--            >-->
<!--              <div>-->
<!--                {{ $t("system.without-warranty") }}-->
<!--              </div>-->

<!--              <div>-->
<!--                {{this.rangeSlider.what_minimum_traffic_do_you_require.maxValue }}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-12 col-md-3 mt-3 mt-md-0">-->
<!--            <input-->
<!--              type="text"-->
<!--              v-model="this.rangeSlider.what_minimum_traffic_do_you_require.defaultValue"-->
<!--              class="filter_input"-->
<!--              disabled-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


      <div class="mt-5">
        <select-box
          :options="select2.categories.data"
          label="name"
          multiple
          v-model="select2.categories.selected"
          :placeholder="$t('system.category')"
        />
      </div>

<!--      <div class="mt-4">-->
<!--        <select-box-->
<!--          :options="select2.websites.data"-->
<!--          label="name"-->
<!--          multiple-->
<!--          v-model="select2.websites.selected"-->
<!--          :placeholder="$t('filter.url')"-->
<!--        />-->
<!--      </div>-->

      <div class="pl-auto mt-4 mb-3" style="z-index: 7;">
        <select-box
            :options="select2.tags.data"
            multiple
            label="word"
            v-model="select2.tags.selected"
            :placeholder="$t('filter.hashtags')"
        />
      </div>

<!--      <div class="pl-auto mt-4 mb-3">-->
<!--        <ad-select-->
<!--            :items="select2.published.data"-->
<!--            dropdownDirection="top"-->
<!--            item-value="code"-->
<!--            item-label="label"-->
<!--            v-model="select2.published.selected"-->
<!--            :placeholder="$t('filter.published')"-->
<!--            :multipleText="$t('filter.published')"-->
<!--        />-->
<!--      </div>-->

      <button class="filter_btn" @click="submitSearch">
        {{ $t("system.search") }}
      </button>
    </div>
  </div>
</template>

<script>
import crud from "@/store/modules/crud";
import RangeSlider from "vue-range-slider";
import "vue-range-slider/dist/vue-range-slider.css";
import SelectBox from "@/components/ui/fields/SelectBox.vue";
import i18n from "@/i18n";
export default {
  components: {
    SelectBox,
    RangeSlider,
  },

  props: {
    max_price: {
      type: Number,
      default: 0
    },
    url: {
      type: Array,
      default: null
    },
    segment: {
      type: Array,
      default: null
    },
    tag: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      rangeSlider: {
        maximum_purchase_price: {
          minValue: 0,
          maxValue: 1500,
          stepBy: 5,
          defaultValue: 0
        },
      },
      select2: {
        websites: {
          data: [],
          selected: null
        },
        categories: {
          data: [],
          selected: null
        },
        tags: {
          data: [],
          selected: null
        }
      },
      staticSegments: [],
      staticUrls: [],
      staticTags: []
    };
  },

  created() {
    this.getPriceParams();
    this.getUrls();
    this.getSegments();
    this.getTags();
  },

  methods: {
    emitCloseAction() {
      this.$emit("close-action");
    },
    filterFnSegments(val, update) {
      this.select2.categories.data = this.staticSegments;

      update(() => {
        this.select2.categories.data = this.staticSegments.filter(segment => {
          return segment.label.toLowerCase().includes(val.toLowerCase());
        });
        this.$forceUpdate();
      });
    },
    filterFnUrls(val, update) {
      this.select2.websites.data = this.staticUrls;

      update(() => {
        this.select2.websties.data = this.staticUrls.filter(url => {
          return url.label.toLowerCase().includes(val.toLowerCase());
        });
        this.$forceUpdate();
      });
    },

    filterFnTags(val, update) {
      this.select2.tags.data = this.staticTags;

      update(() => {
        this.select2.tags.data = this.staticTags.filter(tag => {
          return tag.word.toLowerCase().includes(val.toLowerCase());
        });
        this.$forceUpdate();
      });
    },

    submitSearch() {
      let data = {
        max_price: this.rangeSlider.maximum_purchase_price.defaultValue,
        url: this.select2.websites.selected,
        segment: this.select2.categories.data.filter(cat => this.select2.categories.selected && this.select2.categories.selected.includes(cat.id)),
        tag: this.select2.tags.data.filter(cat => this.select2.tags.selected && this.select2.tags.selected.includes(cat.id)),
      };

      this.$emit("filter-updated", data);

      this.$emit("close-action");
    },

    getUrls() {
      let self = this;

      crud.get("auction/domains/select").then(function(response) {
        response.data.forEach(value => {
          self.staticUrls.push({
            name: value.url.split("//")[1],
            id: value.id
          });
        });
        self.select2.websites.data = self.staticUrls;
      });
    },

    getSegments() {
      let self = this;

      crud.get("google-categories/select").then(function(response) {
        response.data.forEach(item => {
          self.staticSegments.push({
            name: i18n.t('category.'+item.title),
            id: item.id
          });
        });
        self.select2.categories.data = self.staticSegments;
      });
    },

    getPriceParams() {
      let self = this;

      crud.get("auction/min-max").then(function(response) {
        self.rangeSlider.maximum_purchase_price.minValue = response.data.min.split(".")[0];
        self.rangeSlider.maximum_purchase_price.maxValue = response.data.max.split(".")[0];
      });
    },

    getTags() {
      let self = this;

      crud.get("tags/select").then(function(response) {
        self.select2.tags.data = response.data.items;
        self.staticTags = self.select2.tags.data;
      });
    },
    //
    // getPublished() {
    //   this.select2.published.data = [
    //     {code: null, label: i18n.t("filter.all")},
    //     {code: 1, label: i18n.t("filter.published")},
    //     {code: 0, label: i18n.t("filter.not_published")}
    //   ];
    // }
  },

  watch: {
    max_price: function(val){
      this.rangeSlider.maximum_purchase_price.defaultValue = val;
    },
    url: function(val){
      this.select2.websites.selected = val;
    },
    segment: function(val){
      this.select2.categories.selected = val;
    },
    tag: function(val){
      this.select2.tags.selected = val;
    },
  }
};
</script>

<style lang="scss">
.filter_btn {
  border: 0;
  width: 100%;
  background: #00a490;
  color: white;
  font-size: 18px;
  border-radius: 2px;
  line-height: 48px;
  margin-top: 13px;
}

.filter_title {
  color: rgba(0, 0, 0, 0.75);
  font-weight: bold;
  font-size: 18px;
}

.filter_input {
  border: 1px solid rgba(206, 212, 218, 1);
  border-radius: 4px;
  width: 100%;
  padding: 1rem;
}

.filter_slider {
  width: 100% !important;
}

.range-slider-fill {
  background-color: rgba(0, 164, 144, 1);
}

.range-slider-rail {
  background-color: rgba(0, 164, 144, 0.45);
}

.range-slider-rail,
.range-slider-fill {
  height: 12px !important;
  border-radius: 100px !important;
}

.range-slider-knob {
  height: 32px;
  width: 32px;
  background-color: rgba(0, 164, 144, 1);
  border: 0;
}

#filter_overlay {
  font-family: "Roboto", sans-serif;
  position: fixed;
  // display: none;
  z-index: 999999 !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  overflow-y: scroll;
}

#filter_text {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  background: white;
  max-width: 34.9rem;
  width: 100%;
  color: black;
  padding: 2rem;

  @media (max-width: 991px) {
    max-width: 95% !important;
  }

  @media (max-width: 768px) {
    max-height: 90vh;
  }
}

//.vs__selected-options {
//  overflow: hidden !important;
//}
//
//.vs__selected {
//  background: 0;
//  border: 0;
//}
</style>
