<template>
  <div>
    <div class="ml-3 mb-4">
      <h1 class="text-left">
        <strong>{{ $t("menu.admin-offers") }}</strong>
      </h1>
      <p>{{ $t("auctions.please_use_advanced_search") }}</p>
    </div>

    <div class="row col-12">
      <div class="col-md-8 d-md-flex">
        <div class="ad-input-seach position-relative">
          <font-awesome-icon
            icon="fa-solid fa-magnifying-glass"
            class="position-absolute"
            id="font-awesome-icon"
          />

          <input
            type="text"
            class="ad-input-seach mr-3"
            :placeholder="$t('system.search_in_article_title')"
            v-model="searchAuctionName"
            @keyup.enter="searchByName()"
          />
        </div>

        <button
          @click="tableFilterEnabled = true"
          class="px-3 ad-btn-advanced-search"
        >
          <font-awesome-icon icon="fa-solid fa-sliders" class="mr-2" />
          {{ $t("system.advanced-search") }}
        </button>
      </div>
    </div>

    <div class="row ml-3 mt-3" style="margin-bottom: -3rem;">
      <!-- name -->
      <p
        v-if="this.selectedFilterOptions.search"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.search") }}

        <font-awesome-icon
          @click="resetCustomParam('search')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <!-- max_price -->
      <p
        v-if="this.selectedFilterOptions.max_price"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.max_price") }}

        <font-awesome-icon
          @click="resetCustomParam('max_price')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <!-- min_estimated_traffic -->
      <p
        v-if="this.selectedFilterOptions.min_estimated_traffic"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.min_estimated_traffic") }}

        <font-awesome-icon
          @click="resetCustomParam('min_estimated_traffic')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <!-- published -->
      <p
        v-if="this.selectedFilterOptions.published"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.published") }}

        <font-awesome-icon
          @click="resetCustomParam('published')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <!-- segment -->
      <p
        v-if="this.selectedFilterOptions.segment.length"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.segment") }}

        <font-awesome-icon
          @click="resetCustomParam('segment')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <!-- url -->
      <p
        v-if="this.selectedFilterOptions.url.length"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.url") }}

        <font-awesome-icon
          @click="resetCustomParam('url')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
    </div>

    <!-- v-on:params-updated="processEmit" -->
    <TableFilter
      v-if="tableFilterEnabled"
      v-on:close-action="tableFilterEnabled = false"
      v-on:filter-updated="processFilterOptions"
    />

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :tableConfig="this.tableConfig"
        :links="this.table.links"
        :meta="this.table.meta"
        :query="this.query"
        @showModal="openCompanyModal"
        @markAuction="markAuction"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>

    <Modal
      v-if="report.modal"
      @close="report.modal = false"
      @save="submitReportModal()"
      color="bg-secondary"
      :title="$t('modal.didnt_find_what_you_were_looking_for')"
      :button-text="$t('system.send')"
      class="text-left"
    >
      <div class="input-box bg-invert">
        <div class="row col-12 mt-4 mb-3">
          <div class="col-12 mb-5 text-center">
            {{ $t("modal.description") }}
          </div>
          <!-- Topic type -->
          <div class="col-md-6">
            <input
              type="text"
              v-model="report.details.what_topic_are_you_interested"
              class="input-1"
            />
            <label class="input-label">
              {{ $t("modal.what_topic_are_you_interested") }}
              <font-awesome-icon
                icon="fa-solid fa-star-of-life"
                style="color: red;"
              />
            </label>
          </div>

          <!-- Minimum traffic -->
          <div class="col-md-6 mt-5 mt-md-0">
            <input
              type="text"
              v-model="report.details.what_minimum_traffic_do_you_require"
              class="input-1"
            />
            <label class="input-label">
              {{ $t("modal.what_minimum_traffic_do_you_require") }}
              <font-awesome-icon
                icon="fa-solid fa-star-of-life"
                style="color: red;"
              />
            </label>
          </div>

          <!-- Price for opening article -->
          <div class="col-md-6 mt-5">
            <input
              type="text"
              v-model="report.details.price_for_opening_article"
              class="input-1"
            />
            <label class="input-label">
              {{ $t("modal.price_for_opening_article") }}
            </label>
          </div>

          <!-- Max price -->
          <div class="col-md-6 mt-5">
            <input
              type="text"
              v-model="report.details.maximum_purchase_price"
              class="input-1"
            />
            <label class="input-label">
              {{ $t("modal.maximum_purchase_price") }}
              <font-awesome-icon
                icon="fa-solid fa-star-of-life"
                style="color: red;"
              />
            </label>
          </div>

          <!-- Other requirements -->
          <div class="col-md-12 mt-5">
            <textarea
              class="input-1"
              v-model="report.details.other_requirements"
              rows="5"
            >
            </textarea>
            <label class="input-label">
              {{ $t("modal.other_requirements") }}
            </label>
          </div>
        </div>
      </div>
    </Modal>

    <Modal
      v-if="company.modal"
      @close="closeCompanyModal"
      @save="submitCompanyModal()"
      color="bg-secondary"
      :title="$t('company.submit-update-company')"
      :button-text="$t('system.send')"
      class="text-left"
    >
      <div class="input-box bg-invert">
        <div class="row col-12 mt-4 mb-3">
          <!-- Topic type -->
          <div class="col-12">
            <ad-select
                class="text-left"
                :items="companies"
                @filter="filterFnCompanies"
                autocomplete
                item-value="id"
                :key="updateKey"
                item-label="name"
                v-model="company.selected"
                :placeholder="$t('company.name')"
            />
          </div>

          
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/ui/Modal";
import TableFilter from "@/components/ui/v_2/TableFilter";
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import DataViewer from "@/components/ui/DataViewer";
import PreLoader from "@/components/ui/PreLoader";
import rules from "@/mixins/rules.js";
import AdSelect from "../../components/ui/v_2/AdSelect.vue";

export default {
  components: {
    Modal,
    DataViewer,
    PreLoader,
    AdSelect,
    TableFilter
  },

  mixins: [rules],

  data() {
    return {
      selectedFilterOptions: {
        search: null,
        max_price: null,
        min_estimated_traffic: null,
        url: [],
        segment: [],
        published: null
      },
      searchAuctionQuery: null,
      searchAuctionName: null,
      companies: [],
      companiesBackup:[],
      company: {
        modal: false,
        id: null,
        selected: null,
        auction_id: null,
      },
      report: {
        modal: false,
        details: {
          what_topic_are_you_interested: "",
          what_minimum_traffic_do_you_require: "",
          price_for_opening_article: "",
          maximum_purchase_price: "",
          other_requirements: ""
        }
      },
      tableFilterEnabled: false,
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      tableConfig: {
        col: 3,
        width: 50
      },
      table: [],
      tableHeaders: [
        {
            key: "actions",
            translate: i18n.t("system.actions"),
            sorter: false,
            style: null,
            hint: null,
            mobile: {
              class: "col-12"
            },
            buttons: [
              {
                    key: "action",
                    identifiers: ['id'],
                    emitName: 'markAuction',
                    translate: i18n.t("system.editors_pick"),
                    class: "border-0 bg-transparent  mx-1 py-1",
                    activeClass: 'text-danger',
                    icon: 'fa-solid fa-font-awesome',
                    onlyIcon: true,
                    isActive: 'editors_pick',
                    activeIcon: 'fa-solid fa-flag '
              },
          ]
        },
        {
          key: "id",
          translate: "ID",
          sorter: false,
          style: null
          // hint: i18n.t("system.id")
        },
        {
          key: "auction_name",
          translate: i18n.t("advertiser_table.article_name"),
          sorter: false,
          style: "width: 150px !important;",
          hint: null,
          href: {
            link: "/admin/offer-detail/",
            identifier: "id"
          },
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "created_at",
          translate: i18n.t("auctions.created_at"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "deleted_at",
          translate: i18n.t("auctions.deleted_at"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "publisher",
          translate: i18n.t("company.company_name"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
       
        {
          key: "actions",
          translate: i18n.t("system.actions"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          },
          buttons: [
            {
              key: "detail",
              link: "/admin/offer-detail/",
              identifier: "id",
              translate: i18n.t("system.detail"),
              class: "button bg-secondary text-white mx-1 px-3 px-4 py-2"
            },
            {
              key: "edit",
              link: "/admin/offer/edit/",
              identifier: "id",
              translate: i18n.t("system.edit"),
              class: "button bg-warning text-white mx-1 px-3 px-4 py-2"
            },
            {
              key: "delete",
              link: "/auction/",
              identifier: "id",
              emit: true,
              settings: {
                method: "delete",
                data: []
              },
              translate: i18n.t("system.delete"),
              class: "button bg-danger text-white mx-1 px-3 px-4 py-1"
            },
            {
              key: "action",
              identifiers: ['company_id', 'id'],
              emitName: 'showModal',
              translate: i18n.t("company.submit-update-company"),
              class: "button bg-warning text-white mx-1 px-3 px-4 py-1"
            },
           
          ]
        },
        {
          key: "activity",
          translate: i18n.t("activity.activity"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
      ],
      updateKey: 0
    };
  },

  mounted() {
    this.loadTable();
    this.getCompanies()
  },

  methods: {
    markAuction(value) {
      let action = {
        action: "/auction/toggle-editors-pick/" + value.id
      };
      crud
        .post(action.action)
        .then(() => {
          this.loadTable();
        })
        .catch(e => {
          console.log(e);
        });
      console.log(action);
    },
    submitCompanyModal()
    {
      crud.post(`/auction/${this.company.auction_id}/update-company`, {
        company_id: this.company.selected
      }).finally(()=> {
        this.loadTable();
      })
      this.closeCompanyModal()
    },
    getCompanies()
    {
      crud
        .get(`/companies/publishers/select`)
        .then(resp => {
          this.companies = resp.data.items;
          this.companiesBackup = resp.data.items;
        });
    },
    filterFnCompanies(val, update) {
        // filter segments
        this.companies = this.companiesBackup;
        update(() => {
          this.companies = this.companies.filter(company => {
            return company.name.toLowerCase().includes(val.toLowerCase());
          });
          this.$forceUpdate();
        });
      },
    ...mapActions({
      addNotification: "notifications/addNotification"
    }),
    closeCompanyModal()
    {
      this.company.id = null
      this.company.selected = null
      this.company.modal = false
    },
    openCompanyModal(value)
    {
      console.log(value)
      const company = this.companies.filter(c => {
        return c.id === value.company_id
      })
      console.log(company)
      this.company.selected = company[0].id
      this.company.id = value.company_id
      this.company.auction_id = value.id
      this.company.modal = true
      this.updateKey++
    },
    submitReportModal() {
      if (
        this.report.details.what_topic_are_you_interested &&
        this.report.details.what_minimum_traffic_do_you_require &&
        this.report.details.what_minimum_traffic_do_you_require
      ) {
        let self = this;

        let obj = {
          topic: self.report.details.what_topic_are_you_interested,
          minimum_traffic:
            self.report.details.what_minimum_traffic_do_you_require,
          price_per_click: self.report.details.price_for_opening_article,
          price: self.report.details.maximum_purchase_price,
          other: self.report.details.other_requirements
        };

        crud
          .post("wishlist", obj)
          .then(function() {
            self.report.modal = false;

            // Reset data
            self.report.details = {
              what_topic_are_you_interested: "",
              what_minimum_traffic_do_you_require: "",
              price_for_opening_article: "",
              maximum_purchase_price: "",
              other_requirements: ""
            };

            self.addNotification({
              type: "success",
              message: i18n.t("success.record-add")
            });
          })
          .catch(() => {
            self.addNotification({
              type: "danger",
              message: i18n.t("error.oops")
            });
          });
      }
    },
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "";
      let filter = this.generateFilterQuery() ? this.generateFilterQuery() : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud
        .get("auction" + page + filter + sort)
        .then(function(response) {
          // console.log(  Object.keys(response.data.items[0])  );

          response.data.items.forEach(item => {
            item.price ? (item.price = item.price.split(".")[0] + "€") : "";
            item.domain_name
              ? (item.domain_name = item.domain_name
                  .split("//")[1]
                  .replace("www.", ""))
              : "";
            item.price_per_click
              ? 
            (item.price_per_click = item.price_per_click + "€")
              : 
            "";

            // HTML title
            let shortedTitle =
              item.auction_name.length > 56
                ? item.auction_name.substring(0, 56) + " ..."
                : item.auction_name;

            let auctionName =
              '<div class="routerLinkRedesign" style="display: inline-flex;">' +
              '<div style="font-size: 1.25rem;" class="pr-3"><i class="fa-regular fa-file-lines"></i></div>' +
              '<div><span style="font-weight:600;">' +
              shortedTitle +
              "</span><br>" +
              item.domain_name +
              "</div>" +
              "</div>";

            item.auction_name = auctionName;

            // HTML date
            item.time_left
              ? (item.time_left =
                  item.time_left[0] +
                  '<br><span style="color:rgba(230, 81, 0, 1);font-weight:500;">' +
                  item.time_left[1] +
                  "</span>")
              : "";
          });

          self.table = response.data;
          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },
    processEmit(data) {
      console.log(data)
    
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      // if (data.filter) {
      //   this.query.filter = data.filter;

      //   this.query.page = 1;

      //   this.loadTable();
      // }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }
      if (data.action) {
        let self = this;
        if (Object.keys(data).length > 1) {
          self.performance.loading = true;

          let method = data.method;
          let action = data.action;

          delete data["method"];
          delete data["action"];

          let body = data;

          switch (method) {
            case "post":
              crud.post(action, body).then(() => {
                self.loadTable();
              });
              break;
            case "get":
              crud.get(action, body).then(() => {
                self.loadTable();
              });
              break;
            case "put":
              crud.put(action, body).then(() => {
                self.loadTable();
              });
              break;
            case "delete":
              console.log('deletujem', action, body)
              crud.delete(action).then(() => {
                self.loadTable();
              });
              break;
            default:
              return;
          }
        } else {
          self.performance.loading = true;
          console.log(data)
          crud.post(data.action).then(() => {
            self.loadTable();
          }).finally(() => {
            self.performance.loading = false;
          })
          
        }
      }
    },
    searchByName() {
      if (!this.searchAuctionName) return;

      this.selectedFilterOptions.search =
        "&filter[search]=" + this.searchAuctionName;

      this.loadTable();

      // let obj = {
      //   'filter': '&filter[search]=' + this.searchAuctionName
      // }

      // this.processEmit(obj);
    },
    processFilterOptions(data) {
      if (data.max_price > 0) {
        this.selectedFilterOptions.max_price =
          "&filter[max_price]=" + data.max_price;
      }

      if (data.min_estimated_traffic > 0) {
        this.selectedFilterOptions.min_estimated_traffic =
          "&filter[min_estimated_traffic]=" + data.min_estimated_traffic;
      }

      if (Array.isArray(data.url)) {
        this.selectedFilterOptions.url = "&filter[url]=" + data.url.toString();
      }

      if (Array.isArray(data.segment)) {
        this.selectedFilterOptions.segment = "&filter[segment]=" + data.segment.toString();
      }

      if (Number(data.published)) {
        this.selectedFilterOptions.published =
          "&filter[published]=" + data.published;
      }

      this.loadTable();
    },
    resetCustomParam(data) {
      this.selectedFilterOptions[data] = "";

      this.loadTable();
    },
    generateFilterQuery() {
      this.query.page = 1;

      let filter = Object.values(this.selectedFilterOptions)
        .join("")
        .toString();

      return filter;
    }
  }
};
</script>

<style lang="scss" scoped></style>
