var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vc-date-picker',{attrs:{"mode":_vm.mode,"available-dates":_vm.availableDates,"locale":_vm.locale,"update-on-input":"","is24hr":"","popover":{ placement: 'bottom', visibility: 'focus' }},on:{"input":_vm.inputEmit},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('div',{staticClass:"ad-input-main"},[_c('div',{key:_vm.refreshKey,staticClass:"ad-input-wrapper"},[(_vm.icon !== null)?_c('font-awesome-icon',{staticClass:"ad-icon-input",attrs:{"icon":_vm.icon}}):_vm._e(),_c('input',_vm._g({ref:"ad-input",staticClass:"ad-input",class:{
              bordered: true,
              standard: false,
              'ad-valid': !_vm.hasError && !_vm.error && !_vm.filter,
              'ad-error': _vm.error || _vm.hasError,
              'ad-filter': _vm.filter,
              'input-iconized': _vm.icon !== null
            },attrs:{"id":'ad-input-' + _vm.random,"placeholder":" "},domProps:{"value":inputValue}},inputEvents)),_c('label',{class:{ 'label-iconized': _vm.icon !== null },attrs:{"for":'ad-input-' + _vm.random}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.placeholder !== null),expression:"placeholder !== null"}]},[_vm._v(_vm._s(_vm.placeholder))])])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.error || _vm.hasError)?_c('div',{staticClass:"ad-input-error"},[_vm._v(" "+_vm._s(_vm.message || _vm.errorMessage)+" ")]):_vm._e()])],1)]}}]),model:{value:(_vm.internalData),callback:function ($$v) {_vm.internalData=$$v},expression:"internalData"}})
}
var staticRenderFns = []

export { render, staticRenderFns }