<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <h1 class="text-left">
          <strong>{{ $t("menu.translations") }}</strong>
        </h1>
      </div>
    </div>

    <Search class="mb-5" ref="search" v-bind:columns="this.search" />
    <div class="input-box row-md-4 row-sm-10 w-50">
      <select
        v-model="defaultLang"
        @change="setDefaultLang($event)"
        class="input-1"
        id="defaultLang"
        :disabled="
          !$options.filters.isSuperAdmin(this.$store.state.auth.user.roles)
        "
      >
        <option
          v-for="(value, index) in $store.state.enumerators.enumerators[
            'language'
          ]"
          :key="value"
          :value="index"
          >{{ value }}</option
        >
      </select>
      <label for="language" class="input-label">{{
        $t("register.language")
      }}</label>
    </div>
    <DataTable
      :items="items"
      :fields="fields"
      :page="this.page"
      :loading="loading"
      :sortBy="this.sortBy"
    >
      <template #category="{item}">
        <td v-if="item.category">
          {{ item.category }}
        </td>
      </template>
      <template #name="{item}">
        <td>
          {{ item.name }}
        </td>
      </template>
      <template #translation="{item}">
        <td>
          {{ item.translation }}
        </td>
      </template>
      <template #button="{item}">
        <td class="py-2 text-right">
          <a
            href
            @click.prevent="
              showModal = true;
              sendTranslation(item);
              getTranslation(selectedTranslation);
            "
            class="button bg-warning text-white mx-1"
            :title="$t('system.edit')"
            ><font-awesome-icon icon="fa-solid fa-pencil"
          /></a>
        </td>
      </template>
    </DataTable>
    <Modal
      v-if="showModal"
      @close="
        showModal = false;
        getTranslations();
      "
      @save="
        showModal = true;
        saveTranslation(selectedTranslation);
        getTranslations();
      "
      :title="$t('register.translationDetail')"
      color="bg-secondary"
      :buttonText="$t('system.save')"
    >
      <form>
        <div class="row justify-content-lg-center mb-3">
          <div class="input-box col-lg-5">
            <input
              disabled
              type="text"
              class="input-1 mb-3"
              v-model.lazy="selectedTranslation.category"
              id="category"
            />
            <label class="input-label" for="category">
              {{ $t("translations.category") }}
              <validate-form ref="last_name" />
            </label>
          </div>
          <div class="input-box col-lg-5">
            <input
              disabled
              type="text"
              class="input-1 mb-3"
              v-model.lazy="selectedTranslation.name"
              id="name"
            />
            <label class="input-label" for="name">
              {{ $t("translations.name") }}
              <validate-form ref="name" />
            </label>
          </div>
          <div class="input-box col-lg-10 bg-invert-white">
            <textarea
              class="input-1 text-area  mb-3"
              id="description"
              rows="3"
              v-model.lazy="selectedTranslation.description"
            ></textarea>
            <label class="input-label" for="description">
              {{ $t("system.description") }}
              <validate-form ref="description" />
            </label>
          </div>
        </div>
        <div class="row justify-content-lg-center mb-3">
          <div class="input-box col-lg-10 focus bg-invert-white">
            <select
              @change="
                getTranslation(selectedTranslation);
                getTranslations();
              "
              v-model.lazy="defaultLang"
              class="input-1 mb-3"
              id="language"
              :disabled="
                !$options.filters.isSuperAdmin(
                  this.$store.state.auth.user.roles
                )
              "
            >
              <option
                v-for="(value, index) in $store.state.enumerators.enumerators[
                  'language'
                ]"
                :key="value"
                :value="index"
                >{{ value }}</option
              >
            </select>
            <label for="language" class="input-label">{{
              $t("register.language")
            }}</label>
          </div>
          <div class="input-box col-lg-10 bg-invert-white">
            <textarea
              class="input-1 text-area"
              id="translation"
              rows="3"
              v-model.lazy="selectedTranslation.translation"
            ></textarea>
            <label class="input-label" for="translation">
              {{ $t("system.translation") }}
              <validate-form ref="translation" />
            </label>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>
<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import Search from "@/components/ui/Search";
import DataTable from "@/components/ui/DataTable";
import Modal from "@/components/ui/Modal";

export default {
  name: "Translations",
  components: {
    DataTable,
    Search,
    Modal
  },
  data() {
    return {
      defaultLang: "en-GB",
      showModal: false,
      loading: true,
      items: [],
      fields: [
        {
          key: "id",
          label: "ID",
          _style: "min-width:100px;",
          sorter: false
        },
        {
          key: "category",
          label: i18n.t("register.category"),
          _style: "min-width:100px;",
          sorter: false
        },
        {
          key: "name",
          label: i18n.t("system.name"),
          _style: "min-width:100px;",
          sorter: false
        },
        {
          key: "translation",
          label: i18n.t("system.translation"),
          _style: "min-width:100px;",
          sorter: false
        },
        {
          key: "button",
          label: "",
          sorter: false
        }
      ],
      page: {
        totalCount: 0,
        perPage: 0
      },
      sortBy: { column: "id", asc: true },
      search: [
        {
          name: "category",
          label: i18n.t("translations.category"),
          type: "text",
          operator: "and",
          like: true,
          likeFormat: "b"
        },
        {
          name: "name",
          label: i18n.t("translations.name"),
          type: "text",
          operator: "and",
          like: true,
          likeFormat: "b"
        },
        {
          name: "translation",
          label: i18n.t("system.translation"),
          type: "text",
          operator: "and",
          like: true,
          likeFormat: "b"
        }
      ]
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    pagination() {
      this.getTranslations();
    },

    sort() {
      this.getTranslations();
    },
    sendTranslation(item) {
      this.selectedTranslation = item;
    },
    setDefaultLang(event) {
      this.defaultLang = event.target.value;
    },
    saveTranslation() {
      let self = this;
      let updatedSelectedTranslation = {
        id: this.selectedTranslation.id,
        translation_id: this.selectedTranslation.translation_id,
        translation: this.selectedTranslation.translation,
        description: this.selectedTranslation.description,
        lang: this.defaultLang
      };

      crud
        .post(
          "lang/translation/" + this.selectedTranslation.translation_id,
          updatedSelectedTranslation
        )
        .then(function(response) {
          if (response.status >= 200 && response.status < 300) {
            self.addNotification(
              {
                type: "success",
                message: i18n.t("success.record-updated")
              },
              { root: true }
            );
          } else {
            self.addNotification({
              type: "danger",
              message: i18n.t("error.oops")
            });
          }
        })
        .catch(error => {
          self.addNotification({
            type: "danger",
            message:
              i18n.t("error.oops") + "-" + JSON.stringify(error.data.message)
          });
        });
    },
    getTranslation(defaultSelectedTranslation) {
      let self = this;

      crud
        .get(
          "/lang/" +
            self.defaultLang +
            "/translation/" +
            self.selectedTranslation.translation_id
        )
        .then(function(response) {
          self.loading = false;
          self.selectedTranslation.translation_id =
            response.data[0].translation_id;
          if (response.data[0].id === null) {
            self.selectedTranslation.id = null;
          } else {
            self.selectedTranslation.id = response.data[0].id;
          }
          self.selectedTranslation.category = response.data[0].category;
          self.selectedTranslation.name = response.data[0].name;
          self.selectedTranslation.translation = response.data[0].translation;
          self.selectedTranslation.description = response.data[0].description;
        });

      self.selectedTranslation = defaultSelectedTranslation;
    },
    getTranslations(onload = false) {
      let self = this;

      let query = this.$refs.search.parseUrlQuery(onload, this.sortBy);

      if (!onload) {
        crud.get("lang" + query).then(function(response) {
          //get data with deleted or without

          self.items = response.data.items;
          self.page.totalCount = response.data.meta.total;
          self.page.perPage = response.data.meta.per_page;

          self.loading = false;
        });
      }
      this.loading = false;
    }
  },
  mounted() {
    this.getTranslations(true);
  }
};
</script>
