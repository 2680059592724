<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-left mb-0">
          <strong>{{ $t("roles.role") }}</strong>
        </h1>
        <div class="small text-muted pl-1">{{ $t("crud.view") }}</div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-sm-6">
        <p>
          <strong>{{ $t("roles.roleName") }}</strong
          >: {{ roles.description }}
        </p>
      </div>
      <div class="col-sm-6 text-right">
        <input type="checkbox" id="selectAll" v-model="selectAll" />
        <label for="selectAll" class="ml-2" role="button">{{
          $t("system.select-all")
        }}</label>
        <small>|</small>
        <span @click="resetAll" role="button">{{
          $t("system.reset-all")
        }}</span>
      </div>
    </div>

    <form @submit.prevent="submit">
      <DataTable
        :items="permissions"
        :fields="fields"
        :loading="loading"
        :pagination="false"
      >
        <template #name="{item, index}">
          <td>
            <input
              type="checkbox"
              :value="item.name"
              v-model="permissions[index].checked"
              :id="item.name"
              role="button"
            />
            <label :for="item.name" class="ml-2" role="button">{{
              item.name
            }}</label>
          </td>
        </template>
      </DataTable>

      <button type="success" class="button ml-0">
        {{ $t("system.update") }}
      </button>
      <ButtonSpinner v-if="isSubmitting" class="ml-0" />

      <router-link
        class="button bg-light mr-0 my-2 d-inline-block"
        to="/users/roles"
      >
        {{ $t("system.cancel") }}
      </router-link>
    </form>
  </div>
</template>

<script>
import DataTable from "@/components/ui/DataTable";
import ButtonSpinner from "@/components/ui/ButtonSpinner";
import crud from "@/store/modules/crud.js";
import i18n from "@/i18n";
import { mapActions } from "vuex";

export default {
  name: "RoleView",
  components: {
    DataTable,
    ButtonSpinner
  },
  data() {
    return {
      loading: true,
      selected: false,
      roles: [],
      fields: [
        {
          key: "name",
          label: i18n.t("permissions.permissionName"),
          sorter: false
        }
      ],
      permissions: [],
      isSubmitting: false
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    resetAll() {
      this.getAllPermissions();
    },

    getRoleInfo() {
      var self = this;
      crud
        .get("auth-roles/" + this.$route.params.name)
        .then(function(response) {
          self.roles = response.data;
        });
    },
    getAllPermissions() {
      let self = this;

      crud.get("permissions").then(function(response) {
        let res = [];

        for (let i = 0; i < response.data.length; i++) {
          res.push({
            name: response.data[i].name,
            checked: false
          });
        }

        self.permissions = res;
        self.setCurrentPermissions();
        self.loading = false;
      });
    },
    setCurrentPermissions() {
      let self = this;
      crud
        .get("auth-roles/" + this.$route.params.name + "?expand=permissions")
        .then(function(response) {
          let permissions = self.permissions;
          let current = response.data.permissions;

          for (let i = 0; i < permissions.length; i++) {
            for (let j = 0; j < current.length; j++) {
              if (current[j].name === permissions[i].name) {
                self.permissions[i].checked = true;
              }
            }
          }

          self.loading = false;
        });
    },
    submit() {
      this.isSubmitting = true;

      let listOfObjects = Object.keys(this.permissions)
        .map(key => {
          if (this.permissions[key].checked === true)
            return this.permissions[key].name;
        })
        .filter(anyValue => typeof anyValue !== "undefined");

      if (listOfObjects) {
        let data = {};
        data.role = String(this.roles.name);
        data.permissions = listOfObjects;

        crud
          //.post("auth-role-assigns?deleteOld=true", data)
          .post("auth-role-assigns", data)
          .then(() => {
            this.isSubmitting = false;
            this.$router.push("/users/roles");
            this.addNotification(
              {
                type: "success",
                message: i18n.t("success.record-updated")
              },
              { root: true }
            );
          })
          .catch(error => {
            this.isSubmitting = false;
            this.addNotification({
              type: "danger",
              message: error.message
            });
          });
      }
    }
  },
  computed: {
    selectAll: {
      get: function() {
        return this.selected ? true : false;
      },
      set: function(value) {
        if (value) {
          for (let i = 0; i < this.permissions.length; i++) {
            this.permissions[i].checked = true;
          }
        } else {
          for (let i = 0; i < this.permissions.length; i++) {
            this.permissions[i].checked = false;
          }
        }
      }
    }
  },
  created() {
    this.getRoleInfo();
    this.getAllPermissions();
  }
};
</script>

<style></style>
