//Views Support
import Support from "../views/support/SupportIndex.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/support",
          meta: {
            label: "menu.support"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/support",
              name: "menu.support",
              component: Support,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
