<template>
  <div>
    <!-- Bell icon -->
    <span @click="openBell()" class="ad-pointer">
      <div v-if="bell.count > 0" style="position: relative;">
        <div class="notification-count font-xs">
          {{ bell.count }}
        </div>
      </div>
      <font-awesome-icon
        class="mr-md-4 mr-md-2"
        icon="fa-regular fa-bell"
        size="lg"
      />
    </span>

    <!-- Notification list -->
    <div v-if="openedNotificationList" class="notification-list" ref="notification-list">
      <ul v-if="!initLoading" v-click-outside="onClickOutside">
        <li
          v-if="bell.count < 1 && bell.notifications.length < 1"
          style="color: black"
        >
          {{ $t("system.total-count") }} (0)
        </li>

        <template v-else>
          <li 
            v-for="notification in bell.notifications"
            :key="notification.id"
            class="notification-message"
          >
            <main class="d-flex" style="justify-content: space-between; overflow: hidden;">
              <div
                @click="redirectToNotification(notification)"
                style="cursor: pointer;"
              >
                {{ notification.message }}
              </div>

              <div>
                <font-awesome-icon 
                  v-if="!notification.read_at"
                  icon="fa-solid fa-circle" 
                  class="mt-4" 
                  style="font-size: 0.5rem; color: rgba(0, 164, 144, 0.85);" 
                />
              </div>
            </main>

            <small class="notification-time">
              {{ $t("system.change") }}
              {{ notification.created_by }}
              {{ notification.created_at }}
            </small>
          </li>

          <!-- No more notifications -->
          <li v-if="nextUrl === null" class="text-center">
            <hr />

            <svg class="svg-icon__no_more_icons" viewBox="0 0 20 20">
              <path
                d="M10.219,1.688c-4.471,0-8.094,3.623-8.094,8.094s3.623,8.094,8.094,8.094s8.094-3.623,8.094-8.094S14.689,1.688,10.219,1.688 M10.219,17.022c-3.994,0-7.242-3.247-7.242-7.241c0-3.994,3.248-7.242,7.242-7.242c3.994,0,7.241,3.248,7.241,7.242C17.46,13.775,14.213,17.022,10.219,17.022 M15.099,7.03c-0.167-0.167-0.438-0.167-0.604,0.002L9.062,12.48l-2.269-2.277c-0.166-0.167-0.437-0.167-0.603,0c-0.166,0.166-0.168,0.437-0.002,0.603l2.573,2.578c0.079,0.08,0.188,0.125,0.3,0.125s0.222-0.045,0.303-0.125l5.736-5.751C15.268,7.466,15.265,7.196,15.099,7.03"
              ></path>
            </svg>

            <br />

            <span style="color: black">
              {{ $t("system.no-more-notifications") }}
            </span>
          </li>
        </template>
      </ul>

      <ul v-else style="color: black">
        {{
          $t("system.loading")
        }}
        ...
      </ul>
      <ul v-if="loading" style="color: black">
        {{
          $t("system.loading")
        }}
        ...
      </ul>
      <div class="sentinel" ref="sentinel"></div>

    </div>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import Vue from "vue";
import store from "@/store";
import {mapState} from "vuex";

// onClickOutside
Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = event => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  }
});

export default {
  data() {
    return {
      notifications: [],
      nextUrl: null,
      pageCount: 15,

      openedNotificationList: false,
      notificationsCount: 0,
      loading: false,
      initLoading: false,
    };
  },


  mounted() {
    store.dispatch('bell/refreshNotificationsCount');

    window.Echo.private(`App.Models.User.${this.$store.getters["auth/user"].id}`)
        .notification((notification) => {
          store.commit('bell/setCount', notification.count);
        });
  },

  methods: {
    openBell() {
      this.openedNotificationList = !this.openedNotificationList;

      this.initLoading = true;

      // Set notifications as seen
      this.getNotifications();

      //wait for initial list to render and then set up observer
      this.$nextTick().then(() => {
        this.setUpInterSectionObserver();
      });
    },

    destroyed() {
      if (this.listEndObserver) {
        this.listEndObserver.disconnect();
      }
    },

    setUpInterSectionObserver() {
      let options = {
        root: this.$refs["notification-list"],
        margin: "1px",
      };
      this.listEndObserver = new IntersectionObserver(
          this.handleIntersection,
          options
      );

      if (this.$refs['notification-list']) {
        this.listEndObserver.observe(this.$refs["sentinel"]);
      }
    },

    handleIntersection([entry]) {

      if (entry.isIntersecting && this.nextUrl !== null && this.loading === false) {
        this.loading = true;
        this.loadMore();
      }
    },

    loadMore() {
      let self = this;

      crud.get(this.nextUrl).then(function(response) {
        store.commit('bell/addNotificationsData', response.data);

        self.nextUrl = response.data.links.next;
        self.loading = false;
      });
    },

    removeNotification(id) {
      let self = this;

      crud.delete("notification/" + id).then(function() {
        self.getNotifications();
      });
    },

    getNotificationsCount() {
      let self = this;

      crud.get("notification/count").then(function(response) {
        self.notificationsCount = response.data;
      });
    },

    getNotifications() {
      let self = this;

      crud.get("notification").then(function(response) {
        store.commit('bell/setNotificationsData', response.data);

        self.nextUrl = response.data.links.next;
        self.initLoading = false;
      });
    },

    onClickOutside() {
      this.destroyed();
      this.openedNotificationList = false;
    },
    redirectToNotification(data) {
      // Set notification as read
      crud.post(`notification/${data.id}`);

      switch (data.type) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
        case 18:
        case 21:
          this.$router.push(`/${data.role}/order/comments/${data.entity_id}`);
          break;
        case 5:
          this.$router.push("/users");
          break;
        case 6:
          this.$router.push("/finance/transaction");
          break;
        case 17:
          this.$router.push(`/${data.role}/wishlist/conversation/${data.entity_id}`);
          break;
        default:
          return;
      }

      this.openBell();
    }
  },

  computed: {
    ...mapState({
      bell: "bell"
    }),

    userMode: function() {
      return localStorage.view;
    }
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  background: transparent;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  border-radius: 10px;
  background: #cccccc;
}

.c-header-dark {
  .svg-icon__bell {
    fill: rgba(255, 255, 255, 0.75);
  }
}

.c-header-light {
  .svg-icon__bell {
    fill: rgba(0, 0, 21, 0.5);
  }
}

.svg-icon__bell {
  cursor: pointer;
  transition: 0.24s;
  width: 1.5rem;
  height: 1.5rem;

  &:hover {
    fill: #00a99d;
  }
}

.svg-icon__close {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  fill: #a9000d;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.notification-count {
  position: absolute;
  left: 1rem;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  border-radius: 50%;
  background: red;
  color: white;
}

.notification-list {
  z-index: 9999;
  width: 100%;
  max-width: 23.5rem;
  max-height: 22.6rem;
  overflow-y: scroll;
  padding: 1rem 0rem;
  background: white;
  border-radius: 8px;
  position: absolute;
  top: 4rem;
  right: 12rem;

  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);

  @media (max-width: 768px) {
    right: 4rem;
    top: 3rem;
  }

  @media (max-width: 500px) {
    min-width: 20rem;
    max-width: 42.5vh;
    right: 1rem;
    top: 4rem;
  }

  @media (max-width: 359px) {
    max-width: 18rem;
    right: 1rem;
    top: 3rem;
  }

  color: white;
}

.notification-message {
  padding: 0.5rem 1.5rem;
  justify-content: space-between;
  background: white;
  align-items: center;
  transition: 0.24s;

  color: rgba(0, 0, 0, 0.85);
  font-size: 0.875rem;
  line-height: 1.3rem;

  &:hover {
    background: #f0faf8;
  }
}

.notification-time {
  color: rgba(0, 164, 144, 1);
}

.svg-icon__no_more_icons {
  margin-bottom: 0.25rem;
  fill: green;
  width: 2.5rem;
  height: 2.5rem;
}

.sentinel {
  height: 1px;
}
</style>
