<template>
  <div class="create-page">
    <div class="section-header">
      <h3>{{ $t("offer.offer-create") }}</h3>
    </div>
    <ad-form @submit="createOffer">
      <div class="row">
        <div class="col-12 ">
          <section id="default-informations">
            <div class="row">
              <div class="col-12 col-md-6 mt-4">
                <ad-input
                  id="article-name"
                  v-model="offer.main.name"
                  :rules="[rules.required]"
                  :placeholder="$t('offer.name')"
                />
              </div>
              <div class="col-12 col-md-6 mt-4">
                <ad-input
                  v-model="offer.main.domain_url"
                  :rules="[rules.required, rules.url]"
                  :placeholder="$t('auctions.article-domain-url')"
                />
              </div>
<!--              <div class="col-12 col-md-4 mt-4">-->
<!--                <ad-input-->
<!--                  v-model="offer.main.estimated_traffic"-->
<!--                  type="number"-->
<!--                  :rules="[rules.number, rules.required]"-->
<!--                  :placeholder="$t('auctions.estimated_traffic')"-->
<!--                />-->
<!--              </div>-->
            </div>
<!--            <div class="row">-->
<!--              <div class="col-12 mt-4">-->
<!--                <ad-toggle-->
<!--                  :text="$t('auctions.article-published')"-->
<!--                  v-model="offer.main.published"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--            <transition name="fade" mode="out-in">-->
<!--              <div class="row" v-if="!offer.main.published" key="isnt_published">-->
<!--                <div class="col-12 col-sm-6 col-md-4 mt-4">-->
<!--                  <ad-date-->
<!--                      v-model="offer.not_published.start_date"-->
<!--                      icon="fa-solid fa-hourglass-start"-->
<!--                      :placeholder="$t('auctions.start-date')"-->
<!--                  />-->
<!--                </div>-->
<!--                <div class="col-12 col-sm-6 col-md-4 mt-4">-->
<!--                  <ad-date-->
<!--                      v-model="offer.not_published.end_date"-->
<!--                      icon="fa-solid fa-hourglass-end"-->
<!--                      :placeholder="$t('auctions.end-date')"-->
<!--                  />-->
<!--                </div>-->
<!--                <div class="col-12 col-sm-6 col-md-4 mt-4">-->
<!--                  <ad-date-->
<!--                      v-model="offer.not_published.publish_date"-->
<!--                      :placeholder="$t('auctions.publish-date')"-->
<!--                      :rules="[rules.required]"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row" v-else key="is_published">-->
<!--                <div class="col-12 col-md-6 mt-4">-->
<!--                  <ad-input-->
<!--                    v-model="offer.published.url"-->
<!--                    :rules="[rules.url, rules.required]"-->
<!--                    :placeholder="$t('auctions.url')"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </transition>-->

            <div class="row">
              <div v-if="store.getters['auth/isSuperAdmin']" class="col-12 mt-4">
                <select-box
                    :options="searchedCompanies"
                    :rules="[rules.required]"
                    label="name"
                    v-model="offer.main.company_id"
                    :placeholder="$t('company.name')"
                />
              </div>
              <div class="col-12 col-md-6 mt-4">
                <select-box
                  :options="segmentItems"
                  :rules="[rules.required]"
                  label="text"
                  v-model="offer.main.google_category_id"
                  :placeholder="$t('auctions.selected-segments')"
                />
              </div>
              <div class="col-12 col-md-6 mt-4">
                <select-box
                  :options="keywordsSearchedItems"
                  multiple
                  :taggable="true"
                  label="word"
                  v-model="offer.main.tags"
                  :placeholder="$t('offer.reserved-keywords')"
                />
              </div>
            </div>
          </section>
          <section id="products" >
            <div class="section-header mt-4">
              <h3 class="mb-0">{{ $t("products.products") }}</h3>
            </div>
            <div class="row" :class="{'error-products': offer.main.products.length < 1 && productsError}">
              <div class="col-12 col-md-3 col-sm-6 mt-4">
                <ad-product-card
                  :type="1"
                  :countOfViews="computedTraffic"
                  :text="$t('products.text-mention')"
                  operation="create"
                  v-model="offer.main.products[0]"
                />
              </div>
              <div class="col-12 col-md-3 col-sm-6 mt-4">
                <ad-product-card
                  :type="2"
                  :countOfViews="computedTraffic"
                  :text="$t('products.text-statement')"
                  operation="create"
                  v-model="offer.main.products[1]"
                />
              </div>
              <div class="col-12 col-md-3 col-sm-6 mt-4">
                <ad-product-card
                  :type="3"
                  :countOfViews="computedTraffic"
                  :text="$t('products.text-image')"
                  operation="create"
                  v-model="offer.main.products[2]"
                />
              </div>
              <div class="col-12 col-md-3 col-sm-6 mt-4">
                <ad-product-card
                  :type="4"
                  :countOfViews="computedTraffic"
                  :text="$t('products.text-button')"
                  operation="create"
                  v-model="offer.main.products[3]"
                />
              </div>
            </div>
            <div class="row" v-if="offer.main.products.length < 1 && productsError">
              <div class="col text-danger">{{$t('system.is-required')}}</div>
            </div>
          </section>
          <section id="attachments">
            <div class="section-header mt-4">
              <h3 class="mb-0">{{ $t("offer.attachments") }}</h3>
            </div>
            <div class="row">
              <div class="col mt-4">
                <base-uploader
                  :value="offer.main.attachments"
                  @uploaded="setAttachments($event)"
                  @remove="removeAttachment($event)"
                  class="w-100"
                  api-url="attachment"
                  type="grid"
                  :maxFileSize="6000000"
                />
              </div>
            </div>
          </section>
          <section id="summary">
            <div class="section-header mt-4">
              <h3 class="mb-0">{{ $t("offer.summary") }}</h3>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <ad-vue-editor
                  v-model="offer.main.summary"
                  :rules="[rules.required]"
                  :editorToolbar="editorToolbar"
                />
              </div>
            </div>
          </section>
          <section id="detail">
            <div class="section-header mt-4">
              <h3 class="mb-0">{{ $t("offer.details") }}</h3>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <ad-vue-editor
                  v-model="offer.main.detail"
                  :editorToolbar="editorToolbar"
                />
              </div>
            </div>
          </section>
          <section id="about_us">
            <div class="section-header mt-4">
              <h3 class="mb-0">{{ $t("offer.promo") }}</h3>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <ad-vue-editor
                  v-model="offer.main.about_us"
                  :editorToolbar="editorToolbar"
                />
              </div>
            </div>
          </section>
          <div class="row mr-4 mr-md-0">
            <div class="ml-auto__mobile col-2 mt-4">
              <ad-btn
                :title="$t('system.back')"
                type="submit"
                @click="createOffer"
              />
            </div>
            <div class="ml-auto__desktop col-2 mt-4 ml-5 ml-md-0">
              <ad-btn :title="$t('system.save')" />
            </div>
          </div>
        </div>
      </div>
    </ad-form>
  </div>
</template>

<script>
import AdInput from "../../../components/ui/v_2/AdInput.vue";
import AdForm from "../../../components/ui/v_2/AdForm.vue";
import AdBtn from "../../../components/ui/v_2/AdBtn.vue";
import AdProductCard from "../../../components/ui/v_2/products/AdProductCard.vue";
import BaseUploader from "../../../components/attachments/BaseUploader.vue";
import AdVueEditor from '../../../components/ui/v_2/AdVueEditor.vue'
import rules from "../../../mixins/rules.js";
import offer from "../../../mixins/offer.js";
import crud from "../../../store/modules/crud";
import { mapActions } from "vuex";
import store from "@/store";
import SelectBox from "@/components/ui/fields/SelectBox.vue";
import i18n from "@/i18n";
// import i18n from "@/i18n";
export default {
  components: {
    AdInput,
    AdForm,
    AdProductCard,
    BaseUploader,
    AdVueEditor,
    AdBtn,
    SelectBox
  },
  name: "Create",
  mixins: [rules, offer],
  data() {
    return {
      textarea: "",
      error: false,
      segmentItems: [],
      updateKey: 0,
      searchedItems: [],
      keywordsItems: [],
      keywordsSearchedItems: [],
      keywordsSearchedItemsBackup: [],
      searchedCompanies: [],
      companies: [],
      editorToolbar: [
        ["bold"],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ list: "bullet" }]
      ],
      productsError: false
    };
  },
  created() {
    crud
      .get(`/google-categories/tree?language=${this.locale}&maxDepth=1000`)
      .then(resp => {
        let self = this;
        resp.data.forEach(item => {
          self.segmentItems.push({
            text: i18n.t('category.'+item.text),
            id: item.id
          });
        });

      });
    if(store.getters['auth/isSuperAdmin']) {
      crud.get(`/companies/publishers/select`).then(resp => {
        this.searchedCompanies = resp.data.items;
        this.companies = resp.data.items;
      })
    }
    crud
      .get(`/tags/all?language=${this.locale}`)
      .then(resp => {
        this.keywordsItems = resp.data.items;
        this.keywordsSearchedItems = resp.data.items;
      });
  },
  mounted () {
    if (this.isEdit) {
      crud
        .get(`/auction/${this.role}/` + this.$route.params.id)
        .then(response => {
          let data = response.data.items;

          this.getOnlyExistingAttributes(this.offer.main, data)
          this.getOnlyExistingAttributes(this.offer.admin, data)
          this.offer.main.tags = data.tags.map(tag => tag.id);
          this.offer.main.google_category_id = data.category.id;

          this.offer.main.products = {};

          data.products.forEach( (product) => {
            product.price = product.publisher_price;
            this.offer.main.products[product.type-1] = product;
          });
        });
    }
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    createOffer(validated) {
      if (!this.checkProducts()) {
        this.productsError = true
      }

      if (!validated) return;

      // Remove undefined from product object
      Object.keys(this.offer.main.products).forEach(key =>
          (this.offer.main.products[key] === undefined || this.offer.main.products[key] === null) && delete this.offer.main.products[key]);

      const offerToCreate = this.offer.main;

      offerToCreate.google_category_id = this.offer.main.google_category_id;

      offerToCreate.attachments = this.offer.main.attachments.map((val) => val.id);

      if (this.isEdit) {

        crud.put(`auction/${this.$route.params.id}`, offerToCreate).then(() => {
          this.$router.push({ path: `/${this.role}/offers` });
        });

      } else {

        crud.post("auction", offerToCreate).then(() => {
          this.$router.push({ path: `/${this.role}/offers` });
        })
        .catch(error => {
          this.addNotification({
            type: "danger",
            message: error.data.message
          });
        });

      }
    },
    checkProducts() {
      return this.offer.main.products.length > 0;
    },

    setAttachments(attachments) {
      // set attachments to offer
      attachments.forEach(attachment => {
        this.offer.main.attachments.push(attachment);
      });
    },

    removeAttachment(id) {
      this.offer.main.attachments = this.offer.main.attachments.filter((val) => id !== val.id);
    }
  },
  computed: {
    store() {
      return store;
    },
    locale: function() {
      return localStorage.getItem("locale");
    },
    computedTraffic() {
      return parseInt(this.offer.main.estimated_traffic)
    },
    isEdit() {
      let path = window.location.pathname;
      let pathParts = path.split('/');
      pathParts.pop();

      return pathParts[pathParts.length-1] == "edit";
    },
    role() {
      return store.getters['auth/isSuperAdmin'] ? 'admin' : 'publisher';
    }
  }
};
</script>

<style lang="scss" scoped>
.error-products{
  border: 1px solid red;
}

.ml-auto__mobile {

  @media (max-width: 768px) {
    margin-left: auto !important;
  }
}
.create-page {
  max-width: 1800px;
}

.ml-auto__desktop {
  margin-left: 2rem;
  margin-right: 2rem;

  @media (min-width: 769px) {
    margin-left: 0;
    margin-right: 0;
    margin-left: auto !important;
  }
}
</style>
