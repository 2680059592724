import crud from "../store/modules/crud";
import i18n from "@/i18n";
export default {
    data() {
        return {
            credit: 0,
        };
    },
    methods: {
        addCredit() {
            return new Promise((resolve, reject) => {
                crud.post("transaction/deposit", { amount: Number(this.credit) })
                    .then((response) => {
                        if (response.data.proforma) {
                            this.$notify({
                                type: "success",
                                text: i18n.t("success.credit-increased"),
                            });
                            resolve(response.data.proforma);
                        } else {
                            this.$notify({
                                type: "danger",
                                text: i18n.t("error.oops"),
                            });

                            reject(i18n.t("error.oops"));
                        }
                    })
                    .catch(() => {
                        this.$notify({
                            type: "danger",
                            text: i18n.t("error.oops"),
                        });
                        reject(i18n.t("error.oops"));
                    });
            });
        },
    },
    computed: {
        creditText() {
            return Number(this.credit).toFixed(2) + " €";
        },
    },
};
