import crud from "../store/modules/crud";
export default {
  data() {
    return {
      chatKey: 0,
      comments: []
    };
  },
  methods: {
    addNewComments(e) {
      e.forEach(item => {
        this.comments.items.unshift(item);
      });
    },
    getComments(url) {
      crud.get(url).then(response => {
        this.comments = response.data;
        this.comments.items = this.comments.items.reverse();
        this.chatKey++;
      });
    }
  }
};
