var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ad-input-main"},[_c('div',{key:_vm.refreshKey,staticClass:"ad-input-wrapper"},[(_vm.icon !== null)?_c('font-awesome-icon',{staticClass:"ad-icon-input",attrs:{"icon":_vm.icon},on:{"click":_vm.iconClick}}):_vm._e(),_c('input',_vm._g({ref:"ad-input",staticClass:"ad-input",class:{
        bordered: _vm.bordered,
        standard: _vm.standard,
        'ad-valid': !_vm.hasError && !_vm.error,
        'ad-error': _vm.error || _vm.hasError,
        ' input-iconized': _vm.icon !== null
      },attrs:{"disabled":_vm.disabled,"type":_vm.type,"id":'ad-input-' + _vm.random,"placeholder":" "},domProps:{"value":_vm.internalData}},_vm.inputListeners)),_c('label',{class:{ 'label-iconized': _vm.icon !== null },attrs:{"for":'ad-input-' + _vm.random}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.placeholder !== null),expression:"placeholder !== null"}]},[_vm._v(_vm._s(_vm.placeholder))])])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.error || _vm.hasError)?_c('div',{staticClass:"ad-input-error"},[_vm._v(" "+_vm._s(_vm.message || _vm.errorMessage)+" ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }