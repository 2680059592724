import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import i18n, { loadLanguageAsync } from "./i18n";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import filters from "./components/filters.js";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "font-awesome/css/font-awesome.min.css";
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";
import Hotjar from "vue-hotjar";
import ValidateForm from "@/components/ui/ValidateForm";
import VCalendar from "v-calendar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@fortawesome/fontawesome-free/js/all.js";
import Notifications from "vue-notification";
import velocity from "velocity-animate";
import helpers from "@/mixins/helpers";


Vue.component(
  "ValidateForm",
  ValidateForm /*() => import('@/components/ui/ValidateForm')*/
);

Vue.use(VCalendar, {
  componentPrefix: "vc"
});

library.add(fas);
library.add(far);
Vue.component("font-awesome-icon", FontAwesomeIcon);

require("@/store/subscriber");

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.validateStatus = status => {
  return status >= 200 && status < 300;
};

Vue.config.productionTip = false;

Vue.use(CoreuiVue);

Vue.use(Notifications, { velocity });

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GA },
    enabled: process.env.VUE_APP_GA_ENABLED
  },
  router
);

Sentry.init({
  environment: process.env.VUE_APP_ENVIRONMENT,
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


if (process.env.VUE_APP_HOT_JAR) {
  Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOT_JAR,
    isProduction: Boolean(process.env.VUE_APP_HOT_JAR_PRODUCTION)
  });
}

let token = localStorage.getItem('token');
if(token == "undefined" || token === undefined || token === null) {
  const paramsMatch = window.location.href.match(/\?.+/);
  if (paramsMatch) {
    const params = new URLSearchParams(paramsMatch[0]);
    const authToken = params.get('token');
    if (authToken) {
      token = 'Bearer '+ authToken;
    }
  }
}


window.Pusher = require('pusher-js');
window.Pusher.logToConsole = false;

let auth = store.dispatch("auth/attempt", {
  token: token,
  user: JSON.parse(localStorage.getItem("user"))
});

Vue.directive("visible", function(el, binding) {
  el.style.visibility = binding.value ? "visible" : "hidden";
});

Vue.mixin(helpers);

auth.then(() => {
  let promises = [];

  promises.push(store.dispatch("enumerators/fetchEnumerators", {}));
  promises.push(loadLanguageAsync());

  Promise.all(promises).then(() => {
    new Vue({
      i18n,
      router,
      store,
      icons,
      filters,
      render: h => h(App)
    }).$mount("#app");
  });
});
