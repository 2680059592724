import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "sk-SK",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "sk-SK",
  messages: {},
  silentTranslationWarn: true
});

const loadedLanguages = [];
const startingLocale = getStartingLocale();

function getStartingLocale() {
  // const browserLocale = navigator.language.split("-")[0];
  const browserLocale = 'sk-SK';
  const storageLocale = localStorage.getItem("locale");

  if (storageLocale) {
    return storageLocale;
  } else {
    return browserLocale;
  }
}

export function setI18nLanguage(lang) {
  i18n.locale = lang;
  localStorage.setItem("locale", lang);
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  //console.log(lang)
  return lang;
}

export async function loadLanguageAsync(lang) {
  //console.log('loadLanguageAsync')
  if (!lang) lang = startingLocale;
  // If the same language
  if (loadedLanguages.length > 0 && i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang);
    return Promise.resolve();
  }
  //console.log('before json')
  return axios({
    method: "GET",
    url: "lang/" + lang,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json"
    }
  }).then(messages => {
    let msgs = messages.data;
    i18n.setLocaleMessage(lang, msgs);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
}

//loadLanguageAsync(startingLocale)
export default i18n;
