//Views Users
import User from "../views/user/UserIndex.vue";
//import UserCreate from "../views/user/UserCreate.vue";
import UserEdit from "../views/user/UserEdit.vue";
import UserView from "../views/user/UserView.vue";
import ActivityDetail from "../views/user/ActivityDetail.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/users",
          meta: {
            label: "menu.users"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/users",
              //name: "crud.list",
              component: User,
              meta: {
                requiresAuth: true
              }
            },
            /*{
              path: "/users/create",
              name: i18n.t("crud.create"),
              component: UserCreate,
              meta: {
                requiresAuth: true
              }
            },*/
            {
              path: "/users/edit/:id",
              //name: "crud.edit",
              component: UserEdit,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/users/view/:id",
              //name: "crud.view",
              component: UserView,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/:type/activity/:id",
              //name: "crud.edit",
              component: ActivityDetail,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
