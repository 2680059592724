<template>
  <transition-group>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <span
      class="ripple"
      v-bind:ref="'ripple-' + i"
      v-bind:key="'ripple' + i"
      v-for="(val, i) in ripples"
      v-if="val.show"
      v-bind:style="{
        top: val.y + 'px',
        left: val.x + 'px',
        'background-color': color
      }"
      v-on:animationend="rippleEnd(i)"
    >
    </span>
  </transition-group>
</template>

<script>
import ripple from "../../../../mixins/ripple";
export default {
  props: ["color"],
  mixins: [ripple],
  computed: {
    ripplesComputed() {
      return this.ripples.filter(val => val.show === true);
    }
  }
};
</script>

<style lang="scss" scoped>
.ripple {
  width: 1rem;
  height: 1rem;
  position: absolute;
  border-radius: 50%;
  transform: translateX(-100%) translateY(-100%);
  mix-blend-mode: screen;
  animation: ripple 300ms ease-out forwards, fade 550ms ease-out forwards;
}

@keyframes ripple {
  0% {
    transform: translate(-100%, -100%);
  }
  80% {
    transform: translate(-100%, -100%) scale(50);
  }
  100% {
    transform: translate(-100%, -100%) scale(50);
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
</style>
