// // Views - auth
// import LogIn from "../views/auth/LogIn.vue";
// import ForgotPassword from "../views/auth/ForgotPassword.vue";
// import ResetPasswordForm from "../views/auth/ResetPasswordForm.vue";
import Verify from '../views/auth/Verification.vue';
import NotVerified from '../views/auth/NotVerified.vue';
import Login from '../views/auth/NewLogin.vue';
import ForgotPassword from '../views/auth/NewForgotPassword.vue';
import ResetPassword from '../views/auth/NewResetPassword.vue';
import Register from '../views/auth/NewRegister.vue';
import InviteSignUp from '../views/auth/NewInvitation.vue';
import AuthContainer from '../containers/AuthContainer'
// const RegistrationForm = () =>
//   import("../views/auth/registration/RegistrationForm.vue");
// const RegistrationFormInvite = () =>
//   import("../views/auth/registration/RegistrationFormInvite.vue");

// Views
import Dashboard from "../views/dashboard/Dashboard.vue";

export default [
  {
    data: {
      front: [
        {
          path: '/auth',
          name: 'AuthContainer',
          component: AuthContainer,
          children: [
            {
              path: "verify",
              meta: {
                requiresAuth: false,
                nonRedirect: true
              },
              component: Verify
            },
            {
              path: "not-verified",
              meta: {
                requiresAuth: true
              },
              component: NotVerified
            },
            {
              path: "sign-in",
              name: "login",
              meta: {
                requiresAuth: false
              },
              component: Login
            },
            {
              path: "sign-up",
              name: "registration",
              meta: {
                requiresAuth: false
              },
              component: Register
            },
            {
              path: "forgot-password",
              name: "forgot-password",
              meta: {
                requiresAuth: false
              },
              component: ForgotPassword
            },
            {
              path: "reset-password/:token",
              name: "reset-password",
              meta: {
                requiresAuth: false
              },
              component: ResetPassword
            },
            {
              path: 'invite',
              name: "invitation",
              meta: {
                requiresAuth: false
              },
              component: InviteSignUp
            }
          ]
        },
        // {
        //   path: "/login",
        //   name: "login",
        //   component: LogIn,
        //   meta: {
        //     requiresAuth: false
        //   }
        // },
        // {
        //   path: "/auth/forgot-password",
        //   name: "forgot-password",
        //   component: ForgotPassword,
        //   meta: {
        //     requiresAuth: false
        //   }
        // },
        // {
        //   path: "/auth/reset-password/:token",
        //   name: "reset-password-form",
        //   component: ResetPasswordForm,
        //   meta: {
        //     requiresAuth: false
        //   }
        // },
        // {
        //   path: "/registration",
        //   name: "registration",
        //   component: RegistrationForm,
        //   meta: {
        //     requiresAuth: false
        //   }
        // },
        // {
        //   path: "/registration/invite",
        //   name: "registration-invite",
        //   component: RegistrationFormInvite,
        //   meta: {
        //     requiresAuth: false
        //   }
        // },
       
      ],
      admin: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true
          }
        }
      ]
    }
  }
];
