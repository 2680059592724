import PublisherOrders from "@/views/order/publisher/PublisherOrders.vue";
import OrderDetail from '@/views/order/Index.vue'

export default [
  {
    data: {
      admin: [
        {
          path: "/publisher/orders",
          meta: {
            label: "menu.dashboard-publisher"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/publisher/orders",
              name: "menu.dashboard-publisher",
              component: PublisherOrders,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/publisher/order-detail/:id",
              name: "menu.publisher-order-detail",
              component: OrderDetail,
              meta: {
                requiresAuth: true
              }
            },
          ]
        }
      ]
    }
  }
];
