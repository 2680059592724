<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <h1 class="text-left mb-0">
          <strong>{{ $t("menu.users") }}</strong>
        </h1>
        <div class="small text-muted pl-1">{{ $t("crud.view") }}</div>
      </div>
      <div class="col-sm-6 text-sm-right">
        <router-link
          v-if="isAdmin"
          class="button bg-info text-white mr-0 my-2 d-inline-block"
          :to="'/users/activity/' + this.items[0].id"
        >
          <font-awesome-icon icon="fa-solid fa-user" />
          User Activity
        </router-link>

        <router-link
          v-if="isAdmin"
          class="button bg-info text-white mr-0 my-2 d-inline-block"
          :to="'/companies/activity/' + this.items[0].company_id"
        >
          <font-awesome-icon icon="fa-solid fa-building" />
          Companies Activity
        </router-link>

        <router-link
          class="button bg-warning text-white mx-0 my-2 ml-3 d-inline-block"
          :to="'/users/edit/' + this.$route.params.id"
        >
          <font-awesome-icon icon="fa-solid fa-pencil" />
          {{ $t("system.update") + " " + " " + $t("users.user") }}
        </router-link>

        <router-link
          class="button bg-light mr-0 my-2 d-inline-block"
          to="/users"
        >
          {{ $t("system.cancel") }}
        </router-link>
      </div>
    </div>
    <DataTable
      :items="items"
      :fields="fields"
      :rows="rows"
      :loading="loading"
      :pagination="false"
      view="view"
    ></DataTable>
  </div>
</template>

<script>
import DataTable from "@/components/ui/DataTable";
import crud from "@/store/modules/crud.js";
import i18n from "@/i18n";

export default {
  name: "Users",
  components: {
    DataTable
  },
  data() {
    return {
      isAdmin: this.$options.filters.isSuperAdmin(
        this.$store.state.auth.user.roles
      ),
      loading: true,
      items: [],
      status: [],
      selectLanguage: [],
      fields: [
        {
          key: "data",
          label: i18n.t("system.label"),
          sorter: false
        },
        {
          key: "value",
          label: i18n.t("system.value"),
          sorter: false
        }
      ],
      rows: [
        {
          key: "company_id",
          label: i18n.t("register.company") + " ID"
        },
        {
          key: "first_name",
          label: i18n.t("system.first_name")
        },
        {
          key: "last_name",
          label: i18n.t("system.last_name")
        },
        {
          key: "default_role",
          label: i18n.t("register.default-role")
        },
        {
          key: "country",
          label: i18n.t("register.country"),
          value: this.$store.state.enumerators.enumerators["country"],
          valueType: "enumerator"
        },
        {
          key: "status",
          label: i18n.t("register.status"),
          value: this.$store.state.enumerators.enumerators["user-status"],
          valueType: "enumerator"
        },
        {
          key: "language",
          label: i18n.t("register.pref-language"),
          value: this.$store.state.enumerators.enumerators["language"],
          valueType: "enumerator"
        },
        {
          key: "tos",
          label: i18n.t("register.terms"),
          valueType: "boolean"
        },
        {
          key: "created_at",
          label: i18n.t("system.created_at"),
          valueType: "date"
        },
        {
          key: "updated_at",
          label: i18n.t("system.updated_at"),
          valueType: "date"
        },
        {
          key: "email_verified_at",
          label: i18n.t("system.confirm"),
          valueType: "date"
        },
        {
          key: "last_login",
          label: i18n.t("users.last_login"),
          valueType: "date"
        },
        {
          key: "credit",
          label: i18n.t("users.credit"),
        },
        {
          key: "allocated",
          label: i18n.t("users.allocated"),
        },
        {
          key: "on_the_way",
          label: i18n.t("users.on_the_way"),
        }

      ]
    };
  },
  methods: {
    getUserInfo() {
      let self = this;
      this.loading = true;
      crud.get("users/" + this.$route.params.id).then(function(response) {
        self.items.push(response.data);
        self.loading = false;
      });
    }
  },
  created() {
    this.getUserInfo();
  }
};
</script>

<style></style>
