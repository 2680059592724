<template>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalLabel"
    aria-hidden="true"
    @click.self="$emit('close')"
  >
    <div class="modal-dialog" :class="'modal-' + size">
      <div class="modal-content">
        <div class="modal-header" :class="color">
          <h4 class="modal-title">
            {{ title }}
          </h4>
          <button type="button" @click="$emit('close')" class="close">
            <font-awesome-icon icon="fa-solid fa-xmark" size="2x" />
          </button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button
            v-if="buttonText"
            type="button"
            class="button mr-0"
            @click="$emit('save')"
          >
            {{ buttonText }}
          </button>
          <button
            type="button"
            class="button bg-secondary mr-0"
            @click="$emit('close')"
          >
            {{ $t("system.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*
 *   <Modal v-if="showModal" @close="showModal=false" @save="showModal=false" name="Modalko">
 *      <p>dsfkdskf</p>
 *   </Modal>
 *
 *    import Modal from "@/components/ui/Modal";
 *
 *    showModal: true,
 */

export default {
  props: {
    title: String,
    color: String,
    buttonText: String,
    size: {
      type: String,
      default: "lg" //sm, lg
    }
  }
};
</script>
