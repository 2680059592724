export default {
  data() {
    return {
      product: {
        name: null,
        // count_of_views: 0,
        // price_per_view: 0,
        price: 0,
        type: 1
      },
      active: false
    };
  }
};
