<template>
  <div>
  <v-select
      :options="options"
      v-model="selectedValue"
      :label="label"
      :placeholder="placeholder"
      :taggable="taggable"
      :reduce="option => (option.id || option.word)"
      :create-option="word => ({ word })"
      :pushTags="taggable"
      :multiple="multiple"
      :class="{
        'select-valid': !error,
        'select-error': validationTriggered && error
      }"
  />

    <transition name="fade">
      <div class="ad-input-error" v-if="error || hasError">
        {{ message || errorMessage }}
      </div>
    </transition>
</div>
</template>
<script>

import vSelect from "vue-select";
import validation from "@/mixins/validation";

export default {
  name: "SelectBox",
  mixins: [validation],
  components: {
    vSelect
  },
  data(){
    return {
      selectedValue: null,
    }
  },
  props: {
    value: {
      default: null
    },
    options: {
      default() {
        return [];
      }
    },
    label: {
      default: 'label'
    },
    code: {
      default: 'id'
    },
    placeholder: {
      default: null
    },
    taggable: {
      default: false
    },
    multiple: {
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    }
  },
  created() {
    this.selectedValue = this.value;
  },
  watch: {
    value(val) {
      this.selectedValue = val;
    },

    selectedValue(val) {
      this.$emit('input', val);

      this.validate(val);
    }
  }
}

</script>

<style >
.vs__dropdown-toggle {
  color: black;
}


 .select-valid .vs__dropdown-toggle {
  border-color: #00a490;
}

.select-error .vs__dropdown-toggle {
  border-color: #dc3545d9;
  background-color: rgba(220, 53, 69, 0.05);;
}
.vs__selected {
  color: black;
}

.vs__selected-options{
  padding: 0.5rem;
}
</style>