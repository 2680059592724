<template>
  <div>
    <div class="row">
      <div class="col-sm-8">
        <h1 class="text-left">
          <strong>{{ $t("menu.users") }}</strong>
        </h1>
        <p v-html="$t('hints.users-introduction')"></p>
      </div>
      <div
        class="col-sm-4 text-sm-right"
        v-if="!$options.filters.isSuperAdmin($store.state.auth.user.roles)"
      >
        <div
          class="button bg-success text-white mx-0 my-2 d-inline-block"
          role="button"
          @click="invite.modal = true"
        >
          <font-awesome-icon icon="fa-solid fa-plus" />
          {{ $t("users.invite-new-user") }}
        </div>
        <Modal
          v-if="invite.modal"
          :show.sync="invite.modal"
          @close="invite.modal = false"
          @save="inviteUser"
          color="bg-secondary"
          :title="$t('users.invite-new-user')"
          :button-text="$t('system.invite')"
          class="text-left"
        >
          <div class="loader" v-if="invite.loading">Loading...</div>
          <template v-else>
            <p class="py-3">{{ $t("users.fill-invite") }}</p>
            <div class="form-row mb-3 input-box bg-invert-white">
              <div class="col-md-12">
                <input
                  type="text"
                  class="input-1 mb-3"
                  v-model="invite.email"
                  id="invite_email"
                />
                <label class="input-label" for="invite_email">
                  E-mail
                  <small v-if="invite.error" class="bg-danger px-2">{{
                    $t("register.this_email_in_use")
                  }}</small>
                </label>
              </div>
            </div>
          </template>
        </Modal>
      </div>
    </div>
    <dynamic-filter
      :inputs="dynamicFilterInputs"
      v-on:params-updated="processEmit"
    />

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :meta="this.table.meta"
        :tableConfig="{
          col: 500
        }"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import DataViewer from "@/components/ui/DataViewer";
import PreLoader from "@/components/ui/PreLoader";
import DynamicFilter from "@/components/ui/DynamicFilter";
import i18n from "@/i18n";
import Modal from "@/components/ui/Modal";
import { mapActions } from "vuex";
import helpers from "@/mixins/helpers";

export default {
  data() {
    return {
      invite: {
        loading: false,
        modal: false,
        email: null,
        error: false
      },
      query: {
        page: "",
        sort: "",
        filter: "",
        select: []
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "id",
          translate: "ID",
          sorter: true,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "first_name",
          translate: i18n.t("system.first_name"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "last_name",
          translate: i18n.t("system.last_name"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "email",
          translate: i18n.t("system.email"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "company",
          translate: i18n.t("register.company"),
          options: ["name"],
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },  {
          key: "last_login",
          translate: i18n.t("system.last_login"),
          sorter: true,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },{
          key: "status",
          translate: i18n.t("system.status"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "roles",
          translate: i18n.t("roles.roleName"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "actions",
          translate: i18n.t("system.actions"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          },
          buttons: [
            {
              key: "detail",
              link: "/users/view/",
              identifier: "id",
              translate: i18n.t("crud.view"),
              class: "button bg-secondary text-white mx-1 px-3 px-4 py-2"
            },
            {
              key: "edit",
              link: "/users/edit/",
              identifier: "id",
              translate: i18n.t("crud.edit"),
              class: "button bg-warning text-white mx-1 px-3 px-4 py-2"
            },
            {
              key: "delete",
              link: "/users/destroy/",
              identifier: "id",
              emit: true,
              settings: {
                method: "put",
                data: []
              },
              translate: i18n.t("system.delete"),
              class: "button bg-danger text-white mx-1 px-3 px-4 py-1"
            }
          ]
        }
      ],
      dynamicFilterInputs: [
        {
          name: "first_name",
          type: "text",
          label: i18n.t("system.first_name"),
          value: null,
          adminOnly: true
        },
        {
          name: "last_name",
          type: "text",
          label: i18n.t("system.last_name"),
          value: null,
          adminOnly: true
        },
        {
          name: "email",
          type: "email",
          label: i18n.t("system.email"),
          value: null,
          adminOnly: false
        },
        {
          name: "company",
          type: "select2",
          label: i18n.t("register.company"),
          value: [],
          adminOnly: true
        },
        {
          name: "status",
          type: "select",
          label: i18n.t("system.status"),
          value: [],
          adminOnly: false
        }
      ]
    };
  },

  components: {
    DataViewer,
    PreLoader,
    DynamicFilter,
    Modal
  },

  mounted() {
    this.loadTable();

    if (this.$options.filters.isSuperAdmin(this.$store.state.auth.user.roles)) {
      this.getAllCompanies();
    }
  },

  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "";
      let filter = this.query.filter ? this.query.filter : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud
        .get("users" + page + filter + sort)
        .then(function(response) {
          // console.log(  Object.keys(response.data.items[0])  );

          response.data.items.forEach(item => {
            // Status
            item.status = i18n.t(
              "enum." +
                self.$store.state.enumerators.enumerators["user-status"][
                  item.status
                ]
            );
            if(item.last_login !== null) {
              item.last_login = helpers.methods.getDate(item.last_login, 'lll')
            }
          });

          // Dynamic value
          self.dynamicFilterInputs[4].value = self.selectedOptions;

          self.table = response.data;
          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },
    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      if (data.filter) {
        this.query.filter = data.filter;

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }

      // Emited action
      if (data.action) {
        let self = this;

        if (Object.keys(data).length > 1) {
          self.performance.loading = true;

          let method = data.method;
          let action = data.action;

          delete data["method"];
          delete data["action"];

          let body = data;

          switch (method) {
            case "post":
              crud.post(action, body).then(() => {
                self.loadTable();
              });
              break;
            case "get":
              crud.get(action, body).then(() => {
                self.loadTable();
              });
              break;
            case "put":
              crud.put(action, body).then(() => {
                self.loadTable();
              });
              break;
            case "delete":
              crud.delete(action, body).then(() => {
                self.loadTable();
              });
              break;
            default:
              return;
          }
        } else {
          self.performance.loading = true;

          crud.post(data.action).then(() => {
            self.loadTable();
          });
        }
      }
    },
    inviteUser() {
      this.invite.loading = true;
      let self = this;

      let obj = {
        email: this.invite.email
      };

      crud
        .post("auth/invite", obj)
        .then(() => {
          self.invite.loading = false;
          self.invite.error = false;
          self.invite.modal = false;
          self.invite.email = null;
          self.addNotification({
            type: "success",
            message: i18n.t("success.invite-sent")
          });
        })
        .catch(() => {
          self.invite.loading = false;
          self.invite.error = true;
          self.addNotification({
            type: "danger",
            // message: errors.data.errors.email[0]
            message: i18n.t("error.oops")
          });
        });
    },
    getAllCompanies() {
      let self = this;

      crud.get("companies/select").then(function(response) {
        let arr = [];

        response.data.items.forEach(value => {
          arr.push({
            label: value.name,
            code: value.id
          });
        });

        self.optionsCompanies = arr;
        self.dynamicFilterInputs[3].value = arr;
      });
    }
  },

  computed: {
    selectedOptions: function() {
      let enums = Object.entries(
        this.$store.state.enumerators.enumerators["user-status"]
      );

      let arr = [];

      enums.forEach(item => {
        arr.push({
          value: Number([item[0]].toString()),
          name: [item[1]].toString()
        });
      });

      return arr;
    }
  }
};
</script>
