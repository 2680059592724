var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 comments-view pr-md-5"},[_c('div',{},[_c('div',{ref:"comments",attrs:{"id":"comments"},on:{"scroll":_vm.loadMoreComments}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingMore),expression:"loadingMore"}],staticClass:"loader"}),_vm._l((_vm.commentsComputed),function(comment,index){return _c('div',{key:index,staticClass:"d-flex message-row"},[(['message', 'attachment'].includes(comment.type))?_c('div',{staticClass:"message",class:{
              'highlight ': comment.me_sended,
              'my-0 py-1':
                index !== 1 ||
                (_vm.commentsComputed[index - 1] &&
                  _vm.commentsComputed[index - 1].user_id === comment.user_id)
            }},[_c('div',{staticClass:"d-flex"},[(
                  index === 0 ||
                  (_vm.commentsComputed[index - 1] &&
                  _vm.commentsComputed[index - 1].user_id !== comment.user_id)
                  ||
                    (_vm.commentsComputed[index - 1] &&
                       _vm.commentsComputed[index - 1].human_date !== comment.human_date)
                )?_c('div',{staticClass:"mb-2 mt-2 d-flex align-items-center",class:{ 'text-right': comment.me_sended }},[_c('profile-avatar',{staticClass:"mr-2",attrs:{"user":comment.user,"colors":(comment.me_sended) ? [
                  {
                    background: '#AC3492',
                    color: 'white'
                  }
                ]: [
                  {
                    background: '#45AC34',
                    color: 'white'
                  }
                ]}}),_c('span',[_c('strong',[_vm._v(_vm._s(comment.user.first_name))]),_vm._v(", "+_vm._s(comment.human_date))])],1):_vm._e()]),_c('div',{staticClass:"message-content"},[(comment.type === 'message')?_c('div',[_vm._v(" "+_vm._s(comment.comment)+" ")]):_c('div',_vm._l((comment.attachments),function(attachment){return _c('div',{key:attachment.id},[(attachment.file_type.includes('image'))?_c('div',[_c('img',{attrs:{"width":"20%","src":attachment.hash_url}}),_c('div',[_c('a',{attrs:{"target":"_blank","href":attachment.hash_url}},[_vm._v(_vm._s(attachment.name))]),_c('br'),_vm._v(" "+_vm._s(attachment.mb_size)+" ")])]):_c('div',{staticClass:"d-flex"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-regular fa-file-lines","size":"lg","color":"black"}}),_c('div',[_c('a',{staticClass:"d-flex",attrs:{"target":"_blank","href":attachment.hash_url}},[_vm._v(_vm._s(attachment.name))]),_vm._v(" "+_vm._s(attachment.file_type)+" "+_vm._s(attachment.mb_size)+" ")])],1)])}),0)])]):(
              ['system-success', 'system-error'].includes(comment.type)
            )?_c('div',{staticClass:"system-message mt-3"},[_c('div',{staticClass:"content-system py-2",class:comment.type},[_vm._v(" "+_vm._s(comment.comment)+", "+_vm._s(comment.human_date)+" ")])]):_vm._e()])})],2)]),_c('div',{staticClass:"row",attrs:{"id":"messaging-tools"}},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],attrs:{"placeholder":_vm.$t('chat.send-message')},domProps:{"value":(_vm.message)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.sendMessage.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right d-flex justify-content-end"},[_c('base-uploader',{staticClass:"mr-2",attrs:{"type":"button","apiUrl":_vm.uploadUrl},on:{"uploaded":function($event){return _vm.updateMessages($event)}}}),_c('button',{staticClass:"btn btn-success",attrs:{"disabled":_vm.loading},on:{"click":_vm.sendMessage}},[(_vm.loading)?_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}}):[_vm._v(" "+_vm._s(_vm.$t("chat.send-message"))+" ")]],2)],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }