import crud from "@/store/modules/crud";

export default {
  namespaced: true,
  state: {
    enumerators: {}
  },
  getters: {
    enumerators(state) {
      return state.enumerators;
    }
  },
  mutations: {
    FETCH_ENUMERATORS(state, data) {
      state.enumerators = data;
    }
  },
  actions: {
    fetchEnumerators({ commit }) {
      crud
        .get("enums")
        .then(response => {
          commit("FETCH_ENUMERATORS", response.data);
        })
        .catch(response => {
          console.error("Unable to retrieve enumerators");
          console.error(response);
        });
    }
  }
};
