<template>
  <div>
    <div class="row">
      <div class="col-sm-8">
        <h1 class="text-left">
          <strong>{{ $t("publisher.auction-history-title") }}</strong>
        </h1>
        <p v-html="$t('hints.auction-history-publisher-introduction')"></p>
      </div>

      <div class="col-sm-4 text-right">
        <button
          class="button bg-success text-white mr-0 my-2 d-inline-block"
          @click="openAddWebsiteModal()"
        >
          {{ $t("system.add_website") }}
        </button>
      </div>
    </div>

    <dynamic-filter
      :inputs="dynamicFilterInputs"
      v-on:params-updated="processEmit"
    />

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :meta="this.table.meta"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>

    <Modal
      v-if="openedAddWebsiteModal"
      @close="openedAddWebsiteModal = false"
      @save="storeWebsite()"
      color="bg-secondary"
      :title="$t('error.enter-valid-url')"
      :button-text="$t('system.confirm')"
      class="text-left"
    >
      <div class="input-box bg-invert-white">
        <input type="text" class="input-1" v-model="filledUrl" />
        <label class="input-label">
          {{ $t("error.enter-valid-url") }}
        </label>
      </div>
    </Modal>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import DataViewer from "@/components/ui/DataViewer";
import PreLoader from "@/components/ui/PreLoader";
import DynamicFilter from "@/components/ui/DynamicFilter";
import i18n from "@/i18n";
import Modal from "@/components/ui/Modal";

export default {
  data() {
    return {
      filledUrl: "",
      openedAddWebsiteModal: false,
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "id",
          translate: "ID",
          sorter: true,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "url",
          translate: i18n.t("auctions.url-address"),
          sorter: true,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "company",
          translate: i18n.t("register.company"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "actions",
          translate: i18n.t("system.actions"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          },
          buttons: [
            {
              key: "edit",
              link: "/websites/edit/",
              identifier: "id",
              translate: i18n.t("crud.edit"),
              class: "button bg-warning text-white mx-1 px-3 px-4 py-2"
            },
            {
              key: "view",
              link: "/companies/view/",
              identifier: "company_id",
              translate: i18n.t("system.detail"),
              class: "button bg-info text-white mx-1 px-3 px-4 py-2"
            }
          ]
        }
      ],
      dynamicFilterInputs: [
        {
          name: "url",
          type: "text",
          label: i18n.t("auctions.url-address"),
          value: null,
          adminOnly: false
        },
        {
          name: "company",
          type: "select2",
          label: i18n.t("register.company"),
          value: [],
          adminOnly: true
        }
      ]
    };
  },

  components: {
    DataViewer,
    PreLoader,
    DynamicFilter,
    Modal
  },

  mounted() {
    this.adminGetCompanies();
    this.loadTable();
  },

  methods: {
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "";
      let filter = this.query.filter ? this.query.filter : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud
        .get("websites" + page + filter + sort)
        .then(function(response) {
          console.log(response.data);
          // console.log(  Object.keys(response.data.items[0])  );

          response.data.items.forEach(item => {
            item.url
              ? (item.url = item.url.split("//")[1].replace("www.", ""))
              : "";
            item ? (item.actions = { view: true, edit: true }) : "";
            // (item.status) ? item.status = i18n.t("enum." + self.$store.state.enumerators.enumerators["auction-status"][item.status]) : "";
            // (item.profit) ? item.profit = item.profit.split('.')[0] + '€' : "";
            // (item.publish_date) ? item.publish_date = self.$options.filters.toLocaleDateString(item.publish_date) : "";
            // (item.action) ? item.actions = item.action : "";
            console.log(item);
          });

          self.table = response.data;
          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },
    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      if (data.filter) {
        this.query.filter = data.filter;

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }
    },
    storeWebsite() {
      let self = this;

      let obj = {
        url: this.filledUrl
      };

      if (this.filledUrl.includes("https://")) {
        crud.post("websites", obj).then(() => {
          self.openedAddWebsiteModal = false;
          self.filledUrl = null;
          self.loadTable();
        });
      }
    },
    openAddWebsiteModal() {
      this.openedAddWebsiteModal = true;
    },
    updateManageCreditModalEmit() {
      this.openedAddWebsiteModal = false;

      this.loadTable();
    },
    adminGetCompanies() {
      let self = this;
      crud.get("companies/all").then(function(response) {
        let arr = [];
        for (const [key, value] of Object.entries(response.data)) {
          arr.push({
            label: key,
            code: value
          });
        }
        self.dynamicFilterInputs[1].value = arr;
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
