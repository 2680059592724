<template>
  <div>
    <input
      class="input-1 mb-3 d-block"
      type="file"
      @change="change($event)"
      :multiple="multiple"
      :accept="accept"
    />
    <div class="progress">
      <div
        class="progress-bar color-primary"
        role="progressbar"
        :style="{ width: progressValue + '%' }"
        :aria-valuenow="progressValue"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {{ progressValue }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /** Accept types of files */
    accept: {
      type: String
    },
    /** Can upload multiple files at once? */
    multiple: {
      type: Boolean
    },
    /** Max file size of uploaded files */
    maxFileSize: {
      type: Number
    }
  },
  methods: {
    change(e) {
      console.log(e.target.files);
      e.target.files.forEach(file => {
        if (file.size > this.maxFileSize) {
          this.errors.push("upload.filesize");
        }
      });
    }
  }
};
</script>

<style></style>
