<template>
  <div>
    <h1 class="ad-title-main">
      {{ $t("menu.dashboard-advertiser") }}
    </h1>
    <div v-if=" keywordsItems.length > 0 || categories.length > 0">
    <p class="ad-desc-main">
      {{ $t("advertiser.dashboard_desc") }}
    </p>

    <div class="row mt-4">
      <div
        @click="setRedirect()"
        :class="'col-'+numberOfColumns + ' col-md-3 ad-col-item'"
      >
        <div>
          <font-awesome-icon icon="fa-solid fa-plus" class="pr-1" />
          {{ $t("advertiser.follow_topics") }}
        </div>
      </div>

      <div
        v-if="keywordsItems.length > 0"
        @click.prevent="setCol(2)"
        :class="'col-' + numberOfColumns + ' col-md-3 ad-col-item position-relative' +isKeywordsActive"
      >
        <div >
          {{ $t("menu.keywords") }} ({{ keywordsSearchedItems.length }})
          <font-awesome-icon icon="fa-solid fa-sort-down" class="pl-1" />
        </div>

        <div 
          class="position-absolute ad-filter-box"
          v-if="activeCol == 2"
        >
          <ad-select
                class="text-left"
                :items="keywordsSearchedItems"
                :active-out="activeCol == 2"
                @filter="filterFnKeywords"
                multiple
                @hide="activeCol = 0"
                ref="selectbox"
                :key="updateKey"
                autocomplete
                readOnly
                item-value="id"
                item-label="word"
                v-model="keywordsSelected"
                :placeholder="$t('offer.reserved-keywords')"
            />
            
        </div>
      </div>

      <div
        @click="setCol(3)"
        v-if="categories.length > 0"
        :class="'col-'+numberOfColumns + ' col-md-3 ad-col-item position-relative'+ isCategoryActive"
      >
        <div >
          {{ $t("system.category") }} ({{ categories.length }})
          <font-awesome-icon icon="fa-solid fa-sort-down" class="pl-1" />
        </div>

        <div 
          class="position-absolute ad-filter-box"
          v-if="activeCol == 3"
        >

          <ad-select
            class="text-left"
                :items="categories"
                @filter="filterFnCategories"
                :active-out="activeCol == 3"
                @hide="activeCol = 0"
                :key="updateKey"
                ref="selectbox"
                autocomplete
                multiple
                readOnly
                item-value="code"
                item-label="label"
                v-model="categoriesSelected"
                :placeholder="$t('auctions.selected-segments')"
            />
        </div>
      </div>
      <div :class="'col-' + numberOfColumns + 'col-md-3 mt-3 mt-md-0 ml-auto'">
        <router-link
            to="/advertiser/wishlist/create"
            style="border: 1px solid rgba(0, 164, 144, 1); white-space: nowrap;"
            class="px-3 py-2 mt-1"
        >
          {{ $t("wishlist.create-wishlist-title") }}
        </router-link>
      </div>
    </div>

    <hr style="border-color: rgba(233, 237, 239, 1);">

      <pre-loader
          v-if="this.notifications === false"
          :loading="false"
          :success="false"
          :errorMessage="$t('dashboard_advertiser.no_notifications')"
          :error-message-link="{message: $t('dashboard_advertiser.no_notifications_link'), to: '/interests'}"
      />

      <AdMostRelevantOffers
      :queryString="query"
    ></AdMostRelevantOffers>
    </div>
    <pre-loader
        v-else
        :loading="false"
        :success="false"
        :errorMessage="$t('dashboard_advertiser.no_interests')"
        :error-message-link="{message: $t('dashboard_advertiser.no_interests_link'), to: '/interests'}"
    />

    <AdEditorsPick></AdEditorsPick>

    <router-link
        to="/advertiser/offers"
        class="float-right"
    >
      {{ $t("advertiser_table.view_more_articles") }}
      <font-awesome-icon
          icon="fa-solid fa-chevron-right"
          class="ml-3"
      />
    </router-link>


    <!-- ---------------------------------------------------------------- -->

    <ad-last-seen-auctions></ad-last-seen-auctions>

    <div class="row mt-2 mb-4" id="ad-info-boxes">
      <div class="col-md-6 mb-3 md-mb-3 h-full">
        <div class="ad-container-first p-3 pb-4">
          <b>{{ $t("offer.box_first_title_advertiser") }}</b>

          <p>{{ $t("offer.box_first_text_advertiser") }}</p>

          <router-link 
            to="/advertiser/wishlist/create"
            class="ad-router-link"
          >
            {{ $t("wishlist.create-wishlist-title") }}
          </router-link>
        </div>
      </div>

      <div class="col-md-6 h-full">
        <div class="ad-container-last p-3 pb-4">
          <b>{{ $t("offer.box_last_title_advertiser") }}</b>

          <p>{{ $t("offer.box_last_text_advertiser") }}</p>

          <router-link 
            to="/advertiser/offers"
            class="ad-router-link"
          >
            {{ $t("system.offers") }}
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import AdLastSeenAuctions from "@/components/ui/v_2/AdLastSeenAuctions.vue";
  import AdMostRelevantOffers from "@/components/ui/v_2/AdMostRelevantOffers.vue";
  import AdEditorsPick from "@/components/ui/v_2/AdEditorsPickOffers";
  import crud from "@/store/modules/crud.js";
  import AdSelect from '@/components/ui/v_2/AdSelect.vue';
  import _ from "lodash";
  import PreLoader from "@/components/ui/PreLoader";

  export default {

    components: {
      AdLastSeenAuctions,
      AdMostRelevantOffers,
      AdSelect,
      AdEditorsPick,
      PreLoader
    },

    data() {
      return {
        activeCol: 0,
        categories: [],
        categoriesSelected: [],
        categoriesBackup: [],
        keywordsItems: [],
        keywordsSearchedItems: [],
        keywords: [],
        updateKey: 0,
        keywordsSelected: [],
        queryString: null,
        notifications: false
      }
    },
    created() {
      crud
        .get(`/keywords/all?language=${this.locale}`)
        .then(resp => {
          this.keywordsItems = resp.data.items;
          this.keywordsSearchedItems = resp.data.items;
        });
      this.getCategories();
      this.getNotificationsStatus();
    },
    mounted () {
      // this.getKeywords();
    },

    watch: {
      categoriesSelected: {
        handler() {
          // this.generateString();
        },
      },
      // keywordsSelected: {
      //   handler() {
      //     this.generateString();
      //   },
      // }
    },
    computed: {
      locale: function() {
        return localStorage.getItem("locale");
      },
      query() {
        return this.generateString();
      },
      numberOfColumns: function() {
        let columns = 2;

        if(this.keywordsSearchedItems.length > 0){
          columns++;
        }
        if(this.categories.length > 0){
          columns++;
        }

        return 12/columns;
    },
    isKeywordsActive: function (){
      return (this.activeCol == 2) ? 'ad-col-item__active' : '';
    },
    isCategoryActive: function (){
      return (this.activeCol == 3) ? 'ad-col-item__active' : '';
    }

    },
    methods: {
      filterFnKeywords(val, update) {
        this.keywordsSearchedItems = this.keywordsItems;
        update(() => {
          this.keywordsSearchedItems = this.keywordsItems.filter(keyword => {
            return keyword.word.toLowerCase().includes(val.toLowerCase());
          });
          this.$forceUpdate();
        });
      },
      filterFnCategories(val, update) {
        // filter segments
        this.categories = this.categoriesBackup;
        update(() => {
          this.categories = this.categories.filter(category => {
            return category.label.toLowerCase().includes(val.toLowerCase());
          });
          this.$forceUpdate();
        });
      },
      setCol(data) {
          this.activeCol = data
      },
      setRedirect() {
        this.$router.push("/interests")
      },
      getKeywords() {        
        crud.get('keywords/select').then(response => {
          let arr = [];
            
          response.data.forEach(value => {
            arr.push({
              label: value.word,
              code: value.id
            });
          });

          this.keywords = arr;
        }); 
      },
      getCategories() {        
        crud.get('google-categories/company/select').then(response => {
          let arr = [];
            
          response.data.forEach(value => {
            arr.push({
              label: value.title,
              code: value.id
            });
          });

          this.categories = arr;
          this.categoriesBackup = arr
        }); 
      },
      getNotificationsStatus(){
        crud.get('keywords/notifications').then(response => {
          this.notifications = this.notifications || response.data.notifications;
        });

        crud.get('google-categories/notifications').then(response => {
          this.notifications = this.notifications || response.data.notifications;
        });
      },
      generateString() {
        let categoriesQ = _.map(this.categoriesSelected, (n) => {return n});

        let keywordsQ = _.map(this.keywordsSelected, (n) => {return n});

        let arr = [];
        // Categories
        (categoriesQ.length > 0) ? arr[0] = `categories=${categoriesQ}` : arr[0] = null;

        // Keywords
        (keywordsQ.length > 0) ? arr[1] = `keywords=${keywordsQ}` : arr[1] = null;

        let finalQ = `?${arr.join("&")}`;

        if(categoriesQ.length > 0 || keywordsQ.length > 0) {
          return finalQ.replaceAll("?&", "?")
        }  else {
          return null
        };
      }
    },
    
  }
</script>

<style lang="scss" scoped>
  .ad-title-main {
    font-size: 1.75rem;
  }

  .ad-desc-main {
    font-size: 1.125rem;
    font-weight: 500;
    margin-top: 2rem;
  }

  .ad-col-item {
    font-size: 1.125rem;
    text-align: left;
    font-weight: 500;
    cursor: pointer;
  }

  .ad-col-item__active {
    color: rgba(0, 164, 144, 1);

    &:after {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      background: rgba(0, 164, 144, 1);
      position: relative;
      top: 1rem;
    }
  }

  #ad-info-boxes {
    // 

    b {
      line-height: 21.09px;
      font-size: 1.125rem;
      font-weight: 700;
    }

    p {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  .ad-container-first {
    background: rgba(240, 250, 248, 1);

    .ad-router-link {
      font-size: 0.9375rem;
      background: transparent;
      border: 1px solid rgba(0, 164, 144, 1);
      color: rgba(0, 164, 144, 1);
      padding: 0.5rem 2rem;
      border-radius: 2px;
    }
  }

  .ad-container-last {
    background: rgba(0, 0, 0, 0.04);

    .ad-router-link {
      font-size: 0.9375rem;
      border: 1px solid rgba(0, 164, 144, 1);
      background: transparent;
      color: rgba(0, 164, 144, 1);
      padding: 0.5rem 2rem;
      border-radius: 2px;
    }
  }

  .ad-filter-box {
    z-index: 9999;
    width: 100%;
    top: 2.8rem;
    background: white;
  }
</style>