<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-left mb-0">
          <strong>{{ $t("menu.my-profile") }}</strong>
        </h1>
        <div class="small text-muted pl-1">{{ $t("menu.settings") }}</div>
      </div>
      <Form :is-edit="true" :is-my-profile="true"></Form>
    </div>
  </div>
</template>

<script>
import Form from "../../user/Form";

export default {
  name: "MyProfile",
  components: {
    Form
  }
};
</script>
