<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <h1 class="text-left">
          <strong>{{ $t("menu.keywords") }}</strong>
        </h1>
      </div>

      <div class="col-sm-6 text-sm-right">
        <router-link
          class="button bg-success text-white mx-0 my-2 d-inline-block"
          to="/keywords/create"
        >
          <font-awesome-icon icon="fa-solid fa-plus" />
          {{
            $t("system.add") +
              " " +
              $t("system.new") +
              " " +
              $t("my-interests.keyword")
          }}
        </router-link>
      </div>
    </div>

    <Search ref="search" v-bind:columns="this.search" />

    <DataTable
      :items="items"
      :fields="allowedFields"
      :page="this.page"
      :loading="loading"
      :sortBy="this.sortBy"
    >
      <template
        v-if="$options.filters.isSuperAdmin($store.state.auth.user.roles)"
        #companies="{item}"
      >
        <td v-if="item.companies[0]">
          <template v-if="item.companies[0].name === null">
            {{ $t("register.company_empty") }}
          </template>
          <template v-else>
            {{ item.companies[0].name }}
            <span
              v-if="item.companies.length > 1"
              class="button bg-secondary text-white pointer"
              role="button"
              @click="showModal = item.id"
            >
              + {{ item.companies.length - 1 }}
            </span>
            <Modal
              v-if="showModal === item.id"
              @close="showModal = 0"
              color="bg-secondary"
              :title="$t('register.company')"
            >
              <ul class="mt-3">
                <li v-for="company in item.companies" :key="company.id">
                  {{ company.name }}
                </li>
              </ul>
            </Modal>
          </template>
        </td>
        <td v-else>
          <strong>{{ $t("my-interests.keyword-no") }}</strong>
        </td>
      </template>
      <template #companies_count="{item}">
        <td>
          <CBadge color="success">
            <strong>{{ item.companies_count }}</strong>
          </CBadge>
        </td>
      </template>
      <template #language="{item}">
        <td>
          {{ $store.state.enumerators.enumerators["language"][item.language] }}
        </td>
      </template>
      <template #button="{item,index}">
        <td class="py-2 text-right">
          <router-link
            class="button bg-secondary text-white mx-1"
            :to="{ path: '/keywords/view/' + item.id }"
            :title="$t('system.show')"
            ><font-awesome-icon icon="fa-solid fa-eye"
          /></router-link>
          <router-link
            class="button bg-warning text-white mx-1"
            :to="{ path: '/keywords/edit/' + item.id }"
            :title="$t('crud.edit')"
            ><font-awesome-icon icon="fa-solid fa-pencil"
          /></router-link>
          <a
            v-on:click="deleteElement(item.id, index)"
            v-if="$store.state.auth.user"
            href="#"
            class="button bg-danger text-white mx-1"
            :title="$t('system.delete')"
            ><font-awesome-icon icon="fa-solid fa-trash"
          /></a>
        </td>
      </template>
    </DataTable>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import Search from "@/components/ui/Search";
import DataTable from "@/components/ui/DataTable";
import Modal from "@/components/ui/Modal";

export default {
  name: "Keywords",
  components: {
    DataTable,
    Search,
    Modal
  },
  data() {
    return {
      showModal: 0,
      loading: true,
      items: [],
      fields: [
        {
          key: "id",
          label: "ID"
        },
        "word",
        {
          key: "companies_count",
          label: i18n.t("system.count"),
          _style: "min-width:100px;"
        },
        "language",
        {
          key: "companies",
          label: i18n.t("register.company"),
          _style: "min-width:100px;",
          sorter: false
        },
        {
          key: "button",
          label: "",
          sorter: false,
          _style: "min-width:200px;"
        }
      ],
      page: {
        totalCount: 0,
        perPage: 0
      },
      sortBy: { column: "id", asc: false },
      search: [
        {
          name: "word",
          label: i18n.t("menu.keywords"),
          type: "text",
          operator: "or",
          like: true
        },
        {
          name: "language",
          label: i18n.t("system.language"),
          type: "select",
          operator: "or",
          like: false,
          enum: "language"
        }
      ]
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    pagination() {
      this.getKeywords();
    },

    sort() {
      this.getKeywords();
    },

    getKeywords(onload = false) {
      let self = this;

      let query = this.$refs.search.parseUrlQuery(onload, this.sortBy);

      if (!onload) {
        crud
          .get("keywords" + query + "&expand=companies")
          .then(function(response) {
            //get data with deleted or without
            let filter = self.$options.filters.parseUrlQueryFilter(
              self.$route.query
            );

            if (!filter) {
              self.items = response.data.items.filter(function(e) {
                return e.word !== 0;
              });
            } else {
              self.items = response.data.items;
            }

            self.page.totalCount = response.data.meta.total;
            self.page.perPage = response.data.meta.per_page;

            self.loading = false;
          });
      }
      this.loading = false;
    },

    deleteElement(id, index) {
      if (confirm(i18n.t("confirmation.delete"))) {
        let self = this;
        crud
          .delete("keywords/" + id)
          .then(function(response) {
            if (response.status === 204) {
              self.items.splice(index, 1);
              self.page.totalCount -= 1;

              self.addNotification({
                type: "success",
                message: i18n.t("success.record-deleted")
              });
            }
          })
          .catch(error => {
            self.addNotification({
              type: "danger",
              message: error.message
            });
          });
      }
    }
  },
  computed: {
    allowedFields() {
      if (
        !this.$options.filters.isSuperAdmin(this.$store.state.auth.user.roles)
      ) {
        return this.fields.filter(field => field.key !== "companies");
      }
      return this.fields;
    }
  },
  mounted() {
    this.getKeywords(true);
  }
};
</script>

<style></style>
