<template>
  <CSidebar
    fixed
    colorScheme
    :minimize="minimize"
    :show="show"
    dropdown-mode="closeInactive"
    @update:show="
      value => $store.commit('minimalize/set', ['sidebarShow', value])
    "
  >
    <CSidebarBrand class="" :to="logoHref">
      <img
        v-if="!this.minimize"
        class="addmention-logo-sidebar"
        src="/svg/logo_addmention.svg"
        alt="AddMention"
      />

      <img
        v-else
        class="addmention-logo-sidebar__phone p-2"
        src="/svg/logo_addmention.svg"
        alt="AddMention"
      />

      <font-awesome-icon
        class="close-icon text-dark"
        @click="$store.commit('minimalize/set', ['sidebarShow', false])"
        icon="fa-solid fa-xmark"
        size="2x"
      />
    </CSidebarBrand>
    <div class="loader" v-if="loading">Loading...</div>

    <CRenderFunction v-else flat :content-to-render="this.nav" />

    <SupportModal />
  </CSidebar>
</template>

<script>
import crud from "@/store/modules/crud";
import SupportModal from "@/components/ui/SupportModal";

export default {
  name: "TheSidebar",
  data() {
    return {
      loading: false,
      nav: [],
      logoHref: ""
    };
  },
  components: {
    SupportModal
  },
  computed: {
    show() {
      return this.$store.state.minimalize.sidebarShow;
    },
    minimize() {
      return this.$store.state.minimalize.sidebarMinimize;
    }
  },
  mounted: function() {
    if (this.nav && this.nav.length > 0) return; // already fetched.
    this.getMenu();
    //this.navFakeData();
  },
  created() {
    this.$root.$refs.loadMenu = this;

    this.logoHref = "/" + localStorage.view + "/dashboard";

    this.$root.$on("viewHasSwitched", data => {
      this.logoHref = "/" + data + "/dashboard";
    });

    this.$root.$on("updateTheSidebarComponent", () => {
      setTimeout(() => {
        this.getMenu();
      }, 2500);
    });
  },
  methods: {
    getMenu() {
      let self = this;
      self.loading = true;

      return crud.get("navigations/main").then(result => {
        let superadmin = self.$options.filters.isSuperAdmin(
          self.$store.state.auth.user.roles
        );
        self.nav = [];
        if (!superadmin) {
          // self.nav.push(result.data)
          self.nav.push(result.data[localStorage.getItem("view")]);
        } else {
          self.nav.push(result.data);
        }

        self.loading = false;

      });
    },
    toggleMinimize() {
      this.$store.commit("minimalize/set", ["sidebarMinimize", !this.minimize]);
    }
  }
};
</script>

<style scoped>
.addmention-logo-sidebar {
  max-width: 12rem;
  width: 100%;
  height: auto;
}

.close-icon {
  display: none;
}

@media only screen and (max-width: 991px) {
  .addmention-logo-sidebar {
    max-width: 12rem;
    width: 50%;
    height: auto;
  }
  .close-icon {
    display: block;
    position: absolute;
    right: 15px;
  }
}

.addmention-logo-sidebar__phone {
  max-width: 3rem;
  height: auto;
}
</style>
