<template>
  <div>
    <div class="row">
      <div class="col text-center mt-5 mb-5">
        <h2>{{ $t("support.support") }}</h2>
        <h4 class="text-grey">{{ $t("support.subtitle") }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 card-wrapper">
        <div class="card support-card  py-5">
          <div class="card-body px-5">
            <h3>{{ $t("support.publisher-faq") }}</h3>
            <p v-html="$t('support.publisher-faq-text')"></p>
            <a
              class="support-button mt-4"
              target="_blank"
              href="https://www.addmention.com/media.html#list02-13"
              >{{ $t("support.publisher-faq-button") }}</a
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 card-wrapper">
        <div class="card support-card py-5 ">
          <div class="card-body px-5">
            <h3>{{ $t("support.contact-us") }}</h3>
            <p v-html="$t('support.contact-us-text')"></p>
            <a
              class="support-button mt-4"
              href="mailto:support@addmention.com"
              >{{ $t("support.contact-us-button") }}</a
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 card-wrapper">
        <div class="card support-card py-5">
          <div class="card-body px-5">
            <h3>{{ $t("support.advertiser-faq") }}</h3>
            <p v-html="$t('support.advertiser-faq-text')"></p>
            <a
              class="support-button mt-4"
              target="_blank"
              href="https://www.addmention.com/inzerent.html#list02-y"
              >{{ $t("support.advertiser-faq-button") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.text-grey {
  color: rgb(139, 139, 139);
}
.support-card {
  position: relative;
  border-radius: 15px;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 15px 15px 0px 0px;
    width: 100%;
    height: 5px;
  }

  .support-button {
    color: white;
    display: block;
    text-align: center;
    width: 100%;
    border-radius: 15px;
    line-height: 2em;
    background: #00a99d;
    transition: backgorund 0.3s;
    &:hover {
      text-decoration: none;
    }
  }
}
.card-wrapper:nth-of-type(1) {
  .support-card {
    &::before,
    .support-button {
      background: rgb(0, 169, 157);
      background: linear-gradient(
        270deg,
        rgba(0, 169, 157, 1) 0%,
        rgba(0, 213, 167, 1) 100%
      );
    }
  }
}
.card-wrapper:nth-of-type(2) {
  .support-card {
    &::before {
      background: #00a99d;
    }
  }
}
.card-wrapper:nth-of-type(3) {
  .support-card {
    &::before,
    .support-button {
      background: rgb(0, 169, 157);
      background: linear-gradient(
        90deg,
        rgba(0, 169, 157, 1) 0%,
        rgba(0, 213, 167, 1) 100%
      );
    }
  }
}
</style>
