import crud from "@/store/modules/crud";
import i18n from "@/i18n";
export default {
  methods: {
    getCompanies() {
      let arr = [];

      crud.get("companies/select").then(function (response) {
        response.data.items.forEach(value => {
          arr.push({
            label: value.name,
            code: value.id
          });
        });
      });
      return arr;
    },

    getUsers() {
      let arr = [];

      crud.get("users/select").then(function (response) {
        response.data.forEach(value => {
          arr.push({
            label: value.full_name,
            code: value.id
          });
        });
      });
      return arr;
    },

    getKeywords() {
      let arr = [];

      crud.get("keywords/all").then(function (response) {
        response.data.items.forEach(value => {
          arr.push({
            label: value.word,
            code: value.id
          });
        })
      })
      return arr;
    },

    getCategories() {
      let arr = [];

      crud.get("google-categories/select").then(function (response) {
        response.data.forEach(value => {
          arr.push({
            name: i18n.t('category.'+value.title),
            code: value.id
          });
        })
      })
      return arr;
    }
  }
};
