//Views finance
import InvoiceIndex from "@/views/finance/invoice/InvoiceIndex.vue";
import TransactionIndex from "@/views/finance/transaction/TransactionIndex.vue";
import CreditIndex from "@/views/finance/credit/CreditIndex.vue";
import AddCredit from "@/views/finance/credit/AddCredit.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/finance/dashboard",
          meta: {
            label: "menu.dashboard-finance"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            // {
            //   path: "/finance/dashboard",
            //   name: null,
            //   component: FinanceDashboard,
            //   meta: {
            //     requiresAuth: true
            //   }
            // },
            {
              path: "/finance/invoice",
              name: "menu.invoices" + ": " + "crud.list",
              component: InvoiceIndex,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/finance/transaction",
              name: "menu.transactions" + ": " + "crud.list",
              component: TransactionIndex,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/finance/credit",
              name: "system.credit" + ": " + "crud.list",
              component: CreditIndex,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/finance/add-credit",
              name: "system.credit" + ": " + "add-credit",
              component: AddCredit,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
