//Views Companies
import Website from "../views/website/WebsiteIndex.vue";
import WebsiteCreate from "../views/website/WebsiteCreate.vue";
import WebsiteEdit from "../views/website/WebsiteEdit.vue";
//import WebsiteView from "../views/website/WebsiteView.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/websites",
          meta: {
            label: "menu.websites"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/websites",
              name: "menu.websites" + ": " + "crud.list",
              component: Website,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/websites/create",
              name: "menu.websites" + ": " + "crud.create",
              component: WebsiteCreate,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/websites/edit/:id",
              name: "menu.websites" + ": " + "crud.edit",
              component: WebsiteEdit,
              meta: {
                requiresAuth: true
              }
            } /*,
            {
              path: "/websites/view/:id",
              name: i18n.t("menu.websites") + ": " + i18n.t("crud.view"),
              component: WebsiteView,
              meta: {
                requiresAuth: true
              }
            }*/
          ]
        }
      ]
    }
  }
];
