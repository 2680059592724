<template>
  <span>
    <CIcon
      v-if="validate === true"
      name="cil-check"
      :class="invert ? 'text-white' : 'text-success'"
    />
    <CIcon v-if="validate === false" name="cil-ban" class="text-danger" />
    <small v-if="validate === false" class="text-danger">
      <template v-if="!message || message === '' || message === null">
        {{ $t("error.fill-field") }}
      </template>
      <template v-else>
        {{ message }}
      </template>
    </small>
  </span>
</template>
<script>
export default {
  name: "CheckIcon",
  props: ["validate", "invert", "message"]
};
</script>
<style scoped>
.text-white {
  color: #fff;
}
small {
  font-size: 10px;
  padding-left: 5px;
}
</style>
