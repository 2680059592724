<template>
  <ad-form @submit="login">
    <div class="container py-5 ">
      <div class="row text-center">
        <div class="col">
          <h2>{{$t('login.login')}}</h2>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-12 col-sm-8 col-md-6 col-lg-5">
          <p class="lead">
            {{$t('login.login-to-account')}}
          </p>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-12 col-sm-8 col-md-6 col-lg-5">
            <div class="row mt-4">
                <div class="col">
                    <ad-input :placeholder="$t('system.email')" type="email" :hasError="loginErrors.email.length > 0" :autoFocus="true" :errorMessage="loginErrors.email[0]" :rules="[rules.required, rules.email]" v-model="loginUser.email" />
                </div>
            </div>
            <div class="row mt-4">
                <div class="col">
                    <ad-input v-model="loginUser.password"  :hasError="loginErrors.password.length > 0" :errorMessage="loginErrors.password[0]" :rules="[rules.required]" :placeholder="$t('login.password')" type="password" class="mb-2" />
                </div>
            </div>
           
            <div class="row mt-4">
                <div class="col">
                    <ad-btn style="width:100%;" :title="$t('login.login-btn')" />
                </div>
            </div>
            <div class="row mt-4 text-center">
              <div class="col">
                <router-link to="/auth/sign-up">{{$t('register.registration')}}</router-link> | 
                <router-link to="/auth/forgot-password">{{$t('login.forgot-password')}}</router-link> 
              </div>
            </div>
        </div>
      </div>
    </div>
    </ad-form>

</template>

  <script>
  import AdInput from '../../components/ui/v_2/AdInput.vue';
  import rules from '@/mixins/rules.js'
  import auth from '@/mixins/auth.js'
  import AdBtn from '../../components/ui/v_2/AdBtn.vue';
  import AdForm from '../../components/ui/v_2/AdForm.vue'
  // import crud from "@/store/modules/crud.js";
  export default {
    mixins: [rules, auth],
    components: {
        AdInput,
        AdBtn,
        AdForm
    },
  }
  </script>
  
  <style lang="scss" scoped>
  h2 {
    font-weight: bold;
  }
  </style>