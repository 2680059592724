<template>
  <div>
    <dynamic-filter
        :inputs="dynamicFilterInputs"
        v-on:params-updated="processEmit"
    />

    <pre-loader
        v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
        :loading="this.performance.loading"
        :success="this.performance.success"
        :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
          :headers="this.tableHeaders"
          :items="this.table.items"
          :links="this.table.links"
          :meta="this.table.meta"
          :query="this.query"
          v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>
  </div>
</template>

<script>
import DataViewer from "@/components/ui/DataViewer";
import PreLoader from "@/components/ui/PreLoader";
import crud from "@/store/modules/crud.js";
import i18n from "@/i18n";
import helpers from "@/mixins/helpers";
import DynamicFilter from "@/components/ui/DynamicFilter.vue";
import selectbox from "@/mixins/selectbox";

export default {
  mixins: [selectbox],
  created() {
    this.loadTable();

    this.dynamicFilterInputs[2].value = this.getCompanies();
    this.dynamicFilterInputs[1].value = this.getUsers();
    this.dynamicFilterInputs[0].value = this.getCategories();
  },
  data() {
    return {
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "title",
          translate: i18n.t("interests.admin-category"),
          sorter: true,
          style: "width: 150px !important;",
          hint: null,
          mobile: {
            class: "col-12"
          }
        },{
          key: "user",
          translate: i18n.t("interests.admin-user"),
          sorter: true,
          style: null,
          hint: null,
          href: {
            link: "/users/view/",
            identifier: "user_id"
          },
          mobile: {
            class: "col-6"
          }
        },{
          key: "company",
          translate: i18n.t("interests.admin-company"),
          sorter: true,
          style: null,
          hint: null,
          href: {
            link: "/companies/view/",
            identifier: "company_id"
          },
          mobile: {
            class: "col-6"
          }
        },{
          key: "created_at",
          translate: i18n.t("interests.created_at"),
          sorter: true,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        }
      ],
      dynamicFilterInputs: [
        {
          name: "category",
          type: "select2",
          label: i18n.t("interests.admin-category"),
          value: [],
        },{
          name: "user",
          type: "select2",
          label: i18n.t("interests.admin-user"),
          value: [],
        },{
          name: "company",
          type: "select2",
          label: i18n.t("interests.admin-company"),
          value: [],
        },{
          name: "from",
          type: "date",
          label: i18n.t("interests.admin-from"),
          value: null,
        },{
          name: "to",
          type: "date",
          label: i18n.t("interests.admin-to"),
          value: null,
        }
      ]
    };
  },
  components: {
    DynamicFilter,
    DataViewer,
    PreLoader,
  },
  methods: {
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "";
      let filter = this.query.filter ? this.query.filter : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud
          .get("google-categories/admin" + page + filter + sort)
          .then(function(response) {

            response.data.items.forEach(item => {
              if(item.created_at !== null) {
                item.created_at = helpers.methods.getDate(item.created_at, 'lll');
              }
            });
            self.table = response.data;
            self.performance.loading = false;
          })
          .catch(error => {
            self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
            self.performance.success = false;
          });
    },
    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      if (data.filter) {
        this.query.filter = data.filter;

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }

      // Emited action
      if (data.action) {
        let self = this;

        self.performance.loading = true;

        crud.post(data.action).then(() => {
          self.loadTable();
        });
      }
    },

  }
};
</script>

<style></style>
