<template>
  <div>
    <div class="row mt-5">
      <div class="col-lg-12">
        <div class="loader" v-if="this.staticLoading">Loading...</div>
        <div v-else :class="`${responsive ? 'table-responsive' : ''}`">
          <table :class="tableClasses">
            <thead class="table-light">
              <slot name="thead-top" />
              <tr v-if="header">
                <template v-for="(name, index) in columnNames">
                  <th
                    :key="index"
                    :style="headerStyles(index)"
                    :class="{ 'bg-dark': view === 'view' }"
                  >
                    <div
                      v-if="sorter[index] !== false"
                      class="header-sort"
                      :class="{
                        selected: rawColumnNames[index] === sortColumn
                      }"
                      @click="changeSort(rawColumnNames[index])"
                    >
                      <span
                        class="sort"
                        :class="{
                          [sortOrder]: rawColumnNames[index] === sortColumn
                        }"
                        ><i class="fa fa-long-arrow-down" aria-hidden="true"></i
                      ></span>
                      <slot :name="`${rawColumnNames[index]}-header`">{{
                        name
                      }}</slot>
                    </div>
                    <template v-else>
                      <slot :name="`${rawColumnNames[index]}-header`">{{
                        name
                      }}</slot>
                    </template>
                  </th>
                </template>
              </tr>
            </thead>

            <tbody>
              <template v-if="this.items.length === 0">
                <tr>
                  <td :colspan="fields.length">
                    <div class="text-center my-5">
                      <h2 v-if="defaultText === undefined">
                        <svg
                          data-v-431cdece=""
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          role="img"
                          class="text-danger mb-2 c-icon c-icon-custom-size"
                          width="30"
                        >
                          <path
                            fill="var(--ci-primary-color, currentColor)"
                            d="M425.706,86.294A240,240,0,0,0,86.294,425.705,240,240,0,0,0,425.706,86.294ZM256,48A207.1,207.1,0,0,1,391.528,98.345L98.345,391.528A207.1,207.1,0,0,1,48,256C48,141.309,141.309,48,256,48Zm0,416a207.084,207.084,0,0,1-134.986-49.887l293.1-293.1A207.084,207.084,0,0,1,464,256C464,370.691,370.691,464,256,464Z"
                            class="ci-primary"
                          ></path>
                        </svg>
                        <p class="mt-3">
                          <template v-if="this.errorMessage">
                            {{ this.errorMessage }}
                          </template>

                          <template v-else>
                            {{ $t("error.no_matches_found") }}
                          </template>
                        </p>
                      </h2>
                      <h5 v-else>
                        <p v-html="defaultText"></p>
                      </h5>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else v-for="(item, itemIndex) in getItems">
                <tr :key="itemIndex">
                  <template v-for="(colName, index) in rawColumnNames">
                    <template v-if="$scopedSlots[colName]">
                      <slot :name="colName" :item="item" :index="itemIndex" />
                    </template>
                    <td v-else :key="index">{{ String(item[colName]) }}</td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <DataTableInfo
          v-if="!loading && pagination"
          :totalCount="this.page.totalCount"
          :perPage="this.page.perPage"
          :pageCount="this.items.length"
          :currentPage="this.page.currentPage"
          :paginationSection="this.page.section"
          :paginationType="this.paginationType"
          class="footer-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DataTableInfo from "@/components/ui/DataTableInfo";
import i18n from "@/i18n";
import moment from "moment";

//row[valueType]:  enumerator, boolean, date, count

export default {
  name: "DataTable",
  components: {
    DataTableInfo
  },
  props: {
    loading: Boolean,
    items: Array,
    defaultText: String,
    fields: Array,
    rows: Array,
    page: Object,
    responsive: {
      type: Boolean,
      default: true
    },
    view: {
      type: String,
      default: "list" //list - index, view - view
    },
    header: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Boolean,
      default: true
    },
    paginationType: {
      type: String,
      default: "default" //default - from url, api - from api
    },
    addTableClasses: [String, Array, Object],
    size: String,
    dark: Boolean,
    striped: Boolean,
    fixed: Boolean,
    hover: {
      type: Boolean,
      default: true
    },
    border: Boolean,
    sortBy: Object,
    errorMessage: String
  },
  data() {
    return {
      passedItems: this.items || [],
      sortColumn: this.sortBy["column"] || null,
      sortOrder: !this.sortBy["asc"] ? "desc" : "asc",
      staticLoading: true
    };
  },
  created() {
    setTimeout(() => {
      if (this.getItems.length > 0) {
        this.staticLoading = false;
      } else {
        // Fix infinity loading
        this.staticLoading = false;
      }
    }, 5000);
  },
  methods: {
    changeSort(column) {
      let order;

      if (column !== this.sortColumn) {
        order = "asc";
      } else if (column === this.sortColumn && this.sortOrder === "asc") {
        order = "desc";
      } else {
        order = "asc";
      }

      this.sortColumn = column;
      this.sortOrder = order;

      let query = this.$route.query;
      delete query.sort;

      let sort = order === "asc" ? column : "-" + column;
      sort = { sort };

      this.$router.push({ path: this.$route.path });
      this.$router.push({ query: Object.assign(query, sort) });

      this.$parent.sort();
    },
    headerStyles(index) {
      let style = "";
      if (this.fields && this.fields[index] && this.fields[index]._style) {
        style += this.fields[index]._style;
      }
      return style;
    },
    objectsAreIdentical(obj1, obj2) {
      return (
        obj1.length === obj2.length &&
        JSON.stringify(obj1) === JSON.stringify(obj2)
      );
    },
    pretifyName(name) {
      return name
        .replace(/[-_.]/g, " ")
        .replace(/ +/g, " ")
        .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
  },
  computed: {
    tableClasses() {
      return [
        "table",
        this.addTableClasses,
        {
          [`table-${this.size}`]: this.size,
          "table-dark": this.dark,
          "table-striped": this.striped,
          "table-fixed": this.fixed,
          "table-hover": this.hover,
          "table-bordered": this.border,
          border: this.outlined
        }
      ];
    },
    generatedColumnNames() {
      return Object.keys(this.passedItems[0] || {}).filter(
        el => el.charAt(0) !== "_"
      );
    },
    rawColumnNames() {
      if (this.fields) {
        return this.fields.map(el => el.key || el);
      }
      return this.generatedColumnNames;
    },
    columnNames() {
      if (this.fields) {
        return this.fields.map(f => {
          return f.label !== undefined ? f.label : this.pretifyName(f.key || f);
        });
      }
      return this.rawColumnNames.map(el => this.pretifyName(el));
    },
    sorter() {
      if (this.fields) {
        return this.fields.map(el => el.sorter);
      }
      return [];
    },
    getItems() {
      return this.view === "view" ? this.viewItems : this.items;
    },
    viewItems() {
      let self = this;
      let obj = [];
      let objData = [];
      let data = this.items[0];

      Object.keys(data).forEach(key => {
        let item = data[key];
        item =
          item === null || item === "undefined" || item.length === 0
            ? "---"
            : item;

        if (item !== null && typeof item === "object") {
          let el = this.rows.find(function(value) {
            if (value.key === key) {
              return key;
            }
          });

          if (el.valueType === "count") {
            objData = item.length;
          }

          obj.push({ data: key, value: objData });
        } else {
          this.rows.find(function(value, index) {
            if (value.key === key) {
              key = value.label;
              if (self.rows[index].valueType === "enumerator") {
                item = self.rows[index].value[item];
              } else if (self.rows[index].valueType === "date") {
                console.log(item)
                if(item !== "---"){
                  item = moment(item).locale('sk').format('lll');
                }
              } else if (self.rows[index].valueType === "boolean") {
                item =
                  item === 1 || item === true
                    ? i18n.t("system.yes")
                    : i18n.t("system.no");
              }
            }
          });
          obj.push({ data: key, value: item });
        }
      });

      return obj;
    }
  },
  watch: {
    items(val, oldVal) {
      if (val && oldVal && this.objectsAreIdentical(val, oldVal)) {
        return;
      }
      this.staticLoading = false;
      this.passedItems = val || [];
    }
  }
};
</script>
