<template>
  <div>
    <router-view />

    <OldNotifications />

    <notifications
      :position="this.screenWidth > 768 ? 'bottom left' : 'top center'"
      animation-type="velocity"
      :ignore-duplicates="true"
    />
  </div>
</template>

<script>
import OldNotifications from "@/components/notifications/NotificationsList";

export default {
  name: "App",

  components: {
    OldNotifications
  },
  data() {
    return {
      screenWidth: window.innerWidth
    };
  }
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
