<template>
  <div class="">
    <div class="row">
      <div class="col">
        <!-- @click.prevent="goBack" -->
        <router-link :to="'/' + this.userMode + '/orders'">
          <font-awesome-icon icon="fa-solid fa-arrow-left" />
          {{ $t("system.back") }}
        </router-link>
        <ad-btn-dropdown
          appendIcon="fa-solid fa-caret-down"
          class="text-left"
          :label="$t('system.settings')"
        >
          <ad-list>
            <ad-list-item
              v-if="!isSuperAdmin"
              clickable
              @click="reportModal = true"
              class="text-center"
            >
              <font-awesome-icon
                icon="fa-regular fa-circle-question"
                class="mr-2"
              />
              <span>{{ $t("error.report-problem") }}</span>
            </ad-list-item>

            <!-- Cancel auction -->
<!--            <ad-list-item-->
<!--              v-if="isSuperAdmin || (this.orderData.actions && this.orderData.actions.cancel)"-->
<!--              clickable-->
<!--              @click="cancelAuction()"-->
<!--              class="text-center"-->
<!--            >-->
<!--              <font-awesome-icon-->
<!--                icon="fa-solid fa-xmark"-->
<!--                class="mr-2"-->
<!--                style="color: red;"-->
<!--              />-->
<!--              <span>{{ $t("auctions.cancel-auction") }}</span>-->
<!--            </ad-list-item>-->
          </ad-list>
        </ad-btn-dropdown>
      </div>
    </div>
    <div class="row py-3">
      <div class="col">
        <h2>{{ $t("order.order-number", { order: $route.params.id }) }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8 row">
        <!-- Comments -->
        <div
          class="switch-buttons"
          :class="{'active-button': tabs.comments }"
          @click="Object.keys(tabs).forEach(v => tabs[v] = false); tabs.comments = true;"
          >{{ $t("order.discussion") }}
        </div>
        <!-- Order -->
        <div
          class="switch-buttons"
          :class="{'active-button': tabs.order}"
          @click="Object.keys(tabs).forEach(v => tabs[v] = false); tabs.order = true;"
        >
          {{ $t("order.order") }}</div
        >
        <!-- Stats -->
<!--        <div-->
<!--          class="switch-buttons"-->
<!--          :class="{'active-button': tabs.stats }"-->
<!--          @click="Object.keys(tabs).forEach(v => tabs[v] = false); tabs.stats = true;"-->
<!--        >-->
<!--          {{ $t("order.stats") }}</div-->
<!--        >-->
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <order-comments
            v-if="tabs.comments"
            v-on:order-updated="onUpdateOrder"
            v-on:complain="reportModal = true"
            v-bind:order="orderData" />

        <order-detail v-if="tabs.order" :order="orderData"/>

<!--        <order-stats v-if="tabs.stats" :order="orderData"/>-->
      </div>
    </div>
    <Modal
      v-if="reportModal"
      @close="reportModal = false"
      @save="submitReportModal()"
      color="bg-secondary"
      :title="$t('error.report-problem')"
      :button-text="$t('system.confirm')"
      class="text-left"
    >
      <div class="input-box bg-invert">
        <textarea
          class="input-1"
          style="height: 5rem;"
          v-model="reportMessage"
          :placeholder="$t('error.report-problem') + ' ...'"
        ></textarea>
      </div>
    </Modal>

    <!-- @save="publisherCancelBid($route.params.id, cancelMessage)" -->
    <Modal
      v-if="cancelBidModal && imPublisher"
      @close="cancelBidModal = false"
      @save="cancelAuction(cancelMessage)"
      color="bg-secondary"
      :title="$t('order.publisher-cancel-text')"
      :button-text="$t('system.confirm')"
      class="text-left"
    >
      <div class="input-box bg-invert">
        <textarea
          class="input-1"
          style="height: 5rem;"
          v-model="cancelMessage"
          :placeholder="$t('order.publisher-cancel-text') + ' ...'"
        ></textarea>
      </div>
    </Modal>
  </div>
</template>

<script>
import AdBtnDropdown from "../../components/ui/v_2/AdBtnDropdown.vue";
import AdList from "../../components/ui/v_2/list/AdList.vue";
import AdListItem from "../../components/ui/v_2/list/AdListItem.vue";
import i18n from "@/i18n";
import crud from "@/store/modules/crud.js";
import Modal from "@/components/ui/Modal";
import { mapGetters } from "vuex";
import processOrder from "@/mixins/processOrder.js";
import OrderComments from "@/views/order/OrderComments";
import OrderDetail from "@/views/order/OrderDetail";
import store from "@/store";

export default {
  name: "Index",
  components: {
    AdBtnDropdown,
    AdList,
    AdListItem,
    Modal,
    OrderComments,
    OrderDetail,
  },
  mixins: [processOrder],
  data() {
    return {
      reportModal: false,
      cancelBidModal: false,
      reportMessage: null,
      cancelMessage: null,
      orderData: {},
      tabs: {
        comments: true,
        order: false,
        stats: false
      }
    };
  },
  mounted () {
    this.getOrder();

  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      status: "view/status"
    }),
    imPublisher() {
      // return this.user.id === this.item.auction.user_id && this.status === 'publisher'
      return this.userMode === 'publisher'
    },
    imAdvertiser() {
      if (this.status === "publisher") {
        return false;
      }
      return this.user.id === this.orderData.user.id && this.status === "advertiser";
    },
    userMode() {
      if(store.getters["auth/isSuperAdmin"]){
        return 'admin';
      }
      return this.$route.params.type;
    },
    isSuperAdmin() {
      return store.getters["auth/isSuperAdmin"];
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    submitReportModal() {
      if (this.reportMessage !== null && this.reportMessage !== " ") {
        let self = this;

        let obj = {
          id: this.$route.params.id,
          url: window.location.href,
          message: this.reportMessage
        };

        // Close modal
        self.reportModal = false;

        crud
          .post(`bid/${this.$route.params.type}/complaint/${this.orderData.id}`, obj)
          .then(function() {
            // Clear message
            self.reportMessage = null;

            self.addNotification(
              {
                type: "success",
                message: i18n.t("success.problem-reported")
              },
              { root: true }
            );
          })
          .catch(() => {
            self.addNotification({
              type: "danger",
              message: i18n.t("error.oops")
            });
          });
      }
    },
    getOrder() {
      crud.get('/bid/' + this.userMode + '/' + this.$route.params.id).then(response => {
        this.orderData = response.data.items;
      }); 
    },
    cancelAuction(message) {
      // Ask confirm
      if (!confirm("Do you want to confirm the action?")) {
        return;
      }

      crud.post('/bid/' + this.userMode + '/cancel/' + this.$route.params.id, (message) ? { message } : "").then(() => {
        this.$notify({ type: "success", text: i18n.t("success.record-canceled") });
        this.$router.push("/" + this.userMode + "/orders");
      });
    },
    onUpdateOrder(data) {
      Object.assign(this.orderData, data);
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: 600;
}
.switch-buttons {
  color: #000000d9;
  font-weight: 600;
  position: relative;
  padding: 10px 25px;
  font-size: 1.1rem;

  &:hover {
    text-decoration: none;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: #00a490;
      bottom: 0;
      left: 0;
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 1px;
    background: #00a490;
    bottom: 0;
    left: 0;
    transition: all 0.3s;
  }
}

.switch-buttons.active-button {
  color: #00a490;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: #00a490;
    bottom: 0;
    left: 0;
  }
}

.separator {
  width: 100%;
  height: 1px;
  margin-top: 6px !important;
  background: #e9edef;
}
</style>
