<template>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ (bonus) ? $t("credit.manage_bonus_credit") : $t("credit.manage_credit") }}
          </h5>
          <button
            @click="closeManageCreditModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- Modal Content -->
          <div class="form-row input-box bg-invert-white">
            <div class="col-md-6">
              <!-- v-select -->
              <v-select
                ref="search"
                :options="this.companies"
                v-model="selectedCompany"
                class="v-select-custom"
              ></v-select>

              <label class="input-label">
                {{ $t("register.company") }}
              </label>
            </div>

            <div class="col-md-6">
              <select class="form-select input-1" v-model="selectedType">
                <option selected>-</option>
                <option :value="(bonus) ? 7 : 3">
                  {{ transactionType((bonus) ? 7 : 3) }}
                </option>
                <option :value="(bonus) ? 8 : 4">
                  {{ transactionType((bonus) ? 8 : 4) }}
                </option>
              </select>

              <label class="input-label">
                {{ $t("finance.type") }}
              </label>
            </div>
          </div>

          <div class="form-row input-box bg-invert-white mt-3">
            <div class="col-md-12">
              <input type="text" class="input-1" v-model="selectedValue" />
              <label class="input-label">
                {{ $t("system.value") }}
              </label>
            </div>
          </div>

          <div 
            v-if="bonus"
            class="form-row input-box bg-invert-white mt-3"
          >
            <div class="col-md-12">              
              <input type="date" class="input-1" v-model="dateInput">
              <label class="input-label">
                {{ $t("system.date") }}
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="closeManageCreditModal()"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            {{ $t("system.cancel") }}
          </button>

          <button
            @click="updateCredit()"
            type="button"
            class="btn btn-success text-white"
          >
            {{ $t("credit.update_credit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import vSelect from "vue-select";
import siiimpleToast from "@/utils/toast";

export default {
  components: {
    vSelect
  },

  props: ["companies", "bonus"],

  data() {
    return {
      selectedCompany: "-",
      selectedType: "-",
      selectedValue: 0,
      dateInput: null
    };
  },

  methods: {
    transactionType(id) {
      return this.$store.state.enumerators.enumerators["transaction-type"][id];
    },

    closeManageCreditModal() {
      this.$emit("closeManageCreditModalEmit");
    },

    updateCredit() {
      if (
        this.selectedCompany.code == "-" ||
        this.selectedType == "-" ||
        this.selectedValue == 0
      ) {
        return;
      }

      let self = this;

      let obj = {
        company_id: this.selectedCompany.code,
        type: Number(this.selectedType),
        value: Number(this.selectedValue)
      };

      (this.bonus) ? obj.expire_at = this.dateInput : null;

      let api_route = (this.bonus) ? "transaction/bonus-credit" : "transaction/credit";

      crud.post(api_route, obj).then(function(response) {
        if (response.status == 200) {
          // success
          siiimpleToast.success("Credit successfully updated!");
          self.$emit("closeManageCreditModalEmit");
        } else {
          // error
          siiimpleToast.alert("Oops, something went wrong.");
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.modal .btn {
  border-radius: 4px;
}
</style>
