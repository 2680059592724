<template>
  <div v-if="this.items.length > 0" class="mt-5 dataTable" id="newTableElement">
    <simplebar data-simplebar-auto-hide="false" class="table_container">
    <table class="table">
        <colgroup>
          <col :class="{'col-desktop-width-50' : tableConfig.col == (index+1) }" :style="tableConfig.style" v-for="(item, index) in headers" :key="index" />
        </colgroup>
        <thead>
          <tr>
            <!-- Table head top -->
            <slot name="tableHeadTop" />

            <!-- Mobile -->
            <th class="text-center" id="mobileTableHead">
              <span v-for="(item, index) in this.mobileSorter" :key="index">
                <span
                  :class="sortedBy == item.key ? 'sortActive' : null"
                  class="d-block"
                >
                  <a @click="sortBy(item)" style="cursor: pointer;">
                    {{ item.translate }}
                  </a>

                  <small class="mt-1 ml-1">
                    <!-- Dynamic sort icons -->
                    <span v-if="sortedBy == item.key">
                      <font-awesome-icon
                        @click="resetSort"
                        icon="fa-solid fa-xmark"
                        class="mr-2"
                        color="red"
                        style="cursor: pointer;"
                      />

                      <font-awesome-icon
                        icon="fa-solid fa-arrow-up"
                        v-if="sortedByWithSpecialChart.includes('-')"
                      />

                      <font-awesome-icon icon="fa-solid fa-arrow-down" v-else />
                    </span>

                    <!-- Default sort icon -->
                    <font-awesome-icon
                      icon="fa-solid fa-arrows-up-down"
                      v-else
                    />
                  </small>
                </span>
              </span>
            </th>

            <!-- Desktop -->
            <th
              id="desktopTableHead"
              class="th-desktop"
              scope="col"
              v-for="(item, index) in headers"
              :key="index"
              :style="item.style"
            >
              <div>
                <span
                  v-if="item.sorter"
                  :class="sortedBy == item.key ? 'sortActive' : null"
                >
                  <a @click="sortBy(item)" style="cursor: pointer;">
                    {{ item.translate }}
                  </a>

                  <small class="mt-1 ml-1">
                    <!-- Dynamic sort icons -->
                    <span v-if="sortedBy == item.key">
                      <font-awesome-icon
                        @click="resetSort"
                        icon="fa-solid fa-xmark"
                        class="mr-2"
                        color="red"
                        style="cursor: pointer;"
                      />

                      <font-awesome-icon
                        icon="fa-solid fa-arrow-up"
                        v-if="sortedByWithSpecialChart.includes('-')"
                      />

                      <font-awesome-icon icon="fa-solid fa-arrow-down" v-else />
                    </span>

                    <!-- Default sort icon -->
                    <font-awesome-icon
                      icon="fa-solid fa-arrows-up-down"
                      v-else
                    />
                  </small>
                </span>

                <template v-else>
                  {{ item.translate }}
                </template>

                <tooltip
                  v-if="item.hint"
                  :text="item.hint"
                  location="right"
                  color="secondary"
                  class=""
                ></tooltip>
              </div>
              <!-- <div class="resizable-handle">

              </div> -->
            </th>

            <!-- Table head bottom -->
            <slot name="tableHeadBottom" />
          </tr>
        </thead>
        <tbody>
          <!-- Record line -->

          <!-- Mobile -->
          <tr
            id="mobileRecordDetail"
            v-for="(item, index) in items"
            :key="'phone_items_' + index"
            @click="clickRow(item)" 
            :style="{ cursor: clickableRow ? 'pointer !important': 'default'}"
          >
            <div class="row mt-3">
              <div
                v-for="(phoneHeader, index) in mobileHeaders"
                :key="'phone_headers_' + index"
                :class="phoneHeader.mobile.class"
                class="my-2"
              >
                <!-- text-center -->
                <small
                  v-if="!phoneHeader.href"
                  class="pb-2"
                  :class="{ 'd-block': phoneHeader.mobile.display !== 'inline' }"
                  style="opacity: 0.5;"
                >
                  {{ phoneHeader.translate }}
                </small>

                <!-- If router link -->
                <router-link
                  v-if="phoneHeader.href && clickableRow === false"
                  :to="
                    phoneHeader.href.link + item[phoneHeader.href.identifier]
                  "
                  v-html="generateString(item, phoneHeader.key)"
                >
                </router-link>

                <!-- If not router link -->
                <template v-else>
                  <!-- Generate buttons -->
                  <span
                    v-if="
                      typeof generateString(item, phoneHeader.key) ===
                        'object' && phoneHeader.key == 'actions'
                    "
                  >
                    <span
                      v-for="(obj, index) in phoneHeader.buttons"
                      :key="index"
                    >
                      <template v-if="item.actions[obj.key]">
                        <a
                          v-if="obj.href"
                          :class="obj.class"
                          :href="item[obj.link]"
                        >
                          {{ obj.translate }}
                        </a>
                        <router-link
                          v-else-if="!obj.emit && !obj.regex"
                          :to="obj.link + item[obj.identifier]"
                          :class="obj.class"
                        >
                          {{ obj.translate }}
                        </router-link>
                        <router-link
                          v-else-if="!obj.emit && obj.regex"
                          :to="updateRegexLink(obj, item)"
                          :class="obj.class"
                        >
                          {{ obj.translate }}
                        </router-link>
                        <button
                          v-else
                          @click="
                            emitAction(
                              obj.link + item[obj.identifier],
                              obj,
                              item
                            )
                          "
                          :class="obj.class"
                        >
                          {{ obj.translate }}
                        </button>
                      </template>
                    </span>
                  </span>

                  <span
                    v-else-if="
                      typeof generateString(item, phoneHeader.key) === 'object'
                    "
                  >
                    <span
                      v-for="(field, index) in phoneHeader.options"
                      :key="index"
                    >
                      <template v-if="field == 'font-awesome-icon'">
                        <font-awesome-icon
                          :icon="item[phoneHeader.key][field]['icon']"
                          :class="item[phoneHeader.key][field]['class']"
                          class="mr-2"
                        />
                      </template>
                      <template v-else>
                        {{ generateString(item, phoneHeader.key)[field] }}
                      </template>
                    </span>
                  </span>

                  <span :style="{
                    float: phoneHeader.mobile.display === 'inline' ? 'right' : '',
                    'margin-right': phoneHeader.mobile.display === 'inline' ? '4em' : ''
                  }"

                        v-else v-html="generateString(item, phoneHeader.key)">
                  </span>
                </template>
              </div>
            </div>
          </tr>
          <!-- Record line -->

          <!-- Desktop -->
          <tr
            id="desktopRecordDetail"
            v-for="(item, index) in items"
            :key="index"
            :style="{ cursor: clickableRow == true ? 'pointer !important': 'default'}"
            @click="clickRow(item)"
          >
            <!-- Record detail -->
            <slot name="tableBodyTop" v-bind:item="item" />

            <td v-for="(header, index) in headers" :key="index" >
              <span style="display: flex; justify-content: space-between;" :style="header.cell_style">
                <!-- If router link -->
                <router-link
                  v-if="header.href && clickableRow === false && typeof header.href.callback === 'function' &&
                        header.href.callback(item, item[header.href.identifier]) !== null"
                  v-html="generateString(item, header.key)"
                  :to="header.href.callback(item, item[header.href.identifier])"
                >
                </router-link>

                <router-link
                  v-else-if="header.href && clickableRow === false && Number.isInteger(item[header.href.identifier]) &&
                             typeof header.href.callback !== 'function'"
                  v-html="generateString(item, header.key)"
                  :to="header.href.link + item[header.href.identifier]"
                >
                </router-link>

                <!-- If not router link -->
                <template v-else>
                  <!-- Generate buttons -->
                  <span
                    v-if="
                      typeof generateString(item, header.key) === 'object' &&
                        header.key == 'actions'
                    "
                  >
                    <span v-for="(obj, index) in header.buttons" :key="index">
                      <button :class="obj.class + ' ' + (item[obj.isActive] ? obj.activeClass : '')" v-if="obj.key == 'action'"  @click="emitToParent(obj.emitName, obj.identifiers, item)">
                          <font-awesome-icon v-if="obj.icon"
                            :icon="obj.icon  + (item[obj.isActive] ? obj.activeIcon : '')"
                            class="position-absolute"
                          />
                          <span v-if="!obj.onlyIcon">
                            {{obj.translate}}
                          </span>
                      </button>
                      <template v-if="item.actions[obj.key]">
                        <a
                          v-if="obj.href"
                          :class="obj.class"
                          :href="item[obj.link]"
                        >
                          {{ obj.translate }}
                        </a>
                        <router-link
                          v-else-if="!obj.emit && !obj.regex"
                          :to="obj.link + item[obj.identifier]"
                          :class="obj.class"
                        >
                          {{ obj.translate }}
                        </router-link>
                        
                        <router-link
                          v-else-if="!obj.emit && obj.regex"
                          :to="updateRegexLink(obj, item)"
                          :class="obj.class"
                        >
                          {{ obj.translate }}
                        </router-link>
                        <button
                          v-else
                          @click="
                            emitAction(
                              obj.link + item[obj.identifier],
                              obj,
                              item
                            )
                          "
                          :class="obj.class"
                        >
                          {{ obj.translate }}
                        </button>
                      </template>
                    </span>
                  </span>

                  <span
                    v-else-if="
                      typeof generateString(item, header.key) === 'object'
                    "
                  >
                    <span v-for="(field, index) in header.options" :key="index">
                      <template v-if="field == 'font-awesome-icon'">
                        <font-awesome-icon
                          :icon="item[header.key][field]['icon']"
                          :class="item[header.key][field]['class']"
                          class="mr-2"
                        />
                      </template>
                      <template v-else>
                        {{ generateString(item, header.key)[field] }}
                      </template>
                    </span>
                  </span>

                  <span v-else v-html="generateString(item, header.key)">
                  </span>
                </template>

                <!-- Disabled Tooltip -->
                <!-- <tooltip 
                    v-if="generateString(item, header.key).length > 72"
                    :text="item[header.key]" 
                    location="left" 
                    color="secondary" 
                    class="ml-1 mb-1"
                ></tooltip> -->
              </span>
            </td>

            <slot name="tableBodyBottom" v-bind:item="item" />
          </tr>
        </tbody>
      </table>
    </simplebar>

    <!-- Paginate -->
    <template v-if="this.footer !== false">
    <div v-if="this.meta.total" class="table_footer px-3 py-4">
      <!-- Meta -->
      <div style="color: rgba(0, 0, 0, 0.85);">
        {{ this.meta.from }}-{{ this.meta.to }} {{ $t("system.of") }}
        {{ this.meta.total }} {{ $t("system.offers") }}
      </div>

      <!-- Paginate -->
      <div style="color: rgba(0, 164, 144, 1); fill: rgba(0, 164, 144, 1);">
        <!-- <vs-pagination 
          :total="this.meta.last_page" 
          v-model="this.meta.current_page"
          @input="updatePagination"
          icon-pack="fa"
          prev-icon="fa-solid fa-angle-left" 
          next-icon="fa-solid fa-angle-right"
          :max="(this.innerWidth > 991) ? 9 : 7"
        ></vs-pagination> -->

        <a
          v-if="this.meta.current_page > 1"
          @click="updatePagination(-1)"
          style="cursor: pointer;"
          class="mr-4"
        >
          <font-awesome-icon icon="fa-solid fa-chevron-left" class="mr-2" />
          {{ $t("system.previous") }}
        </a>

        <a
          v-if="this.meta.current_page !== this.meta.last_page"
          @click="updatePagination(1)"
          style="cursor: pointer;"
        >
          {{ $t("system.next") }}
          <font-awesome-icon icon="fa-solid fa-chevron-right" class="ml-2" />
        </a>
      </div>
    </div>

    <div v-else class="table_footer px-3 py-4">
      <div>
        <b>{{ $t("system.total-count") }}:</b> {{ this.items.length }}
      </div>
    </div>
  </template>

</div>

  <pre-loader class="mt-5"
    v-else
    :loading="false"
    :success="false"
    :errorMessage="$t(emptyMessage)"
    :error-message-link="emptyMessageLink"
  />
</template>

<script>
import tooltip from "@/components/ui/Tooltip";
import "simplebar/dist/simplebar.min.css";
import PreLoader from "@/components/ui/PreLoader";
import simplebar from "simplebar-vue";

export default {
  props: {
    headers: {},
    items: {},
    links: {},
    meta: {},
    query: {},
    emptyMessage: {
      default: 'error.no_matches_found'
    },
    emptyMessageLink: {
      default: false
    },
    footer: {
      default: true
    },
    tableConfig:{
      type: Object,
      default:  () => {
        return {
          col: 1,
          width: 50
        }
      }
    },
    clickableRow: {
      type: Boolean,
      default: false
    }
  },

  components: { tooltip, simplebar, PreLoader },

  data() {
    return {
      innerWidth: window.innerWidth,
      sortedByWithSpecialChart: this.query ? this.query.sort : null
    };
  },

  mounted() {
    if (window.location.search) {
      this.getJsonFromUrl();
    }
    
  },

  computed: {
    sortedBy: function() {
      return this.query.sort.replace("-", "");
    },
    mobileHeaders: function() {
      var newArray = this.headers.filter(header => header.mobile);

      return newArray;
    },
    mobileSorter: function() {
      var newArray = this.headers.filter(header => header.mobile.sorter ?? header.sorter);

      return newArray;
    }
  },

  methods: {
    clickRow(item) {
      if(!this.clickableRow) {
        return;
      }
      const clickableHeader = this.headers.filter(header => header.href !== undefined)
      this.$router.push({path: clickableHeader[0].href.link + item[clickableHeader[0].href.identifier]})
    },
    updateRegexLink(obj, item) {
      // eslint-disable-next-line
        return obj.link.replace(/(\:\w+)/, item[obj.identifier]);
    },
    generateString(item, key) {
      // If string contain HTML
      if (/<\/?[a-z][\s\S]*>/i.test(item[key])) {
        return item[key];
      }

      // If string does not contain HTML
      if (item[key] == null) {
        return "N/A";
      } else {
        if (item[key].length > 72 && this.innerWidth > 991) {
          return item[key].substring(0, 72) + " ...";
        } else {
          return item[key];
        }
      }
    },
    // updatePagination(evt) {
    updatePagination(data) {
      let id = this.meta.current_page + data;

      let obj = {
        page: id
      };

      console.log(obj);

      this.$emit("params-updated", obj);
    },
    sortBy(data) {
      let obj = {
        sort: data.key
      };

      this.$emit("params-updated", obj);
    },
    resetSort() {
      let obj = {
        reset: "sort"
      };

      this.$emit("params-updated", obj);
    },
    emitToParent(name, identifiers, obj)
    {
      console.log(name, identifiers,obj)
      let value = {}
      identifiers.forEach(identifier => {
        value[identifier] = obj[identifier]
      })
      this.$emit(name, value)
    },

    emitAction(url, header, item) {
      // Ask confirm
      if (!confirm("Do you want to confirm the action?")) {
        return;
      }

      // Emit with custom method
      let arr = [];

      if (header.settings) {
        header.settings.data.forEach(head => {
          head.identifier
            ? arr.push(
                '"' + [head.name] + '"' + ":" + [item[header.identifier]][0]
              )
            : arr.push('"' + [head.name] + '"' + ":" + [head.value][0]);
        });

        arr.push(
          '"' + "method" + '"' + ":" + '"' + header.settings.method + '"'
        );
      }

      let settings = JSON.parse("{" + arr.toString() + "}");

      // Default emit
      let action = {
        action: url
      };

      let obj = { ...action, ...settings };

      this.$emit("params-updated", obj);
    },
    getJsonFromUrl(url) {
      if (!url) url = location.href;

      var question = url.indexOf("?");

      var hash = url.indexOf("#");

      if (hash == -1 && question == -1) return {};

      if (hash == -1) hash = url.length;

      var query =
        question == -1 || hash == question + 1
          ? url.substring(hash)
          : url.substring(question + 1, hash);

      var result = {};

      query.split("&").forEach(function(part) {
        if (!part) return;
        part = part.split("+").join(" "); // replace every + with space, regexp-free version
        var eq = part.indexOf("=");
        var key = eq > -1 ? part.substr(0, eq) : part;
        var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
        var from = key.indexOf("[");
        if (from == -1) result[decodeURIComponent(key)] = val;
        else {
          var to = key.indexOf("]", from);
          var index = decodeURIComponent(key.substring(from + 1, to));
          key = decodeURIComponent(key.substring(0, from));
          if (!result[key]) result[key] = [];
          if (!index) result[key].push(val);
          else result[key][index] = val;
        }
      });

      // Search by url params
      if (
        !localStorage.getItem("searchQueryByUrl") ||
        localStorage.getItem("searchQueryByUrl") !==
          location.pathname +
            "_" +
            JSON.stringify(result) +
            "_" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes()
      ) {
        this.searchByUrlParams(result);
      }
    },
    searchByUrlParams(result) {
      // Set localStorage info
      let checkItemUnique =
        location.pathname +
        "_" +
        JSON.stringify(result) +
        "_" +
        new Date().getHours() +
        ":" +
        new Date().getMinutes();

      localStorage.setItem("searchQueryByUrl", checkItemUnique);

      // Remove page from url
      delete result.page;

      // Remove sort from url
      delete result.sort;

      let newSearch = JSON.stringify(result)
        .replace("{", "")
        .replace("}", "")
        .split(",");

      let searchString = [];

      newSearch.forEach(item => {
        searchString.push("&" + item.replaceAll(":", "="));
      });

      let obj = {
        filter: searchString
          .toString()
          .replaceAll('"', "")
          .replaceAll(",", "")
      };

      this.$emit("params-updated", obj);
    }
  },
  // updatePagination(evt) {
  updatePagination(data) {
    let id = this.meta.current_page + data;

    let obj = {
      page: id
    };

    console.log(obj);

    this.$emit("params-updated", obj);
  },
  sortBy(data) {
    let obj = {
      sort: data.key
    };

    this.$emit("params-updated", obj);
  },
  resetSort() {
    let obj = {
      reset: "sort"
    };

    this.$emit("params-updated", obj);
  },
  emitAction(url, header, item) {
    // Ask confirm
    if (!confirm("Do you want to confirm the action?")) {
      return;
    }

    // Emit with custom method
    let arr = [];

    if (header.settings) {
      header.settings.data.forEach(head => {
        head.identifier
          ? arr.push(
              '"' + [head.name] + '"' + ":" + [item[header.identifier]][0]
            )
          : arr.push('"' + [head.name] + '"' + ":" + [head.value][0]);
      });

      arr.push('"' + "method" + '"' + ":" + '"' + header.settings.method + '"');
    }

    let settings = JSON.parse("{" + arr.toString() + "}");

    // Default emit
    let action = {
      action: url
    };

    let obj = { ...action, ...settings };

    this.$emit("params-updated", obj);
  },
  getJsonFromUrl(url) {
    if (!url) url = location.href;

    var question = url.indexOf("?");

    var hash = url.indexOf("#");

    if (hash == -1 && question == -1) return {};

    if (hash == -1) hash = url.length;

    var query =
      question == -1 || hash == question + 1
        ? url.substring(hash)
        : url.substring(question + 1, hash);

    var result = {};

    query.split("&").forEach(function(part) {
      if (!part) return;
      part = part.split("+").join(" "); // replace every + with space, regexp-free version
      var eq = part.indexOf("=");
      var key = eq > -1 ? part.substr(0, eq) : part;
      var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
      var from = key.indexOf("[");
      if (from == -1) result[decodeURIComponent(key)] = val;
      else {
        var to = key.indexOf("]", from);
        var index = decodeURIComponent(key.substring(from + 1, to));
        key = decodeURIComponent(key.substring(0, from));
        if (!result[key]) result[key] = [];
        if (!index) result[key].push(val);
        else result[key][index] = val;
      }
    });

    // Search by url params
    if (
      !localStorage.getItem("searchQueryByUrl") ||
      localStorage.getItem("searchQueryByUrl") !==
        location.pathname +
          "_" +
          JSON.stringify(result) +
          "_" +
          new Date().getHours() +
          ":" +
          new Date().getMinutes()
    ) {
      this.searchByUrlParams(result);
    }
  },
  searchByUrlParams(result) {
    // Set localStorage info
    let checkItemUnique =
      location.pathname +
      "_" +
      JSON.stringify(result) +
      "_" +
      new Date().getHours() +
      ":" +
      new Date().getMinutes();

    localStorage.setItem("searchQueryByUrl", checkItemUnique);

    // Remove page from url
    delete result.page;

    // Remove sort from url
    delete result.sort;

    let newSearch = JSON.stringify(result)
      .replace("{", "")
      .replace("}", "")
      .split(",");

    let searchString = [];

    newSearch.forEach(item => {
      searchString.push("&" + item.replaceAll(":", "="));
    });

    let obj = {
      filter: searchString
        .toString()
        .replaceAll('"', "")
        .replaceAll(",", "")
    };

    this.$emit("params-updated", obj);
  }
};
</script>

<style lang="scss">
table{
  // table-layout: fixed;
  overflow: hidden;
}

.col-desktop-width-50 {
  width: 50%;
    @media (max-width: 992px) {
      width: 100%;
    }
}


.routerLinkRedesign {
  color: rgba(0, 0, 0, 0.85);

  &:hover {
    text-decoration: underline;
    -webkit-text-decoration-color: black;
    text-decoration-color: black;
  }
}

#newTableElement {
  font-family: "Roboto", sans-serif !important;

  #mobileRecordDetail,
  #mobileTableHead {
    @media (min-width: 992px) {
      display: none;
    }
  }

  #desktopRecordDetail,
  #desktopTableHead {
    @media (max-width: 991px) {
      display: none;
    }
  }

  #desktopRecordDetail {
    color: rgba(0, 0, 0, 0.75);
  }

  #desktopTableHead {
    border: 0 !important;
  }

  .simplebar-scrollbar::before {
    background-color: #00a99d;
    opacity: 1;
  }

  .simplebar-scrollbar {
    height: 7px;
  }

  .pagination {
    color: #3b3b3b;
    font-size: 0.8rem;
  }

  .sortActive {
    color: #3b3b3b;
  }

  .table_footer {
    // background: #3b3b3b;
    border-top: 1px solid rgba(218, 220, 224, 1);
    color: white;
    font-size: 1rem;

    @media (max-width: 677px) {
      div {
        text-align: center;
        margin: 0 auto !important;

        &:nth-child(2) {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }

    @media (min-width: 678px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    -webkit-border-bottom-right-radius: 0.5rem;
    -webkit-border-bottom-left-radius: 0.5rem;
    -moz-border-radius-bottomright: 0.5rem;
    -moz-border-radius-bottomleft: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .table_container {
    overflow-x: auto;

    -webkit-border-top-left-radius: 0.5rem;
    -webkit-border-top-right-radius: 0.5rem;
    -moz-border-radius-topleft: 0.5rem;
    -moz-border-radius-topright: 0.5rem;

    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    @media (min-width: 992px) {
      // height: 70vh;
    }
  }

  ol,
  ul,
  dl {
    margin-bottom: 0 !important;
  }

  thead {
    background: transparent;
    color: white;
    font-size: 15px;
    color: black;

    th {
      padding: 0.8rem;
      padding-left: 0.75rem;
      font-weight: 500 !important;
    }

    * {
      border-top: 0;
    }
  }

  th,
  td {
    white-space: nowrap;
  }

  tbody {
    //

    tr,
    .strippedMobileRow {
      transition: 0.25s;
      font-size: 15px;

      &:hover {
        cursor: auto !important;
        background: rgba(0, 0, 0, 0.03);
        transition: 0.25s;
      }

      // &:nth-child(odd) {
      //   background: #F8F8F8;

      //   &:hover {
      //     background: #E8E8E8;
      //   }
      // }
    }
  }
}

// .th-desktop {
//   position: relative;
//   .resizable-handle {
//     position: absolute;
//     right: 0;
//     top: 0;
//     height: 100%;
//     width: 5px;
//     background: red;
//   }
// }
</style>
