<template>
  <div>
    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="[]"
        :meta="[]"
        :query="this.query"
        :footer="false"
        v-on:params-updated="processEmit"
        :empty-message="$t('dashboard_advertiser.most_relevant_empty')"
        :empty-message-link="{message: $t('dashboard_advertiser.most_relevant_empty_link'),to: $router.resolve({name: 'menu.wishlist-advertiser'}).href}"
      >
      </DataViewer>
    </template>
  </div>
</template>

<script>
  import crud from "@/store/modules/crud.js";
  import DataViewer from "@/components/ui/DataViewer";
  import PreLoader from "@/components/ui/PreLoader";
  import i18n from "@/i18n";

export default {
  data() {
    return {
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "auction_name_new",
          translate: i18n.t("advertiser_table.article_name"),
          sorter: false,
          style: "width: 150px !important;",
          hint: null,
          href: {
            link: "/advertiser/offer-detail/",
            identifier: "id"
          },
          mobile: {
            class: "col-12"
          }
        },
        // {
        //   key: "estimated_traffic",
        //   translate: i18n.t("auctions.impressions"),
        //   sorter: false,
        //   style: "text-align:right;",
        //   cell_style: "justify-content: right !important;",
        //   hint: null,
        //   mobile: {
        //     class: "col-6"
        //   }
        // },
        // {
        //   key: "min_price_per_view",
        //   translate: i18n.t("auctions.price_per_click"),
        //   sorter: false,
        //   style: "text-align:right;",
        //   cell_style: "justify-content: right !important;",
        //   hint: null,
        //   mobile: {
        //     class: "col-6"
        //   }
        // },
        {
          key: "price",
          translate: i18n.t("auctions.mention_price"),
          sorter: false,
          style: "text-align:right;",
          cell_style: "justify-content: right !important;",
          hint: null,
          mobile: {
            class: "col-6"
          }
        }
      ],
      isAdmin: this.$options.filters.isSuperAdmin(
        this.$store.state.auth.user.roles
      )
    };
  },

  components: {
    DataViewer,
    PreLoader
  },

  props: ['queryString'],

  mounted() {
    this.loadTable();
  },

  watch: {
    queryString: {
      handler() {
        this.loadTable();
      },
    }
  },

  methods: {
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      let link = (this.queryString) ? "dashboard/mostRelevantOffers" + this.queryString : "dashboard/mostRelevantOffers";

      crud
        .get(link)
        .then(function(response) {
          response.data.items.forEach(item => {
            // HTML title
            let shortedTitle =
              item.name.length > 120
                ? item.name.substring(0, 120) + " ..."
                : item.name;

            let url = item.url ?? ''

            let auctionName =
              '<div class="routerLinkRedesign ad-dominant-table-col" style="display: inline-flex;">' +
              '<div style="font-size: 1.25rem;" class="pr-3"><i class="fa-regular fa-file-lines"></i></div>' +
              '<div><span style="font-weight:600;">' +
              shortedTitle +
              "</span><br>" +
              url +
              "</div>" +
              "</div>";

            item.auction_name_new = auctionName;

            let min_price = 0;
            item.min_price ? (min_price = Intl.NumberFormat("sk-SK").format(item.min_price)+ "€")  : "";

            let max_price = 0;
            item.max_price ? (max_price = Intl.NumberFormat("sk-SK").format(item.max_price)+ "€")  : "";

            if(max_price == min_price){
              item.price = min_price;
            }else {
              item.price = min_price + ' ' + i18n.t('offer.to') + ' ' + max_price;
            }

            // item.min_price_per_view ? item.min_price_per_view = Intl.NumberFormat("sk-SK", { style: 'currency', currency: 'EUR' }).format(item.min_price_per_view) : "";
            // item.detail.estimated_traffic = Intl.NumberFormat("sk-SK").format(item.detail.estimated_traffic)

            // if(item.detail.publish_date) {
            //   item.dates = '<span> ' + moment(item.detail.publish_date).locale('sk').format('ll') + ', <br class="ad-hide-mobile-table"><span style="color: #E65100;"> ' + moment(item.detail.publish_date).locale('sk').fromNow() + ' </span></span>';
            // }
          });

          self.table.items = response.data.items;
          self.performance.loading = false;
        })
        .catch(error => {
          console.log(error);
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },
    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      if (data.filter) {
        this.query.filter = data.filter;

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }

      // Emited action
      if (data.action) {
        let self = this;

        self.performance.loading = true;

        crud.post(data.action).then(() => {
          self.loadTable();
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .ad-title-modify {
    color: rgba(0, 0, 0, 0.85);
    bottom: -3rem; 
    font-size: 18px; 
    font-weight: 500;
  }

  .dataTable {
    margin-top: 1rem !important;
  }
</style>