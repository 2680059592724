import WishlistIndex from "@/views/wishlist/publisher/Index.vue";
// import WishlistCreate from "@/views/wishlist/publisher/Create.vue";
// import WishlistUpdate from "@/views/wishlist/publisher/Update.vue";
import WishlistRead from "@/views/wishlist/publisher/Read.vue";
import WishlistActive from "@/views/wishlist/publisher/ActiveChannels.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/publisher/wishlist",
          meta: {
            label: "menu.wishlist-publisher"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/publisher/wishlist",
              //name: "menu.wishlist-publisher",
              component: WishlistIndex,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/publisher/wishlist/conversations",
              //name: "menu.wishlist-publisher",
              component: WishlistActive,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/publisher/wishlist/:id",
              //name: "menu.wishlist-publisher",
              component: WishlistRead,
              props: true,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/publisher/wishlist/:type/:id/",
              //name: "menu.wishlist-advertiser",
              props: true,
              component: WishlistRead,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
