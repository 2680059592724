<template>
  <div>

    <div 
      v-if="userMode !== 'advertiser'"
      class="col-12 col-md-6 px-4 py-3"
      style="background: rgba(240, 250, 248, 1);"
    >
      <small class="d-block">
        {{ $t("order.metrics_title") }}
      </small>

      <hr class="mb-4" style="border: 1px solid rgba(0, 164, 144, 0.3);">

      <div class="input-box">
        <input
          type="text"
          class="input-1"
          id="insertLink"
          v-model="item.tracking.tracking_url"
          disabled
        />
        <label for="insertLink" class="input-label">
          {{ $t("order.metrics_url") }}
        </label>

        <div 
          class="col-md-3 text-center my-auto py-2" 
          style="background: rgba(0, 164, 144, 1); color: white; font-weight: bold; cursor: pointer;"
          @click="copyUrl"
        >
          {{ $t("system.copy") }}
        </div>
      </div>

      <small>
        {{ $t("order.metrics_text") }}
      </small>
    </div>

    <div v-if="item.tracking.updated_at === null && userMode === 'advertiser'" class="no-tracking">
      {{ $t('order.no_tracking_code')}}
    </div>

    <div v-if="item.tracking.updated_at !== null"  class="blocks mt-4">
      <div class="row" style="font-weight: bold;">

        <div v-if="item.detail.publish_date != null" class="col-lg-3 mb-2">
          <div class="p-4" style="background: #f7f7f7; height: 100%;">
            {{ $t("auctions.publication") }}
            <h3>
              {{ getDate(item.detail.publish_date) }}
            </h3>
          </div>
        </div>

        <div class="col-lg-3 mb-2">
          <div class="p-4" style="background: #f7f7f7; height: 100%;">
            {{ $t("system.updated_at") }}
            <h3 v-if="item.tracking.updated_at">
              {{ getDate(item.tracking.updated_at, 'lll') }}
            </h3>
            <h3 v-else>
              {{ getDate([], 'lll') }}
            </h3>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="p-4" style="background: #f7f7f7; height: 100%">
            {{ $t("auctions.publication") }}
            <h3 v-if="item.tracking.times_opened > 0">
              {{ item.tracking.times_opened }}x
            </h3>
            <h3 v-else>
              0x
            </h3>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

  export default {
    props: ['order'],
    data() {
      return {
        item: {},
      }
    },

    mounted () {
      this.item = this.order;
    },

    computed: {
      userMode: function() {
        return localStorage.view;
      }
    },
    
    methods: {
      copyUrl() {
        navigator.clipboard.writeText(this.item.tracking.tracking_url);

        alert('OK');
      }
    },

  }
</script>

<style lang="scss" scoped>
.blocks{
  max-width: 60rem;
}

.no-tracking {
  background-color: #F0FAF8;
  padding: 1rem;
  max-width: 50rem;
}
</style>