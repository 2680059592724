import PublisherOffers from "@/views/offer/publisher/PublisherOffers.vue";
import CreateOffer from "@/views/offer/publisher/Create.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/publisher/offers",
          meta: {
            label: "menu.dashboard-publisher"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/publisher/offers",
              // name: "menu.dashboard-publisher",
              component: PublisherOffers,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/publisher/offer/create",
              // name: "menu.new-offer",
              component: CreateOffer,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/publisher/offer/edit/:id",
              // name: "menu.edit-offer",
              component: CreateOffer,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
