<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <h1 class="text-left">
          <strong>{{ $t("wishlist.index") }}</strong>
        </h1>
        <p>
          {{$t("wishlist.advertiser-description")}}
        </p>
      </div>
      <div class="col-12 col-md-6 text-right">
        <ad-btn
          style="max-width: 200px;"
          class="px-3 py-2"
          :title="$t('offer.create_demand')"
          to="wishlist/create"
        />
      </div>
    </div>

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :clickable-row="true"
        :meta="this.table.meta"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>
  </div>
</template>

<script>
import DataViewer from "@/components/ui/DataViewer";
import PreLoader from "@/components/ui/PreLoader";
import AdBtn from "../../../components/ui/v_2/AdBtn.vue";
import crud from "@/store/modules/crud.js";
import i18n from "@/i18n";
import moment from 'moment'

export default {
  created() {
    this.loadTable();
  },
  data() {
    return {
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "name",
          translate: i18n.t("wishlist.name"),
          sorter: true,
          style: "width: 150px !important;",
          hint: null,
          href: {
            link: "wishlist/detail/",
            identifier: "id"
          },
          mobile: {
            class: "col-12",
            sorter: false
          }
        },
        // {
        //   key: "company",
        //   translate: i18n.t("wishlist.counterparty"),
        //   sorter: false,
        //   style: null,
        //   hint: null,
        //   mobile: {
        //     class: "col-12"
        //   }
        // },
        {
          key: "expiration",
          translate: i18n.t("wishlist.expiration-date"),
          sorter: true,
          style: null,
          hint: null,
          mobile: {
            class: "col-6",
            sorter: false
          }
        },
        {
          key: "price",
          translate: i18n.t("wishlist.price"),
          sorter: true,
          style: null,
          hint: null,
          mobile: {
            class: "col-6",
            sorter: false
          }
        }
      ]
    };
  },
  components: {
    DataViewer,
    PreLoader,
    AdBtn
  },
  methods: {
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "";
      let filter = this.query.filter ? this.query.filter : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud
        .get("wishlist/advertiser" + page + filter + sort)
        .then(function(response) {
          response.data.items.forEach(item => {
            let url = item.url.split("//")
            if(url[1] !== undefined) {

              item.url
                ? (item.url = item.url.split("//")[1].replace("www.", ""))
                : "";
            }

            item.expiration
              ? (item.expiration = moment(item.expiration).locale('sk').format('ll'))
              : "";

            item.price
              ? (item.price = Number(item.price).toFixed(2) + "€")
              : "";

            // HTML title
            // let shortedTitle =
            //   item.name.length > 56
            //     ? item.name.substring(0, 56) + " ..."
            //     : item.name;

            let auctionName =
              '<div class="routerLinkRedesign" style="display: inline-flex;">' +
              '<div style="font-size: 1.25rem;" class="pr-3"><i class="fa-regular fa-file-lines"></i></div>' +
              '<div><span style="font-weight:600;">' +
              item.name +
              "</span><br>" +
              item.url +
              "</div>" +
              "</div>";

            item.name = auctionName;

            // HTML company
            item.company = '<span><b> ' + item.user + ', </b><br class="ad-hide-mobile-table"> ' + item.company + ' </span>';
          });
          self.table = response.data;
          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },
    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      if (data.filter) {
        this.query.filter = data.filter;

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }

      // Emited action
      if (data.action) {
        let self = this;

        self.performance.loading = true;

        crud.post(data.action).then(() => {
          self.loadTable();
        });
      }
    }
  }
};
</script>

<style></style>
