import crud from "@/store/modules/crud";

export default {

    namespaced: true,
    state: {
        count: 0,
        notifications: []
    },

    getters: {
        count(state)
        {
            return state.count;
        },

        notifications(state)
        {
            return state.notifications;
        },

    },

    mutations: {

        setNotifications(state, data) {
            state.notifications = data;
        },

        setNotificationsData(state, data) {
            state.notifications = data.items;
            state.count = data.count;
        },

        addNotificationsData(state, data) {
            state.notifications = state.notifications.concat(data.items);
            state.count = data.count;
        },

        setCount(state, data) {
            state.count = data;
        },
    },

    actions: {
        

        async refreshNotifications({ commit }) {
            crud.get("notifications").then((response) => {

                commit('setNotificationsData', response.data);
            });

        },

        async refreshNotificationsCount({ commit }) {
            crud.get("notification/count").then((response) => {

                commit('setCount', response.data);
            });
        }
    }
}