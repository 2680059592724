<template>
  <div>
    <div class="row">
      <div class="col-sm-8">
        <h1 class="text-left mb-4">
          <strong>{{ $t("activity.activity") }}</strong>
        </h1>
        <p v-html="$t('hints.transactions-introduction')"></p>
      </div>
    </div>

    <dynamic-filter
        :inputs="dynamicFilterInputs"
        v-on:params-updated="processEmit"
    />

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :tableConfig="{col: -1}"
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :meta="this.table.meta"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import DataViewer from "@/components/ui/DataViewer";
import PreLoader from "@/components/ui/PreLoader";
import i18n from "@/i18n";
import DynamicFilter from "@/components/ui/DynamicFilter";
import moment from "moment/moment";

export default {
  name: "ActivityDetail",
  data() {
    return {
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "type_name",
          translate: i18n.t("activity.type"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        },{
          key: "value",
          translate: i18n.t("activity.value"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        },{
          key: "entity_name",
          translate: i18n.t("activity.entity"),
          sorter: false,
          style: "width: 150px !important;",
          hint: null,
          href:{
            identifier: "entity_id",
            callback: function(item, id){
              switch (item.type) {
                case "1":
                  return "/admin/order-detail/"+id;
                case "2":
                  return "/admin/order-detail/"+id;
                case "3":
                case "4":
                  return "/admin/order-detail/"+id;
                case "5":
                  return "/admin/offer-detail/"+id;
                case "20":
                  return "/admin/order-detail/"+id;
                case "6":
                  return null;
                case "7":
                  return null;
                case "19":
                case "14":
                case "8":
                  return "/admin/order-detail/"+id;
                case "9":
                  return null;
                default:
                  return null;
              }
            }
          },
          mobile: {
            class: "col-12"
          }
        },{
          key: "opposite_user_name",
          translate: i18n.t("activity.opposite_user"),
          sorter: false,
          style: null,
          hint: null,
          href: {
            link: "/users/view/",
            identifier: "opposite_user"
          },
          mobile: {
            class: "col-12"
          }
        },{
          key: "opposite_company_name",
          translate: i18n.t("activity.opposite_company"),
          sorter: false,
          style: null,
          hint: null,
          href: {
            link: "/companies/view/",
            identifier: "opposite_company"
          },
          mobile: {
            class: "col-12"
          }
        },{
          key: "created_by",
          translate: i18n.t("activity.created_by"),
          sorter: false,
          style: null,
          hint: null,
          href: {
            link: "/users/view/",
            identifier: "created_by_id"
          },
          mobile: {
            class: "col-12"
          }
        },{
          key: "description",
          translate: i18n.t("activity.description"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        },{
          key: "created_at",
          translate: i18n.t("activity.created_at"),
          sorter: false,
          valueType: "date",
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        }
      ],
      dynamicFilterInputs: [
        {
          name: "type",
          type: "select2",
          label: i18n.t("activity.type"),
          value: [],
        },{
          name: "id",
          type: "text",
          label: i18n.t("activity.id"),
          value: null,
        },{
          name: "from",
          type: "date",
          label: i18n.t("activity.from"),
          value: null,
        },{
          name: "to",
          type: "date",
          label: i18n.t("activity.to"),
          value: null,
        }
      ]
    };
  },

  components: {
    DataViewer,
    PreLoader,
    DynamicFilter
  },

  mounted() {
    this.loadTable();

    Object.values(this.$store.state.enumerators.enumerators["activity-type"]).forEach((value, index) => {
      this.dynamicFilterInputs[0].value.push({label: value, code: index+1})
    });
  },

  methods: {
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "";
      let filter = this.query.filter ? this.query.filter : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud
        .get(window.location.pathname + page + filter + sort)
        .then(function(response) {

          response.data.items.forEach(item => {
            item.type
              ? (item.type_name = i18n.t(
                  "activity." +
                    self.$store.state.enumerators.enumerators["activity-type"][
                      item.type
                    ]
                ))
              : "";

            // Opposite user
            item.opposite_user || item.opposite_user_id
              ? (item.opposite_user_data =
                  item.opposite_user_name + " (" + item.opposite_user + ")")
              : "";

            item.created_at = moment(item.created_at).locale('sk').format('lll')
          });

          self.table = response.data;
          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },

    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      if (data.filter) {
        this.query.filter = data.filter;

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }

      // Emited action
      if (data.action) {
        let self = this;

        self.performance.loading = true;

        crud.post(data.action).then(() => {
          self.loadTable();
        });
      }
    }
  }
};
</script>
