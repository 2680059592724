import WishlistIndex from "@/views/wishlist/advertiser/Index.vue";
import WishlistCreate from "@/views/wishlist/advertiser/Create.vue";
import WishlistUpdate from "@/views/wishlist/advertiser/Update.vue";
import WishlistRead from "@/views/wishlist/advertiser/Read.vue";
import WishlistActive from "@/views/wishlist/advertiser/ActiveChannels.vue";
// import ActiveChannel from "@/views/wishlist/advertiser/ActiveChannel.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/advertiser/wishlist",
          meta: {
            label: "menu.wishlist-advertiser"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/advertiser/wishlist",
              name: "menu.wishlist-advertiser",
              component: WishlistIndex,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/advertiser/wishlist/conversations",
              // name: "menu.wishlist-advertiser",
              component: WishlistActive,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/advertiser/wishlist/create",
              // name: "menu.wishlist-advertiser",
              component: WishlistCreate,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/advertiser/wishlist/:id/update",
              name: "menu.wishlist-advertiser-update",
              component: WishlistUpdate,
              meta: {
                requiresAuth: true
              }
            },{
              path: "/admin/wishlist/:id/update",
              name: "menu.wishlist-admin-update",
              component: WishlistUpdate,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/advertiser/wishlist/:type/:id/",
              // name: "menu.wishlist-advertiser",
              component: WishlistRead,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
