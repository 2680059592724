<template>
  <button
    class="ad-button-new"
    v-if="to === null"
    :type="type"
    v-on="inputListeners"
    :style="{ color: textColor, 'background-color': flatColor }"
    ref="rippleItem"
    :class="{ flat: flat, 'bordered': bordered }"
    :disabled="loading"
  >

    <font-awesome-icon v-if="loading" icon="spinner" spin />

    <template v-if="!loading">
      <font-awesome-icon
        :icon="prependIcon"
        v-if="prependIcon !== null"
        :class="{'ad-icon-input': toggle}"
      />
      {{ title }}
      <font-awesome-icon
        :icon="appendIcon"
        v-if="appendIcon !== null"
        :class="{'ad-icon-input': toggle}"
      />
      <ad-ripple ref="rippleObject" :color="rippleColor" v-if="ripple" />
    </template>

  </button>
  <router-link
    class="ad-button-new"
    :type="type"
    v-on="inputListeners"
    :style="{ color: textColor, 'background-color': flatColor }"
    ref="rippleItem"
    :class="{ flat: flat }"
    v-else
    :to="to"
  >
  <font-awesome-icon
      :icon="prependIcon"
      v-if="prependIcon !== null"
      :class="{'ad-icon-input': toggle}"
    />
    {{ title }}
    <font-awesome-icon
      :icon="appendIcon"
      v-if="appendIcon !== null"
      :class="{'ad-icon-input': toggle}"
    />
    <ad-ripple ref="rippleObject" :color="rippleColor" v-if="ripple" />
  </router-link>
</template>

<script>
import AdRipple from "./animations/AdRipple.vue";
export default {
  components: {
    AdRipple
  },
  props: {
    toggle: {
      default: false,
      type: Boolean
    },
    ripple: {
      default: false,
      type: Boolean
    },
    title: {
      default: "",
      type: String
    },
    type: {
      default: "submit",
      type: String
    },
    flat: {
      default: false,
      type: Boolean
    },
    bordered: {
      default: false,
      type: Boolean
    },
    prependIcon: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: "white"
    },
    to: {
      type: String,
      default: null
    },
    backgroundColor: {
      type: String,
      default: null
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    rippleColor() {
      if (this.flat) {
        return "#00a490";
      }
      return "#00a4917b";
    },
    flatColor() {
      if(this.backgroundColor !== null){
        return this.backgroundColor
      }
      if (this.flat) {
        return "white";
      }
      return "#00a491";
    },
    // V-on all listeners
    inputListeners: function() {
      var vm = this;
      return Object.assign({}, this.$listeners, {
        click: function(event) {
          if (vm.ripple) {
            vm.$refs.rippleObject.animateRipple(event);
          }

          vm.$emit("click", event);
        },
        submit: function(e) {
          vm.$emit("submit", e);
        }
      });
    },
    textColor() {
      if (this.flat && this.color === "white") {
        return "#00a491";
      }
      return this.color;
    }
  }
};
</script>
<style lang="scss" scoped>
.ad-button-new {
  //@media (max-width: 768px) {
  //  all: unset !important;
  //  color: white !important;
  //  font-weight: 500 !important;
  //  font-size: 1em !important;
  //  background: #00a490 !important;
  //  padding: 0.5rem 2rem !important;
  //}

  padding: 0 1rem;
  text-align: center;
  min-height: 36px;
  min-width: 5rem !important;
  color: white;
  font-weight: 500;
  font-size: 1em;
  background: #00a490;
  outline: none;
  border: none;
  border-radius: 2px;
  //   z-index: 9999;
  line-height: 36px;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
  &.flat {
    border: none;
    // background: white !important;
  }
  &.bordered {
    border: 1px solid #00a490 !important;  
  }
}

.ad-button-new:hover {
  color: white;
}
.ad-icon-input {
  transform: rotateZ(-90deg);
  transition: transform 0.3s;
}
</style>
