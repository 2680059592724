import axios from "axios";
import store from "@/store";
import router from "@/router/index";
import i18n from "@/i18n";

export default {
  unauthorized: function(error) {
    if (error.response) {
      if (error.response.status === 401) {
        //logout - clear token/userID
        store
          .dispatch("auth/attempt", {
            token: null,
            user_id: null
          })
          .then(() => {
            store
              .dispatch(
                "notifications/addNotification",
                { type: "success", message: i18n.t("success.user-logout") },
                { root: true }
              )
              .then(() => {
                router.push("/auth/sign-in"); //if login expire
              });
          });
      } else if (error.response.status === 403) {
        //forbidden
        return error
        // router.push("/dashboard");
      } else {
        return error.response;
      }
    }
  },
  headers: function(extend) {
    let tokens =
      store.state.auth.token !== null
        ? { Authorization: store.state.auth.token }
        : {};

    if (extend !== null) {
      tokens["X-Pap-UserId"] = extend.headers["X-Pap-UserId"];
      tokens["X-Pap-SessionId"] = extend.headers["X-Pap-SessionId"];
      tokens["X-Pap-Role"] = extend.headers["X-Pap-Role"] === "A" ? "A" : "M";
      Object.keys(extend.headers).forEach(key => {
        tokens[key] = extend.headers[key];
      });
    }

    let headers = { headers: tokens };

    return headers;
  },
  get: async function(endpoint, extend = null) {
    let self = this;

    let res = await axios
      .get(endpoint, this.headers(extend))
      .catch(function(error) {
        return Promise.reject(self.unauthorized(error));
      })
      .then(response => {
        return response;
      });

    return res;
  },
  post: async function(endpoint, data, extend = null, config = null) {
    let self = this;
    const headers = this.headers(extend);
    config = { ...config, ...headers };
    let res = await axios
      .post(endpoint, data, config)
      .catch(function(error) {
        return Promise.reject(self.unauthorized(error));
      })
      .then(response => {
        return response;
      });

    return res;
  },
  put: async function(endpoint, data, extend = null) {
    let self = this;

    let res = await axios
      .put(endpoint, data, this.headers(extend))
      .then(response => {
        return response;
      })
      .catch(function(error) {
        return Promise.reject(self.unauthorized(error));
      });

    return res;
  },
  delete: async function(endpoint, extend = null) {
    let self = this;

    let res = await axios
      .delete(endpoint, this.headers(extend))
      .catch(function(error) {
        return Promise.reject(self.unauthorized(error));
      })
      .then(response => {
        return response;
      });

    return res;
  }
};
