<template>
    <div class="row mb-5">
        <div class="col-12 col-md-8 comments-view pr-md-5">
            <div class="">
                <!-- Show messages for bid -->
                <div id="comments" ref="comments" v-on:scroll="loadMoreComments">
                    <div class="loader" v-show="loadingMore"></div>

                    <!-- First Info comment -->
                    <div v-if="advertiser" class="message highlight  my-0 py-1">
                      <div class="d-flex">
                        <div class="mb-2 mt-2 d-flex align-items-center text-right">
                          <profile-avatar :user="advertiser" class="mr-2" :colors="(imAdvertiser) ? [
                      {
                        background: '#AC3492',
                        color: 'white'
                      }
                    ]: [
                      {
                        background: '#45AC34',
                        color: 'white'
                      }
                    ]"/>
                          <span><strong>{{ advertiser.first_name }}</strong>, {{ getDate(order.created_at, 'lll') }}</span>
                        </div>
                      </div>
                      <div class="message-content first-comment">
                        <div>
                          <strong v-if="order.product.type != 1">{{ $t('order.info') }}</strong>
                          <strong v-else>{{ $t('order.backlink_info') }}</strong>
                          <br>
                          <div style="word-break: break-all;" class="mt-2">
                            {{ order.info }}
                          </div>
                          <br>

                          <template v-if="order.product.type != 1">
                          <strong>{{ $t('order.expectation') }}</strong><br>
                          <div style="word-break: break-all;" class="mt-2">
                            {{ order.expectation }}
                          </div>
                          <br>
                          </template>
                          <strong>{{ $t('order.url_to_your_page') }}</strong>
                          <br>
                          <div class="mt-2">
                            <a style="word-break: break-all;" :href="order.url">{{ order.url }}</a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-for="(comment, index) in comments" :key="index" class="d-flex message-row">
                        <div v-if="['message', 'attachment'].includes(comment.type)" class="message"
                            :class="{
                                'highlight ': comment.me_sended,
                                'my-0 py-1': index !== 1 ||
                                    (comments[index - 1] &&
                                        comments[index - 1].user_id == comment.user_id)
                            }">
                            <div class="d-flex">
                                <div class="mb-2 mt-2 d-flex align-items-center"
                                    :class="{ 'text-right': comment.me_sended }"
                                    v-if="
                    index === 0 ||
                    (comments[index - 1] &&
                    comments[index - 1].user_id !== comment.user_id)
                    ||
                      (comments[index - 1] &&
                        comments[index - 1].human_date != comment.human_date)
                  ">
                    <profile-avatar :user="comment.user" class="mr-2" :colors="(comment.me_sended) ? [
                      {
                        background: '#AC3492',
                        color: 'white'
                      }
                    ]: [
                      {
                        background: '#45AC34',
                        color: 'white'
                      }
                    ]"/>
                                    <span><strong>{{ comment . user . first_name }}</strong>,
                                        {{ comment . human_date }}</span>
                                </div>
                            </div>
                            <div class="message-content">
                                <div style="word-break: break-all;" v-if="comment.type === 'message'">
                                    {{ comment . comment }}
                                </div>
                                <div v-else>
                                    <div v-for="attachment in comment.attachments" :key="attachment.id">
                                        <div v-if="attachment.file_type === 'image'">
                                            <img width="20%" :src="attachment.hash_url" />
                                            <div>
                                                <a target="_blank"
                                                    :href="attachment.hash_url">{{ attachment . name }}</a>
                                                <br />
                                                {{ attachment . mb_size }}
                                            </div>
                                        </div>
                                        <div v-else class="d-flex">
                                            <font-awesome-icon icon="fa-regular fa-file-lines" class="mr-2"
                                                size="lg" color="black" />
                                            <div>
                                                <a target="_blank" :href="attachment.hash_url"
                                                    class="d-flex">{{ attachment . name }}</a>
                                                {{ attachment . file_type }} {{ attachment . mb_size }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="['system-success', 'system-error'].includes(comment.type)"
                            class="system-message mt-3">
                            <!-- <div class="header-system">
                                System
                            </div> -->
                            <div class="content-system py-2 " :class="comment.type">
                                {{ $t(comment.comment) }}, {{ comment . human_date }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Field with actions -->
            <div class="actions-field p-3 mb-3"
                v-if="(order.status === 1 && (imPublisher || isSuperAdmin))
                      || (order.status === 2 && (imPublisher || isSuperAdmin))
                      || (order.status === 3 && (imAdvertiser || isSuperAdmin))
                      || (order.status === 4 && (imPublisher || isSuperAdmin))
                      || (order.status === 6 && (imAdvertiser || isSuperAdmin))"
            >

                <div class="d-block mt-1 ">
                    <div v-if="order.status !== 7">
                        <div class="row">
                            <div class="col">
                                <div v-if="order.status === 1 && imPublisher" >
                                    {{ $t('order.publisher-accept-text') }}
                                </div>
                                <div v-if="order.status == 2 && imPublisher" >
                                    {{ $t('order.publisher-in-review-text') }}
                                </div>
                                <div  v-if="order.status === 3 && imAdvertiser" >
                                    {{ $t('order.advertiser-accept-text') }}
                                </div>
                                <div v-if="order.status === 4 && imPublisher" >
                                  <template v-if="order.product.type === 1 || order.product.type === 2">
                                    {{ $t('order.publisher-publish-one-step') }}
                                  </template>

                                  <template v-else>
                                    {{ $t('order.publisher-publish-text') }}
                                  </template>
                                </div>
<!--                                <div v-if="order.status === 5 && imAdvertiser" >-->
<!--                                    {{ $t('order.advertiser-checked-review-and-done-text') }}-->
<!--                                </div>-->
                                <div v-if="order.status === 6 && imAdvertiser" >
                                      {{ $t('order.advertiser-order-done-text') }}
                                </div>
                              <div v-if="isSuperAdmin" >
                                    {{ $t("enum." + $store.state.enumerators.enumerators["bid-status"][order.status]
                              ) }}
                                </div>
                            </div>
                        </div>
                        <div class="actions-buttons mt-3">
                            <div>
                                <!-- Publisher cancel order  -->
                                <ad-btn class="btn-cancel mr-3" flat :title="$t('order.publisher-cancel')"
                                    @click="cancelBidModal = true" v-if="(imPublisher || isSuperAdmin)" />
                            </div>
                            <div>
                                <!-- Publisher accepting new order -->
                                <ad-btn class="" :title="$t('order.publisher-accept')"
                                    @click="publisherAcceptBid($route.params.id)"
                                    v-if="order.status === 1 && (imPublisher || isSuperAdmin)" />
                                <!-- Publisher put order to review -->
                                <ad-btn class="" :title="$t('order.publisher-in-review')"
                                    @click="publisherToReviewBid($route.params.id).then(() => loadMoreComments())"
                                    v-if="order.status == 2 && (imPublisher || isSuperAdmin)" />
                                <!-- Advertiser checked review and accepted it -->
                                <div class="d-flex">
                                    <ad-btn class="mx-2 bg-warning" :title="$t('order.advertiser-checked-review-and-denied')"
                                        @click="advertiserDeniedReview($route.params.id).then(() => loadMoreComments())"
                                        v-if="order.status === 3 && (imAdvertiser || isSuperAdmin)" />
                                    <ad-btn class="" :title="$t('order.advertiser-accept')"
                                        @click="advertiserApproveReview($route.params.id).then(() => loadMoreComments())"
                                        v-if="order.status === 3 && (imAdvertiser || isSuperAdmin)" />
                                </div>
                                <!-- Publisher published item and waiting to checked by advertiser -->
                                <ad-btn class="" :title="$t('order.publisher-publish')"
                                    @click="publisherPublishBid($route.params.id).then(() => loadMoreComments())"
                                    v-if="order.status === 4 && (imPublisher || isSuperAdmin)" />
                                <!-- Advertiser checked published item and order is done -->
                                <ad-btn class="" :title="$t('order.advertiser-complain')"
                                    @click="$emit('complain')"
                                    v-if="order.status === 6 && (imAdvertiser)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Add new comment -->
            <div v-if="!isSuperAdmin" id="messaging-tools">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <textarea @keydown.enter.prevent="sendMessage" v-model="message" :placeholder="$t('chat.send-message')"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 row justify-content-end  send-buttons">

                            <base-uploader type="confirm" class="mr-2"
                                :apiUrl="'/bid/' + $route.params.type + '/comment/' + $route.params.id"
                                :multiple="false"
                                @uploaded="updateMessages($event)" />

                            <button @click="sendMessage" class="btn btn-success">
                                {{ $t('chat.send-message') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            v-if="cancelBidModal && (imPublisher || isSuperAdmin)"
            @close="cancelBidModal = false"
            @save="cancelAuction(cancelMessage)"
            color="bg-secondary"
            :title="$t('order.publisher-cancel-text')"
            :button-text="$t('system.confirm')"
            class="text-left"
            >
            <div class="input-box bg-invert">
                <textarea
                class="input-1"
                style="height: 5rem;"
                v-model="cancelMessage"
                :placeholder="$t('order.publisher-cancel-text') + ' ...'"
                ></textarea>
            </div>
        </Modal>
    </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import ProfileAvatar from "../../components/ui/ProfileAvatar.vue";
import BaseUploader from "../../components/attachments/BaseUploader.vue";
import processOrder from "@/mixins/processOrder.js";
import AdBtn from "../../components/ui/v_2/AdBtn.vue";
import Modal from "@/components/ui/Modal";
import i18n from "@/i18n";
import {
    mapGetters
} from "vuex";
import moment from "moment";
import store from "@/store";

export default {
    components: {
        ProfileAvatar,
        BaseUploader,
        AdBtn,
        Modal
    },
    mixins: [processOrder],
    props: {
      order: {}
    },
    data() {
        return {
            message: "",
            attachmentUrl: process.env.VUE_APP_ATTACHMENT_URL,
            comments: [],
            cancelBidModal: false,
            cancelMessage: "",
            moreDataUrl: "",
            loadingMore: false
        };
    },
    created() {
      this.getComments();
    },
    mounted() {
        // this.$refs.comments.$el.addEventListener("wheel", this.loadMoreComments);
        setTimeout(() => {
            // window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
            this.$refs.comments.scrollTop = this.$refs.comments.scrollHeight;
        }, 1000);
    },
    computed: {
      moment() {
        return moment
      },
      ...mapGetters({
          authenticated: "auth/authenticated",
          user: "auth/user",
          status: "view/status"
      }),
      imPublisher() {
          // return this.user.id === this.order.auction.user_id && this.status === 'publisher'
          return this.userMode === 'publisher'
      },
      imAdvertiser() {
          if (this.status === "publisher") {
              return false;
          }

          return ((this.order.user && this.user.id === this.order.user.id) || this.user.roles.includes('superadmin')) && this.status ===
              "advertiser";
      },
      url() {
        return  this.order.url ?? this.order.domain
      },
      advertiser() {
        return this.order.user;
      },
      userMode() {
        if(store.getters["auth/isSuperAdmin"]){
          return 'admin';
        }
        return this.$route.params.type;
      },
      isSuperAdmin() {
        return store.getters["auth/isSuperAdmin"];
      }
    },
    methods: {
        getComments() {
            crud
                .get(
                    "bid/" +
                    this.userMode +
                    "/comments/" +
                    Number(this.$route.params.id)
                )
                .then(resp => {
                    this.comments = resp.data.data.reverse();
                    this.moreDataUrl = resp.data.next_page_url;
                    this.$nextTick(function() {
                        this.$refs.comments.scrollTop = this.$refs.comments.scrollHeight;
                    });
                });
        },
        loadMoreComments() {
            if (
                this.$refs.comments.scrollTop === 0 &&
                this.loadingMore === false &&
                this.moreDataUrl !== null
            ) {
                this.loadingMore = true;
                crud
                    .get(this.moreDataUrl)
                    .then(resp => {
                        this.comments.unshift(...resp.data.data.reverse());
                        this.moreDataUrl = resp.data.next_page_url;
                    })
                    .finally(() => {
                        this.loadingMore = false;
                    });
            }else {
              this.getComments();
            }
        },
        sendMessage(e) {
            if (e.shiftKey) {
                return;
            }
            const msgToSend = this.message;
            this.message = "";
            crud
                .post(
                    "bid/" +
                    this.$route.params.type +
                    "/comment/" +
                    Number(this.$route.params.id), {
                        message: msgToSend
                    }
                )
                .then(async response => {
                    await this.comments.push(response.data);
                    this.$refs.comments.scrollTop = this.$refs.comments.scrollHeight;
                    // window.scrollTop = this.$refs.comments.scrollHeight;
                });
        },
        changeInput(e) {
            e.preventDefault();
            this.message = e.target.innerHTML;
        },
        updateMessages(e) {
            this.comments.push(e);
        },
        cancelAuction(message) {
            // Ask confirm
            if (!confirm("Do you want to confirm the action?")) {
                return;
            }

            crud.post('/bid/' + this.userMode + '/cancel/' + this.$route.params.id, (message) ? { message } : "").then((response) => {
                this.$notify({ type: "success", text: i18n.t("success.record-canceled") });

                this.loadMoreComments();

                this.$emit('order-updated', response.data);

                this.cancelBidModal = false;
              // this.$router.push("/" + this.userMode + "/orders");
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .message {
        font-size: 0.9rem;
        padding: 10px;

        .message-content {
            padding-left: calc(4.5rem - 32px);
        }
    }

    .message>span {
        display: inline-block;
        background: #e4e6eb;
        color: #050505;
    }

    .auction-more-details {
        background: white;
        // border: 1px solid #e9edef;
        font-size: 0.9em;
        font-weight: bold;

        .small-text {
            font-size: 0.8em;
        }

        & .row.py-2 {
            border: 1px solid #e9edef;
            // border-top: 1px solid #E9EDEF;
            border-collapse: collapse;
        }
    }

    .auction-details {
        background: #fafafa;
        border: 1px solid #e9edef;
        font-size: 0.9em;

        .small-text {
            font-size: 0.8em;
        }
    }

    #comments {
      max-height: 100%;
      //overflow-x: hidden;
      //overflow-y: auto;
      padding: 15px;
      //max-height: 20rem;
    }

    #messaging-tools {
      position: relative;
    }


    .custom-textarea,
    textarea {
        width: 100%;
        min-height: 5rem;
        padding: 10px;
        background: #ffffff;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .btn {
        background: #00a99d;
    }

    .bid-header {
        font-size: 1.3em;
        border-bottom: #e9edef 1px solid;
    }

    .system-message {
        width: 100%;
        text-align: center;
        color: #000000d9;
        border-radius: 2px;

        .system-success {
            background: #e6fffa !important;
        }

        .system-error {
            background: #ffdada;
        }
    }

    .load-more-comments {
        text-align: center;
    }

    .actions-field {
        background-color: rgba(240, 250, 248, 1);

        button.cancel-auction {
            border: 1px solid rgba(0, 164, 144, 1);
            background: transparent;
            color: rgba(0, 164, 144, 1);
            border-radius: 2px;
        }

        button.accept-auction {
            background: rgba(0, 164, 144, 1);
            color: white;
            border-radius: 2px;
            border: 1px solid rgba(0, 164, 144, 1);
        }
    }

    .first-comment {
      background-color: #F5F5F5;
      margin-left: 40px;
      padding: 20px !important;
    }

  .btn-cancel {
      background: #F0FAF8 !important;
      border: 1px solid #00a490 !important;
      border-radius: 2px;
      outline: none;
      color: #00a490;
      &:hover,
      &:focus,
      &:active {
        background-color: #00a490 !important;
        outline: none;
        color: white !important;
        border: 1px solid #00a490 !important;
        border-color: #00a490 !important;
        box-shadow: none !important;
      }
  }

  .actions-buttons {
    display: flex;
    justify-content: right;

  }

  label div {
    padding: 1px;
  }

  .send-buttons{
    font-size: 0.875rem;
    max-height: 35px;
  }

.btn-success{
  max-height: 32px;
}


</style>
