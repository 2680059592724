export default {
  data() {
    return {
      validations: [],
      error: false,
      message: "",
      validationTriggered: false
    };
  },
  methods: {
    validate(val) {
      // validate element based on argument rules and returns state of validation (true/false)
      this.error = false;
      this.message = "";
      if (this.validations && this.validations.length > 0) {
        this.validations.forEach(validation => {
          const validator = validation(val);
          if (validator !== true) {
            this.error = true;
            this.message = validator;
          }
        });
        this.validationTriggered = true;
      }
      return !this.error;
    },
    resetValidation() {
      // reset validation status of element to default
      this.error = false;
      this.message = "";
      this.validationTriggered = false;
    }
  },
  beforeUpdate() {
    this.validations = this.$attrs.rules;
  },
  updated() {
    this.validations = this.$attrs.rules;
  },
  mounted() {
    // set rules on init
    this.validations = this.$attrs.rules;
  }
};
