import axios from "axios";
import i18n, { loadLanguageAsync } from "@/i18n";
import router from "@/router";
import Echo from "laravel-echo";
import store from "@/store";
import * as Sentry from "@sentry/vue";

export default {
  namespaced: true,

  state: {
    token: null,
    user: null
  },

  getters: {
    authenticated(state) {
      return state.token && state.user;
    },

    user(state) {
      return state.user;
    },

    isSuperAdmin(state) {
      if(state.user.roles.includes("superadmin"))
      {
        return true;
      }
      return false;
    },

    getLocale(state) {
      return state.user.language.split('-')[1];
    }
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_USER(state, data) {
      state.user = data;
    }
  },

  actions: {
    async logIn({ dispatch }, credentials) {
      let response = await axios.post("auth/login", credentials);

      return dispatch("attempt", {
        token: "Bearer " + response.data._token.key,
        user: response.data._user
      });
    },

    async verifyUser({commit, state}, reload = false) {
      let user = {}
      await axios.get("auth/user", { headers: {"Authorization": state.token}}).then(resp => {
        user = resp.data._user
      })
      await commit("SET_USER", user);
      if(reload && user.verified === true) {
        window.location.reload()
      }
      
    },

    async attempt({ commit, state }, obj) {
      if (obj !== null) {
        if (obj.token) {
          commit("SET_TOKEN", obj.token);
          commit("SET_USER", obj.user);
          if (obj.user) {
            const roles = JSON.stringify(obj.user.roles);
            localStorage.setItem("roles", roles);
            localStorage.setItem("default_role", obj.user.default_role);

            Sentry.setUser({ email: obj.user.email, id: obj.user.id });

            //set lang
            let lang = obj.user.language;
            await loadLanguageAsync(lang);
          } else {
            await axios.get("auth/user", { headers: {"Authorization": obj.token}}).then(resp => {
              commit("SET_USER", resp.data._user);

              const roles = JSON.stringify(resp.data._user.roles);

              localStorage.setItem("roles", roles);
              localStorage.setItem("default_role", resp.data._user.default_role);

              Sentry.setUser({ email: resp.data._user.email, id: resp.data._user.id });

              loadLanguageAsync(resp.data._user.language);
            })
          }

          store.dispatch("auth/initWebsocket")
        } else {
          commit("SET_TOKEN", null);
          commit("SET_USER", "");
        }
      }

      if (!state.token) {
        return;
      }
    },

    logOut({ commit, dispatch, state }) {
      store.dispatch("auth/disconnectWebsocket")

      Sentry.setUser(null);

      return axios
        .post("auth/logout", "", {
          headers: {
            Authorization: state.token
          }
        })
        .then(() => {
          dispatch(
            "notifications/addNotification",
            { type: "success", message: i18n.t("success.user-logout") },
            { root: true }
          );

          commit("SET_TOKEN", null);
          commit("SET_USER", "");
        })
        .catch(() => {
          dispatch(
            "notifications/addNotification",
            { type: "success", message: i18n.t("success.user-logout") },
            { root: true }
          );

          commit("SET_TOKEN", null);
          commit("SET_USER", "");
        });
    },

    initWebsocket() {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_WS_KEY,
        wsHost: process.env.VUE_APP_WS_SERVER,
        wsPort: process.env.VUE_APP_WS_PORT,
        wssHost: process.env.VUE_APP_WS_SERVER,
        wssPort: process.env.VUE_APP_WS_PORT,
        enabledTransports: ['wss', 'ws'],
        disableStats: true,
        encrypted: false,
        cluster: 'mt1',
        forceTLS: false,
        authEndpoint: process.env.VUE_APP_WS_AUTH,
        auth: {
          headers: {
            Authorization: localStorage.getItem('token'),
          }
        },
      });

    },

    disconnectWebsocket(){
      window.Echo.disconnect();
    },


    async forgotPassword({ dispatch }, credentials) {
      return await axios
        .post("auth/forgot-password", {
          email: credentials.email
        })
        .then(result => {
          this.response = result.data;

          dispatch(
            "notifications/addNotification",
            { type: "success", message: i18n.t("success.email-sent") },
            { root: true }
          );
        })
        .catch(function(error) {
          if (error.response) {
            if (error.response.data.message == "Please wait before retrying.") {
              dispatch(
                "notifications/addNotification",
                {
                  type: "danger",
                  message: i18n.t("error.please-wait-before-retrying")
                },
                { root: true }
              );
            } else {
              dispatch(
                "notifications/addNotification",
                { type: "danger", message: i18n.t("error.username-incorrect") },
                { root: true }
              );
            }
          }
        });
    },

    async resetPassword({ dispatch }, credentials) {
      return await axios
        .post("auth/reset-password", {
          token: credentials.token,
          email: credentials.email,
          password: credentials.password,
          password_confirmation: credentials.password_confirmation
        })
        .then(
          () => {
            dispatch(
              "notifications/addNotification",
              { type: "success", message: i18n.t("success.record-updated") },
              { root: true }
            );
            router.push({ name: "login" });
          },
          () => {
            dispatch(
              "notifications/addNotification",
              { type: "danger", message: i18n.t("error.login-incorrect") },
              { root: true }
            );
          }
        );
    }
  }
};
