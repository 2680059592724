<template>
  <div>
    <!-- title -->
    <div class="row">
      <div class="col-sm-6">
        <h1 class="text-left">
          <strong>{{ $t("menu.dashboard-publisher") }}</strong>
        </h1>
        <p
          class="mt-5"
          v-if="!$options.filters.isSuperAdmin($store.state.auth.user.roles)"
        >
          {{ $t("advertiser.invoice-questions") }}
          <a href="mailto:invoice@addmention.com">invoice@addmention.com</a>.
        </p>
        <p class="mt-2" v-else>&nbsp;</p>
      </div>

      <div class="col-sm-6 text-right mb-4 mb-sm-0">
        <!-- <button 
          @click="setViewAndRedirect('advertiser', '/advertiser/auctions')"
          class="btn mr-2"
          style="background-color:#334B48;color:white;border-radius: 4px;"
        >
          <font-awesome-icon icon="fa-solid fa-cart-arrow-down" />
          {{ $t("finance.start-buying") }}
        </button>

        <button 
          @click="setViewAndRedirect('publisher', '/publisher/auction/create')"
          class="btn"
          style="background-color:#96B1AD;color:white;border-radius: 4px;"
        >
          <font-awesome-icon icon="fa-solid fa-cart-plus" />
          {{ $t("finance.start-selling") }}
        </button> -->
      </div>
    </div>

    <!-- credit -->
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-6">
                <template
                  v-if="
                    !$options.filters.isSuperAdmin($store.state.auth.user.roles)
                  "
                >
                  {{ $t("system.credit") }}:
                  <router-link to="/finance/dashboard">
                    {{ this.creditStatus }}€
                  </router-link>
                </template>
              </div>
              <div class="col-12 col-md-6 py-2 py-md-0">
                <div class="d-flex justify-content-md-end">
                  <template
                    v-if="
                      !$options.filters.isSuperAdmin(
                        $store.state.auth.user.roles
                      )
                    "
                  >
                  <ad-btn to="/finance/add-credit" class="mr-1" :title="$t('finance.deposit')" style="max-width:150px;" prepend-icon="fa-solid fa-plus" />
                    <!-- <credit-deposit
                      v-if="
                        !$options.filters.isInArray(
                          $store.state.auth.user.roles,
                          'user'
                        )
                      "
                    /> -->
                    <!-- <credit-withdrawal
                              v-if="
                              !$options.filters.isInArray(
                                  $store.state.auth.user.roles,
                                  'user'
                              )
                              "
                          /> -->
                  </template>

                  <button
                    @click="
                      setViewAndRedirect('advertiser', '/advertiser/auctions')
                    "
                    class="button color-green-dark text-white ml-1 mr-0"
                  >
                    <font-awesome-icon icon="fa-solid fa-cart-arrow-down" />
                    {{ $t("finance.start-buying") }}
                  </button>

                  <button
                    @click="
                      setViewAndRedirect(
                        'publisher',
                        '/publisher/auction/create'
                      )
                    "
                    class="button bg-warning text-white ml-1 ml-md-none mr-0"
                  >
                    <font-awesome-icon icon="fa-solid fa-cart-plus" />
                    {{ $t("finance.start-selling") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- stats & charts -->
    <div class="row mt-3">
      <div class="col-md-6 my-auto">
        <!-- <div class="mb-5">
          <router-link 
            to="/advertiser/auctions"
          >
            <h1 
              class="underlined"
              style="color: #3c4b64"
            >
              {{ allActiveAuctions }}
            </h1>
          </router-link>
          <h2>
            {{ $t("menu.all_active_auctions") }}
          </h2>
        </div> -->

        <div>
          <h1 class="underlined">
            {{ myActiveAuctions }}
          </h1>
          <h2 style="display: flex;">
            {{ $t("menu.myActiveAuctions") }}
            <tooltip
              :text="$t('hints.myActiveAuctions')"
              location="right"
              color="secondary"
            >
            </tooltip>
          </h2>
          <h4 class="mt-3" style="opacity: 0.7;">
            {{ motivationalAuctions(myActiveAuctions) }}
          </h4>
        </div>

        <div class="mt-5">
          <h1 class="underlined">
            {{ myActiveBids }}
          </h1>
          <h2 style="display: flex;">
            {{ $t("menu.myActiveBids") }}
            <tooltip
              :text="$t('hints.myActiveBids')"
              location="right"
              color="secondary"
            >
            </tooltip>
          </h2>
          <h4 class="mt-3" style="opacity: 0.7;">
            {{ motivationalActiveBids(myActiveBids) }}
          </h4>
        </div>
      </div>
      <div class="mt-5 mt-md-0 col-md-6">
        <h1 class="pb-3 text-center">
          {{ $t("auctions.won_lost") }}
        </h1>
        <pie-chart :pieChartData="pieChartData"></pie-chart>
      </div>
    </div>

    <!-- top 10 segments & keywords -->
    <div class="row" style="margin-top: 5rem; margin-bottom: 5rem;">
      <div class="col-md-6">
        <h1 style="display: flex; justify-content: center;">
          {{ $t("menu.top_ten_segments") }}
          <tooltip
            :text="$t('hints.top_ten_segments')"
            location="right"
            color="secondary"
          >
          </tooltip>
        </h1>

        <div>
          <a
            v-for="(item, index) in topTenSegmentsPaginate[0]"
            :key="index"
            :href="originalUrl + '/interests/segments/list'"
            class="top_word shadow-sm mr-3"
          >
            {{ item.title }}
          </a>

          <!-- <div class="mt-4 ml-3">
            <ul class="row">
              <li
                v-for="index in topTenSegmentsPaginate[0].last_page"
                :key="index"
                @click="topTenSegments(index)"
                class="mx-1"
                style="cursor: pointer;"
              >
                <template
                  v-if="index == topTenSegmentsPaginate[0].current_page"
                >
                  <span class="p-2 text-white" style="background: #00a99d">
                    <b>{{ index }}</b>
                  </span>
                </template>
                
                <template 
                  v-else
                >
                  <span class="p-2 bg-light">
                    {{ index }}
                  </span>
                </template>
              </li>
            </ul>
          </div>         -->
        </div>

        <div class="block mt-5 text-center">
          <router-link
            class="btn btn-success shadow-sm"
            to="/interests/segments/list"
          >
            {{ $t("menu.advertiserSegmentsList") }}
          </router-link>
        </div>
      </div>
      <div class="col-md-6 mt-5 mt-md-0 mb-3">
        <h1 style="display: flex; justify-content: center;">
          {{ $t("menu.top_ten_keywords") }}
          <tooltip
            :text="$t('hints.top_ten_keywords')"
            location="left"
            color="secondary"
          >
          </tooltip>
        </h1>

        <div>
          <a
            v-for="(item, index) in topTenKeywordsPaginate[0]"
            :key="index"
            :href="originalUrl + '/interests/keywords/list'"
            class="top_word shadow-sm mr-3"
          >
            {{ item.word }}
          </a>

          <!-- <div class="mt-4 ml-3">
            <ul class="row">
              <li
                v-for="index in topTenKeywordsPaginate[0].last_page"
                :key="index"
                @click="topTenKeywords(index)"
                class="mx-1"
                style="cursor: pointer;"
              >
                <template
                  v-if="index == topTenKeywordsPaginate[0].current_page"
                >
                  <span class="p-2 text-white" style="background: #00a99d">
                    <b>{{ index }}</b>
                  </span>
                </template>
                
                <template 
                  v-else
                >
                  <span class="p-2 bg-light">
                    {{ index }}
                  </span>
                </template>
              </li>
            </ul>
          </div>   -->
        </div>

        <div class="block mt-5 text-center">
          <router-link
            class="btn btn-success shadow-sm"
            to="/interests/keywords/list"
          >
            {{ $t("menu.advertiserKeywordsList") }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="row my-5">
      <div class="col-md-6">
        <h1 class="text-center mb-4">
          {{ $t("publisher.most_active_auctions") }}
        </h1>

        <div>
          <ul class="row">
            <li
              class="col-12 col-sm-6"
              v-for="(item, index) in mostActiveAuctionsList"
              :key="index"
            >
              <div>
                <a
                  :href="originalUrl + '/publisher/auction/publish/' + item.id"
                >
                  {{ item.name }}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 mt-4 mt-md-0">
        <h1 class="text-center mb-4">
          {{ $t("publisher.the_ending_auctions") }}
        </h1>

        <div>
          <ul class="row">
            <li
              class="col-12 col-sm-6"
              v-for="(item, index) in endingAuctionsList"
              :key="index"
            >
              <div>
                <a
                  :href="originalUrl + '/publisher/auction/publish/' + item.id"
                >
                  {{ item.name }}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import crud from "@/store/modules/crud";
// import CreditDeposit from "@/components/ui/CreditDeposit";
// import CreditWithdrawal from "@/components/ui/CreditWithdrawal";
import AdBtn from '@/components/ui/v_2/AdBtn.vue'
import PieChart from "@/components/chart/PieChart";
import { mapActions } from "vuex";
import tooltip from "@/components/ui/Tooltip";

export default {
  components: {
    // CreditDeposit,
    AdBtn,
    // CreditWithdrawal,
    PieChart,
    tooltip
  },

  data() {
    return {
      allActiveAuctions: 0,
      myActiveAuctions: 0,
      myActiveBids: 0,
      topTenSegmentsPaginate: [],
      topTenKeywordsPaginate: [],
      pieChartData: [
        {
          type: "won",
          title: i18n.t("auctions.accepted"),
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.2)"
        },
        {
          type: "lost",
          title: i18n.t("auctions.pending"),
          borderColor: "rgba(255,99,132,1)",
          backgroundColor: "rgba(255, 99, 132, 0.2)"
        }
      ],
      mostActiveAuctionsList: [],
      endingAuctionsList: [],
      originalUrl: window.location.origin,
      creditStatus: 0
    };
  },

  mounted() {
    this.totalActiveAuctions();
    this.mineActiveAuctions();
    this.mineActiveBids();
    this.topTenSegments();
    this.topTenKeywords();
    this.mostActiveAuctions();
    this.endingAuctions();
  },

  created() {
    this.$root.$on("creditValueHasBeenUpdated", data => {
      this.creditStatus = data;
    });
  },

  methods: {
    ...mapActions({
      setView: "view/setUserView"
    }),
    totalActiveAuctions() {
      let self = this;
      crud.get("auction/advertiser/count").then(function(response) {
        self.allActiveAuctions = response.data[0];
      });
    },
    mineActiveAuctions() {
      let self = this;
      crud.get("auction/publisher/active-count").then(function(response) {
        self.myActiveAuctions = response.data;
      });
    },
    mineActiveBids() {
      let self = this;
      crud.get("bid/advertiser/active-count").then(function(response) {
        self.myActiveBids = response.data;
      });
    },
    topTenSegments() {
      // data = data ?? 1;

      let self = this;
      // crud.get("google-categories/top-segments?page=" + data).then(function(response) {
      crud.get("google-categories/top-segments").then(function(response) {
        self.topTenSegmentsPaginate = [];
        self.topTenSegmentsPaginate.push(response.data);
      });
    },
    topTenKeywords() {
      // data = data ?? 1;

      let self = this;
      // crud.get("keywords/top-keywords?page=" + data).then(function(response) {
      crud.get("keywords/top-keywords").then(function(response) {
        self.topTenKeywordsPaginate = [];
        self.topTenKeywordsPaginate.push(response.data);
      });
    },
    mostActiveAuctions() {
      let self = this;
      crud.get("auction/most-active").then(function(response) {
        self.mostActiveAuctionsList = response.data;
      });
    },
    endingAuctions() {
      let self = this;
      crud.get("auction/ending").then(function(response) {
        self.endingAuctionsList = response.data.items;
      });
    },
    setViewAndRedirect(view, url) {
      this.setView({
        status: view,
        checked: view == "advertiser" ? true : false
      });
      this.$root.$refs.loadMenu.getMenu();
      this.$router.push(url);
    },
    motivationalAuctions(data) {
      if (data < 1) {
        return i18n.t("publisher.dashboard_message_" + 1);
      } else if (data > 0 && data < 11) {
        return i18n.t("publisher.dashboard_message_" + 2);
      } else {
        // if data >= 11
        return i18n.t("publisher.dashboard_message_" + 3);
      }
    },
    motivationalActiveBids(data) {
      if (data < 1) {
        return i18n.t("publisher.dashboard_message_" + 4);
      } else if (data > 0 && data < 11) {
        return i18n.t("publisher.dashboard_message_" + 5);
      } else {
        // if data >= 11
        return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h2,
h3,
h4 {
  font-weight: lighter;
}

.underlined {
  border-bottom: 3px solid #00a99d;
  max-width: 5.5rem;
}

.top_word {
  background-color: white;
  padding: 0.5em 1em;
  border-radius: 24px;
  display: inline-block;
  margin-top: 1em;
}

ul {
  list-style-type: none;
  padding-left: 0;
  border-radius: 24px;
}

li {
  // font-size: 1.5em;
  margin-bottom: 0.5em;

  &:last-child {
    border-bottom: 0;
  }

  div {
    background-color: white;
    padding: 0.5em 2em;
    border-bottom: 1px solid #f6f6f6;
  }
}
</style>
