import AdvertiserOrders from "@/views/order/advertiser/AdvertiserOrders.vue";
import AdvertiserOrderNew from "@/views/order/advertiser/AdvertiserOrderNew.vue";
import OfferDetail from "@/views/offer/OfferDetail.vue";
import OrderDetail from '@/views/order/Index.vue'

export default [
  {
    data: {
      admin: [
        {
          path: "/advertiser/orders",
          meta: {
            label: "menu.dashboard-advertiser"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/advertiser/orders",
              // name: "menu.dashboard-advertiser",
              component: AdvertiserOrders,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/advertiser/order-create/:id/:product_id",
              // name: "menu.advertiser-order-new",
              component: AdvertiserOrderNew,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/:type/offer-detail/:id",
              // name: "menu.advertiser-offer-detail",
              component: OfferDetail,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/:type/order-detail/:id",
              // name: "menu.advertiser-order-detail",
              component: OrderDetail,
              meta: {
                requiresAuth: true
              }
            },
          ]
        }
      ]
    }
  }
];
