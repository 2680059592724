import crud from "@/store/modules/crud";
import moment from "moment/moment";

export default {

    namespaced: true,
    state: {
        credit: 0,
        allocated: 0,
        incoming: 0,
        orders_count: 0,
        bonus: {}
    },

    getters: {
        credit(state)
        {
            return state.credit
        },

        allocated(state)
        {
            return state.allocated;
        },

        incoming(state)
        {
            return state.incoming;
        },

        ordersCount(state)
        {
            return state.orders_count;
        },

        bonus(state)
        {
          return state.bonus;
        }

    },

    mutations: {
        setCredit(state, data) {
            state.credit = data.credit;
            state.allocated = data.allocated;
            state.incoming = data.incoming;
            state.orders_count = data.orders_count;

            data.bonus.forEach(item => {
                item.expire_at
                    ? (item.expire_at = moment(item.expire_at).locale('sk').format('ll'))
                    : "";
            });

            state.bonus = data.bonus;
        },
    },

    actions: {
        async refreshCredit({ commit }) {
            crud.get("credit/detail").then((response) => {

                commit('setCredit', response.data);
            });

        }
    }
}