import crud from "../store/modules/crud";

export default {
  data() {
    return {
      item: {}
    };
  },
  methods: {
      async publisherAcceptBid(bid_id) {
        let res = await crud
            .post(`/bid/publisher/accept/${bid_id}`)
            .then(response => {
              this.item = response.data;
              this.$emit('order-updated', response.data);
            })
            .catch(e => {
              console.log(e);
            });

        return res;
    },
    async publisherToReviewBid(bid_id) {
        let res = await crud
            .post(`/bid/publisher/to-review/${bid_id}`)
            .then(response => {
              this.item = response.data;
              this.$emit('order-updated', response.data);
            })
            .catch(e => {
              console.log(e);
            });
        return res;
    },
    async publisherPublishBid(bid_id) {
        let res = await crud
            .post(`/bid/publisher/publish/${bid_id}`)
            .then(response => {
              this.item = response.data;
              this.$emit('order-updated', response.data);
            })
            .catch(e => {
              console.log(e);
            });

        return res;
    },
    async publisherCancelBid(bid_id, message) {
        let res = await crud
            .post(`/bid/publisher/cancel/${bid_id}`, { message: message })
            .then(response => {
              this.item = response.data;
              this.$emit('order-updated', response.data);
            })
            .catch(e => {
              console.log(e);
            });
        return res;
    },
    async advertiserApproveReview(bid_id) {
      let res = await crud
        .post(`/bid/advertiser/approve/${bid_id}`)
        .then(response => {
          this.item = response.data;
          this.$emit('order-updated', response.data);
        })
        .catch(e => {
          console.log(e);
        });
      return res;
    },
    async advertiserDeniedReview(bid_id) {
        let res = await crud
            .post(`/bid/advertiser/review-denied/${bid_id}`)
            .then(response => {
              this.item = response.data;
              this.$emit('order-updated', response.data);
            })
            .catch(e => {
              console.log(e);
            });
        return res;
    },
    async advertiserCheckedReviewAndDone(bid_id) {
        let res = await crud
            .post(`/bid/advertiser/published-correctly/${bid_id}`)
            .then(response => {
              this.item = response.data;
              this.$emit('order-updated', response.data);
            })
            .catch(e => {
              console.log(e);
            });
        return res;
    },
    getBid() {
      crud
        .get(
          "auction/" +
            this.$route.params.type +
            "/bidding/bid/" +
            Number(this.$route.params.id)
        )
        .then(response => {
          this.item = response.data;
        });
    }
  }
};
