<template>
    <div>
        <div class="row mb-2">
            <div class="col">
                <router-link to="/publisher/wishlist"
                    ><font-awesome-icon icon="fa-solid fa-arrow-left" /> {{ $t("system.back") }}</router-link
                >
            </div>
        </div>
        <div class="row pb-0 pt-4">
            <div class="col-12 col-md-8">
                <h4>{{ item.name }}</h4>
            </div>
        </div>
        <div class="row py-4">
            <div class="col-12 col-md-8">
                <ul class="detailed-list">
                    <!-- <li>
                        <font-awesome-icon icon="fa-regular fa-file-lines" class="mr-2" color="black" />{{ item.name }}
                    </li> -->
                    <li>
                        <font-awesome-icon icon="fa-solid  fa-desktop" class="mr-2" color="black" />
                        {{ item.url }}
                    </li>
                    <li>
                        <!-- <font-awesome-icon icon="fa-solid fa-chart-simple" class="mr-2" /> {{$t('auctions.estimated_traffic')}} {{item.detail.estimated_traffic }} <span class="delimiter"></span>  -->
                        <font-awesome-icon icon="fa-solid fa-chart-line" class="mr-2" />
                        {{ $t("system.price") }}
                        {{ item.price }}
                        <span class="delimiter"></span>
                        <font-awesome-icon icon="fa-solid fa-wallet" class="mr-2" />
                        {{$t("wishlist.expiration-date")}} {{ momentDate(item.expiration) }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="row py-4 ">
            <div class="col-12 col-md-8 pb-4 row-delimitered">
                <h4>{{ $t("offer.summary") }}</h4>
                <div v-html="item.detail"></div>
            </div>
        </div>
        <div class="row py-4 ">
            <div class="col-12 col-md-8 pb-4 row-delimitered">
                <h4>{{ $t("offer.details") }}</h4>
                <div v-html="item.expectation"></div>
            </div>
        </div>
        <div class="row py-4">
            <div class="col-12 col-md-8">
                <ad-chat
                    :createApiUrl="`wishlist/publisher/${wishlist_id}/comment/channel/${channel_id ?? ''}`"
                    @loaded-more="addNewComments"
                    @comment-added="newComment"
                    :key="chatKey"
                    :items="comments"
                    :getApiUrl=" channel_id !== null ? `wishlist/${channel_id}/comment` : null"
                    :uploadApiUrl="`wishlist/publisher/${wishlist_id}/comment/channel/${channel_id ?? ''}`"
                />
            </div>
        </div>
    </div>
</template>

<script>
import wishlist from "@/mixins/wishlist.js";
import chat from "@/mixins/chat.js";
import AdChat from "../../../components/ui/v_2/AdChat.vue";
export default {
  components: {
    AdChat
  },
  mixins: [chat, wishlist],
  props: ['id'],
  created() {
    if(this.$route.params.type === 'detail'){

      this.wishlist_id = this.id;

      this.getWishlist("publisher")
        .then(response => {
          if (response.channel !== null) {
            this.channel_id = response.channel;

            this.getComments(`wishlist/${response.channel}/comment`);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }

    if(this.$route.params.type === 'conversation') {
      this.channel_id = this.id;

      this.getChannel().then(resp => {
        this.wishlist_id = resp.items.wishlist_id;
        this.item = resp.items;
      });
    }
  },
  methods: {
    newComment(data) {
      if(data.channel_id && this.channel_id === null){
        this.channel_id = data.channel_id;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h4 {
    font-weight: 600;
}
ul {
    &.detailed-list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            padding: 0.7em 1em;
            display: flex;
            align-items: center;
            border-collapse: collapse;
            border-bottom: 1px solid #e9edef;
            border-top: 1px solid #e9edef;
            margin: -1px 0 0 -1px;

            .delimiter {
                margin: 0px 2em;
                height: 24px;
                width: 1px;
                display: inline-block;
                background: #e9edef;
            }
        }
    }

    li {
        line-height: 24px;
    }
}

.row-delimitered {
    border-bottom: 1px solid #e9edef;
}
</style>
