// import Segments from "../views/interests/Segments.vue";
// import SegmentsList from "../views/interests/SegmentsList.vue";
// import Keywords from "../views/interests/Keywords.vue";
// import KeywordsList from "../views/interests/KeywordsList.vue";
import Interests from "../views/interests/Interests.vue"
import InterestsNew from "../views/interests/InterestsNew.vue"
import KeywordsList from "@/views/interests/KeywordsList.vue";
import CategoriesList from "@/views/interests/CategoriesList.vue";
export default [
  {
    data: {
      admin: [
        {
          path: "/interests",
          meta: {
            label: "menu.interests"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/interests",
              name: "menu.interests",
              component: Interests,
              meta: {
                requiresAuth: true
              }
            },{
              path: "/interests/new",
              name: "menu.interests",
              component: InterestsNew,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/interests/admin/keywords",
              name: "menu.interests-keywords",
              component: KeywordsList,
              meta: {
                requiresAuth: true
              }
            },{
              path: "/interests/admin/categories",
              name: "menu.interests-categories",
              component: CategoriesList,
              meta: {
                requiresAuth: true
              }
            }
          //   {
          //     path: "/interests/segments",
          //     name: "menu.interests-segments",
          //     component: Segments,
          //     meta: {
          //       requiresAuth: true
          //     }
          //   },
          //   {
          //     path: "/interests/segments/list",
          //     name: "menu.interests-segments-list",
          //     component: SegmentsList,
          //     meta: {
          //       requiresAuth: true
          //     }
          //   }
          ]
        }
      ]
    }
  }
];
