<template>
  <div>
    <div class="row">
      <div
        class="col-12 col-sm-6 col-lg-4 mb-3  preview d-flex justify-items-center align-items-center  py-2"
        v-for="(preview, index) in previews"
        :key="index"
        style="min-width: unset;"
      >
        <div v-if="!detailMode" class="overlay" >
          <div class="btn btn-danger" @click="removeItem(index)">
            <font-awesome-icon icon="fa-solid fa-trash" />
          </div>
        </div>
        <a :href='preview.path' target="_blank" class="file-link">
          <div>
            <img
              :src="preview.url"
              width="100%"
              alt=""
              srcset=""
              v-if="!preview.url.includes('file')"
            />
            <font-awesome-icon v-else :icon="'fa-solid ' + preview.url"  />
            <div>{{ preview.name }}</div>
          </div>
        </a>
      </div>
      <label
          v-if="!detailMode && (numberOfFiles === null || numberOfFiles > files.length)"
          for="grid-uploader" class="col-12 col-sm-6 col-lg-4 mb-3 ">
        <div class="uploader-card">
          <span>
            {{ $t("upload.upload") }}
            <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
          </span>
        </div>
      </label>
    </div>
    <input
      v-if="!detailMode"
      class="input-1 mb-3 d-block"
      id="grid-uploader"
      type="file"
      @change="change($event)"
      :multiple="multiple"
      :accept="accept"
      v-bind="files"
    />
    <div class="progress" v-if="progress">
      <div
        class="progress-bar color-primary"
        role="progressbar"
        :style="{ width: progressValue + '%' }"
        :aria-valuenow="progressValue"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {{ progressValue }}%
      </div>
    </div>
    <div class="text-danger" v-if="errors.length > 0">
      <div v-for="(error, index) in errors" :key="index">{{ $t(error) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: null
    },
    /** Accept types of files */
    accept: {
      type: String,
      default: "image/png, image/jpeg, application/pdf, application/msword"
    },
    /** Can upload multiple files at once? */
    multiple: {
      default: false,
      type: Boolean
    },
    numberOfFiles: {
      default: null
    },
    /** Show progressbar? */
    progress: {
      default: false,
      type: Boolean
    },
    /** Show progressbar? */
    progressValue: {
      default: 0,
      type: Number
    },
    /** Max file size of uploaded files */
    maxFileSize: {
      default: 10000,
      type: Number
    },
    detailMode: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      files: [],
      previews: [],
      errors: []
    };
  },
  created() {
    if(this.value.length > 0) {
      this.generatePreview(this.value);
    }
  },
  methods: {
    change(e) {
      this.errors = [];
      var canEmitChange = this.checkFiles(e.target.files);
      /** Generate preview  */
      this.generatePreview(this.files);
      /** Emit to BaseUploader */
      if (canEmitChange) {
        this.$emit("change", e.target.files);
      }
    },

    checkFiles(files) {
      this.errors = [];
      var canEmitChange = false;
      /** Check if file can be uploaded */
      [...files].forEach(file => {
        let alreadyExists = this.files.filter(f => f.name === file.name).length > 0;

        if (!alreadyExists) {
          if (!file.size || file.size <= this.maxFileSize) {
            this.files.push(file);
            canEmitChange = true;
          } else {
            this.errors.push("upload.filesize");
          }
        }else{
          console.log('ALREADY EXISTS')
        }
      });
      return canEmitChange;
    },

    generatePreview(files) {
      let previews = [];

      files.forEach((file, key) => {

        if ( !file.path && file.type && file.type.split("/")[0] === "image") {
          previews[key] = {
            url: URL.createObjectURL(file),
            name: file.name
          };
          file.onload = () => {
            URL.revokeObjectURL(this.previews[key]);
          };
        } else if (file.type && file.type.split("/")[0] === "image"){
          previews[key] = {
            url: file.path,
            name: file.name
          };

        }else {
          switch (file.type.split("/")[1]) {
            case "pdf":
              previews[key] = {
                url: "fa-file-pdf",
                name: file.name
              };
              break;

            case "msword":
              previews[key] = {
                url: "fa-file-word",
                name: file.name
              };
              break;
            default:
              previews[key] = {
                url: "fa-file",
                name: file.name
              };
              break;
          }
        }

        if(file.path) {
          previews[key]["path"] = file.hash_url;
        }
      });

      this.previews = previews;
    },
    /** Delete file */
    removeItem(index) {
      this.$emit("remove", this.files[index]);
      this.files.splice(index, 1);
      this.previews.splice(index, 1);
    },
  },
  watch: {
    value(newVal){
      if(this.checkFiles(newVal)) {
        this.generatePreview(this.files);
      }
    }
  },
  filters:{
    getUrl(path){
      return window.location.origin + '/api/' + path;
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  div.uploader-card {
    border: 1px solid #dadce0;
    padding: 20px;
    text-align: center;
    display: flex;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
}
.preview {
  position: relative;
  text-align: center !important;
  justify-content: center;
  .overlay {
    position: absolute;
    width: 95%;
    height: 100%;
    display: none;
    background: rgba($color: #000000, $alpha: 0);
    transition: all 0.3s;
  }

  &:hover {
    .overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba($color: #000000, $alpha: 0.5);
    }
  }
}

input {
  display: none !important;
}

.file-link {
  color: #3c4b64;
}

</style>
