<template>
  <div>
    <div class="ml-3 mb-4">
      <h1 class="text-left">
        <strong>{{ $t("menu.active-offers") }}</strong>
      </h1>
      <p>{{ $t("auctions.please_use_advanced_search") }}</p>
    </div>

    <div class="row col-12">
      <div class="col-md-8 d-md-flex">
        <div class="ad-input-seach position-relative">
          <font-awesome-icon
            icon="fa-solid fa-magnifying-glass"
            class="position-absolute"
            id="font-awesome-icon"
          />

          <input
            type="text"
            class="ad-input-seach mr-3"
            :placeholder="$t('system.search_in_article_title')"
            v-model="searchAuctionName"
            @keyup.enter="searchByName()"
          />
        </div>

        <button
          @click="tableFilterEnabled = true"
          class="px-3 ad-btn-advanced-search"
        >
          <font-awesome-icon icon="fa-solid fa-sliders" class="mr-2" />
          {{ $t("system.advanced-search") }}
        </button>
      </div>
    </div>

    <div class="row ml-3 mt-3" style="margin-bottom: -3rem;">
      <!-- name -->
      <p
        v-if="this.selectedFilterOptions.search"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.search") }}

        <font-awesome-icon
          @click="resetCustomParam('search')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <!-- max_price -->
      <p
        v-if="this.selectedFilterOptions.max_price"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.max_price") }}

        <font-awesome-icon
          @click="resetCustomParam('max_price')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <!-- min_estimated_traffic -->
      <p
        v-if="this.selectedFilterOptions.min_estimated_traffic"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.min_estimated_traffic") }}

        <font-awesome-icon
          @click="resetCustomParam('min_estimated_traffic')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
      <!-- published -->
<!--      <p-->
<!--        v-if="this.selectedFilterOptions.published"-->
<!--        class="ad-filter-btn mb-0 position-relative"-->
<!--      >-->
<!--        {{ $t("filter.published") }}-->

<!--        <font-awesome-icon-->
<!--          @click="resetCustomParam('published')"-->
<!--          class="close-btn position-absolute ad-pointer"-->
<!--          icon="fa-solid fa-xmark"-->
<!--        />-->
<!--      </p>-->
      <!-- tag -->
      <p
        v-if="this.selectedFilterOptions.tag.length > 1"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.hashtag") }}

        <font-awesome-icon
          @click="resetCustomParam('tag')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>

      <p
          v-for="(tag, index) in this.selectedFilterOptions.tag"
          v-bind:key="index"
          class="ad-filter-btn mb-0 position-relative"
      >
        {{ tag.word }}

        <font-awesome-icon
            @click="resetCustomParam('tag', tag.id)"
            class="close-btn position-absolute ad-pointer"
            icon="fa-solid fa-xmark"
        />
      </p>


      <!-- segment -->
      <p
        v-if="this.selectedFilterOptions.segment.length > 1"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.segment") }}

        <font-awesome-icon
          @click="resetCustomParam('segment')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>

      <p
          v-for="(segment, index) in this.selectedFilterOptions.segment"
          v-bind:key="index"
          class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t(segment.name) }}

        <font-awesome-icon
            @click="resetCustomParam('segment', segment.id)"
            class="close-btn position-absolute ad-pointer"
            icon="fa-solid fa-xmark"
        />
      </p>
      <!-- url -->
      <p
        v-if="this.selectedFilterOptions.url.length"
        class="ad-filter-btn mb-0 position-relative"
      >
        {{ $t("filter.url") }}

        <font-awesome-icon
          @click="resetCustomParam('url')"
          class="close-btn position-absolute ad-pointer"
          icon="fa-solid fa-xmark"
        />
      </p>
    </div>

    <!-- v-on:params-updated="processEmit" -->
    <TableFilter
      v-show="tableFilterEnabled"
      v-on:close-action="tableFilterEnabled = false"
      v-on:filter-updated="processFilterOptions"

      :max_price="selectedFilterOptions.max_price"
      :min_estimated_traffic="selectedFilterOptions.min_estimated_traffic"
      :segment="selectedFilterOptions.segment"
      :tag="selectedFilterOptions.tag"
      :url="selectedFilterOptions.url"
    />

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :meta="this.table.meta"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>

    <div class="row mt-2 mb-4" id="ad-info-boxes">
      <div class="col-md-6 mb-3 md-mb-3 h-full">
        <div class="ad-container-first p-3 pb-4">
          <b>{{ $t("offer.topic_does_not_suit_you") }}</b>

          <p>{{ $t("offer.want_to_help_get_started") }}</p>

          <router-link 
            to="/publisher/wishlist"
            class="ad-router-link"
          >
            {{ $t("system.demand_list") }}
          </router-link>
        </div>
      </div>

      <div class="col-md-6 h-full">
        <div class="ad-container-last p-3 pb-4">
          <b>{{ $t("offer.space_for_novelty") }}</b>

          <p>{{ $t("offer.what_you_will_write_about") }}</p>

          <router-link 
            to="/publisher/offer/create"
            class="ad-router-link"
          >
            {{ $t("offer.create_offer") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableFilter from "@/components/ui/v_2/TableFilter.vue";
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import DataViewer from "@/components/ui/DataViewer.vue";
import PreLoader from "@/components/ui/PreLoader.vue";
import rules from "@/mixins/rules.js";
import moment from 'moment'

export default {
  components: {
    DataViewer,
    PreLoader,
    TableFilter
  },

  mixins: [rules],

  data() {
    return {
      selectedFilterOptions: {
        search: null,
        max_price: null,
        min_estimated_traffic: null,
        url: [],
        segment: [],
        tag: [],
        published: null
      },
      searchAuctionQuery: null,
      searchAuctionName: null,
      report: {
        modal: false,
        details: {
          what_topic_are_you_interested: "",
          what_minimum_traffic_do_you_require: "",
          price_for_opening_article: "",
          maximum_purchase_price: "",
          other_requirements: ""
        }
      },
      tableFilterEnabled: false,
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "auction_name",
          translate: i18n.t("advertiser_table.article_name"),
          sorter: true,
          style: "width: 150px !important;",
          hint: null,
          href: {
            link: "/publisher/offer-detail/",
            identifier: "id"
          },
          mobile: {
            class: "col-12",
            sorter: false
          }
        },
        // {
        //   key: "time_left",
        //   translate: i18n.t("auctions.publication"),
        //   sorter: false,
        //   style: null,
        //   hint: null,
        //   mobile: {
        //     class: "col d-none"
        //   }
        // },
        // {
        //   key: "estimated_traffic",
        //   translate: i18n.t("auctions.impressions"),
        //   cell_style: "justify-content: right !important;",
        //   sorter: false,
        //   style: "text-align:right;",
        //   hint: null,
        //   mobile: {
        //     class: "col"
        //   }
        // },
        // {
        //   key: "price_per_click",
        //   translate: i18n.t("auctions.price_per_click"),
        //   cell_style: "justify-content: right !important;",
        //   sorter: false,
        //   style: "text-align:right;",
        //   hint: null,
        //   mobile: {
        //     class: "col"
        //   }
        // },
        {
          key: "price",
          translate: i18n.t("auctions.mention_price"),
          cell_style: "justify-content: right !important;",
          sorter: true,
          style: "text-align:right;",
          hint: null,
          mobile: {
            class: "col",
            sorter: false
          }
        }
      ]
    };
  },

  mounted() {
    let params = this.$route.query;

    if( params['filter[max_price]'] !== undefined){
      this.selectedFilterOptions.max_price = Number.parseFloat(params['filter[max_price]']);
    }

      if (params['filter[min_estimated_traffic]'] !== undefined) {
      this.selectedFilterOptions.min_estimated_traffic = Number.parseFloat(params['filter[min_estimated_traffic]']);
    }

    if (params['filter[url]'] !== undefined) {
      let urls = params['filter[url]'].split(',');
      urls.forEach( (url, index) => { urls[index] = Number.parseInt(url)})

      this.selectedFilterOptions.url = urls;
    }

    if (params['filter[segment]'] !== undefined) {
      let segments = params['filter[segment]'].split(',');
      segments.forEach( (segment, index) => { segments[index] = Number.parseInt(segment); })

      this.selectedFilterOptions.segment = segments;
    }

    if (params['filter[published]'] !== undefined) {
      this.selectedFilterOptions.published = Number.parseInt(params['filter[published]']);
    }

    if (params['filter[tag]'] !== undefined) {
      let tags = params['filter[tag]'].split(',');
      tags.forEach((tag, index) => { tags[index] = Number.parseInt(tag); })

      this.selectedFilterOptions.tag = tags;
    }

    if (params['filter[search]'] !== undefined){
      this.selectedFilterOptions.search = params['filter[search]'];
    }

    this.processFilterOptions(this.selectedFilterOptions);
  },

  methods: {
    ...mapActions({
      addNotification: "notifications/addNotification"
    }),
    submitReportModal() {
      if (
        this.report.details.what_topic_are_you_interested &&
        this.report.details.what_minimum_traffic_do_you_require &&
        this.report.details.what_minimum_traffic_do_you_require
      ) {
        let self = this;

        let obj = {
          topic: self.report.details.what_topic_are_you_interested,
          minimum_traffic: self.report.details.what_minimum_traffic_do_you_require,
          price_per_click: self.report.details.price_for_opening_article,
          price: self.report.details.maximum_purchase_price,
          other: self.report.details.other_requirements
        };

        crud.post("wishlist", obj)
            .then(function() {
            self.report.modal = false;

            // Reset data
            self.report.details = {
              what_topic_are_you_interested: "",
              what_minimum_traffic_do_you_require: "",
              price_for_opening_article: "",
              maximum_purchase_price: "",
              other_requirements: ""
            };

            self.addNotification({
              type: "success",
              message: i18n.t("success.record-add")
            });
          })
          .catch(() => {
            self.addNotification({
              type: "danger",
              message: i18n.t("error.oops")
            });
          });
      }
    },
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // this.query = this.$route.query
      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "?";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud.get("auction/publisher/list" + page + this.query.filter + sort)
        .then(function(response) {

          response.data.items.forEach(item => {
            // item.price ?
            //     ((item.price = Intl.NumberFormat("sk-SK").format(item.price)) + " €" )
            //     : "";
            // item.domain_name
            //   ? (item.domain_name = item.domain_name
            //       .split("//")[1]
            //       .replace("www.", ""))
            //   : "";
            // item.price_per_click
            //   ?
            // (item.price_per_click = Intl.NumberFormat("sk-SK", { style: 'currency', currency: 'EUR' }).format(item.price_per_click))
            //   :
            // "";
            //
            // item.estimated_traffic = Intl.NumberFormat("sk-SK").format(item.estimated_traffic)

            let min_price = 0;
            item.min_price ? (min_price = Intl.NumberFormat("sk-SK").format(item.min_price)+ "€")  : "";

            let max_price = 0;
            item.max_price ? (max_price = Intl.NumberFormat("sk-SK").format(item.max_price)+ "€")  : "";

            if(max_price == min_price){
              item.price = min_price;
            }else {
              item.price = min_price + ' ' + i18n.t('offer.to') + ' ' + max_price;
            }

            // HTML title
            let shortedTitle =
              item.auction_name.length > 56
                ? item.auction_name.substring(0, 56) + " ..."
                : item.auction_name;

            let auctionName =
              '<div class="routerLinkRedesign" style="display: inline-flex;">' +
              '<div style="font-size: 1.25rem;" class="pr-3"><i class="fa-regular fa-file-lines"></i></div>' +
              '<div><span style="font-weight:600;">' +
              shortedTitle +
              "</span><br>" +
              item.domain_name +
              "</div>" +
              "</div>";

            item.auction_name = auctionName;

            // HTML date
            item.time_left[1]
              ? (item.time_left =
                  moment(item.time_left[0]).locale('sk').format('ll') +
                  '<br><span style="color:rgba(230, 81, 0, 1);font-weight:500;">' +
                  moment(item.time_left[0]).locale('sk').fromNow() +
                  "</span>")
              : item.time_left = i18n.t("filter.published");
          });

          self.table = response.data;
          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },

    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }

      // Emited action
      if (data.action) {
        let self = this;

        self.performance.loading = true;

        crud.post(data.action).then(() => {
          self.loadTable();
        });
      }
    },
    searchByName() {
      if (!this.searchAuctionName) return;

      this.selectedFilterOptions.search = this.searchAuctionName;

      this.processFilterOptions(this.selectedFilterOptions);
    },

    processFilterOptions(data) {
      this.query.filter = "";

      if (data.max_price > 0) {
        this.query.filter += "&filter[max_price]=" + data.max_price;
        this.selectedFilterOptions.max_price = data.max_price;
      }
      //
      // if (data.min_estimated_traffic > 0) {
      //   this.query.filter += "&filter[min_estimated_traffic]=" + data.min_estimated_traffic;
      //   this.selectedFilterOptions.min_estimated_traffic = data.min_estimated_traffic;
      //
      // }

      // if (Array.isArray(data.url) && data.url.length > 0) {
      //   this.query.filter += "&filter[url]=" + data.url.toString();
      //   this.selectedFilterOptions.url = data.url;
      // }

      if (Array.isArray(data.segment) && data.segment.length > 0) {
        this.query.filter += "&filter[segment]=" + data.segment.map( (value) => value.id).toString();
        this.selectedFilterOptions.segment = data.segment;
      }

      if (Array.isArray(data.tag) && data.tag.length > 0) {
        this.query.filter += "&filter[tag]=" + data.tag.map( (value) => value.id).toString();
        this.selectedFilterOptions.tag = data.tag;
      }

      // if (data.published !== null) {
      //   this.query.filter += "&filter[published]=" + data.published;
      //   this.selectedFilterOptions.published = data.published;
      // }

      if (this.selectedFilterOptions.search !== undefined && this.selectedFilterOptions.search !== null) {
        this.query.filter += "&filter[search]=" + this.selectedFilterOptions.search;
      }

      this.loadTable();
    },

    resetCustomParam(data, id = null) {
      if(Array.isArray(this.selectedFilterOptions[data])){
        if(id === null) {
          this.selectedFilterOptions[data] = [];
        }else{
          this.selectedFilterOptions[data].forEach((option, index)=> {
            if(option.id === id){
              this.selectedFilterOptions[data].splice(index,1);
            }
          });
        }
      }
      else{
        this.selectedFilterOptions[data] = null;
      }

      this.processFilterOptions(this.selectedFilterOptions);
    }
  }
};
</script>

<style lang="scss" scoped>
  #ad-info-boxes {
    // 

    b {
      line-height: 21.09px;
      font-size: 1.125rem;
      font-weight: 700;
    }

    p {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  .ad-container-first {
    background: rgba(240, 250, 248, 1);

    .ad-router-link {
      font-size: 0.9375rem;
      background: transparent;
      border: 1px solid rgba(0, 164, 144, 1);
      color: rgba(0, 164, 144, 1);
      padding: 0.5rem 2rem;
      border-radius: 2px;
    }
  }

  .ad-container-last {
    background: rgba(0, 0, 0, 0.04);

    .ad-router-link {
      font-size: 0.9375rem;
      border: 1px solid rgba(0, 164, 144, 1);
      background: transparent;
      color: rgba(0, 164, 144, 1);
      padding: 0.5rem 2rem;
      border-radius: 2px;
    }
  }
</style>