//Views Advertiser Action
import AdvertiserDashboard from "../views/dashboard/DashboardAdvertiser.vue";


export default [
  {
    data: {
      admin: [
        {
          path: "/advertiser/dashboard",
          meta: {
            label: "menu.dashboard-advertiser"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/advertiser/dashboard",
              //name: "menu.dashboard-advertiser",
              component: AdvertiserDashboard,
              meta: {
                requiresAuth: true
              }
            },
          ]
        }
      ]
    }
  }
];
