<template>
  <ad-form @submit="create($event, role)">
    <div class="container">
      <div class="row">
        <div class="col">
          <h3>{{ $t("wishlist.advertiser-create-wishlist") }}</h3>
        </div>
      </div>
      <!-- Name -->
      <div class="row">
        <div class="col-12 col-md-8 mt-4">
          <ad-input
            v-model="item.name"
            :rules="[rules.required]"
            :placeholder="$t('wishlist.name')"
          />
        </div>
      </div>
      <!-- Price -->
      <div class="row">
        <div class="col-12 col-md-8 mt-4">
          <ad-input
            v-model="item.price"
            :rules="[rules.required, rules.number]"
            :placeholder="$t('wishlist.price')"
          />
        </div>
      </div>
      <!-- Url -->
      <div class="row">
        <div class="col-12 col-md-8 mt-4">
          <ad-input
            v-model="item.url"
            :rules="[rules.required, rules.url]"
            :placeholder="$t('wishlist.url')"
          />
        </div>
      </div>
      <!-- Company -->
      <div v-if="store.getters['auth/isSuperAdmin']" class="row">
        <div class="col-12 col-md-8 mt-4">
          <select-box
              :options="searchedCompanies"
              :rules="[rules.required]"
              label="name"
              v-model="item.company_id"
              :placeholder="$t('company.name')"
          />
        </div>
      </div>

      <!-- Detail -->
      <div class="row">
        <div class="col-12 col-md-8 mt-4">
          <h5 class="mb-2">{{$t('wishlist.detail')}}</h5>
        </div>
        <div class="col-12 col-md-8">
          <ad-vue-editor
            :editorToolbar="editorToolbar"
            v-model="item.detail"
            :rules="[rules.required]"
          />
        </div>
      </div>
      <!-- Expectation -->
      <div class="row">
        <div class="col-12 col-md-8 mt-4">
          <h5 class="mb-2">{{$t('wishlist.expectation')}}</h5>
        </div>
        <div class="col-12 col-md-8">
          <ad-vue-editor
            :editorToolbar="editorToolbar"
            v-model="item.expectation"
            :rules="[rules.required]"
          />
        </div>
      </div>
      <!-- Expiration -->
      <div class="row">
        <div class="col-12 col-md-8 mt-4">
           <vc-date-picker
                  v-model="item.expiration"
                  mode="dateTime"
                  :available-dates="{ start: new Date(), end: null }"
                  :locale="locale"
                  update-on-input

                  :popover="{ placement: 'auto', visibility: 'focus' }"
                >
                  <template v-slot="{ inputValue, inputEvents, togglePopover }">
                    <ad-input
                      :key="key"
                      :datePickerData="inputValue"
                      v-on="inputEvents"
                      :value="inputValue"
                      @focus="togglePopover"
                      :rules="[rules.required]"
                      icon="fa-solid fa-hourglass-start"
                      :placeholder="$t('wishlist.expiration-date')"
                    />
                  </template>
                </vc-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8 mt-4">
          <ad-btn :title="$t('wishlist.create-submit')" type="submit" />
        </div>
      </div>
    </div>
  </ad-form>
</template>

<script>
import AdInput from "../../../components/ui/v_2/AdInput.vue";
import AdVueEditor from "../../../components/ui/v_2/AdVueEditor.vue";
import AdBtn from "../../../components/ui/v_2/AdBtn.vue";
import AdForm from "../../../components/ui/v_2/AdForm.vue";
import wishlist from "@/mixins/wishlist.js";
import rules from "@/mixins/rules.js";
import store from "@/store";
import crud from "@/store/modules/crud";
import SelectBox from "@/components/ui/fields/SelectBox.vue";
export default {
  components: {
    AdInput,
    AdBtn,
    AdForm,
    AdVueEditor,
    SelectBox
  },
  mixins: [wishlist, rules],
  name: 'create',
  data() {
    return {
      key: 0,
      editorToolbar: [
        ["bold"],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ list: "bullet" }]
      ],
      searchedCompanies: [],
      companies: [],
      updateKey: 0,
    };
  },
  created() {

    if(store.getters["auth/isSuperAdmin"]) {
      crud.get(`/companies/advertiser/select`).then(resp => {
        this.searchedCompanies = resp.data.items;
        this.companies = resp.data.items;
      });
    }
  },
  computed: {
    locale: function() {
      return localStorage.getItem("locale");
    },
    store() {
      return store;
    },
    role() {
      return store.getters['auth/isSuperAdmin'] ? 'admin' : 'advertiser';
    }
  },
  methods: {
    filterFnCompanies(val, update) {
      // filter segments
      this.searchedCompanies = this.companies;
      update(() => {
        this.searchedCompanies = this.companies.filter(company => {
          return company.text.toLowerCase().includes(val.toLowerCase());
        });
        this.$forceUpdate();
      });
    }
  },
  watch: {
    'item.expiration': () => {
      this.key++
    }
  }
};
</script>

<style></style>
