//Views Segments
import Segments from "../views/segment/SegmentIndex.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/segments",
          meta: {
            label: "menu.segments"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/segments",
              name: "menu.segments" + ": " + "crud.list",
              component: Segments,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
