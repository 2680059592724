export default {
  state: {
    validated: {}
  },
  mutations: {
    add(state, item) {
      let o = Object.keys(item);
      if (state.validated[o]) {
        state.validated[o] = item[o];
      } else {
        let key = Object.keys(item);
        state.validated[key] = item[key];
      }
    }
  }
};
