<template>
  <div class="ad-dropdown-wrapper">
    <div class="d-flex">
      <ad-btn
        ref="dropdownButton"
        :title="label"
        :ripple="ripple"
        :class="{ opened: show }"
        type="button"
        flat
        toggle
        :prependIcon="prependIcon"
        :appendIcon="appendIcon"
        @click.prevent="show = !show"
      />
    </div>
    <div class="ad-popover" ref="adPopover" v-show="show">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
import AdBtn from "./AdBtn.vue";
export default {
  data() {
    return {
      show: false
    };
  },
  components: {
    AdBtn
  },
  props: {
    ripple: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    prependIcon: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    }
  },
  methods: {
    blurDropdown(e) {
      /** Hide dropdown on blur input */
      if (
        !this.$refs.dropdownButton.$el.contains(e.target) &&
        !this.$refs.adPopover.contains(e.target) &&
        this.show === true
      ) {
        this.show = false;
      }
    }
  },
  mounted() {
    document.addEventListener(
      "click",
      this.blurDropdown
    ); /*- event for blur input */
  },
  beforeDestroy() {
    document.removeEventListener("click", this.blurDropdown);
  }
};
</script>

<style lang="scss" scoped>
.ad-dropdown-wrapper {
  min-width: 100px;
  display: inline-block;
  position: relative;
  .ad-popover {
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    // width: 100%;
    min-width: 130px;
    position: absolute;
    z-index: 1030;
  }
}
</style>
