<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <h1 class="text-left mb-5">
          <strong>{{ $t("menu.segments") }}</strong>
        </h1>
      </div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li
        class="nav-item"
        v-for="(language, index) in languages"
        :key="language"
        :id="'lang' + index"
        data-toggle="tab"
        role="tab"
        :aria-controls="'lang' + index"
        aria-selected="true"
      >
        <a
          class="nav-link"
          :class="{ active: activeTab === index }"
          href="javascript:void(0)"
          @click="onClickTab(language, index)"
        >
          {{ $t("lang." + language) }}
        </a>
      </li>
    </ul>

    <Search ref="search" :columns="this.search" class="mt-5" />

    <DataTable
      :loading="loading"
      :items="items"
      :fields="fields"
      :page="page"
      :sortBy="sortBy"
    >
      <template #companies_count="{item}">
        <td>
          <strong>{{ item.companies_count }}</strong>
        </td>
      </template>
      <template #language="{item}">
        <td>
          {{ $store.state.enumerators.enumerators["language"][item.language] }}
        </td>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/components/ui/DataTable";
import Search from "@/components/ui/Search";
import crud from "@/store/modules/crud.js";
import i18n from "@/i18n";

export default {
  name: "Segments",
  components: {
    DataTable,
    Search
  },
  data() {
    return {
      loading: false,
      activeTab: 0,
      activeLang: "sk-SK",
      items: [],
      treeNames: {},
      fields: [
        {
          key: "id",
          label: "ID"
        },
        {
          key: "companies_count",
          label: i18n.t("system.count")
        },
        {
          key: "title",
          label: i18n.t("system.name")
        },
        {
          key: "language",
          label: i18n.t("system.language")
        }
      ],
      page: {
        totalCount: 0,
        perPage: 0
      },
      sortBy: { column: "companies_count", asc: true },
      search: [
        {
          name: "title",
          label: i18n.t("advertiser_table.segment"),
          type: "text",
          operator: "and",
          like: true,
          likeFormat: "b"
        }
      ]
    };
  },
  methods: {
    pagination() {
      this.getSegments();
    },

    sort() {
      this.getSegments();
    },

    onClickTab(lang, index) {
      this.activeLang = lang;
      this.activeTab = index;
      this.getSegments();
    },

    getSegments(onload = false) {
      let self = this;

      let query = this.$refs.search.parseUrlQuery(onload, this.sortBy);

      if (!onload) {
        crud
          .get(
            "google-categories" +
              query +
              "&filter[and][][language][like]=" +
              this.activeLang
          )
          .then(function(response) {
            self.items = response.data.items;
            self.page.totalCount = response.data.meta.total;
            self.page.perPage = response.data.meta.per_page;
            self.loading = false;
          });
      }
      this.loading = false;
    }
  },
  mounted() {
    this.getSegments(true);
  },
  computed: {
    languages: function() {
      return Object.keys(this.$store.state.enumerators.enumerators["language"]);
    }
  }
};
</script>
