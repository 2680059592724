<template>
  <fieldset class="" :class="{'border input-box fieldset': toggleType === 'input', 'd-flex': toggleType === 'default'}">
    <legend class="bg-white" v-if="toggleType === 'input'">
      {{ text }}
    </legend>
    <div class="mr-2" :class="{'mr-auto': toggleRight }">{{text}}</div>
    <div class="custom-control custom-switch">
      <input
        type="checkbox"
        class="custom-control-input"
        :id="'ad-switch-' + random"
        data-onstyle="secondary"
        @change="emitValue"
        :value="value"
        :checked="value"
      />
      <label class="custom-control-label recalibrate" :for="'ad-switch-' + random">
        <div v-if="withText">
          <span v-if="!internalData">{{ $t("system.no") }}</span>
          <span v-else>{{ $t("system.yes") }}</span>

        </div>
      </label>
    </div>
  </fieldset>
</template>

<script>

export default {
  name: "AdToggle",
  props: {
    toggleRight: {
      type:Boolean,
      default: false
    },
    withText: {
      type: Boolean,
      default: true
    },
    toggleType: {
      type: String,
      default: 'input'
    },
    value: {
      type: Boolean
    },
    text: {
      type: String
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      internalData: false,
      random: 0
    }
  },
  created() {
    this.random = Math.random() * 10;
  },
  mounted() {
    this.internalData = this.value
  },
  methods: {
    emitValue(e) {
      this.internalData = Boolean(e.target.checked);

      this.$emit("input", this.internalData);
    }
  }
};
</script>

<style lang="scss" scoped>
.fieldset {
  padding: 1.1em 0.8em 0.8em 0.8em;
  position: relative;
}
input {
  display: none;
}
.custom-control {
  font-size: 1em;
}

label {
  position: absolute;
  left: 2em;
  top: 0;
  margin: 0;
  color: #000000a6;
  transition: transform 0.3s;
}
legend {
  left: 0.3em;
  top: 0.2em !important;
  margin: 0 !important;
  color: #000000a6 !important;
  transition: transform 0.3s;
}
</style>
