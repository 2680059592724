<template>
  <div>
    <div class="mb-4">
      <h1 class="text-left">
        <strong>{{ $t("order.my_purchases") }}</strong>
      </h1>
      <p>{{ $t("auctions.please_use_advanced_search") }}</p>
    </div>

    <div class="mb-5">
      <div class="ad-input-seach position-relative">
        <font-awesome-icon
          icon="fa-solid fa-magnifying-glass"
          class="position-absolute"
          id="font-awesome-icon"
        />

        <span>
          <input
            type="text"
            class="ad-input-seach mr-3"
            :placeholder="$t('system.search_in_article_title')"
            v-model="searchAuctionName"
            @keyup.enter="searchByName()"
          />

          <span
            @click="clearFilter()"
            v-if="this.propFilter.filter.length"
          >
            <i 
              class="fa-solid fa-xmark"
              style="cursor: pointer;"
            ></i>
          </span>
        </span>
      </div>
    </div>

    <AdRequiringAction
      :propFilter="this.propFilter"
    >
    </AdRequiringAction>

    <div class="row">
      <div class="col-sm-6">
        <p class="ad-table-title-sm">{{ $t("order.all_sales") }}</p>
      </div>
    </div>

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :meta="this.table.meta"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import DataViewer from "@/components/ui/DataViewer.vue";
import PreLoader from "@/components/ui/PreLoader.vue";
import i18n from "@/i18n";
import AdRequiringAction from "@/components/ui/v_2/AdRequiringAction.vue";

export default {
  data() {
    return {
      propFilter: {
        filter: []
      },
      searchAuctionName: null,
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "auction_name",
          translate: i18n.t("advertiser_table.article_name"),
          sorter: true,
          style: "width: 150px !important;",
          hint: null,
          href: {
            link: "/advertiser/order/comments/",
            identifier: "id"
          },
          mobile: {
            class: "col-12",
            sorter: false
          }
        },
        {
          key: "company",
          translate: i18n.t("wishlist.counterparty"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "status",
          translate: i18n.t("system.status"),
          sorter: true,
          style: null,
          hint: null,
          mobile: {
            class: "col",
            sorter: false
          }
        },
        {
          key: "price",
          translate: i18n.t("system.price"),
          sorter: true,
          cell_style: "justify-content: right !important;",
          style: "text-align:right;",
          hint: null,
          mobile: {
            class: "col",
            sorter: false
          }
        }
      ]
    };
  },

  components: {
    DataViewer,
    PreLoader,
    AdRequiringAction
  },

  mounted() {
    this.loadTable();
  },

  methods: {
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "";
      let filter = this.query.filter ? this.query.filter : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud
        .get("bid/advertiser" + page + filter + sort)
        .then(function(response) {
          // console.log(  Object.keys(response.data.items[0])  );

          response.data.items.forEach(item => {
            item.price ? (item.price = Intl.NumberFormat("sk-SK").format(item.price) + " €") : "";

            item.domain_name
              ? (item.domain_name = item.domain_name
                  .split("//")[1]
                  .replace("www.", ""))
              : "";

            // HTML title
            let shortedTitle =
              item.auction_name.length > 72
                ? item.auction_name.substring(0, 72) + " ..."
                : item.auction_name;

            let auctionName =
              '<div class="routerLinkRedesign ad-dominant-table-col" style="display: inline-flex;">' +
              '<div style="font-size: 1.25rem;" class="pr-3"><i class="fa-regular fa-file-lines"></i></div>' +
              '<div><span style="font-weight:600;">' +
              shortedTitle +
              "</span><br>" +
              item.domain +
              "</div>" +
              "</div>";

            item.auction_name = auctionName;

            // HTML company
            item.company = '<span><b> ' + item.user + ', </b><br class="ad-hide-mobile-table"> ' + item.company + ' </span>';
          });

          self.table = response.data;
          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },
    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      if (data.filter) {
        this.query.filter = data.filter;

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }

      // Emited action
      if (data.action) {
        let self = this;

        self.performance.loading = true;

        crud.post(data.action).then(() => {
          self.loadTable();
        });
      }
    },
    searchByName() {
      if (!this.searchAuctionName) return;

      let obj = {
        filter: "&filter[search]=" + this.searchAuctionName.replaceAll(",", "")
      };

      this.processEmit(obj);

      this.propFilter = obj;
    },
    clearFilter() {
      this.propFilter = null;

      this.searchAuctionName = null;

      let obj = {
        filter: []
      };

      this.processEmit(obj);

      this.propFilter = obj;
    }
  }
};
</script>

<style lang="scss" scoped>
  .ad-table-title-sm {
    font-size: 0.9375rem;
    font-weight: 900;
    margin-bottom: -4.5rem;
  }
</style>