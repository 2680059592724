<template>
  <button class="button color-green-dark" type="button" disabled>
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    <template v-if="size === 'lg'">
      Loading...
    </template>
  </button>
</template>
<script>
export default {
  name: "button-spinner",
  props: {
    size: {
      type: String,
      default: "lg" //sm, lg
    }
  }
};
</script>
