<!-- Base Uploader  -->
<template>
  <div>
    <input-uploader
      v-if="type === 'input'"
      @change="process($event)"
      @remove="remove($event)"
      :accept="accept"
      :multiple="multiple"
      :progress="progress"
      :maxFileSize="maxFileSize"
      :progressValue="progressValue"
    />
    <grid-uploader
      v-if="type === 'grid'"
      @change="process($event)"
      @remove="remove($event)"
      :value="value"
      :accept="accept"
      :multiple="multiple"
      :progress="progress"
      :maxFileSize="maxFileSize"
      :progressValue="progressValue"
      :number-of-files="numberOfFiles"
    />
    <button-uploader
      v-if="type === 'button'"
      @change="process($event)"
      @remove="remove($event)"
      :accept="accept"
      :multiple="multiple"
      :maxFileSize="maxFileSize"
    />
    <confirm-uploader
        v-if="type === 'confirm'"
        @change="process($event)"
        @remove="remove($event)"
        :value="value"
        :accept="accept"
        :multiple="multiple"
        :progress="progress"
        :maxFileSize="maxFileSize"
        :progressValue="progressValue"
        :number-of-files="numberOfFiles"
      />

  </div>
</template>

<script>
import crud from "@/store/modules/crud";
import InputUploader from "./InputUploader.vue";
import GridUploader from "./GridUploader.vue";
import ButtonUploader from "./ButtonUploader.vue";
import ConfirmUploader from "@/components/attachments/ConfirmUploader.vue";
export default {
  components: {
    InputUploader,
    GridUploader,
    ButtonUploader,
    ConfirmUploader
  },
  props: {
    value: {
      default: null
    },
    /** Endpoint for upload files */
    apiUrl: {
      required: true,
      type: String
    },
    /** Type of uploader */
    type: {
      required: true,
      type: String,
      validator: value => {
        // check type of input
        try {
          if (!["input", "grid", "button", "confirm"].includes(value)) {
            throw "BaseUploader: Value is not allowed!";
          }
          if (value === "input") {
            throw "BaseUploader: Input is in progress";
          }

          return (
            ["input", "grid", "button", "confirm"].includes(value) && value !== "input"
          );
        } catch (error) {
          console.log(error);
          return;
        }
      }
    },
    /** Accept types of files */
    accept: {
      type: String,
      default: "image/png, image/jpeg, application/pdf, application/msword"
    },
    /** Can upload multiple files at once? */
    multiple: {
      default: false,
      type: Boolean
    },
    numberOfFiles: {
      default: null
    },
    /** Max file size of uploaded files */
    maxFileSize: {
      default: 10000000,
      type: Number
    },
    /** Show progressbar? */
    progress: {
      default: false,
      type: Boolean
    },
    /** Autoupload on change event event */
    autoUpload: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      uploadedFiles: [],
      progressValue: 0
    };
  },
  created() {
    this.uploadedFiles = this.value;
  },
  methods: {
    /** Upload files */
    uploadFiles(formData, config) {
      crud
        .post(
          this.apiUrl,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } },
          config
        )
        .then(response => {
          this.uploadedFiles = response.data;
          this.$emit("uploaded", this.uploadedFiles);
        });
    },

    /** Uploading process */
    process(files) {
      this.progressValue = 0;
      const formData = new FormData();

      const config = {
        onUploadProgress: progressEvent => {
          this.progressValue = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        }
      };

      [...files].forEach(f => {
        formData.append("file[]", f);
      });

      if (this.autoUpload) {
        this.uploadFiles(formData, config);
      }
    },
    /** Remove File */
    remove(file) {
      this.uploadedFiles.forEach((f, index) => {
        if (f.name === file.name) {
          crud.delete(this.apiUrl + `/${f.id}`).then(() => {
            this.uploadedFiles.splice(index, 1);
            this.$emit("remove", f.id);
          });
        }
      });
    }
  },
  watch: {
    value(newVal) {
      this.uploadedFiles = newVal;
    }
  }
};
</script>

<style></style>
