<template>
    <ad-form @submit="process">
        <div class="container" :key="key">
            <div class="row">
                <div class="col">
                    <h3>{{ $t("menu.my-company") }}</h3>
                </div>
            </div>
            <!-- Name -->
            <div class="row">
                <div class="col-12 col-md-8 mt-4">
                    <ad-input v-model="company.name" :rules="[rules.required]" :placeholder="$t('company.name')" />
                </div>
            </div>
            <!-- Vat ID, Tax ID, Reg Number -->
            <div class="row">
                <div class="col-12 col-md-4 mt-4">
                    <ad-input v-model="company.vat_id" :rules="[rules.required]" :placeholder="$t('company.vat_id')" />
                </div>
                <div class="col-12 col-md-4 mt-4">
                    <ad-input v-model="company.tax_id" :placeholder="$t('company.tax_id')" />
                </div>
                <div class="col-12 col-md-8 mt-4">
                    <ad-input
                        v-model="company.reg_number"
                        :rules="[rules.required]"
                        :placeholder="$t('company.reg_number')"
                    />
                </div>
            </div>
            <!-- Street -->
            <div class="row">
                <div class="col-12 col-md-8 mt-4">
                    <ad-input v-model="company.street" :rules="[rules.required]" :placeholder="$t('company.street')" />
                </div>
            </div>
            <!-- Postal Code -->
            <div class="row">
                <div class="col-12 col-md-8 mt-4">
                    <ad-input
                        v-model="company.postal_code"
                        :rules="[rules.required]"
                        :placeholder="$t('company.postal_code')"
                    />
                </div>
            </div>
            <!-- Country -->
            <div class="row">
                <div class="col-12 col-md-8 mt-4">
                    <ad-select
                        :items="countries"
                        item-value="id"
                        item-label="name"
                        dropdownDirection="top"
                        v-model="company.country"
                        :placeholder="$t('company.country')"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-8 mt-4">
                    <ad-btn :title="$t('company.submit-update-company')" type="submit" />
                </div>
            </div>
        </div>
    </ad-form>
</template>

<script>
import AdInput from "../../../components/ui/v_2/AdInput.vue";
import AdBtn from "../../../components/ui/v_2/AdBtn.vue";
import AdForm from "../../../components/ui/v_2/AdForm.vue";
import AdSelect from "../../../components/ui/v_2/AdSelect.vue";
import company from "@/mixins/company.js";
import rules from "@/mixins/rules.js";

export default {
    components: {
        AdInput,
        AdBtn,
        AdForm,
        AdSelect,
    },
    created() {
        this.getCompany();
    },
    mixins: [company, rules],
    computed: {
        countries() {
            // console.log(this.$store)
            const countries = this.$store.state.enumerators.enumerators["country"];
            const countriesKeys = Object.keys(countries);
            let customCountries = [];
            countriesKeys.map((country) => {
                let newCountry = {
                    id: country,
                    name: countries[country],
                };
                customCountries.push(newCountry);
            });
            return customCountries;
        },
    },
    methods: {
        process(validation) {
            if (!validation) {
                return;
            }
            this.updateCompany();
        },
    },
};
</script>

<style></style>
