export default {
  data() {
    return {
      offer: {
        main: {
          name: "",
          published: false,
          publish_frequency: null,
          google_category_id: null,
          summary: null,
          domain_url: 'https://',
          detail: null,
          about_us: null,
          attachments: [],
          tags: null,
          products: {},
          estimated_traffic: 0,
          company_id: null
        },
        // not_published: {
        //   start_date: null,
        //   end_date: null,
        //   publish_date: null
        // },
        // published: {
        //   url: "https://",
        // },
        admin: {
          company_id: null
        }
      }
    };
  }
};
