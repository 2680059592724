<template>
  <CDropdownHeader>
    <CDropdown :disabled="!isDropDownEnabled" placement="bottom-end">
      <template  v-if="!isDropDownEnabled" v-slot:toggler>
        <router-link to="/finance/add-credit" style="font-size: 14px; color:black;" class="d-flex align-items-center credit-link">
          <font-awesome-icon icon="fa-solid fa-wallet" size="lg" />

          <div   class="mx-2 ml-3 d-none d-md-block">
            <span>
              {{ $t("system.credit") }}:
            </span>
            <div style="font-weight: bold;">
              {{ credit.credit }}€
            </div>
          </div>
        </router-link>
      </template>

      <template  v-if="isDropDownEnabled" v-slot:toggler-content>
        <div class="d-flex align-items-center ad-pointer">
          <font-awesome-icon icon="fa-solid fa-wallet" size="lg" />

          <div class="mx-2 ml-3 d-none d-md-block">
            <span style="font-size: 14px;">
              {{ $t("system.credit") }}:
            </span>
            <div style="font-weight: bold;">
              {{ credit.credit }}€
            </div>
          </div>

          <font-awesome-icon
            v-if="isDropDownEnabled"
            icon="fa-solid fa-sort-down"
          />
        </div>
      </template>

    <CDropdownItem v-if="isDropDownEnabled" style="color:black; font-weight: 100;" :disabled="true">
    <div class="credit-detail__data">
        <span
          v-if="credit.orders_count > 0"
        >
          <b class="ad-color">
            <router-link
              to="/advertiser/orders"
            >
              {{ $t("finance.planned_campaigns") }}
              {{ credit.orders_count }}
            </router-link>
          </b>

          {{ $t("finance.used_credit") }}
          {{ credit.allocated }}€
        </span>

        <hr 
          v-if="credit.orders_count > 0"
          style="width: 300px; position: relative; right: 50px; border-color: rgba(233, 237, 239, 1);"
        >

        <div
          v-if="credit.bonus.length"
        >
          <div 
            v-for="(item, index) in credit.bonus"
            :key="index"
            class="row mb-3"
          >
            <div class="col-6 pr-0">
              <b>{{ $t("finance.bonus_credit") }}</b> {{ item.value }}€
            </div>

            <div class="col-6 pl-0">
              <b>{{ $t("finance.credit_expiration") }}</b> {{ item.expire_at }}
            </div>
          </div>
        </div>

        <hr 
          v-if="credit.bonus.length"
          style="width: 300px; position: relative; right: 50px; border-color: rgba(233, 237, 239, 1);"
        >
      </div>
      </CDropdownItem>
      <CDropdownItem>
        <router-link
          class="credit-detail__data-btn py-2"
          to="/finance/add-credit"
        >
          {{ $t("finance.add-credit") }}
        </router-link>
    </CDropdownItem>
    </CDropdown>
  </CDropdownHeader>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";

export default {
  name: "credit",
  props: {
    link: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "my-credit"
    }
  },
  data() {
    return {
      timer: "",
      emmitCredit: "",
      showCreditMenu: false
    };
  },
  created() {
    this.timer = setInterval(() => store.dispatch('credit/refreshCredit'), 60000);
  },
  mounted()
  {
    store.dispatch('credit/refreshCredit');
  },
  computed: {
    ...mapState({
      credit: "credit"
    }),

    isDropDownEnabled: function() {
      return this.credit.orders_count > 0 || this.credit.bonus.length;
    }
  },
  methods: {
    emmitCreditGlobally() {
      this.$root.$emit("creditValueHasBeenUpdated", this.credit.credit);
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    }
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  }
};
</script>

<style lang="scss" scoped>
  .credit-link:hover{
    text-decoration: none !important;
  }

  .credit-detail {
    min-width: 16.4rem;
    max-width: 100%;
    // Mobile
    right: -3rem;
    top: 3rem;
    
    @media (min-width: 768px) {
      right: -5rem;
      top: 3.5rem;
    }
  }

  .credit-detail__data {
    padding: 2px 2px;
    font-size: 14px;
    overflow-x: hidden;

    b {
      font-weight: bold;
      display: block;
      margin-bottom: 0.5rem;

      &.ad-color {
        color: rgba(0, 164, 144, 1);
      }
    }
  }

  .credit-detail__data-btn {
    display: block;
    text-align: center;
    background: white;
    font-size: 15px;
    border: 1px solid rgba(0, 164, 144, 1);
    border-radius: 2px;
    width: 100%;
  }
</style>
