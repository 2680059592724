<template>
  <div>Loading ...</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    this.setRedirect();
  },

  methods: {
    ...mapActions({
      setView: "view/setUserView"
    }),
    setRedirect() {
      let data =
        localStorage.default_role !== "null"
          ? localStorage.default_role
          : "advertiser";

      this.setView({
        status: data,
        checked: data == "advertiser" ? true : false
      });
      // this.$root.$refs.loadMenu.getMenu();
      this.$router.push("/" + data + "/dashboard");
    }
  }
};
</script>

<style lang="scss" scoped></style>
