<template>
  <div class="ad-product-card-wrapper p-4">
    <div>
      <div class="ad-product-card-header" :class="{ 'not-included': !active && operation == 'create' }">
        <span>{{ $t(`products.${productTypes[type]}`) }}</span>
        <transition name="fade" mode="out-in" v-if="operation == 'create'">
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            class="ad-error-text"
            v-if="!active"
            key="is_inactive"
          />
          <font-awesome-icon
            icon="fa-solid fa-check"
            class="ad-valid-text"
            v-else
            key="is_active"
          />
        </transition>
      </div>
      <div class="ad-product-card-img mt-4 text-center" :class="{ 'not-included': !active && operation == 'create' }">
        <img
          :src="'/svg/products/' + productTypes[type] + '.svg'"
          alt=""
          srcset=""
          width="100%"
        />
      </div>
      <div class="ad-product-card-info mt-4" :class="{ 'not-included': !active && operation == 'create' }" v-html="text"></div>
    </div>
    <div class="ad-product-card-parameters " >
      <div v-if="operation === 'show'">
        <div class="param-show">
          <div class="param-info">
            {{ $t("products.count-of-views") }}
          </div>
          <div class="param-value">
            {{ value.countOfViews }}
          </div>
        </div>
        <div class="param-show">
          <div class="param-info">
            {{ $t("products.price") }}
          </div>
          <div class="param-value">
            {{ value.price }}
          </div>
        </div>
      </div>
      <div class="param-create-update" v-if="operation === 'create'">
        <ad-input
          :class="{ 'not-included': !active && operation == 'create' }"
          :rules="customRules"
          :disabled="!active"
          ref="price"
          v-model="product.price"
          @input="emitInput"
          :placeholder="$t('products.price')"
          standard
          class="mt-3"
        />
<!--        <ad-input-->
<!--          disabled-->
<!--          :class="{ 'not-included': !active && operation == 'create' }"-->
<!--          ref="price-per-view"-->
<!--          :value="pricePerView"-->
<!--          :placeholder="$t('products.price-per-view')"-->
<!--          standard-->
<!--          class="mt-3"-->
<!--        />-->
        <ad-btn
          class="mt-4"
          id="toggle-active"
          type="button"
          @click="toggleActive"
          :title="
            active
              ? $t('products.remove-from-offer')
              : $t('products.add-to-offer')
          "
        />
      </div>
      <div v-if="operation === 'show'">
        <ad-btn class="mt-4" @click="orderProduct" :title="$t('order.order')" />
      </div>
    </div>
  </div>
</template>

<script>
import AdInput from "../AdInput.vue";
import product from "../../../../mixins/product";
import rules from "../../../../mixins/rules";
import AdBtn from "../AdBtn.vue";
export default {
  mixins: [product, rules],
  components: {
    AdInput,
    AdBtn
  },
  props: {
    type: {
      type: Number,
      default: 1
    },
    countOfViews: {
      type: Number,
      default: 0
    },
    operation: {
      type: String,
      default: "show"
    },
    value: {
      default() {
        return {};
      }
    },
    data: {
      default() {
        return {};
      }
    },
    text: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      productTypes: this.$store.state.enumerators.enumerators["product-type"]
    };
  },
  mounted() {
    this.product = this.value
    if(this.product.id) {
      this.active = true
    }
  },
  methods: {
    emitInput() {
      this.product.type = this.type;
      // this.product.price_per_view = this.pricePerView;
      this.$emit("input", this.product);
    },
    toggleActive() {
      this.product.type = this.type;
      if (this.active === true) {
        //this.countOfViews = 0;
        this.product.price = 0;
        this.$refs.price.resetValidation();
        this.active = !this.active;
        this.$emit("input");
      }else{
        this.active = !this.active;
        this.$emit("input", this.product);

        this.$nextTick(() => this.$refs.price.focus())
      }

    },
    orderProduct() {
      this.product.type = this.type;
      this.$emit("input", this.value);
    }
  },
  computed: {
    customRules() {
      if (this.active) {
        return [this.rules.required];
      } else {
        return null;
      }
    },
    // pricePerView() {
    //   if (this.product.price === 0 || this.countOfViews === 0 || !this.product.price) {
    //     return 0;
    //   }
    //   return this.product.price / this.countOfViews;
    // }
  },
  watch: {
    // "product.price":  function(val) {
    //   this.product.price_per_view =
    //   parseFloat(val) / parseFloat(this.countOfViews);
    //   this.$forceUpdate()
    // },
    // "product.countOfViews":  function(val) {
    //   this.product.price_per_view = parseFloat(this.product.price) / parseFloat(val);
    // },
    "value":  function(val) {
      this.product = val;

      if(val.id) {
        this.active = true
      }
    },
    // "countOfViews": function(val) {
    //   this.product.price_per_view = parseFloat(this.product.price) / parseFloat(val);
    // }
  }
};
</script>

<style lang="scss" scoped>
.ad-product-card-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ad-product-card-wrapper {
  border: 1px solid #e9edef;

  transition: opacity 0.3s;
  .not-included {
    opacity: 0.6;
  }
  .ad-product-card-header {
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: 600;
    display: flex;
    align-content: center;
    align-items: center;
    svg {
      margin-left: auto;
    }
  }

  .ad-product-card-parameters {
    .param-show {
      display: flex;
      .param-value {
        margin-left: auto;
      }
    }
  }

  #toggle-active {
    opacity: 1 !important;
  }
}
</style>
