import { render, staticRenderFns } from "./NewForgotPassword.vue?vue&type=template&id=40d21578&scoped=true&"
import script from "./NewForgotPassword.vue?vue&type=script&lang=js&"
export * from "./NewForgotPassword.vue?vue&type=script&lang=js&"
import style0 from "./NewForgotPassword.vue?vue&type=style&index=0&id=40d21578&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d21578",
  null
  
)

export default component.exports