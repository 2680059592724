<template>
  <div></div>
</template>
<script>
// import siiimpleToast from "@/utils/toast";

export default {
  props: ["notification"],
  methods: {
    openToast(text) {
      if (text === "success") {
        // siiimpleToast.success(this.notification.message);
        this.$notify({ type: "success", text: this.notification.message });
      } else if (text === "danger") {
        // siiimpleToast.alert(this.notification.message);
        this.$notify({ type: "error", text: this.notification.message });
      } else {
        // siiimpleToast.message(this.notification.message);
        this.$notify({ type: "warn", text: this.notification.message });
      }
    }
  },
  created: function() {
    this.openToast(this.notification.type);
  }
};
</script>
