//Views Roles
import Role from "../views/role/RoleIndex.vue";
import RoleCreate from "../views/role/RoleCreate.vue";
import RoleEdit from "../views/role/RoleEdit.vue";
import RoleView from "../views/role/RoleView.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/users/roles",
          meta: {
            label: "roles.role"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/users/roles",
              //name: "crud.list",
              component: Role,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/users/roles/create",
              //name: "crud.create",
              component: RoleCreate,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/users/roles/edit/:name",
              //name: "crud.edit",
              component: RoleEdit,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/users/roles/view/:name",
              //name: "crud.view",
              component: RoleView,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
