<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <h1 class="text-left mb-0">
          <strong>{{ $t("menu.websites") }}</strong>
        </h1>
        <div class="small text-muted pl-1">{{ $t("crud.edit") }}</div>
      </div>
      <div class="col-sm-6 text-sm-right">
        <router-link
          class="button bg-light mr-0 my-2 d-inline-block"
          to="/websites"
        >
          {{ $t("system.cancel") }}
        </router-link>
      </div>
    </div>

    <div class="card mt-5">
      <div class="card-body">
        <Form :is-edit="true"></Form>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./Form";

export default {
  name: "WebsiteEdit",
  components: {
    Form
  }
};
</script>

<style></style>
