<template>
  <div>
    <div class="row py-4">
      <div class="col-12 col-md-8">
        <h4>{{ $t(`products.${productTypes[item.product.type]}`) }}</h4>
        <span v-if="item.domain == null">{{ item.detail.name }}</span>
        <a target=”_blank” v-else :href="item.domain">{{ item.detail.name }}</a>
      </div>
    </div>
    <div class="row py-4">
      <div class="col-12 col-md-8">
        <ul class="detailed-list">
          <li>
            <font-awesome-icon
              icon="fa-regular fa-file-lines"
              class="mr-2"
              color="black"
            />{{ $t(`products.${productTypes[item.product.type]}`) }}
            <span class="delimiter"></span>
            <font-awesome-icon
              icon="fa-solid  fa-desktop"
              class="mr-2"
              color="black"
            />
            <span v-if="item.domain !== null">
              {{ item.domain }}

            </span>
          </li>
<!--          <li>-->
<!--            <font-awesome-icon icon="fa-solid fa-calendar-plus" class="mr-2" />-->
<!--            {{ $t("auctions.publish-date") }} {{ item.detail.publish_date }}-->
<!--          </li>-->
<!--          <li>-->
<!--            <font-awesome-icon icon="fa-solid fa-chart-simple" class="mr-2" />-->
<!--            {{ $t("auctions.estimated_traffic") }}-->
<!--            {{ item.detail.estimated_traffic }} <span class="delimiter"></span>-->
<!--            <font-awesome-icon icon="fa-solid fa-chart-line" class="mr-2" />-->
<!--            {{ $t("auctions.price_per_view") }}-->
<!--            {{ item.product.price_per_view }}-->
<!--          </li>-->
          <li>
            <font-awesome-icon icon="fa-solid fa-wallet" class="mr-2" />
            {{ $t("system.price") }} {{  parseFloat(item.product.price).valueOf() }} €
            <template v-if="item.video_url">
              <span class="delimiter"></span>
              <font-awesome-icon
                  icon="fa-solid  fa-desktop"
                  class="mr-2"
                  color="black"
              />
              <span v-if="item.video_url !== null">
                {{ item.video_url }}
              </span>
            </template>
          </li>
        </ul>
      </div>
    </div>
    <div class="row py-4 ">
      <img v-on:click="modal = true" class="image" :src="item.image">
    </div>    
    
    <div class="row py-4 ">
      <div class="col-12 col-md-8 pb-4 row-delimitered">
        <h4>{{ $t("offer.summary") }}</h4>
        <div v-html="item.detail.summary"></div>
      </div>
    </div>
    <div class="row py-4 ">
      <div class="col-12 col-md-8 pb-4 row-delimitered">
        <h4>{{ $t("offer.details") }}</h4>
        <div v-html="item.detail.detail"></div>
      </div>
    </div>
    <div class="row py-4 ">
      <div class="col-12 col-md-8 pb-4 row-delimitered">
        <h4>{{ $t("offer.about-us") }}</h4>
        <div v-html="item.detail.about_us"></div>
      </div>
    </div>
    <div
        v-if="modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalLabel"
        aria-hidden="true"
        @click.self="modal = false"
    >
      <div class="modal-dialog" :class="'modal-xl'">
        <div class="modal-content">
          <div >
            <img
                style="width: 100%"
                :src="item.image">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import store from "@/store";

export default {
  props: ['order'],
  created() {
    this.item = this.order;
  },
  data() {
    return {
      modal: false,
      item: {},
      productTypes: this.$store.state.enumerators.enumerators["product-type"]
    };
  },
  computed: {
    userMode() {
      if(store.getters["auth/isSuperAdmin"]){
        return 'admin';
      }
      return this.$route.params.type;
    }
  }
};
</script>

<style lang="scss" scoped>
h4 {
  font-weight: 600;
}
ul {
  &.detailed-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      padding: 0.7em 1em;
      display: flex;
      align-items: center;
      border-collapse: collapse;
      border-bottom: 1px solid #e9edef;
      border-top: 1px solid #e9edef;
      margin: -1px 0 0 -1px;

      .delimiter {
        margin: 0px 2em;
        height: 24px;
        width: 1px;
        display: inline-block;
        background: #e9edef;
      }
    }
  }

  li {
    line-height: 24px;
  }
}

.row-delimitered {
  border-bottom: 1px solid #e9edef;
}

.image{
   width: 15em;
  padding-left: 1em;
  cursor: pointer;
}
</style>
