/**
 * Use it as mixin
 * Then you have access to these rules in components through its scope a.k.a. magic keyword "this"
 * Example in component:
 *   in script - this.rules.required
 *   in template - rules.required
 *
 */
import i18n from "@/i18n";
import _ from "lodash";
export default {
  computed: {
    rules() {
      /**
       * exampleRule: (value) => value === true || 'Error message'
       */
      return {
        required: val =>
          _.isDate(val) || (_.isNumber(val) && val > 0) || !_.isEmpty(val) || i18n.t("system.is-required"),
        url: val => {
          //eslint-disable-next-line
          const expression = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
          const regex = new RegExp(expression);
          return regex.test(val) || i18n.t("error.enter-valid-url");
        },
        number: val => {
          const expression = /^\d+$/;
          const regex = new RegExp(expression);
          return regex.test(val) || i18n.t("error.number-check");
        },
        float: val => {
          const expression = /^[+-]?([0-9]*[.])?[0-9]+$/;
          const regex = new RegExp(expression);
          return regex.test(val) || i18n.t("error.number-check");
        },
        email: val => {
          const expression = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
          const regex = new RegExp(expression);
          return regex.test(val) || i18n.t("error.email-check");
        },
        password: val => {
          const expression = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@{}$!:%*#?&]{8,}$/; // 8 characters, 1 uppercase, 1 digit and allowed special chars
          const regex = new RegExp(expression);
          return regex.test(val) || i18n.t("error.password-check");
        },
        password_repeat: (val, val2) => { // this must be used only with auth mixin
          console.log(val === val2)
          return val == val2 || i18n.t("error.password-repeat-check");
        }
        /** Feel free to extend rules here :) */
      };
    }
  }
};
