<template>
  <div>
    <span class="dot" :class="bgColor"></span>
    <small><slot></slot></small>
  </div>
</template>

<script>
export default {
  name: "dot",
  props: {
    color: String
  },
  computed: {
    bgColor() {
      const colors = [
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "info",
        "light",
        "dark",
        "white"
      ];

      if (colors.includes(this.color)) {
        return "bg-" + this.color;
      }

      return null;
    }
  }
};
</script>
