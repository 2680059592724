<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <h1 class="text-left mb-4">
          <strong>{{ $t("system.credit") }}</strong>
        </h1>
      </div>
      <div class="col-sm-6 text-sm-right">
        <button
          class="button bg-success text-white mr-0 my-2 d-inline-block"
          @click="openManageCreditModal()"
        >
          {{ $t("credit.manage_credit") }}
        </button>

        <button
          class="button bg-info text-white mr-0 my-2 d-inline-block"
          @click="openManageBonusCreditModal()"
        >
          {{ $t("credit.manage_bonus_credit") }}
        </button>

        <router-link
          class="button bg-light mr-0 my-2 d-inline-block"
          to="/finance/dashboard"
        >
          {{ $t("system.cancel") }}
        </router-link>
      </div>
    </div>

    <dynamic-filter
      :inputs="dynamicFilterInputs"
      v-on:params-updated="processEmit"
    />

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :meta="this.table.meta"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>

    <!-- Manage Credit Modal -->
    <ManageCredit
      v-if="openedManageCreditModal"
      :companies="this.optionsCompanies"
      @closeManageCreditModalEmit="updateManageCreditModalEmit()"
    ></ManageCredit>

    <ManageCredit
      v-if="openedManageBonusCreditModal"
      :companies="this.optionsCompanies"
      :bonus="true"
      @closeManageCreditModalEmit="updateManageBonusCreditModal()"
    ></ManageCredit>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import DataViewer from "@/components/ui/DataViewer";
import PreLoader from "@/components/ui/PreLoader";
import DynamicFilter from "@/components/ui/DynamicFilter";
import i18n from "@/i18n";
import ManageCredit from "@/components/ui/ManageCredit";
import moment from "moment/moment";

export default {
  data() {
    return {
      openedManageCreditModal: false,
      openedManageBonusCreditModal: false,
      optionsCompanies: [],
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "updated_at",
          translate: i18n.t("system.updated_at"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "id",
          translate: "ID",
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "value",
          translate: i18n.t("system.value"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        }
      ],
      dynamicFilterInputs: [
        {
          name: "company",
          type: "select2",
          label: i18n.t("register.company"),
          value: [],
          adminOnly: false
        }
      ]
    };
  },

  components: {
    DataViewer,
    PreLoader,
    DynamicFilter,
    ManageCredit
  },

  mounted() {
    this.loadTable();

    this.getAllCompanies();

    if (this.$options.filters.isSuperAdmin(this.$store.state.auth.user.roles)) {
      this.tableHeaders.splice(this.tableHeaders.length - 1, 0, {
        key: "company",
        translate: i18n.t("register.company"),
        sorter: false,
        style: null,
        hint: null,
        mobile: {
          class: "col-6"
        }
      });
    }
  },

  methods: {
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "";
      let filter = this.query.filter ? this.query.filter : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud
        .get("companies/credit" + page + filter + sort)
        .then(function(response) {
          console.log(response);
          // console.log(  Object.keys(response.data.items[0])  );

          response.data.items.forEach(item => {
            item.updated_at = (item.updated_at ? moment(item.expire_at).locale('sk').format('lll') : "");
            item.value ? (item.value = item.value.split(".")[0] + "€") : "";
          });

          self.table = response.data;
          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },
    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      if (data.filter) {
        this.query.filter = data.filter;

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }
    },
    openManageCreditModal() {
      this.openedManageCreditModal = true;
    },

    updateManageCreditModalEmit() {
      this.openedManageCreditModal = false;

      this.loadTable();
    },

    openManageBonusCreditModal() {
      this.openedManageBonusCreditModal = true;
    },

    updateManageBonusCreditModal() {
      this.openedManageBonusCreditModal = false;

      this.loadTable();
    },

    getAllCompanies() {
      let self = this;

      crud.get("companies/select").then(function(response) {
        let arr = [];

        response.data.items.forEach(value => {
          arr.push({
            label: value.name,
            code: value.id
          });
        });

        self.optionsCompanies = arr;
        self.dynamicFilterInputs[0].value = arr;
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
