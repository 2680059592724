<template>
  <ad-form @submit="resetPassword">
    <div class="container py-5 ">
      <div class="row text-center">
        <div class="col">
          <h2>{{$t('login.reset-password')}}</h2>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-12 col-sm-8 col-md-6 col-lg-5">
          <p class="lead">
            {{$t('login.reset-password-lead')}}
          </p>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-12 col-sm-8 col-md-6 col-lg-5">
            <div class="row mt-4">
                <div class="col">
                    <ad-input :placeholder="$t('login.email')" type="email" :rules="[rules.required, rules.email]" v-model="toResetPasswordObj.email" :key="updateKey"/>
                </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                  <ad-input :rules="[rules.required, rules.password]" :placeholder="$t('login.new-password')" type="password" v-model="toResetPasswordObj.password" class="mb-2" />
                  <div class="row mt-4">
                    <PasswordStrength
                      ref="passwordStrength"
                      :password-input="toResetPasswordObj.password"
                      :password-confirm-input="toResetPasswordObj.password_confirmation"
                    ></PasswordStrength>
                  </div>
              </div>
          </div>
          <div class="row mt-4">
              <div class="col">
                  <ad-input type="password" :rules="[() => toResetPasswordObj.password_confirmation == toResetPasswordObj.password || $t('error.password-repeat-check'), rules.required]" v-model="toResetPasswordObj.password_confirmation" :placeholder="$t('login.new-password-repeat')" />
              </div>
          </div>
            <div class="row mt-4">
                <div class="col">
                    <ad-btn :title="$t('login.reset-password-btn')" />
                </div>
            </div>
        </div>
      </div>
    </div>
    </ad-form>

  </template>
  
  <script>
  import AdInput from '../../components/ui/v_2/AdInput.vue';
  import rules from '@/mixins/rules.js'
  import auth from '@/mixins/auth.js'
  import AdBtn from '../../components/ui/v_2/AdBtn.vue';
  import AdForm from '../../components/ui/v_2/AdForm.vue'
  import PasswordStrength from "@/components/ui/PasswordStrength";
  // import crud from "@/store/modules/crud.js";
  export default {
    mixins: [rules, auth],
    data() {
      return {
        updateKey: 0
      }
    },
    components: {
        AdInput,
        AdBtn,
        AdForm,
        PasswordStrength
    },
    async mounted() {
      this.toResetPasswordObj.token = this.$route.params.token
      this.toResetPasswordObj.email = this.$route.query.email
      this.updateKey++
    }
  }
  </script>
  
  <style lang="scss" scoped>
  h2 {
    font-weight: bold;
  }
  </style>