<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <h1 class="text-left mb-0">
          <strong>{{ $t("menu.keywords") }}</strong>
        </h1>
        <div class="small text-muted pl-1">{{ $t("crud.view") }}</div>
      </div>
      <div class="col-sm-6 text-sm-right">
        <router-link
          class="button bg-light mr-0 my-2 d-inline-block"
          to="/keywords"
        >
          {{ $t("system.cancel") }}
        </router-link>
      </div>
    </div>

    <DataTable
      :items="items"
      :fields="fields"
      :rows="rows"
      :loading="loading"
      :pagination="false"
      view="view"
    ></DataTable>

    <!--CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <h4 id="traffic" class="card-title mb-0">
              {{ $t("my-interests.keyword") }}
            </h4>
            <div class="small text-muted">{{ $t("crud.view") }}</div>
          </CCol>
          <CCol sm="6" class="text-right pt-2">
            <CButton
              :to="'/keywords/edit/' + this.$route.params.id"
              size="sm"
              color="info"
              class="mr-2"
              >{{ $t("system.update") }}</CButton
            >

            <CButton color="secondary" square size="sm" to="/keywords">
              {{ $t("system.cancel") }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <br />
            <CSpinner color="info" class="loading" v-if="loading" />
            <CDataTable v-else :items="viewItems" :fields="fields" hover>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard-->
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import i18n from "@/i18n";
import DataTable from "@/components/ui/DataTable";

export default {
  name: "KeywordsView",
  components: {
    DataTable
  },
  data() {
    return {
      loading: true,
      items: [],
      itemsUsers: [],
      fields: [
        {
          key: "data",
          label: i18n.t("system.label"),
          sorter: false
        },
        {
          key: "value",
          label: i18n.t("system.value"),
          sorter: false
        }
      ],
      rows: [
        {
          key: "word",
          label: i18n.t("my-interests.keyword")
        },
        {
          key: "language",
          label: i18n.t("system.language"),
          value: this.$store.state.enumerators.enumerators["language"],
          valueType: "enumerator"
        }
      ],
      status: [],
      selectLanguage: []
    };
  },
  methods: {
    getCompanyInfo() {
      let self = this;
      crud.get("keywords/" + this.$route.params.id).then(function(response) {
        self.items.push(response.data);
        self.loading = false;
      });
    }
  },
  created() {
    this.getCompanyInfo();
  }
};
</script>

<style></style>
