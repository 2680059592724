<template>
  <div class="col">
    <CSpinner color="info" class="loading" v-if="loading" />
    <form @submit.prevent="submit" v-else>
      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="col-md-9">
              <div class="row">
                <div class="col-12 col-md-6 input-box bg-invert-white">
                  <span class="input-group-addon d-flex align-items-center">
                    <font-awesome-icon icon="fa-solid fa-user" />
                  </span>
                  <input
                    type="text"
                    id="name"
                    class="input-1"
                    v-model="form.first_name"
                  />
                  <label class="input-label" for="name">{{
                    $t("system.first_name")
                  }}</label>
                </div>
                <div class="col-12 col-md-6 input-box bg-invert-white">
                  <input
                    type="text"
                    id="surname"
                    class="input-1"
                    v-model="form.last_name"
                  />
                  <label for="surname" class="input-label">{{
                    $t("system.last_name")
                  }}</label>
                </div>
                <br />

                <div class=" col-12 col-md-6 input-box bg-invert ">
                  <input
                    type="email"
                    id="email"
                    class="input-1"
                    :disabled="
                      !$options.filters.isSuperAdmin(
                        $store.state.auth.user.roles
                      )
                    "
                    v-model="form.email"
                  />
                  <label class="input-label" for="email">{{
                    $t("system.email")
                  }}</label>
                </div>
                <div class="col-12 col-md-6">
                  <div class="input-box bg-invert-white  mb-3">
                    <span class="input-group-addon d-flex align-items-center">
                      <font-awesome-icon icon="fa-solid fa-phone" />
                    </span>
                    <input
                      type="tel"
                      id="phone"
                      class="input-1"
                      v-model="form.phone"
                    />
                    <label class="input-label" for="phone"
                      >{{ $t("register.phone") }}
                      <small class="d-none d-sm-inline"
                        >({{ $t("register.phone-international") }})</small
                      ></label
                    >
                  </div>
                </div>
                <div 
                  v-if="local.def == 'publisher' && local.roles.includes('publisher')"
                  class="col-12 col-md-6  input-box bg-invert-white"
                >
                  <select
                    v-model="form.default_role"
                    class="input-1 mb-3"
                    id="default_role"
                  >
                    <option
                      v-for="(value, index) in roleSelector"
                      :key="index"
                      :value="value.value"
                      :selected="form.default_role === value.value"
                      >{{ value.label }}</option
                    >
                  </select>
                  <label for="default_role" class="input-label">{{
                    $t("register.default_role")
                  }}</label>
                  <tooltip
                    :text="$t('hints.default_role')"
                    location="left"
                    color="secondary"
                    class="ml-1 mb-3"
                  ></tooltip>
                </div>

                <div
                  class="col-12 col-md-6 input-box"
                  :class="{
                    'bg-invert-white': !$options.filters.isSuperAdmin(
                        this.$store.state.auth.user.roles
                      )
                  }"
                >
                  <select
                    v-model="form.status"
                    class="input-1 mb-3"
                    id="status"
                    :disabled="
                      !$options.filters.isSuperAdmin(
                        this.$store.state.auth.user.roles
                      )"
                  >
                    <option
                      v-for="item in allowedStatuses"
                      :key="item.id"
                      :value="item.id"
                      :selected="form.status == item.id"
                      >{{ generateStatusTranslate(item.value) }}</option
                    >
                  </select>
                  <label for="status" class="input-label">{{
                    $t("register.status")
                  }}</label>
                </div>
                <div class="col-12 col-md-6 input-box bg-invert-white">
                  <select
                    v-model="form.language"
                    class="input-1 mb-3"
                    id="language"
                  >
                    <option
                      v-for="(value, index) in $store.state.enumerators
                        .enumerators['language']"
                      :key="value"
                      :value="index"
                      >{{ generateLangTranslate(index) }}</option
                    >
                  </select>
                  <label for="language" class="input-label">{{
                    $t("register.language")
                  }}</label>
                </div>
                <div class="col-12 col-md-6 input-box bg-invert-white">
                  <select
                    v-model="form.country"
                    class="input-1 mb-3"
                    id="country"
                  >
                    <option
                      v-for="(value, index) in $store.state.enumerators
                        .enumerators['country']"
                      :key="value"
                      :value="index"
                      >{{ generateCountryTranslate(index) }}</option
                    >
                  </select>
                  <label for="country" class="input-label">{{
                    $t("register.country")
                  }}</label>
                </div>

                <div class="col-lg-12 pt-2" v-if="isEdit">
                  <input
                    type="checkbox"
                    :value="1"
                    v-model="changePass"
                    id="changePass"
                  />
                  <label class="checkbox-label ml-2" for="changePass">
                    {{ $t("login.change-password") }}
                  </label>
                  <hr />

                  <template v-if="changePass">
                    <div
                      class="col-lg-8"
                      v-if="(!changePass && !isEdit) || (changePass && isEdit)"
                    >
                      <password
                        ref="Password"
                        :form="form"
                        size="10"
                        auto="false"
                        characters="a-z,A-Z,0-9,#"
                      ></password>
                      <hr />
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div
                v-if="$options.filters.isSuperAdmin($store.state.auth.user.roles)"
                class="col-md-3"
            >
              <template
                v-if="!$options.filters.isSuperAdmin($store.state.auth.user.roles)"
              >
                <label
                  v-if="
                    !$options.filters.isInArray(
                      this.$store.state.auth.user.roles,
                      'user'
                    )
                  "
                  style="display: flex;"
                >
                  {{ $t("roles.role") }}
                  <tooltip
                    :text="$t('hints.role')"
                    location="left"
                    color="secondary"
                    class="ml-1 mb-1"
                  ></tooltip> </label
                ><br />
                <span
                  v-for="item in selectRoles"
                  v-bind:key="item.label"
                  :class="{
                    'd-none':
                      item.value === 'superadmin' ||
                      item.value === 'publisher' ||
                      item.value === 'advertiser'
                  }"
                >
                  <input
                    class="input-1"
                    type="checkbox"
                    :value="item.value"
                    v-model="form.roles"
                    :id="item.value"
                    :disabled="myProfile"
                    @click="checkRoles(item.value)"
                  />
                  <label
                    class="checkbox-label pl-2"
                    :for="item.value"
                    role="button"
                  >
                    {{ generateRoleTranslate(item.label) }}
                  </label>
                  <br />
                </span>
              </template>
              <template v-else>
                <label>{{ $t("roles.role") }} </label><br />

                <span v-for="item in selectRoles" v-bind:key="item.label">
                  <input
                    class="input-1"
                    type="checkbox"
                    :value="item.value"
                    v-model="form.roles"
                    :id="item.value"
                    :disabled="myProfile"
                  />
                  <label class="checkbox-label pl-2" :for="item.value">
                    {{ generateRoleTranslate(item.label) }}
                  </label>
                  <br />
                </span>
              </template>

              <br />
              <p v-if="form.company">
                {{ $t("register.company") }}<br />
                <router-link :to="'/settings/my-company'">
                  <strong>{{ form.company.name }}</strong>
                </router-link>
              </p>
            </div>
          </div>

          <button
            v-if="isEdit && !isSubmitting"
            type="submit"
            class="button ml-0"
          >
            {{ $t("system.update") }}
          </button>

          <button
            v-if="!isEdit && !isSubmitting"
            type="submit"
            class="button color-green-dark"
          >
            {{ $t("crud.create") }}
          </button>
          <ButtonSpinner v-if="isSubmitting" class="ml-0" />

          <router-link
            v-if="!this.isMyProfile && this.isEdit"
            class="button bg-light mr-0 my-2 d-inline-block"
            to="/users"
          >
            {{ $t("system.cancel") }}
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n, { loadLanguageAsync } from "@/i18n";
import Password from "@/components/ui/PasswordGenerator.vue";
import ButtonSpinner from "@/components/ui/ButtonSpinner";
import tooltip from "@/components/ui/Tooltip";
import _ from "lodash";

export default {
  name: "UserCreate",
  props: ["isEdit", "isMyProfile"],
  components: { Password, ButtonSpinner, tooltip },
  data() {
    return {
      local: {
        def: localStorage.default_role,
        roles: localStorage.roles
      },
      loading: true,
      form: {
        id: 0,
        first_name: null,
        last_name: null,
        password: null,
        password_confirmation: null,
        email: null,
        status: null,
        phone: null,
        language: "sk-SK",
        country: "sk-SK",
        default_role: null,
        company: null,
        roles: []
      },
      changePass: false,
      item: [],
      isSubmitting: false,
      showError: false,
      passwordVisible: false,
      selectRoles: [],
      myProfile: false,
      enums: [],
      allowedStatuses: []
    };
  },
  computed: {
    roleSelector: function() {
      let response = [];
      let object = JSON.parse(localStorage.roles);
      _.pull(object, "admin").forEach(role => {
        response.push({
          value: role,
          label: i18n.t(role + ".status")
        });
      });
      return response;
    }
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    getUser() {
      let self = this;

      let userID =
        this.isMyProfile && this.isEdit
          ? Number(this.$store.state.auth.user.id)
          : Number(this.$route.params.id);

      crud
        .get("users/" + userID + "?expand=company,roles")
        .then(function(response) {
          self.loading = false;
          self.form.id = response.data.id;
          self.form.first_name = response.data.first_name;
          self.form.last_name = response.data.last_name;
          self.form.email = response.data.email;
          self.form.phone = response.data.phone;
          self.form.status = response.data.status;
          self.form.language = response.data.language;
          self.form.company = response.data.company;
          self.form.country = response.data.country;
          self.form.default_role = response.data.default_role;
          self.form.roles = response.data.roles.map(key => {
            return key.name;
          });
          self.meUser();
        });
    },

    meUser() {
      if (
        this.isEdit &&
        Number(this.$store.state.auth.user.id) === Number(this.form.id)
      ) {
        this.myProfile = true;
      }
    },

    validate() {
      //validate password
      if (this.changePass && !this.$store.state.validate.validated.password) {
        return false;
      }
      return true;
    },

    addPasswordEdit() {
      if (!this.changePass) {
        delete this.form.password_confirmation;
        delete this.form.password;
      }
    },

    checkRoles(role) {
      //temp function - admin/user radio function + set 'publisher', 'advertiser' role for all
      if (
        !this.$options.filters.isSuperAdmin(
          this.$store.state.auth.user.roles
        ) &&
        !this.myProfile &&
        role !== "superadmin"
      ) {
        this.form.roles = [];
        this.form.roles.push(role, "publisher", "advertiser");
      }
    },

    getRole() {
      let self = this;

      crud.get("auth-roles?per-page=100000").then(response => {
        const roles = response.data.items;
        for (const property in roles) {
          self.selectRoles.push({
            label: roles[property]["description"],
            value: roles[property]["name"]
          });
        }
      });
    },

    submit() {
      this.isSubmitting = true;

      if (this.validate()) {
        if (this.isEdit) {
          this.addPasswordEdit();

          let userID =
            this.isMyProfile && this.isEdit
              ? Number(this.$store.state.auth.user.id)
              : Number(this.$route.params.id);

          // Only superadmin & admin can change role
          if (
            this.$store.state.auth.user.roles.includes("superadmin") ||
            this.$store.state.auth.user.roles.includes("admin")
          ) {
            // console.log('You are admin or superadmin');
          } else {
            delete this.form.status;
          }

          crud
            .put("users/" + userID, this.form)
            .then(() => {
              this.isSubmitting = false;
              if (!this.isMyProfile) this.$router.push("/users");
              if (this.isMyProfile && this.changePass) {
                this.changePass = false;
                this.form.password = null;
                //this.confirmPassword = null;
                this.valid_password = false;
              }

              if (this.myProfile) {
                loadLanguageAsync(this.form.language);
              } //set new lang

              this.addNotification(
                {
                  type: "success",
                  message: i18n.t("success.record-updated")
                },
                { root: true }
              );
            })
            .catch(error => {
              this.isSubmitting = false;

              this.addNotification({
                type: "danger",
                message: error.response.data[0].message
              });
            });
        } else {
          crud
            .post("users", this.form)
            .then(() => {
              this.isSubmitting = false;
              loadLanguageAsync(this.form.language); //set new lang
              this.$router.push("/users");
              this.addNotification(
                {
                  type: "success",
                  message: i18n.t("success.record-add")
                },
                { root: true }
              );
            })
            .catch(error => {
              this.isSubmitting = false;

              this.addNotification({
                type: "danger",
                message: error.response.data[0].message
              });
            });
        }
      } else {
        let messageVal = "";
        let messageRol = "";

        if (!this.validate()) {
          messageVal = i18n.t("error.check-password") + ".";
        }

        if (this.form.roles.length === 0) {
          messageRol = i18n.t("error.roles-empty") + ".";
        }

        this.isSubmitting = false;
        this.addNotification({
          type: "danger",
          message: messageVal + messageRol
        });
      }

      this.$root.$emit("updateTheSidebarComponent");
    },
    generateLangTranslate(data) {
      return i18n.t("lang." + data);
    },
    generateCountryTranslate(data) {
      return i18n.t("country." + data);
    },
    generateStatusTranslate(data) {
      return i18n.t("enum." + data);
    },
    generateRoleTranslate(data) {
      return i18n.t("role." + data);
    }
  },
  created() {
    this.isEdit ? this.getUser() : "";
    !this.$options.filters.isInArray(this.$store.state.auth.user.roles, "user")
      ? this.getRole()
      : "";

    this.allowedStatuses.push(
      {
        id: 0,
        value: this.$store.state.enumerators.enumerators["user-status"][0]
      },
      {
        id: 9,
        value: this.$store.state.enumerators.enumerators["user-status"][9]
      },
      {
        id: 10,
        value: this.$store.state.enumerators.enumerators["user-status"][10]
      }
    );

    // Only superadmin can see all enums
    if (this.$store.state.auth.user.roles.includes("superadmin")) {
      this.allowedStatuses.push({
        id: 8,
        value: this.$store.state.enumerators.enumerators["user-status"][8]
      });
    }
  },
};
</script>

<style>
.psw {
  margin-top: 5px;
}
</style>
