<template>
  <vc-date-picker
      v-model="internalData"
      :mode="mode"
      :available-dates="availableDates"
      :locale="locale"
      update-on-input
      @input="inputEmit"
      is24hr
      :popover="{ placement: 'bottom', visibility: 'focus' }"
  >
    <template v-slot="{ inputValue, inputEvents }">

      <div class="ad-input-main">
        <div class="ad-input-wrapper"  :key="refreshKey">
          <font-awesome-icon
              :icon="icon"
              v-if="icon !== null"
              class="ad-icon-input"
          />
          <input
              v-on="inputEvents"
              :value="inputValue"
              ref="ad-input"
              class="ad-input"
              :id="'ad-input-' + random"
              :class="{
                bordered: true,
                standard: false,
                'ad-valid': !hasError && !error && !filter,
                'ad-error': error || hasError,
                'ad-filter': filter,
                'input-iconized': icon !== null
              }"
              placeholder=" "
          />
          <label
              :for="'ad-input-' + random"
              :class="{ 'label-iconized': icon !== null }"
          >
            <span v-show="placeholder !== null">{{ placeholder }}</span>
          </label>
        </div>
        <transition name="fade">
          <div class="ad-input-error" v-if="error || hasError">
            {{ message || errorMessage }}
          </div>
        </transition>
      </div>
    </template>
  </vc-date-picker>
</template>

<script>
import validation from "@/mixins/validation";

export default {
  name: "AdDate",
  mixins: [validation],
  props: {
    value: {
      default: null
    },
    mode: {
      default: "dateTime"
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    availableDates: {
      default() { return {start: new Date(), end: null} }
    },
    icon: {
      type: String,
      default: "fa-regular fa-calendar"
    },
    placeholder: {
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    filter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      random: 0,
      refreshKey: 0,
      internalData: null
    }
  },
  mounted() {
    this.internalData = this.value;

    if(this.autoFocus)
    {
      this.$refs['ad-input'].focus()
    }
  },
  created() {
    this.random = Math.random() * 10;
  },
  methods: {
    iconClick() {
      // console.log(this.$refs["ad-input"].focus());
      this.$refs["ad-input"].target.focus();
    },
    inputEmit(e) {
      this.$emit("input", e); // emit value - v-model

      if (this.validations.length > 0)
          // if has rules then validate input
        this.validate(e);
    }
  },
  computed: {
    locale: function () {
      return localStorage.getItem("locale");
    },

  },
  watch: {
    value(val) {
      this.internalData = val
    }
  }
}
</script>

<style lang="scss" scoped>
.ad-input-main {
  position: relative;
  .ad-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    .ad-icon-input {
      position: absolute;
      cursor: pointer;
      left: 0.8em;
    }
    .ad-input {
      width: 100%;
      // height: 0.3em;
      line-height: 0.3em;
      padding: 1.1em 0.8em 0.8em 0.8em;
      font-size: 1em;
      border: none;
      transition: border-color 0.3s;

      &.ad-error {
        border-color: #dc3545d9;
        background-color: rgba(220, 53, 69, 5%);
      }

      &.ad-valid {
        border-color: #00a490;
      }

      &.input-iconized {
        padding-left: 2.5em !important;
      }

      &.autocomplete {
        display: none;
      }

      &.ad-filter {
        border-color: #dadce0;
        background-color: #fafafa;
      }


    }

    .bordered {
      border: 1px solid #ced4da;
      border-radius: 4px;
    }

    .standard {
      border: none;
      border-bottom: 1px solid #ced4da;
      border-radius: 0;
    }

    label {
      position: absolute;
      left: 1em;
      top: 0.9em;
      margin: 0;
      color: #000000a6;
      transform-origin: left;
      transition: transform 0.3s;
      pointer-events: none;
      &.label-iconized {
        left: 2em !important;
      }
    }

    .ad-input:focus ~ label,
    .ad-input:not(:placeholder-shown).ad-input:not(:focus) ~ label {
      transform: translateY(-0.8em) scale(0.7) translateX(-0.1em);

      &.label-iconized {
        transform: translateY(-0.8em) scale(0.7) translateX(0.8em);
      }
    }
  }
  .ad-input-error {
    color: #dc3545d9;
    font-size: 0.8em;
    position: absolute;
    padding-left: 1em;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s, transform 0.3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-5px);
  }
}
</style>
