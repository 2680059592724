<template>
  <div class="row">
    <div class="col-12 comments-view pr-md-5">
      <div class="">
        <!-- Show messages for bid -->
        <div id="comments" ref="comments" v-on:scroll="loadMoreComments">
          <div class="loader" v-show="loadingMore"></div>
          <div
            v-for="(comment, index) in commentsComputed"
            :key="index"
            class="d-flex message-row"
          >
            <div
              v-if="['message', 'attachment'].includes(comment.type)"
              class="message"
              :class="{
                'highlight ': comment.me_sended,
                'my-0 py-1':
                  index !== 1 ||
                  (commentsComputed[index - 1] &&
                    commentsComputed[index - 1].user_id === comment.user_id)
              }"
            >
              <div class="d-flex">
                <div
                  class="mb-2 mt-2 d-flex align-items-center"
                  :class="{ 'text-right': comment.me_sended }"
                  v-if="
                    index === 0 ||
                    (commentsComputed[index - 1] &&
                    commentsComputed[index - 1].user_id !== comment.user_id)
                    ||
                      (commentsComputed[index - 1] &&
                         commentsComputed[index - 1].human_date !== comment.human_date)
                  "
                >
                  <profile-avatar :user="comment.user" class="mr-2" :colors="(comment.me_sended) ? [
                    {
                      background: '#AC3492',
                      color: 'white'
                    }
                  ]: [
                    {
                      background: '#45AC34',
                      color: 'white'
                    }
                  ]" />
                  <span
                    ><strong>{{ comment.user.first_name }}</strong
                    >, {{ comment.human_date }}</span
                  >
                </div>
              </div>
              <div class="message-content">
                <div v-if="comment.type === 'message'">
                  {{ comment.comment }}
                </div>
                <div v-else>
                  <div
                    v-for="attachment in comment.attachments"
                    :key="attachment.id"
                  >
                    <div v-if="attachment.file_type.includes('image')">
                      <img width="20%" :src="attachment.hash_url" />
                      <div>
                        <a target="_blank" :href="attachment.hash_url">{{
                          attachment.name
                        }}</a>
                        <br />
                        {{ attachment.mb_size }}
                      </div>
                    </div>
                    <div v-else class="d-flex">
                      <font-awesome-icon
                        icon="fa-regular fa-file-lines"
                        class="mr-2"
                        size="lg"
                        color="black"
                      />
                      <div>
                        <a
                          target="_blank"
                          :href="attachment.hash_url"
                          class="d-flex"
                          >{{ attachment.name }}</a
                        >
                        {{ attachment.file_type }} {{ attachment.mb_size }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="
                ['system-success', 'system-error'].includes(comment.type)
              "
              class="system-message mt-3"
            >
              <!-- <div class="header-system">
                                System
                            </div> -->
              <div class="content-system py-2 " :class="comment.type">
                {{ comment.comment }}, {{ comment.human_date }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Add new comment -->
      <div class="row" id="messaging-tools">
        <div class="col">
          <div class="row">
            <div class="col-12">
              <textarea
                @keydown.enter.prevent="sendMessage"
                v-model="message"
                :placeholder="$t('chat.send-message')"
              ></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right d-flex justify-content-end">
              <base-uploader
                type="button"
                class="mr-2"
                :apiUrl="uploadUrl"
                @uploaded="updateMessages($event)"
              />
              <button @click="sendMessage" :disabled="loading" class="btn btn-success">
                <font-awesome-icon v-if="loading" icon="spinner" spin />

                <template v-else>
                  {{ $t("chat.send-message") }}
                </template>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import ProfileAvatar from "../ProfileAvatar.vue";
import BaseUploader from "../../attachments/BaseUploader.vue";
// import i18n from "@/i18n";

export default {
  props: {
    getApiUrl: {
      type: String,
      default: null
    },
    createApiUrl: {
      type: String
    },
    uploadApiUrl: {
      type: String
    },
    items: {
      default() {
        return [];
      }
    }
  },
  components: {
    ProfileAvatar,
    BaseUploader
  },
  data() {
    return {
      message: "",
      comments: [],
      moreDataUrl: "",
      loadingMore: false,
      loading: false
    };
  },
  created() {
    if (this.items.length < 1) {
      this.getComments();
    }
  },
  mounted() {
    if (this.items?.items !== undefined) {
      this.moreDataUrl = this.items.links.next;
    }
    setTimeout(() => {
      if (this.$refs.comments !== undefined) {
        this.$refs.comments.scrollTop = this.$refs.comments?.scrollHeight;
      }
    }, 1000);
  },
  computed: {
    commentsComputed() {
      if (this.items.items !== undefined) {
        return [...this.items?.items, ...this.comments];
      }
      return [...this.comments];
    },
    createUrl() {
      if(this.createApiUrl === null) return null;

      return this.createApiUrl.replace(/\/$/, "");
    },
    getUrl() {
      if(this.getApiUrl === null) return null;

      return this.getApiUrl.replace(/\/$/, "");
    },
    uploadUrl() {
      if(this.uploadApiUrl === null) return null;

      return this.uploadApiUrl.replace(/\/$/, "");
    }
  },
  methods: {
    getComments() {
      if(this.getUrl === null) {
        return;
      }

      crud.get(this.getUrl).then(resp => {
        if (resp.data.items.length < 1) {
          return;
        }
        this.comments = resp.data.items.reverse();
        this.moreDataUrl = resp.data.links.next;
        this.$nextTick(function() {
          this.$refs.comments.scrollTop = this.$refs.comments.scrollHeight;
        });
      });
    },
    loadMoreComments() {
      if (
        this.$refs.comments.scrollTop === 0 &&
        this.loadingMore === false &&
        this.moreDataUrl !== null
      ) {
        this.loadingMore = true;
        crud
          .get(this.moreDataUrl)
          .then(resp => {
            // this.comments.unshift(...resp.data.items.reverse());
            this.moreDataUrl = resp.data.links.next;
            this.$emit("loaded-more", resp.data.items);
          })
          .finally(() => {
            this.loadingMore = false;
          });
      }
    },
    sendMessage(e) {
      if (e.shiftKey || this.message == '') {
        return;
      }
      const msgToSend = this.message;
      this.message = "";

      this.loading = true;

      crud
        .post(this.createUrl, {
          message: msgToSend
        })
        .then(async response => {
          this.comments.push(response.data.items);
          this.$refs.comments.scrollTop = this.$refs.comments.scrollHeight;
          this.$emit("comment-added", response.data.items);
          this.loading = false;
        }).catch(() => this.loading = false);
    },
    changeInput(e) {
      e.preventDefault();
      this.message = e.target.innerHTML;
    },
    updateMessages(e) {
      this.comments.push(e.items);
    }
  }
};
</script>

<style lang="scss" scoped>
.message {
  font-size: 0.9rem;
  padding: 10px;

  .message-content {
    padding-left: calc(4.5rem - 32px);
  }
}
.message > span {
  display: inline-block;
  background: #e4e6eb;
  color: #050505;
}
.auction-more-details {
  background: white;
  // border: 1px solid #e9edef;
  font-size: 0.9em;
  font-weight: bold;
  .small-text {
    font-size: 0.8em;
  }
  & .row.py-2 {
    border: 1px solid #e9edef;
    // border-top: 1px solid #E9EDEF;
    border-collapse: collapse;
  }
}
.auction-details {
  background: #fafafa;
  border: 1px solid #e9edef;
  font-size: 0.9em;
  .small-text {
    font-size: 0.8em;
  }
}

.comments-view {
  border-right: 1px solid #e9edef;
}

#comments {
  max-height: 100%;
  //overflow-x: hidden;
  //overflow-y: auto;
  padding: 15px;
  //max-height: 20rem;
}

.custom-textarea,
textarea {
  width: 100%;
  min-height: 5rem;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.btn {
  background: #00a99d;
}

.bid-header {
  font-size: 1.3em;
  border-bottom: #e9edef 1px solid;
}

.system-message {
  width: 100%;
  text-align: center;
  color: #000000d9;
  border-radius: 2px;

  .system-success {
    background: #e6fffa !important;
  }
  .system-error {
    background: #ffdada;
  }
}

.load-more-comments {
  text-align: center;
}
</style>
