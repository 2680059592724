import AdvertiserOffers from "@/views/offer/advertiser/AdvertiserOffers.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/advertiser/offers",
          meta: {
            label: "menu.dashboard-advertiser"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/advertiser/offers",
              name: "menu.dashboard-advertiser",
              component: AdvertiserOffers,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
