<template>
  <div>
    <vue-editor
      class="vue-editor"
      :class="{
        'ad-valid': validationTriggered && !error,
        'ad-error': validationTriggered && error
      }"
      :value="value"
      :editorToolbar="editorToolbar"
      v-on="inputListeners"
    />
    <transition name="fade">
      <div class="ad-input-error" v-if="error || hasError">
        {{ message || errorMessage }}
      </div>
    </transition>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import validation from "../../../mixins/validation";
export default {
  mixins: [validation],
  components: {
    VueEditor
  },
  props: {
    value: {
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    editorToolbar: {
      default() {
        return [];
      }
    }
  },
  computed: {
    // V-on all listeners
    inputListeners: function() {
      var vm = this;
      return Object.assign({}, this.$listeners, {
        // This ensures that the component works with v-model
        input: function(event) {
          vm.$emit("input", event); // emit value - v-model

          if (vm.validations && vm.validations.length > 0)
            // if has rules then validate input
            vm.validate(event);
        },
        change: function(event) {
          vm.$emit("change", event);
        },
        blur: function(e) {
          vm.$emit("blur", e);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ad-error {
  border-color: #dc3545d9;
}

.ad-valid {
  border-color: #00a490;
}

.ad-input-error {
  color: #dc3545d9;
  font-size: 0.8em;
  position: absolute;
  padding-left: 1em;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-5px);
}
</style>
