<template>
  <div>
    <!-- Loading -->
    <div 
      v-if="this.loading && this.success"
      class="pre_loader_wrapper"
    >
      <div class="spinner"></div>
    </div>

    <!-- Error -->
    <div v-if="this.loading && !this.success" class="pre_loader_wrapper text-center">
      <font-awesome-icon
        icon="fa-solid fa-bug"
        class="d-block mb-4"
        style="margin: 0 auto; font-size: 4rem; color: red;"
      />
      <h1>
        {{ this.errorMessage }}
      </h1>
    </div>

    <!-- Any results -->
    <div v-if="!this.loading && !this.success" 
      class="ad-any-results-notification px-4 py-3"
    >
      <!-- <font-awesome-icon
        icon="fa-solid fa-magnifying-glass"
        class="d-block mb-4"
        style="margin: 0 auto; font-size: 4rem; color: #3b3b3b;"
      />
      <h1>
        {{ this.errorMessage }}
      </h1> -->

      {{ this.errorMessage }}

      <template v-if="this.errorMessageLink">
        <router-link :to="this.errorMessageLink.to">{{ this.errorMessageLink.message }}</router-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["loading", "success", "errorMessage", "errorMessageLink"]

  // watch: {
  //   success: function(newVal, oldVal) { // watch it
  //     console.log('Prop changed: ', newVal, ' | was: ', oldVal)
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.pre_loader_wrapper {
  // border: 1px solid red;
  padding: 5rem;
  background: white;
}

.spinner {
  margin: 0 auto;
  width: 88px;
  height: 88px;
  display: grid;
  border: 7px solid #0000;
  border-radius: 50%;
  border-right-color: #00a99d;
  animation: spinner-a4dj62 1s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 3.5px;
  border: inherit;
  border-radius: 50%;
  animation: spinner-a4dj62 2s infinite;
}

.spinner::after {
  margin: 14px;
  animation-duration: 3s;
}

@keyframes spinner-a4dj62 {
  100% {
    transform: rotate(1turn);
  }
}

.ad-any-results-notification {
  background: rgba(255, 236, 207, 1);
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.875rem;
  border-radius: 4px;
}
.ad-any-results-notification > a {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: underline;
}
</style>
