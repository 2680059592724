<script>
import crud from "@/store/modules/crud";
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,

  props: ["pieChartData"],

  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            borderWidth: 1,
            borderColor: [],
            backgroundColor: [],
            data: []
          }
        ]
      },
      options: {
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },

  mounted() {
    this.processPieChartData();
  },

  computed: {
    userMode: function() {
      return localStorage.view;
    }
  },

  methods: {
    processPieChartData() {
      let self = this;

      let query = null;

      this.userMode == "publisher"
        ? (query = "bid/publisher/accepted-pending")
        : (query = "bid/advertiser/won-lost-count");

      crud.get(query).then(function(response) {
        // Process data
        self.pieChartData.forEach(value => {
          self.chartData.labels.push(value.title);

          if (value.type == "won") {
            if (response.data["won"]) {
              self.chartData.datasets[0].data.push(response.data["won"]);
            } else {
              self.chartData.datasets[0].data.push(response.data["accepted"]);
            }
          } else {
            if (response.data["lost"]) {
              self.chartData.datasets[0].data.push(response.data["lost"]);
            } else {
              self.chartData.datasets[0].data.push(response.data["pending"]);
            }
          }

          self.chartData.datasets[0].borderColor.push(value.borderColor);
          self.chartData.datasets[0].backgroundColor.push(
            value.backgroundColor
          );
        });

        // Render PieChart
        self.renderChart(self.chartData, self.options);
      });
    }
  }
};
</script>
