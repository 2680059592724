<template>
  <ad-form @submit="forgotPasswordMethod">
    <div class="container py-5 ">
      <div class="row text-center">
        <div class="col">
          <h2>{{$t('login.forgot-password')}}</h2>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-12 col-sm-8 col-md-6 col-lg-5">
          <p class="lead">
            {{$t('login.forgot-password-lead')}}
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-md-6 col-lg-5">
            <div class="row mt-4">
                <div class="col">
                    <ad-input :placeholder="$t('login.email')" type="email" :rules="[rules.required, rules.email]" v-model="resetPasswordObj.email" />
                </div>
            </div>
            <div class="row mt-4">
                <div class="col">
                    <ad-btn :title="$t('login.reset-password')" />
                </div>
            </div>
        </div>
      </div>
    </div>
    </ad-form>

  </template>
  
  <script>
  import AdInput from '../../components/ui/v_2/AdInput.vue';
  import rules from '@/mixins/rules.js'
  import auth from '@/mixins/auth.js'
  import AdBtn from '../../components/ui/v_2/AdBtn.vue';
  import AdForm from '../../components/ui/v_2/AdForm.vue'
  // import crud from "@/store/modules/crud.js";
  export default {
    mixins: [rules, auth],
    components: {
        AdInput,
        AdBtn,
        AdForm
    },
  }
  </script>
  
  <style lang="scss" scoped>
  h2 {
    font-weight: bold;
  }
  </style>