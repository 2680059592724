<template>
  <div class="ad-select-container" ref="ad-select">
    <ad-input
      v-model="modelValueInput"
      :placeholder="placeholderComputed"
      @keydown.enter="addNewChoice"
      @focus="active = true"
      v-if="!readOnly"
      ref="ad-select-input"
      :has-error="validationTriggered && error"
      :class="{
        'ad-valid': validationTriggered && !error,
        'ad-error': validationTriggered && error
      }"
    />
    <!-- <div class="ad-selected-item" v-for="(ajtem, index) in internalData" :key="index">
        {{ ajtem }}
    </div> -->
    <transition name="fade">
      <div class="ad-input-error" v-if="error || hasError">
        {{ message || errorMessage }}
      </div>
    </transition>
    <select v-on="inputListeners" :value="value" :multiple="multiple">
      <option
        class="option"
        :value="item[itemValue]"
        v-for="item in items"
        :key="item[itemValue]"
        >{{ item[itemLabel] }}</option
      >
    </select>
    <div
      class="ad-select-wrapper"
      :class="dropdownDirection"
      v-if="active || (activeOut && readOnly)"
      ref="ad-dropdown"
    >
      <div class="ad-select-inside-wrapper">
        <div class="ad-select-item-search" v-if="autocomplete">
          <ad-input
            icon="fa-solid fa-magnifying-glass"
            placeholder="Search"
            standard
            v-model="searchString"
            @input="filter"
          />
        </div>
        <div
          class="ad-select-item"
          :class="{ selected: internalData.includes(item[itemValue]) }"
          v-for="item in items"
          :key="item[itemValue]"
          @click="selectItem(item[itemValue])"
        >
          <label :for="'ad-option-' + item[itemValue]">
            <div :class="multiple ? 'checkbox' : 'radio'"></div>
            {{ item[itemLabel] }}
          </label>
          <input
            :id="'ad-option-' + item[itemValue]"
            :type="multiple ? 'checkbox' : 'radio'"
            :checked="internalData.includes(item[itemValue])"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdInput from "./AdInput.vue";
import validation from "../../../mixins/validation";
export default {
  name: "AdSelect",
  mixins: [validation],
  data() {
    return {
      modelValueInput: "",
      internalData: [],
      active: false,
      searchString: "",
      inputTimer: null,
      anchorEl: {}
    };
  },
  components: {
    AdInput
  },
  props: {
    dropdownDirection: {
      default: "bottom"
    },
    value: {
      default: null
    },
    activeOut: {
        type: Boolean,
        default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    useInput: {
      type: Boolean,
      default: true
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    items: {
      default() {
        return [];
      }
    },
    itemLabel: {
      type: String,
      default: null
    },
    itemValue: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    debounce: {
      type: Number,
      default: 300
    },
    creatable: {
      type: Boolean,
      default: false
    },
    multipleText: {
      type: String,
      default: null 
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    filter(value) {
      // filter callback
      clearTimeout(this.inputTimer); // debounce timer clear
      this.inputTimer = setTimeout(() => {
        this.$emit("filter", value, fn => {
          fn();
        });
      }, this.debounce);
    },
    async toggleValueInData(value) {
      // toggle value in internal data
      // console.log(this.internalData)
      if (!this.internalData.includes(value)) {
        this.internalData.unshift(value);
      } else {
        this.internalData = this.internalData.filter(val => {
          return val != value;
        });
      }
    },
    selectValue(value) {
      // select value to internal data
      this.internalData = [];
      this.internalData.unshift(value);
    },
    createInputLabelValues() {
      // create label from selected values
      this.modelValueInput = "";
      let labels = [];
      this.internalData.forEach(item => {
        this.items.map(itemFromDb => {
          if (itemFromDb[this.itemValue] == item) {
            labels.unshift(itemFromDb[this.itemLabel]);
          }
        });
      });
      this.modelValueInput = labels.join(", ");
      if (this.multiple && this.internalData.length > 0) {
        this.modelValueInput += ", ";
      }
      this.modelValueInput = this.modelValueInput.replace(/, $/, "");
    },
    async processValue(value) {
      // process input - check if its radio or checkbox (one or multiple choices)

      if (this.multiple) {
        await this.toggleValueInData(value);
      } else {
        this.selectValue(value);
        await this.createInputLabelValues();
      }
    },
    async selectItem(val) {
      // emit internal data to two ways binding - v-model
      await this.processValue(val);
      if (this.multiple) {
        this.$emit("input", this.internalData);
      } else {
        this.$emit("input", this.internalData[0]);
      }
      if (this.validations.length > 0)
        // if has rules then validate input
        this.validate(this.internalData);
    },
    blurInput(evt) {blurInput:{
      /** Hide dropdown on blur input */
      if (evt && evt.target && (this.$el.contains(evt.target) || this.anchorEl.contains(evt.target))) 
      {
        break blurInput;
      }
      this.active = false
      this.searchString = ''
      this.$emit('hide')
      // if (
      //   ((this.$refs["ad-select-input"] !== undefined && 
      //   !this.$refs["ad-select-input"].$el.contains(e.target)) ||
      //   (this.$refs["ad-dropdown"] !== undefined &&
      //   !this.$refs["ad-dropdown"].contains(e.target)))
      //   && this.readOnly
      // ) {
      //   this.active = false;
      //   this.searchString = "";
      //   // this.filter(this.searchString)
      // }
    }},
    async addNewChoice(e) {
      // add new choices by enter
      if(e.path !== undefined){
        e.preventDefault();
      } 
      if (
        e.target.value === null ||
        e.target.value === undefined ||
        e.target.value.trim().length < 1
      ) {
        return;
      }
      if (this.creatable) {
        const newObjects = [];
        const inputTextValues = e.target.value.split(",");

        inputTextValues.forEach(item => {
          let newObject = {};
          if (item === null || item === undefined || item.trim().length < 1) {
            return;
          }

          if (this.items.length < 1) {
            newObject[this.itemValue] = item.trim();
            newObject[this.itemLabel] = item.trim();
            newObjects.unshift(newObject);
            this.processValue(item.trim());
          } else {
            let cantPush = this.items.some(
              data => data[this.itemValue] === item.trim()
            );
            if (!cantPush) {
              newObject[this.itemValue] = item.trim();
              newObject[this.itemLabel] = item.trim();
              newObjects.unshift(newObject);
              this.processValue(item.trim());
            }
          }
        });

        this.modelValueInput = ''
        await this.$emit("add-new", newObjects);
        // await this.createInputLabelValues();
        await this.$emit("input", this.internalData);
      }
    }
  },
  computed: {
    // V-on all listeners
    inputListeners: function() {
      var vm = this;
      return Object.assign({}, this.$listeners, {
        // This ensures that the component works with v-model
        input: function(event) {
          vm.processValue(event.target.value);
          if (vm.multiple === true) {
            vm.$emit("input", vm.internalData); // emit multiple value - v-model
          } else {
            vm.$emit("input", vm.internalData[0]); // emit single value - v-model
          }

          if (vm.validations.length > 0)
            // if has rules then validate input
            vm.validate(vm.internalData);
        },
        blur: function(event)
        {
            vm.$emit(event)
        }
      });
    },
    placeholderComputed: function () {
      if(this.multiple && this.internalData.length > 0) {
        return `${this.multipleText} (${this.internalData.length})`
      }
      return this.placeholder
    }
  },
  watch: {
    modelValueInput: function (value) {
      if(value.includes(',') && this.multiple)  {
        let e = {
          target: {
            value: value
          }
        }
        this.addNewChoice(e)
        this.modelValueInput = '';
      }
    },
    value: function(val) {
      this.internalData = val;
    }
  },
  mounted() {
    setTimeout(() => {
      // console.log(this.value)
      if(this.value !== null) {
        // console.log(typeof this.value)
        if(typeof this.value === 'object')
        {
          this.internalData = this.value
        }
        else {
          this.internalData.unshift(this.value)
        }
        this.createInputLabelValues();
        // this.processValue(this.value)
      }
    }, 500)

    this.$nextTick(() => {
      this.anchorEl = this.$el.parentNode
    })
    
    document.addEventListener(
      "mousedown",
      this.blurInput
      ); /*- event for blur input */
  },
  beforeDestroy() {
    document.removeEventListener("mousedown", this.blurInput);
  },
};
</script>

<style lang="scss" scoped>
select {
  display: none;
}
.ad-select-container {
  position: relative;
  background: #ffffff;
  .ad-selected-item{
    top:0;
    left:0;
  }
}
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ad-select-wrapper {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 100%;
  padding: 1em 1em;
  z-index: 1000;
  background: #ffffff;
  position: absolute;

  &.bottom {
    // top: 0%;
  }
  &.top {
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.25) !important;
    bottom: 100%;
  }
  .ad-select-inside-wrapper {
    max-height: 14em;
    overflow: auto;
  }

  .ad-select-item-search {
    width: 100%;
    padding-right: 0.6em;
  }

  .ad-select-item {
    padding: 0.6em 0em 0.6em 0em;
    cursor: pointer;
    input {
      display: none;
    }
    label {
      pointer-events: none;
      position: relative;
      margin: 0;
      div {
        display: inline-block;
        position: relative;
        min-width: 16px;
        min-height: 16px;
        margin: 0em 0.4em;
        /** radio button style */
        &.radio::before {
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;
          content: "";
          width: 16px;
          height: 16px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 100px;
          transform: scale(1);
          transition: transform 0.3s;
        }
        &.radio::after {
          cursor: pointer;
          content: "";
          position: absolute;
          left: 25%;
          top: 25%;
          width: 8px;
          height: 8px;
          background: #00a490;
          border-radius: 100px;
          transform: scale(0);
          transition: transform 0.3s;
        }
        /** checkbox button style */
        &.checkbox::before {
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;
          content: "";
          width: 16px;
          height: 16px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          transform: scale(1);
          transition: transform 0.3s;
        }
      }
    }
    &.selected {
      label {
        div {
          &.radio::before {
            border-color: #00a490;
            transform: scale(1.2);
          }
          &.radio::after {
            transform: scale(1);
          }

          &.checkbox::before {
            background: #00a490;
          }
          &.checkbox::after {
            cursor: pointer;
            position: absolute;
            content: "";
            left: 32%;
            top: 12%;
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
</style>
