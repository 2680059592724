<template>
  <div class="container py-5 text-center">
    <div class="row">
      <div class="col">
        <h2>{{$t('verification.success')}}</h2>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-12 col-sm-8 col-md-5 text-justify">
        <p class="lead">
          {{$t('verification.thank-you-text')}}
        </p>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-12 col-sm-8 col-md-5 text-justify">
        <div class="alert alert-warning" role="alert" >
          {{$t('verification.link-text')}}
          <a href="#" @click.prevent="resendVerification">{{$t('verification.link-name')}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/mixins/auth'
export default {
    mixins: [auth],
    mounted() {
      this.$store.dispatch("auth/verifyUser", true)
    }
}
</script>

<style lang="scss" scoped>
h2 {
  font-weight: bold;
}
</style>