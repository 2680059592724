<template>
  <ad-form @submit="update($event, role)">
    <div class="container">
      <!-- Name -->
      <div class="row">
        <div class="col-12 col-md-8">
          <ad-input
            v-model="item.name"
            :rules="[rules.required]"
            placeholder="Name"
          />
        </div>
      </div>
      <!-- Price -->
      <div class="row">
        <div class="col-12 col-md-8 mt-4">
          <ad-input
            v-model="item.price"
            :rules="[rules.required, rules.number]"
            placeholder="Price"
          />
        </div>
      </div>
      <!-- Url -->
      <div class="row">
        <div class="col-12 col-md-8 mt-4">
          <ad-input
            v-model="item.url"
            :rules="[rules.required, rules.url]"
            placeholder="Url"
          />
        </div>
      </div>

      <!-- Company -->
      <div v-if="store.getters['auth/isSuperAdmin']" class="row">
        <div class="col-12 col-md-8 mt-4">
          <select-box
              :options="searchedCompanies"
              :rules="[rules.required]"
              label="name"
              v-model="item.company_id"
              :placeholder="$t('company.name')"
          />
        </div>
      </div>

      <!-- Detail -->
      <div class="row">
        <div class="col-12 col-md-8 mt-4">
          <h5 class="mb-2">Detail</h5>
        </div>
        <div class="col-12 col-md-8">
          <ad-vue-editor
            :editorToolbar="editorToolbar"
            v-model="item.detail"
            :rules="[rules.required]"
          />
        </div>
      </div>
      <!-- Expectation -->
      <div class="row">
        <div class="col-12 col-md-8 mt-4">
          <h5 class="mb-2">Expectation</h5>
        </div>
        <div class="col-12 col-md-8">
          <ad-vue-editor
            :editorToolbar="editorToolbar"
            v-model="item.expectation"
            :rules="[rules.required]"
          />
        </div>
      </div>
      <!-- Expiration -->
      <div class="row">
        <div class="col-12 col-md-8 mt-4">
          <vc-date-picker
            v-model="item.expiration"
            mode="dateTime"
            :available-dates="{ start: new Date(), end: null }"
            :locale="locale"
            :popover="{ placement: 'auto', visibility: 'focus' }"
          >
            <template v-slot="{ inputValue, inputEvents, togglePopover }">
              <ad-input
                v-on="inputEvents"
                @focus="togglePopover"
                :datePickerData="inputValue"
                :value="inputValue"
                :rules="[rules.required]"
                icon="fa-regular fa-calendar"
                :placeholder="$t('auctions.publish-date')"
              />
            </template>
          </vc-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-3 mt-4">
          <ad-btn :title="$t('system.save')" type="submit" />
        </div>
      </div>
    </div>
  </ad-form>
</template>

<script>
import AdInput from "../../../components/ui/v_2/AdInput.vue";
import AdBtn from "../../../components/ui/v_2/AdBtn.vue";
import AdForm from "../../../components/ui/v_2/AdForm.vue";
import AdVueEditor from "../../../components/ui/v_2/AdVueEditor.vue";
import wishlist from "@/mixins/wishlist.js";
import rules from "@/mixins/rules.js";
import store from "@/store";
import crud from "@/store/modules/crud";
import SelectBox from "@/components/ui/fields/SelectBox.vue";

export default {
  components: {
    SelectBox,
    AdInput,
    AdVueEditor,
    AdBtn,
    AdForm,
  },
  mixins: [wishlist, rules],
  name: 'update',
  data() {
    return {
      editorToolbar: [
        ["bold"],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ list: "bullet" }]
      ],
      searchedCompanies: [],
      companies: [],
      updateKey: 0,
    };
  },
  created() {
    this.getWishlist(this.role, this.$route.params.id);

    crud.get(`/companies/advertiser/select`).then(resp => {
      this.searchedCompanies = resp.data.items;
      this.companies = resp.data.items;
    });
  },
  methods: {
    filterFnCompanies(val, update) {
      // filter segments
      this.searchedCompanies = this.companies;
      update(() => {
        this.searchedCompanies = this.companies.filter(company => {
          return company.text.toLowerCase().includes(val.toLowerCase());
        });
        this.$forceUpdate();
      });
    }
  },
  computed: {
    locale: function() {
      return localStorage.getItem("locale");
    },
    store() {
      return store;
    },
    role() {
      return store.getters['auth/isSuperAdmin'] ? 'admin' : 'advertiser';
    }
  }
};
</script>

<style></style>
