<template>
  <div>
    <input
      type="file"
      @change="change($event)"
      :multiple="multiple"
      :accept="accept"
      ref="input-file"
      id="button-uploader"
    />
    <label for="button-uploader">
      <button @click.prevent="triggerFile()" class="btn btn-primary">
        <font-awesome-icon icon="fa-solid fa-paperclip" class="mr-1" />{{$t('chat.send-attachment')}}
      </button>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    /** Accept types of files */
    accept: {
      type: String
    },
    /** Can upload multiple files at once? */
    multiple: {
      type: Boolean
    },
    /** Max file size of uploaded files */
    maxFileSize: {
      type: Number
    }
  },
  methods: {
    change(e) {
      var canEmitChange = false;
      [...e.target.files].forEach(file => {
        console.log(this.maxFileSize, file.size, file.size > this.maxFileSize);
        if (file.size > this.maxFileSize) {
          this.errors.push("upload.filesize");
        } else {
          canEmitChange = true;
        }
      });

      /** Emit to BaseUploader */
      if (canEmitChange) {
        this.$emit("change", e.target.files);
      }
    },
    triggerFile() {
      this.$refs["input-file"].click();
    }
  }
};
</script>

<style lang="scss" scoped>
input {
  display: none;
}

label {
  margin: 0;
}

button {
  background: #ffffff;
  border: 1px solid #00a490;
  border-radius: 2px;
  outline: none;
  color: #00a490;
  &:hover,
  &:focus,
  &:active {
    background-color: #00a490 !important;
    outline: none;
    color: white;
    border: 1px solid #00a490 !important;
    border-color: #00a490 !important;
    box-shadow: none !important;
  }
}
</style>
