//Views Companies
import AdminOffers from "../views/admin/AdminOffers.vue";
import AdminBid from "../views/admin/Bid/BidIndex.vue";
import Index from "@/views/order/Index.vue";
import CreateOffer from "@/views/offer/publisher/Create.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/admin/offers",
          meta: {
            label: "menu.auctions"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/admin/offers",
              //name: "crud.list",
              component: AdminOffers,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/admin/offer/edit/:id",
              //name: "crud.list",
              component: CreateOffer,
              meta: {
                requiresAuth: true
              }
            },
            // AdminBid
            {
              path: "/admin/orders",
              //name: "crud.list",
              component: AdminBid,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/admin/order-detail/:id",
              //name: "crud.list",
              component: Index,
              meta: {
                requiresAuth: true
              }
            },
          ]
        }
      ]
    }
  }
];
