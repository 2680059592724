<template>
  <div>
    <div class="col">
      <div class="loader" v-if="loading">Loading...</div>
      <template
        v-else-if="
          form.reg_number === null &&
            !$options.filters.isSuperAdmin($store.state.auth.user.roles)
        "
      >
        {{ $t("error.company-not-set") }}:
        <a href="mailto:info@addmention.com" title="E-mail" target="_blank"
          >info@addmention.com</a
        >
      </template>
      <form v-else>
        <div class="form-row mb-3 input-box">
          <div class="col-md-12">
            <input
              type="text"
              class="input-1 mb-3"
              v-model="form.company_name"
              id="company_name"
              :disabled="
                !$options.filters.isSuperAdmin($store.state.auth.user.roles)
              "
            />
            <label class="input-label" for="company_name">
              {{ $t("register.company_name") }}
              <validate-form ref="company_name" />
            </label>
          </div>
        </div>
        <hr class="my-4" />
        <div class="form-row input-box">
          <div class="col-md-12">
            <input
              type="text"
              id="street"
              class="input-1 mb-3"
              :disabled="
                !$options.filters.isSuperAdmin($store.state.auth.user.roles)
              "
              v-model="form.street"
              required
            />
            <label for="street" class="input-label">{{
              $t("register.street")
            }}</label>
          </div>
        </div>

        <br />

        <div class="form-row mb-3 input-box">
          <div class="col-12 col-md-4">
            <input
              type="text"
              id="city"
              class="input-1 mb-3"
              :disabled="
                !$options.filters.isSuperAdmin($store.state.auth.user.roles)
              "
              v-model="form.city"
              required
            />
            <label for="city" class="input-label">{{
              $t("register.city")
            }}</label>
          </div>
          <div class="col-12 col-md-4">
            <input
              type="text"
              id="postal_code"
              class="input-1 mb-3"
              :disabled="
                !$options.filters.isSuperAdmin($store.state.auth.user.roles)
              "
              v-model="form.postal_code"
              required
            />
            <label for="postal_code" class="input-label">{{
              $t("register.zip")
            }}</label>
          </div>
          <div class="col-12 col-md-4">
            <select
              v-model="form.country"
              class="input-1"
              id="country"
              :disabled="
                !$options.filters.isSuperAdmin($store.state.auth.user.roles)
              "
            >
              <option
                v-for="(value, index) in $store.state.enumerators.enumerators[
                  'country'
                ]"
                :key="value"
                :value="index"
                >{{ value }}</option
              >
            </select>
            <label for="country" class="input-label">{{
              $t("register.country")
            }}</label>
          </div>
        </div>

        <hr class="my-4" />

        <div class="form-row mb-3 input-box">
          <div class="col-12 col-md-4">
            <input
              type="text"
              id="registration_number"
              class="input-1 mb-3"
              :disabled="
                !$options.filters.isSuperAdmin($store.state.auth.user.roles)
              "
              v-model="form.reg_number"
              required
            />
            <label for="registration_number" class="input-label">{{
              $t("register.reg_number")
            }}</label>
          </div>
          <div class="col-12 col-md-4">
            <input
              type="text"
              id="vat"
              class="input-1 mb-3"
              :disabled="
                !$options.filters.isSuperAdmin($store.state.auth.user.roles)
              "
              v-model="form.vat_id"
              required
            />
            <label for="vat" class="input-label">{{
              $t("register.vat")
            }}</label>
          </div>
          <div class="col-12 col-md-4">
            <input
              type="text"
              id="tax"
              class="input-1 mb-3"
              :disabled="
                !$options.filters.isSuperAdmin($store.state.auth.user.roles)
              "
              v-model="form.tax_id"
              required
            />
            <label for="vat" class="input-label">{{
              $t("register.tax")
            }}</label>
          </div>

        </div>

        <div class="form-row mb-3 input-box">
          <div class="col-12 col-md-4">
            <select
              v-model="form.status"
              class="input-1 mb-3"
              id="status"
              :disabled="
                !$options.filters.isSuperAdmin(
                  this.$store.state.auth.user.roles
                )
              "
            >
              <option
                v-for="(value, index) in $store.state.enumerators.enumerators[
                  'company-status'
                ]"
                :key="value"
                :value="index"
                >{{ value }}</option
              >
            </select>
            <label for="status" class="input-label">{{
              $t("register.status")
            }}</label>
          </div>
        </div>

        <div class="form-row">
          <div
            class="col-md-auto"
            v-if="$options.filters.isSuperAdmin($store.state.auth.user.roles)"
          >
            <button
              v-if="isEdit && !isSubmitting"
              type="button"
              class="button ml-0"
              @click="validate"
            >
              {{ $t("system.update") }}
            </button>
            <button
              v-if="!isEdit && !isSubmitting"
              @click="validate"
              type="button"
              class="button color-green-dark"
            >
              {{ $t("crud.create") }}
            </button>
            <ButtonSpinner v-if="isSubmitting" class="ml-0" />

            <router-link
              class="button bg-light mr-0 my-2 d-inline-block"
              to="/companies"
            >
              {{ $t("system.cancel") }}
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import crud from "@/store/modules/crud";
import { mapActions } from "vuex";
import i18n from "@/i18n";

export default {
  name: "CompanyCreate",
  props: ["isEdit", "isMyCompany"],
  data() {
    return {
      loading: true,
      isSubmitting: false,
      form: {
        id: null,
        company_name: null,
        street: null,
        postal_code: null,
        city: null,
        country: "sk-SK",
        reg_number: null,
        vat_id: null,
        status: null,
        has_error: []
      }
    };
  },
  methods: {
    ...mapActions({ addNotification: "notifications/addNotification" }),

    getCompany() {
      let self = this;

      let companyId = this.isMyCompany
        ? Number(this.$store.state.auth.user.company.id)
        : Number(this.$route.params.id);

      crud.get("companies/" + companyId).then(function(response) {
        let data = response.data;

        self.form.id = data.id;
        self.form.company_name = data.name;
        self.form.street = data.street;
        self.form.postal_code = data.postal_code;
        self.form.city = data.city;
        self.form.country = data.country;
        self.form.reg_number = data.reg_number;
        self.form.vat_id = data.vat_id;
        self.form.tax_id = data.tax_id;
        self.form.status = String(data.status);

        self.loading = false;
      });
    },

    validate() {
      this.isSubmitting = true;

      this.form.has_error = [];

      // if (this.form.company_name === "" || this.form.company_name.trim() === "")
      //   this.form.has_error.push("register.company_name");

      // if (this.form.street === "" || this.form.street.trim() === "")
      //   this.form.has_error.push("register.street");

      // if (this.form.postal_code === "" || this.form.postal_code.trim() === "")
      //   this.form.has_error.push("register.zip");

      // if (this.form.city === "" || this.form.city.trim() === "")
      //   this.form.has_error.push("register.city");

      /*if (this.form.reg_number === "" || this.form.reg_number.trim() === "")
        this.form.has_error.push("register.reg_number");*/

      if (this.form.has_error.length === 0) {
        this.submit();
      } else {
        const error = this.form.has_error.map(x => i18n.t(x));
        this.addNotification({
          type: "danger",
          message: i18n.t("error.fill-field") + ":<br>" + error
        });
      }
      this.isSubmitting = false;
    },

    submit() {
      this.isSubmitting = true;

      let objCompany = {
        name: this.form.company_name,
        street: this.form.street,
        postal_code: this.form.postal_code,
        city: this.form.city,
        country: this.form.country,
        reg_number: this.form.reg_number,
        vat_id: this.form.vat_id
      };

      //role superAdmin = can change status
      if (
        this.$options.filters.isSuperAdmin(this.$store.state.auth.user.roles)
      ) {
        objCompany.status = this.form.status;
      }

      if (this.isEdit) {
        crud
          .put("companies/" + this.form.id, objCompany)
          .then(() => {
            this.isSubmitting = false;
            if (!this.isMyCompany) this.$router.push("/companies");
            this.addNotification({
              type: "success",
              message: i18n.t("success.record-updated")
            });
          })
          .catch(error => {
            this.isSubmitting = false;
            const errors = Object.keys(error.data.errors).map(x =>
              i18n.t("register." + x)
            );
            this.addNotification({
              type: "danger",
              message: error.data.message + "<br>" + errors
            });
          });
      } else {
        crud
          .post("companies", objCompany)
          .then(() => {
            this.isSubmitting = false;
            this.$router.push("/companies");
            this.addNotification(
              {
                type: "success",
                message: i18n.t("success.record-add")
              },
              { root: true }
            );
          })
          .catch(error => {
            this.isSubmitting = false;

            this.addNotification({
              type: "danger",
              message: error.response.data[0].message
            });
          });
      }
    }
  },
  created() {
    this.isEdit ? this.getCompany() : "";
  }
};
</script>
