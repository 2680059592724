import crud from "../store/modules/crud";
import i18n from "@/i18n";

export default {
    data() {
        return {
            segments:[],
            showSegments: false,
            activeSegmentsCount: 0,
            segmentNotification: false
        }
    },
    methods: {
        getSegments() {
            crud.get(`google-categories/tree?language=${this.locale}&maxDepth=50`).then(resp => {
                this.segments = resp.data
            })
        },
        toggleFollow(id) {
            crud.post(`google-categories/toggle-company`, {category: id, language: this.locale}).then(() => {
                this.segments.map(segment => {
                    if(segment.id === id) {
                        segment.following = !segment.following
                    }
                })

                this.$notify({
                    type: "success",
                    text: i18n.t("success.success"),
                });
            })
        },
    },
    computed: {
        locale: function() {
            return localStorage.getItem("locale");
        },
    }
}