import MyProfileSettingsIndex from "../views/settings/my_profile_settings/MyProfileSettingsIndex.vue";
import MyCompanySettingsIndex from "../views/settings/my_company_settings/MyCompanySettingsIndex.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/settings/my-profile",
          meta: {
            label: "menu.settings"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/settings/my-profile",
              name: "users.my-profile",
              component: MyProfileSettingsIndex,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/settings/my-company",
              name: "users.my-company",
              component: MyCompanySettingsIndex,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
