import WishlistIndex from "@/views/wishlist/admin/Index.vue";
import Channels from "@/views/wishlist/admin/Channels.vue";
import WishlistRead from "@/views/wishlist/admin/Read.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/admin/wishlist",
          meta: {
            label: "menu.wishlist"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/admin/wishlist",
              component: WishlistIndex,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/admin/wishlist/conversations",
              component: Channels,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/admin/wishlist/:type/:id/",
              //name: "menu.wishlist-advertiser",
              component: WishlistRead,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
