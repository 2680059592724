<template>
    <div class="container">
      <h3 class="module-title">{{$t('interests.interests')}}</h3>

      <div class="row-delimiter">
        <p class="title font-weight-bold ">{{ $t('interests.watched-keywords-categories') }}</p>
        <div class="row">
          <div class="watch-topics ml-3">
            <p><font-awesome-icon class="mr-2" icon="fa-solid fa-plus" />{{ $t('interests.watch-topics')}}</p>
          </div>
          <div class="ml-auto mb-3">
            <router-link to="/advertiser/wishlist/create" class="px-3 py-2" style="color: #00a48f; border: 1px solid #00a48f">
              {{ $t("wishlist.create-wishlist-title") }}
            </router-link>
          </div>
        </div>
      </div>

      <h4 class="mt-4">{{$t('interests.notifications')}}</h4>

        <div>
            <div class="row">
              <div class="col-md-6">
                <ad-toggle
                    v-model="checkBoxes.categories"
                    @input="toggleCategories"
                    :text="$t('interests.turn-on-categories')"
                    toggle-type="default"
                    toggle-right
                    :with-text="false" />
              </div>
            </div>

            <div class="col-4 mt-2" v-if="checkBoxes.categories">
              <form>
                  <input
                      type="radio"
                      id="daily_1"
                      name="google-categories"
                      value="1"
                      @change="onChange($event)"
                      v-model="checkBoxes.categories_frequency.frequency"
                  >
                  <label class="ml-2" for="daily_1">{{ $t("interests.daily") }}</label><br>
                  <input
                      type="radio"
                      id="weekly_1"
                      name="google-categories"
                      value="7"
                      @change="onChange($event)"
                      v-model="checkBoxes.categories_frequency.frequency"
                  >
                  <label class="ml-2" for="weekly_1">{{ $t("interests.weekly") }}</label><br>
              </form>
            </div>
        </div>
        <div>
            <div class="row">
              <div class="col-md-6">
                <ad-toggle
                    :value="checkBoxes.keywords"
                    @input="toggleKeywords"
                    :text="$t('interests.turn-on-keywords')"
                    toggle-type="default"
                    toggle-right
                    :with-text="false"
                />
              </div>
            </div>

            <div class="col-4 mt-2" v-if="checkBoxes.keywords">
              <form>
                <input
                    type="radio"
                    id="daily_2"
                    name="keywords"
                    value="1"
                    @change="onChange($event)"
                    v-model="checkBoxes.keywords_frequency.frequency"
                >
                <label class="ml-2" for="daily_2">{{ $t("interests.daily") }}</label><br>
                <input
                    type="radio"
                    id="weekly_2"
                    name="keywords"
                    value="7"
                    @change="onChange($event)"
                    v-model="checkBoxes.keywords_frequency.frequency"
                >
                <label class="ml-2" for="weekly_2">{{ $t("interests.weekly") }}</label><br>
              </form>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8 mt-4">
                <h4>{{$t('interests.keywords')}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8">
                <ad-input v-model="value" :placeholder="$t('interests.add-keywords')" @keyup.enter="addKeyword" />
                <ad-btn :title="$t('interests.add-keywords-button')" @click="addKeyword" class="mt-3" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8 mt-4">
                <h5
                    @click="showKeywords = !showKeywords"
                    style="cursor:pointer;"
                    class="font-weight-bold row-delimiter pb-2 mb-0"
                >
                    {{$t('interests.following-words')}} ({{ keywords.length }})
                </h5>
                <transition name="fade">
                    <div v-show="showKeywords">
                        <div v-for="keyword in keywords" :key="keyword.id" class="py-1 row-delimiter d-flex">
                            {{ keyword.word }}
                            <ad-btn
                                flat
                                prepend-icon="fa-solid fa-trash"
                                @click="deleteKeyword(keyword.id)"
                                :title="$t('interests.delete-word')"
                                class="ml-auto"
                                style="max-width: 150px"
                            />
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8 mt-4">
                <h5
                    @click="showSegments = !showSegments"
                    style="cursor:pointer;"
                    class="font-weight-bold row-delimiter pb-2 mb-0"
                >
                    {{$t('interests.following-segments')}} ({{ followingSegmentsCount }})
                </h5>
                <transition name="fade">
                    <div v-show="showSegments">
                        <div v-for="segment in segments" :key="segment.id" class="py-1 row-delimiter d-flex">
                            {{ $t('category.'+segment.text) }}
                            <ad-btn
                                flat
                                :prepend-icon="!segment.following ? 'fa-regular fa-star' : 'fa-solid fa-star'"
                                @click="toggleFollow(segment.id)"
                                class="ml-auto"
                                style="max-width: 150px"
                            />
                        </div>
                    </div>
                </transition>
            </div>
        </div>
      <div  class="mt-4 mb-3">
        <router-link to="/advertiser/dashboard" class="px-3 py-2" style="color: #00a48f; border: 1px solid #00a48f">
          {{ $t("interests.leave") }}
        </router-link>
      </div>
    </div>
</template>

<script>
import AdToggle from "@/components/ui/v_2/AdToggle.vue";
import AdInput from "@/components/ui/v_2/AdInput.vue";
import AdBtn from "@/components/ui/v_2/AdBtn.vue";
import keyword from "@/mixins/keyword.js";
import segment from "@/mixins/segment.js";
import crud from "@/store/modules/crud";
import i18n from "@/i18n";

export default {
    components: {
        AdToggle,
        AdInput,
        AdBtn,
    },
    data() {
        return {
            notification: false,
            checkBoxes: {
              categories: false,
              categories_frequency: 0,
              keywords: false,
              keywords_frequency: 0
            }
        }
    },
    mixins: [keyword, segment],
    async created() {
        this.getKeywords();
        this.getSegments();
        // this.getNotificationStatus()

        await crud.get('keywords/notifications').then(resp => {
          this.checkBoxes.keywords = resp.data.notifications;
          this.checkBoxes.keywords_frequency = resp.data.frequency;
        })

        await crud.get('google-categories/notifications').then(resp => {
          this.checkBoxes.categories = resp.data.notifications;
          this.checkBoxes.categories_frequency = resp.data.frequency;
        })
        this.notification = this.checkBoxes.keywords && this.checkBoxes.categories

    },
    methods: {
        onChange(event) {
          let obj = {
            notification: true,
            frequency: event.target.value
          };

          crud.post(`${event.target.name}/notifications`, obj).then(() => {
            this.$notify({
              type: "success",
              text: i18n.t("interests.saved"),
            });
          })
        },
        toggleNotifications() {
            this.notification = !this.notification
            this.toggleCategories(this.notification)
            this.toggleKeywords(this.notification)
        },
        getNotificationStatus() {
            crud.get(`notification/segment-kw`).then(resp => {
                this.notification = resp.data
            })
        },
        toggleCategories(val = null) {
          let obj = {
            notification: (this.checkBoxes.categories) ? true : false,
            frequency: 1
          };

          if(val !== null)
          {
            obj.notification = val
          }

          crud.post(`google-categories/notifications`, obj).then(() => {
            this.checkBoxes.categories = obj.notification;

            this.$notify({
              type: "success",
              text: i18n.t("success.success"),
            });
          })
        },
        toggleKeywords(val = null) {
          let obj = {
            notification: (this.checkBoxes.keywords) ? true : false,
            frequency: 1
          };

          if(val !== null)
          {
            obj.notification = val
          }
          
          crud.post(`keywords/notifications`, obj).then(() => {
            this.checkBoxes.keywords = obj.notification;

            this.$notify({
              type: "success",
              text: i18n.t("success.success"),
            });
          })
        }
    },
    computed: {
        followingSegmentsCount() {
            const segmentsFiltered = this.segments.filter(segment => segment.following === true)
            return segmentsFiltered.length
        }
    }
};
</script>

<style lang="scss">
  .module-title{
    margin-bottom: 2.5rem;
  }

  .title{
    margin-bottom: 1.9rem;
  }

  .customWidth {
    max-width: 100% !important;

    @media (min-width: 768px) {
      max-width: 200px !important;
    }
  }

  .watch-topics {
    border-bottom: 2px solid #00a48f;
    color: #00a48f;
    fill: #00a48f
  }
</style>
