import store from "@/store";
import Axios from "axios";

//set token, user_id to the localstorage

store.subscribe(mutation => {
  switch (mutation.type) {
    case "auth/SET_TOKEN":
      if (mutation.payload) {
        Axios.defaults.headers.common["Authorization"] = "${mutation.payload}";
        localStorage.setItem("token", mutation.payload);
      } else {
        Axios.defaults.headers.common["Authorization"] = null;
        localStorage.removeItem("token");
      }
      break;
  }
  switch (mutation.type) {
    case "auth/SET_USER":
      if (mutation.payload) {
        localStorage.setItem("user", JSON.stringify(mutation.payload));
      } else {
        localStorage.removeItem("user");
      }
      break;
  }
});
