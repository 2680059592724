<template>
  <CHeader fixed colorScheme="">
    <CToggler
      in-header
      class="ml-3 d-lg-none d-xl-none"
      @click="$store.commit('minimalize/toggleSidebarMobile')"
    />

    <CHeaderNav class="mr-4 ml-auto">
      <NotificationBell class="mr-4 mr-md-0"></NotificationBell>

      <div class="vertical-separator mx-3 d-none d-md-block"></div>

      <div
        v-if="!$options.filters.isSuperAdmin($store.state.auth.user.roles)"
      >
        <credit ref="creditor"></credit>
      </div>

      <div class="vertical-separator mx-3 d-none d-md-block"></div>

      <CDropdownHeader class="user-dropdown ml-3 ml-md-0">
        <CDropdown nav placement="bottom-end">
          <template v-slot:toggler-content>
            <div class="d-flex align-items-center">
              <profile-avatar :user="user" class="mr-1" />
              <span class="mx-1 d-none d-md-block" v-if="user">
                {{ user.first_name }} {{ user.last_name }} <br />
                <strong>{{ user.company.name }}</strong>
              </span>
              <font-awesome-icon class="ml-1" icon="fa-solid fa-angle-down" />
            </div>
          </template>
          <CDropdownItem class="d-block d-md-none">
            {{ $t("menu.credit") }}: <strong>{{ credit.credit }}€</strong>
          </CDropdownItem>
          <CDropdownItem to="/settings/my-profile">
            <font-awesome-icon class="mr-2" icon="fa-regular fa-user" />
            {{ $t("menu.profile") }}
          </CDropdownItem>
          <CDropdownItem to="/settings/my-company">
            <font-awesome-icon class="mr-2" icon="fa-regular fa-building" />
            {{ $t("register.company") }}
          </CDropdownItem>
          <CDropdownItem style="border-bottom: none; font-weight: bold; pointer-events: none; cursor: none;">
            <font-awesome-icon class="mr-2" icon="fa-solid fa-users" />
            {{ $t("system.active-role") }}
          </CDropdownItem>
          
          <CDropdownItem
            v-if="this.getRoles.includes('publisher')"
            @click.native="modal.openModal = true"
            :disabled="(view.status == 'publisher')"
            class="small-dropdown-item"
          >
            <font-awesome-icon
              class="mr-2"
              icon="fa-solid fa-check"
              v-visible="(view.status === 'publisher')"
            />
            {{ $t("publisher.status") }}
          </CDropdownItem>
          <CDropdownItem
            v-if="this.getRoles.includes('advertiser')"
            @click.native="modal.openModal = true"
            :disabled="view.status == 'advertiser'"
            class="small-dropdown-item"
          >
            <font-awesome-icon
              class="mr-2"
              icon="fa-solid fa-check"
              v-visible="view.status === 'advertiser'"
            />
            {{ $t("advertiser.status") }}
          </CDropdownItem>
          <!-- <CDropdownItem to="/websites">
            <font-awesome-icon class="mr-2" icon="fa-regular fa-file-code" />
            {{ $t("menu.websites") }}
          </CDropdownItem> -->
          <CDropdownItem @click.prevent="logOut" :title="$t('login.logout')">
            <font-awesome-icon
              class="mr-2"
              icon="fa-solid fa-arrow-right-from-bracket"
            />
            {{ $t("login.logout") }}
          </CDropdownItem>
        </CDropdown>
      </CDropdownHeader>

      <CHeaderNavLink
        to="/support"
        :title="$t('users.my-profile')"
        class="d-none d-md-block"
      >
        <font-awesome-icon
          icon="fa-regular fa-circle-question"
          class="text-white"
          size="lg"
        />
      </CHeaderNavLink>
    </CHeaderNav>

    <Modal
      v-if="modal.openModal"
      :show.sync="modal.openModal"
      @close="modal.openModal = false"
      @save="switchView"
      color="bg-secondary"
      :title="$t('system.publisher-view') + ': ' + $t(view.status + '.status')"
      :button-text="$t('system.confirm')"
    >
      <h4>
        {{
          $t("confirmation.switch-view", {
            1: $t(view.status + ".status"),
            2: $t(
              (view.status === "advertiser" ? "publisher" : "advertiser") +
                ".status"
            )
          })
        }}
      </h4>
      <p v-html="$t('confirmation.switch-text')"></p>
    </Modal>
  </CHeader>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
// import BreadCrumbRouter from "@/components/ui/BreadCrumb";
import Modal from "@/components/ui/Modal";
import Credit from "@/components/ui/Credit";
import NotificationBell from "@/components/ui/NotificationBell";
import ProfileAvatar from "../components/ui/ProfileAvatar.vue";
//import NotificationsPush from "@/components/notifications/NotificationsPush";

export default {
  components: {
    // BreadCrumbRouter,
    Modal,
    Credit,
    NotificationBell,
    ProfileAvatar
    //NotificationsPush
  },
  name: "TheHeader",
  data() {
    return {
      /*view: {
        status: null,
        //status : this.$store.state.enumerators.enumerators['system-view'][1],
        checked: true
      },*/
      modal: {
        openModal: false
      },
      creditStatus: 0
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user"
    }),
    ...mapState({
      view: "view",
      credit: "credit"
    }),
    hideSwitcher() {
      let roles = JSON.parse(localStorage.roles); //create array
      return !!roles.includes("superadmin");
    },
    getRoles() {
      let roles = JSON.parse(localStorage.roles);
      return roles;
    }
  },
  created() {
    this.setView();
    this.checkWebUrlAndSet();

    // Mobile credit
    this.$root.$on("creditValueHasBeenUpdated", data => {
      this.creditStatus = data;
    });
  },
  methods: {
    ...mapActions({
      logOutAction: "auth/logOut",
      setView: "view/setUserView"
    }),

    checkWebUrlAndSet() {
      this.setView({
        status: localStorage.view,
        checked: localStorage.view == "advertiser" ? true : false
      });
    },

    switchView() {
      this.modal.openModal = false;

      //let status1 = "advertiser";
      //let status2 = "publisher";
      let status1 = this.$store.state.enumerators.enumerators["system-view"][1]; //advertiser
      let status2 = this.$store.state.enumerators.enumerators["system-view"][2]; //publisher
      switch (this.view.status) {
        case status1:
          //this.view.status = status2;
          this.setView({ status: status2, checked: false });
          this.$root.$refs.loadMenu.getMenu();
          this.$router.push("/" + status2 + "/offers");
          break;
        case status2:
          //this.view.status = status1;
          this.setView({ status: status1, checked: true });
          this.$root.$refs.loadMenu.getMenu();
          this.$router.push("/" + status1 + "/offers");
          break;
      }

      this.$root.$emit("viewHasSwitched", localStorage.view);
    },

    logOut() {
      this.logOutAction().then(() => {
        this.$router.replace({
          name: "login"
        });
      });
    }
  },
  watch: {
    "modal.openModal": function(e) {
      if (e === false) {
        let status1 = this.$store.state.enumerators.enumerators[
          "system-view"
        ][1]; //advertiser

        let checked = localStorage.view === status1;
        this.setView({ status: localStorage.view, checked: checked });
      }
    }
  }
};
</script>
<style scoped>
.dropdown-item {
  font-size: 1rem;
}
</style>
