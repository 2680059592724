<template>
  <div
    class="ad__list_item"
    :class="{ clickable: clickable }"
    ref="rippleItem"
    @click="clickItem"
  >
    <slot name="default"></slot>
    <ad-ripple ref="rippleObject" :color="color" v-if="clickable" />
  </div>
</template>

<script>
import AdRipple from "../animations/AdRipple.vue";
export default {
  props: {
    clickable: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "#00A490"
    }
  },
  components: {
    AdRipple
  },
  methods: {
    clickItem(e) {
      if (!this.clickable) {
        return;
      }
      this.$refs.rippleObject.animateRipple(e);

      this.$emit("click", e);
    }
  }
};
</script>

<style lang="scss" scoped>
.ad__list_item {
  padding: 0.8rem;
  overflow: hidden;
  // z-index:9999;
  position: relative;
  &.clickable {
    cursor: pointer !important;
  }
}
</style>
