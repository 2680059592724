// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const logo = [
  "0 0 41 30",
  `
<polygon fill="#FFFFFF" points="19.994,0.666 12.493,17.335 17.911,17.752 19.994,13.378 22.078,17.961 27.914,18.794 "/>
<path fill="#FFFFFF" d="M34.998,20.877c0,0-10.21-1.874-33.34-3.332c0,0,26.048,5,38.343,10.625L34.998,20.877z"/>
<polygon fill="#FFFFFF" points="24.787,24.211 31.248,26.296 32.704,29.003 27.081,29.003 "/>
<polygon fill="#FFFFFF" points="11.035,20.462 16.034,21.921 12.91,29.003 7.283,29.003 "/>
`
];
