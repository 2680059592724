<template>
  <div>
    <div class="row">
      <div class="col-sm-8">
        <h1 class="text-left mb-4">
          <strong>{{ $t("menu.transactions") }}</strong>
        </h1>
        <p v-html="$t('hints.transactions-introduction')"></p>
      </div>
<!--      <div class="col-sm-4 text-sm-right">-->
<!--        <router-link-->
<!--          class="button bg-light mr-0 my-2 d-inline-block"-->
<!--          to="/finance/dashboard"-->
<!--        >-->
<!--          {{ $t("system.cancel") }}-->
<!--        </router-link>-->
<!--      </div>-->
    </div>

    <dynamic-filter
      :inputs="dynamicFilterInputs"
      v-on:params-updated="processEmit"
    />

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :meta="this.table.meta"
        :tableConfig="{
          col: 500
        }"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import DataViewer from "@/components/ui/DataViewer";
import PreLoader from "@/components/ui/PreLoader";
import DynamicFilter from "@/components/ui/DynamicFilter";
import i18n from "@/i18n";
import moment from 'moment'
import store from "@/store";

export default {
  data() {
    return {
      isAdmin: this.$options.filters.isSuperAdmin(
        this.$store.state.auth.user.roles
      ),
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "id",
          translate: "ID",
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "type_translate",
          translate: i18n.t("finance.type"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "value",
          translate: i18n.t("system.value"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },{
          key: "status_translate",
          translate: i18n.t("system.status"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "created_at",
          translate: i18n.t("system.created_at"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "actions",
          translate: i18n.t("system.actions"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          },
          buttons: [
            {
              key: "detail",
              link: "/advertiser/offer-detail/",
              identifier: "auction_id",
              translate: i18n.t("transaction.offer_detail"),
              class: "button bg-info text-white mx-1 px-3 px-4 py-2"
            },{
              key: "detail",
              link: "/advertiser/order/detail/",
              identifier: "related_id",
              translate: i18n.t("transaction.order_detail"),
              class: "button bg-info text-white mx-1 px-3 px-4 py-2"
            },{
              key: "proforma",
              href: " ",
              link: "proforma",
              identifier: "proforma",
              translate: i18n.t("transaction.proforma"),
              class: "button bg-info text-white mx-1 px-3 px-4 py-2"
            },{
              key: "regular",
              href: " ",
              link: "regular",
              identifier: "regular",
              translate: i18n.t("transaction.regular"),
              class: "button bg-info text-white mx-1 px-3 px-4 py-2"
            }
          ]
        }
      ],
      dynamicFilterInputs: [
        {
          name: "company",
          type: "select2",
          label: i18n.t("register.company"),
          value: [],
          adminOnly: true
        },
        {
          name: "user",
          type: "select2",
          label: i18n.t("menu.users"),
          value: [],
          adminOnly: true
        }
      ]
    };
  },

  components: {
    DataViewer,
    PreLoader,
    DynamicFilter
  },

  mounted() {
    this.loadTable();

    if (store.getters["auth/isSuperAdmin"]) {
      this.tableHeaders[this.tableHeaders.length-1].buttons = [{
        key: "detail",
        link: "/admin/offer-detail/",
        identifier: "auction_id",
        translate: i18n.t("transaction.offer_detail"),
        class: "button bg-info text-white mx-1 px-3 px-4 py-2"
      },{
        key: "detail",
        link: "/admin/order-detail/",
        identifier: "related_id",
        translate: i18n.t("transaction.order_detail"),
        class: "button bg-info text-white mx-1 px-3 px-4 py-2"
      }];

      this.tableHeaders.splice(
        this.tableHeaders.length - 1,
        0,
        {
          key: "user",
          translate: i18n.t("menu.users"),
          sorter: false,
          style: null,
          hint: null,
          href: {
            link: "/users/activity/",
            identifier: "user_id"
          },
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "company",
          translate: i18n.t("register.company"),
          sorter: false,
          style: null,
          hint: null,
          href: {
            link: "/companies/activity/",
            identifier: "company_id"
          },
          mobile: {
            class: "col-12"
          }
        }
      );

      this.adminGetCompanies();
      this.adminGetUsers();
    }
  },

  methods: {
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "";
      let filter = this.query.filter ? this.query.filter : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud
        .get("transaction" + page + filter + sort)
        .then(function(response) {

          response.data.items.forEach(item => {
            item.action ? (item.actions = item.action) : "";

            if (Number(item.value)) {
              item.value = item.value.split(".")[0] + "€";
            }

            item.type
              ? (item.type_translate = i18n.t(
                  "enum." +
                    self.$store.state.enumerators.enumerators[
                      "transaction-type"
                    ][item.type]
                ))
              : "";

            item.status
              ? (item.status_translate = i18n.t(
                  "enum." +
                    self.$store.state.enumerators.enumerators[
                      "transaction-status"
                    ][item.status]
                ))
              : "";

            item.created_at
              ? (item.created_at = moment(item.created_at).locale('sk').format('ll'))
              : "";

            if (self.isAdmin) {
              item.related_id_href = `<a href="/admin/order/comments/${item.related_id}">${item.related_id}</a>`;
            } else {
              if (item.type == 3 || item.type == 7) {
                item.related_id_href = `<a href="/publisher/order/comments/${item.related_id}">${item.related_id}</a>`;
              } else if(item.type == 4) {
                if (item.proforma ) {
                  item.related_id_href = `<a href="${item.proforma}">${item.proforma}</a>`;
                  if(item.c){
                    item.related_id_href += `<a href="${item.regular}">${item.regular}</a>`;
                  }
                }
              }  else  {
                item.related_id_href = `<a href="/advertiser/order/comments/${item.related_id}">${item.related_id}</a>`;
              }
            }
          });

          self.table = response.data;
          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },
    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      if (data.filter) {
        this.query.filter = data.filter;

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }

      // Emited action
      if (data.action) {
        let self = this;

        self.performance.loading = true;

        crud.post(data.action).then(() => {
          self.loadTable();
        });
      }
    },
    adminGetCompanies() {
      let self = this;
      crud.get("companies/select").then(function(response) {
        let arr = [];
        response.data.items.forEach(item => {
          arr.push({
            label: item.name,
            code: item.id
          });
        });
        self.dynamicFilterInputs[0].value = arr;
      });
    },
    adminGetUsers() {
      let self = this;
      crud.get("users/select").then(function(response) {
        let arr = [];
        response.data.forEach(item => {
          arr.push({
            label: item.full_name,
            code: item.id
          });
        });
        self.dynamicFilterInputs[1].value = arr;
      });
    }
  }
};
</script>
