<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <h1 class="text-left">
          <strong>{{ $t("menu.roles") }}</strong>
        </h1>
      </div>
    </div>

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :meta="this.table.meta"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import DataViewer from "@/components/ui/DataViewer";
import PreLoader from "@/components/ui/PreLoader";
import i18n from "@/i18n";

export default {
  components: {
    DataViewer,
    PreLoader
  },

  data() {
    return {
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "description",
          translate: i18n.t("system.description"),
          sorter: false,
          style: null,
          // hint: i18n.t("system.id")
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "name",
          translate: i18n.t("roles.role"),
          sorter: false,
          style: null,
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "updated_at",
          translate: i18n.t("system.updated_at"),
          sorter: false,
          style: "width: 150px;",
          mobile: {
            class: "col-12"
          }
        },
        {
          key: "actions",
          translate: i18n.t("system.actions"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          },
          buttons: [
            {
              key: "detail",
              link: "/users/roles/view/",
              identifier: "name",
              translate: i18n.t("system.detail"),
              class: "button bg-warning text-white mx-1 px-3 px-4 py-2"
            }
          ]
        }
      ]
    };
  },

  mounted() {
    this.loadTable();
  },

  methods: {
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      crud
        .get("auth-roles")
        .then(function(response) {
          // console.log(  Object.keys(response.data.items[0])  );

          response.data.items.forEach(item => {
            item.updated_at ? (item.updated_at = Date(item.updated_at)) : "";
          });

          // Table
          self.table = response.data;
          self.table.items = response.data.items;
          self.table.links = [];
          self.table.meta = [];

          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },
    processEmit(data) {
      let self = this;

      if (data.action) {
        self.performance.loading = true;

        crud.post(data.action).then(() => {
          self.loadTable();
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
