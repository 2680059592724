<template>
  <div class="filter row">
    <div v-for="(input, index) in this.inputs" :key="index" class="input-box">
      <!-- Content for super admin -->
      <template v-if="isAdmin == true">
        <template v-if="input.type == 'text' || input.type == 'email'">
          <input
            :type="input.type"
            class="input-1 ml-3"
            :id="input.name"
            v-model="dynamicModels[input['name']]"
            v-on:keyup.enter="submit()"
          />

          <label class="input-label" :for="input.name">
            {{ input.label }}
          </label>
        </template>

        <template v-else-if="input.type == 'select2'">
            <v-select
              multiple
              :class="['select2input', 'ml-3']"
              :options="input.value"
              :reduce="item => item.code"
              v-model="dynamicModels[input['name']]"
              class="v-select-custom mb-3 "
              style="min-width: 15rem;"
            >
            </v-select>

            <label class="input-label" :for="input.name">
              {{ $t(input.label) }}
            </label>
        </template>

        <template v-else-if="input.type == 'select'">
          <div class="w-100 ml-3">
            <select class="input-1 mb-3" v-model="dynamicModels[input['name']]">
              <option value="">{{ $t("system.select") }}</option>
              <option
                v-for="(item, index) in input.value"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </select>

            <label class="input-label">
              {{ $t(input.label) }}
            </label>
          </div>
        </template>

        <template v-else-if="input.type == 'date'">
          <div class="w-100 ml-3">
            <ad-date
                v-model="dynamicModels[input['name']]"
                class="v-select-custom mb-3"
                :availableDates="{ start: null, end: null}"
                :filter="true"
            ></ad-date>

            <label class="input-label">
              {{ $t(input.label) }}
            </label>
          </div>
        </template>

      </template>

      <!-- Content for common user -->
      <template v-else-if="input.adminOnly == false">
        <template v-if="input.type == 'text' || input.type == 'email'">
          <input
            :type="input.type"
            class="input-1 ml-3"
            :id="input.name"
            v-model="dynamicModels[input['name']]"
            v-on:keyup.enter="submit()"
          />

          <label class="input-label" :for="input.name">
            {{ input.label }}
          </label>
        </template>

        <template v-else-if="input.type == 'select2'">
          <div class="w-100 ml-3">
            <v-select
              multiple
              :options="input.value"
              :reduce="item => item.code"
              v-model="dynamicModels[input['name']]"
              class="v-select-custom"
              style="min-width: 10rem; max-width: 15rem;"
            >
            </v-select>

            <label class="input-label">
              {{ $t(input.label) }}
            </label>
          </div>
        </template>

        <template v-else-if="input.type == 'select'">
          <div class="w-100 ml-3">
            <select class="input-1 mb-3" v-model="dynamicModels[input['name']]">
              <option value="">{{ $t("system.select") }}</option>
              <option
                v-for="(item, index) in input.value"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </select>

            <label class="input-label">
              {{ $t(input.label) }}
            </label>
          </div>
        </template>

        <template v-else-if="input.type == 'date'">
          <div class="w-100 ml-3">
            <ad-date
                v-model="dynamicModels[input['name']]"
                class="v-select-custom mb-3"
                :availableDates="{ start: null, end: null}"
            ></ad-date>

            <label class="input-label">
              {{ $t(input.label) }}
            </label>
          </div>
        </template>

      </template>
    </div>

    <template v-if="this.userInputs.length > 0 || this.isAdmin">
      <button
        type="submit"
        class="button color-green-dark ml-0 my-3 ml-3"
        @click="submit()"
      >
        {{ $t("system.search") }}
      </button>

      <button type="submit" class="button bg-light ml-0 my-3" @click="cancel()">
        {{ $t("system.cancel") }}
      </button>
    </template>
  </div>
</template>

<script>
import vSelect from "vue-select";
import AdDate from "@/components/ui/v_2/AdDate";

export default {
  props: ["inputs"],

  components: {
    vSelect,
    AdDate
  },

  data() {
    return {
      dynamicModels: {},
      isAdmin: this.$options.filters.isSuperAdmin(
        this.$store.state.auth.user.roles
      )
    };
  },

  computed: {
    userInputs: function() {
      var newArray = this.inputs.filter(input => !input.adminOnly);

      return newArray;
    }
  },

  methods: {
    submit() {
      let queryArr = [];

      Object.entries(this.dynamicModels).forEach(element => {
        if (element[1] !== null && element[1] !== "") {
          if(element[1] instanceof Object && element[1].constructor === Date){
            queryArr.push("&filter[" + element[0] + "]=" + element[1].toISOString());

          }else {
            queryArr.push("&filter[" + element[0] + "]=" + element[1]);
          }
        }
      });

      let queryString = queryArr.join("");

      if (queryString) {
        // Emit for old table
        this.$emit("filter-submitted", queryString);

        // Emit for new table
        this.$emit("params-updated", {filter: queryString});
      }
    },
    cancel() {
      // Emit for old table
      this.$emit("filter-submitted", null);

      // Emit for new table
      this.$emit("params-updated", {reset: "filter"});

      this.dynamicModels = {};
    }
  }
};
</script>

<style lang="scss" scoped>

.filter {
  display: flex;
}

.v-select{
  background-color: #fafafa !important;
}

</style>
