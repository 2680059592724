import crud from "../store/modules/crud";
import i18n from "@/i18n";
export default {
    data() {
        return {
            keywords: [],
            value: '',
            showKeywords: false,
        }
    },
    methods: {
        addKeyword()
        {
            crud.post(`keywords`, {word: this.value, language: this.locale}).then(() => {
                this.value = ''
                this.$notify({
                    type: "success",
                    text: i18n.t('success.success')
                })
                this.getKeywords();
                this.showKeywords = true;
            })
        },
        getKeywords() {
            crud.get(`keywords`).then(resp => {
                this.keywords = resp.data.items
            })
        },
        deleteKeyword(id) {
            crud.delete(`keywords/${id}/detach`).then(() => {
                this.$notify({
                    type: 'success',
                    text: i18n.t('success.success')
                })
                this.getKeywords()
            })
        },
    },
    computed: {
        locale: function() {
            return localStorage.getItem("locale");
        },
    }
}