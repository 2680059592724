<template>
  <div>
    <div class="row mb-2">
      <div class="col">
        <router-link to="/advertiser/wishlist" @click.prevent="goBack"
          ><font-awesome-icon icon="fa-solid fa-arrow-left" />
          {{ $t("system.back") }}</router-link
        >
        <ad-btn-dropdown
        
          v-if="item.active == true && $route.params.type === 'detail'"
          appendIcon="fa-solid fa-caret-down"
          class="text-left ml-3"
          ripple
          :label="$t('system.settings')"
        >
          <ad-list>
            <ad-list-item
              clickable
              @click="
                $router.push({
                  name: 'menu.wishlist-'+role+'-update',
                  params: { id: $route.params.id }
                })
              "
              class="text-center"
            >
              <font-awesome-icon
                icon="fa-solid fa-pen-to-square"
                class="mr-2"
              />
              <span>{{ $t("wishlist.edit") }}</span>
            </ad-list-item>
            <ad-list-item clickable @click="deactivate" class="text-center">
              <font-awesome-icon icon="fa-solid fa-trash" class="mr-2" />
              <span>{{ $t("wishlist.delete") }}</span>
            </ad-list-item>
          </ad-list>
        </ad-btn-dropdown>
      </div>
    </div>
    <div class="row pb-0 pt-4">
      <div class="col-12 col-md-8">
        <h4>{{ item.name }}</h4>
      </div>
    </div>
    <div class="row py-4">
      <div class="col-12 col-md-8">
        <ul class="detailed-list">
          <!-- <li>
            <font-awesome-icon
              icon="fa-regular fa-file-lines"
              class="mr-2"
              color="black"
            />{{ item.name }}
          </li> -->
          <li>
            <font-awesome-icon
              icon="fa-solid  fa-desktop"
              class="mr-2"
              color="black"
            />
            {{ item.url }}
          </li>
          <li>
            <!-- <font-awesome-icon icon="fa-solid fa-chart-simple" class="mr-2" /> {{$t('auctions.estimated_traffic')}} {{item.detail.estimated_traffic }} <span class="delimiter"></span>  -->
            <font-awesome-icon icon="fa-solid fa-chart-line" class="mr-2" />
            {{ $t("system.price") }}
            {{ item.price }}
            <span class="delimiter"></span>
            <font-awesome-icon icon="fa-solid fa-wallet" class="mr-2" />
            {{$t("wishlist.expiration-date")}} {{ momentDate(item.expiration) }}
          </li>
        </ul>
      </div>
    </div>
    <div class="row py-4 ">
      <div class="col-12 col-md-8 pb-4 row-delimitered">
        <h4>{{ $t("offer.summary") }}</h4>
        <div v-html="item.detail"></div>
      </div>
    </div>
    <div class="row py-4 ">
      <div class="col-12 col-md-8 pb-4 row-delimitered">
        <h4>{{ $t("offer.details") }}</h4>
        <div v-html="item.expectation"></div>
      </div>
    </div>

    <div
        class="row py-4"
        v-if="item.channel !== null && comments.items.length > 0"
    >
      <div class="col-12 col-md-8">
        <ad-chat
            v-if="(comments.length < 1 && $route.params.type === 'detail') || $route.params.type === 'conversation'"
            :createApiUrl="(item.channel !== null) ? `wishlist/advertiser/${item.channel}/comment` : `wishlist/advertiser/${item.id}/comment-wishlist`"
            @loaded-more="addNewComments"
            :key="chatKey"
            :items="comments"
            :getApiUrl="`wishlist/${item.channel}/comment`"
            :uploadApiUrl="`wishlist/advertiser/${item.channel}/comment`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import wishlist from "@/mixins/wishlist.js";
import chat from "@/mixins/chat.js";
import AdBtnDropdown from "../../../components/ui/v_2/AdBtnDropdown.vue";
import AdList from "../../../components/ui/v_2/list/AdList.vue";
import AdListItem from "../../../components/ui/v_2/list/AdListItem.vue";
import store from "@/store";
import AdChat from "@/components/ui/v_2/AdChat.vue";

export default {
  components: {
    AdChat,
    AdBtnDropdown,
    AdList,
    AdListItem,
  },
  mixins: [chat, wishlist],
  name: "Read",
  created() {
    if(this.$route.params.type === 'detail'){

      this.getWishlist("admin")
      .then(response => {
        if (response.channel !== null)
        this.getComments(`wishlist/${response.channel}/comment`);
      })
      .catch(e => {
        console.log(e);
      });
    } 
    if(this.$route.params.type == 'conversation') {
      this.getChannel().then(resp => {
        if (resp.items.wishlist_id !== null){
          this.item = resp.items;
          this.getComments(`wishlist/${this.$route.params.id}/comment`);
        }
      });
    }
  },
  computed: {
    store() {
      return store;
    },
    role() {
      return store.getters['auth/isSuperAdmin'] ? 'admin' : 'advertiser';
    }
  }

};
</script>

<style lang="scss" scoped>
h4 {
  font-weight: 600;
}
ul {
  &.detailed-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      padding: 0.7em 1em;
      display: flex;
      align-items: center;
      border-collapse: collapse;
      border-bottom: 1px solid #e9edef;
      border-top: 1px solid #e9edef;
      margin: -1px 0 0 -1px;

      .delimiter {
        margin: 0px 2em;
        height: 24px;
        width: 1px;
        display: inline-block;
        background: #e9edef;
      }
    }
  }

  li {
    line-height: 24px;
  }
}

.row-delimitered {
  border-bottom: 1px solid #e9edef;
}
</style>
