import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import credit from "./modules/credit";
import bell from "./modules/bell";
import notifications from "./modules/notifications";
import minimalize from "./modules/minimalize";
import crud from "./modules/crud";
import enumerators from "./modules/enumerators";
import VuexPersistence from "vuex-persist";
import validate from "./modules/validate";
import view from "./modules/view";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["enumerators"]
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    bell,
    credit,
    notifications,
    minimalize,
    crud,
    enumerators,
    validate,
    view
  },
  plugins: [vuexLocal.plugin]
});
