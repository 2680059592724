<template>
  <form @submit.prevent="submit" v-if="columns.length > 0">
    <div class="row icons" :class="floatRight === true ? 'float-right' : ''">
      <div
        v-for="(column, index) in columns"
        :key="column.name"
        class="input-box col-md-auto col-sm-12"
      >
        <span
          v-if="index === 0"
          class="input-group-addon d-flex align-items-center"
        >
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
        </span>
        <template v-if="column.type === 'text'">
          <input
            type="text"
            class="input-1"
            :class="{ 'border-right': index === 0 }"
            :id="'search' + index"
            v-model="data[column.name]"
          />
          <label class="input-label" :for="'search' + index">
            {{ column.label }}
          </label>
        </template>
        <template v-else-if="column.type === 'select'">
          <div :class="horizontalSet ? 'col-sm-9 pr-3' : 'pr-3'">
            <select
              v-model="data[column.name]"
              class="input-1"
              :id="column.name"
            >
              <option
                v-bind:value="index"
                v-for="(value, index) in enums[column.enum]"
                v-bind:key="value"
                >{{ value }}</option
              >
            </select>
            <label
              :for="column.name"
              class="input-label"
              :class="horizontalSet ? 'col-form-label col-sm-3' : ''"
              >{{ column.label }}</label
            >
          </div>
        </template>
        <template v-else-if="column.type === 'select2'">
          <v-select
            :options="column.options"
            v-model="data[column.name]"
            :id="column.name"
            :clearable="false"
            class="v-select-custom"
          ></v-select>
          <label :for="column.name" class="input-label label-icon">{{
            column.label
          }}</label>
        </template>
        <template v-else-if="column.type === 'checkbox'">
          <CInputCheckbox
            type="checkbox"
            :label="column.label"
            :custom="true"
            class="mt-2 mx-4"
            :checked.sync="data[column.name]"
          />
        </template>
        <template v-else-if="column.type === 'checkbox-group'">
          <fieldset class="border input-box row">
            <legend>{{ column.label }}</legend>
            <template v-for="(value, key) in column.options">
              <div class="col-md-auto col-sm-12" :key="key">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="column.name + '-' + key"
                  :value="key"
                  v-model="data[column.name]"
                />
                <label
                  :for="column.name + '-' + key"
                  class="custom-control-label"
                  >{{ $t("enum." + value) }}</label
                >
              </div>
            </template>
          </fieldset>
        </template>
      </div>
      <div class="col-auto my-auto text-sm-left">
        <button
          type="submit"
          class="button color-green-dark ml-0"
          :disabled="isSubmitting"
        >
          {{ $t("system.search") }}
        </button>
        <button
          v-if="cancelBtn"
          type="button"
          class="button color-grey ml-0"
          :disabled="isSubmitting"
          @click="cancel"
        >
          {{ $t("system.cancel") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import vSelect from "vue-select";
//import crud from "@/store/modules/crud";

/**
 *  In parent file
 *
 *  <Search ref="search" v-bind:columns="this.search" />
 *
 *   import Search from "@/components/ui/Search";
 *
 *   components: {Search},
 *   data() {
 *   return {
 *      sortBy: { column: 'status', asc: false},
 *     search: [
 *       {
 *         name: "username",
 *         label: i18n.t("login.username"),
 *         type: "text", //text, select
 *         operator: "or", //or,in
 *         like: true, //true, false
 *         likeFormat: "b"   //l - %left , r - right%, b - %both%
 *       },
 *       {
 *         name: "email",
 *         label: "E-mail",
 *         type: "text",
 *         operator: "or",
 *         like: true
 *       },
 *       {
 *         name: "status",
 *         label: i18n.t("system.status"),
 *         type: "select",
 *         operator: "or",
 *         like: false,
 *         enum: "user-status"
 *       },
 *       {
 *         name: "campaign_id",
 *         options: [], //options object to select box  [{ id: '0001', name: 'John'}]
 *         label: i18n.t("integration-process.campaign-name"),
 *         type: "select2",
 *         operator: "or",
 *         like: false
 *       },
 *       {
 *         name: "publish",
 *         label: i18n.t("advertiser_table.publish"),
 *         id: "publish",
 *         operator: "or",
 *         type: "checkbox",
 *       },
 *       {
 *         name: "status",
 *         label: i18n.t("system.status"),
 *         options: [], [{ id: '4', name: 'Publish'}]
 *         operator: "in",
 *         type: "checkbox-group",
 *       }
 *     ]
 *   };
 *
 *   methods: {
 *     pagination() {
 *       some functions ... call after click  this.getUsers();
 *    },
 *
 *    //some function
 *    getUsers() {
 *        let query = this.$refs.search.parseUrlQuery(onload = false, sortObj = {});
 *
 *        if (this.$route.query.page) {
 *           crud.get("users" + query).then(function(response) {
 *             self.items = response.data.items;
 *
 *             self.page.totalCount = response.data._meta.totalCount;
 *             self.page.perPage = response.data._meta.perPage;
 *
 *             self.loading = false;
 *           });
 *         }
 *    }
 *   }
 *   mounted() {
 *      this.getUsers(true);
 *   }
 *
 *
 */

export default {
  name: "Search",
  props: ["columns", "floatRight", "horizontal"],
  components: {
    vSelect
  },
  data() {
    return {
      isSubmitting: false,
      cancelBtn: false,
      //enums: [],
      data: []
    };
  },
  created() {
    this.init();
    this.setInput();
  },
  methods: {
    init() {
      //create array in data name
      const chg = this.columns.find(item => item.type === "checkbox-group");
      if (chg !== undefined) {
        this.data[chg.name] = [];
      }
    },
    cancel() {
      this.cancelBtn = false;
      this.data = [];
      this.init();
      this.$router.push({ query: { page: "1" } });
      this.$parent.pagination();
      this.isSubmitting = false;
    },

    objectEqual(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          return false;
        }
      }

      return true;
    },

    setInput() {
      for (let key in this.$route.query) {
        if (key.includes("filter")) {
          let arrString = key.replace("filter", "");
          let clearArr = arrString
            .split("]")
            .filter(n => n)
            .map(function(a) {
              return a.replace("[", "");
            });

          if (clearArr[1] === "") {
            //add strandart value from url
            this.data[clearArr[2]] = this.$route.query[key].replace(/%/g, "");
          } else {
            //add array from value
            this.data[clearArr[1]][clearArr[3]] = this.$route.query[key];
          }

          this.cancelBtn = true;
        }
      }
    },

    parseUrlQuery(onload = false, sortObj = {}) {
      let apiUrl = "?";
      let obj = this.$route.query;

      //onload sorting
      if (
        (Object.keys(obj).length === 0 && onload) ||
        (!("sort" in this.$route.query) && onload)
      ) {
        if (Object.keys(obj).length === 0 && onload) obj["page"] = 1;
        obj["sort"] = (!sortObj.asc ? "-" : "") + sortObj.column;
      }

      for (let key in obj) {
        if (key) {
          if (key === "page") {
            apiUrl += "page=" + obj[key];
          } else if (key.includes("filter") || key === "sort") {
            apiUrl += obj[key]
              ? "&" + key + "=" + encodeURIComponent(obj[key])
              : "";
          }
        }
      }

      return apiUrl;
    },

    submit() {
      if (Object.keys(this.data).length > 0) {
        this.isSubmitting = true;
        this.cancelBtn = true;
        let url = "";
        let query = { page: "1" };

        for (let key in this.data) {
          for (let i = 0; i < this.columns.length; i++) {
            let el = this.columns[i];
            if (
              this.columns[i].type === "checkbox-group" &&
              el.name === key &&
              this.data[key]
            ) {
              //checkbox-group url

              for (let a in Object.keys(this.data[key])) {
                url =
                  '{"filter[' +
                  el.operator +
                  "][" +
                  key +
                  "][in]" +
                  "[" +
                  a +
                  ']" : "' +
                  this.data[key][a] +
                  '"}';

                Object.assign(query, JSON.parse(url));
              }
            } else if (
              this.columns[i].type === "select2" &&
              el.name === key &&
              this.data[key]
            ) {
              url =
                '{"filter[' +
                el.operator +
                "][][" +
                key +
                ']" : "' +
                this.data[key]["code"] +
                '"}';

              Object.assign(query, JSON.parse(url));
            } else {
              if (el.name === key && this.data[key]) {
                let like = el.like ? "[like]" : "";
                url =
                  '{"filter[' +
                  el.operator +
                  "][][" +
                  key +
                  "]" +
                  like +
                  '" : "' +
                  (this.columns[i].likeFormat === "l" ||
                  this.columns[i].likeFormat === "b"
                    ? "%"
                    : "") +
                  this.data[key] +
                  (this.columns[i].likeFormat === "r" ||
                  this.columns[i].likeFormat === "b"
                    ? "%"
                    : "") +
                  '"}';
                Object.assign(query, JSON.parse(url));
              }
            }
          }
        }

        //compare url and query ... equal is error
        if (!this.objectEqual(this.$route.query, query)) {
          this.$router.push({ query: query });
          this.$parent.pagination();
        }
        this.isSubmitting = false;
      }
    }
  },
  computed: {
    enums: function() {
      return this.$store.state.enumerators.enumerators;
    },
    horizontalSet: function() {
      return this.horizontal ? true : false;
    }
  }
};
</script>
