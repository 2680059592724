//Views Companies
import Company from "../views/company/CompanyIndex.vue";
//import CompanyCreate from "../views/company/CompanyCreate.vue";
import CompanyEdit from "../views/company/CompanyEdit.vue";
import CompanyView from "../views/company/CompanyView.vue";

export default [
  {
    data: {
      admin: [
        {
          path: "/companies",
          meta: {
            label: "menu.companies"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/companies",
              //name: "crud.list",
              component: Company,
              meta: {
                requiresAuth: true
              }
            },
            /*{
              path: "/companies/create",
              name: i18n.t("register.company") + ": " + i18n.t("crud.create"),
              component: CompanyCreate,
              meta: {
                requiresAuth: true
              }
            },*/
            {
              path: "/companies/edit/:id",
              name: "register.company" + ": " + "crud.edit",
              component: CompanyEdit,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: "/companies/view/:id",
              name: "register.company" + ": " + "crud.view",
              component: CompanyView,
              meta: {
                requiresAuth: true
              }
            }
          ]
        }
      ]
    }
  }
];
