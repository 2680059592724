<template>
  <CheckIcon
    :validate="icon"
    :invert="invert ? true : false"
    :message="message"
  />
</template>
<script>
/*
 *     <validate-form ref="first_name"/>
 *
 *      @blur="checkInput('first_name')"
 *      @keyup="checkInput('first_name')"
 *
 *
 *     required: {
 *        first_name: { type: "text" },
 *        last_name: { type: "text" }
 *      },
 *
 *    checkInput(field) {
 *       this.$refs[field].checkAfterKeyUp( this.form[field], field, this.required)
 *     },
 *
 */
//import store from "@/store";
import CheckIcon from "@/components/ui/CheckIcon";
import store from "@/store";
import crud from "@/store/modules/crud";
import i18n from "@/i18n";

export default {
  name: "ValidateForm",
  props: ["formData", "invert"],
  components: {
    CheckIcon
  },
  data() {
    return {
      icon: null,
      message: null,
      uniqueValidated: false
    };
  },
  methods: {
    checkUniqueValue(endpoint, column, value) {
      let self = this;
      let data = { [column]: value };

      const result = crud
        .post("validate/" + endpoint, data)
        .then(response => {
          if (response.data.success) {
            self.message = "";
            self.uniqueValidated = true;
          } else {
            self.message = "";
            self.uniqueValidated = false;
          }
        })
        .catch(error => {
          // self.message = i18n.t('error.email-exist')
          let slug = JSON.stringify(error.data.errors)
            .match(/\[(.*?)\]/)[1]
            .replace(/[^\w\s]/gi, "")
            .replaceAll(" ", "-")
            .toLowerCase();

          self.message = i18n.t("error." + slug);

          self.uniqueValidated = false;
        });

      return result;
    },

    validate(val, ref, required) {
      //ref = name
      /*console.log(val)
      console.log(required)
      console.log(ref)*/

      let result = {};

      //text format
      if (required[ref].type === "text") {
        if (val === null || val === "" || val.trim() === "") {
          result[ref] = false;
        } else {
          if (ref == "vat_id") {
            // this.checkUniqueValue("company", "vat_id", val);
            // result[ref] = this.uniqueValidated;
          } else if (ref == "reg_number") {
            // this.checkUniqueValue("company", "reg_number", val);
            // result[ref] = this.uniqueValidated;
          } else {
            result[ref] = true;
          }
        }
      }

      //checkbox format
      if (required[ref].type === "checkbox") {
        if (!val) {
          result[ref] = false;
        } else {
          result[ref] = true;
        }
      }

      //email format
      if (required[ref].type === "email") {
        let re = /\S+@\S+\.\S+/;
        if (val === null || val === "") {
          this.message = "";
          result[ref] = false;
        } else if (val && !re.test(val)) {
          this.message = "";
          result[ref] = false;
        } else if (val && re.test(val)) {
          this.checkUniqueValue("user", "email", val);
          result[ref] = this.uniqueValidated;
        }
      }

      //text phone
      if (required[ref].type === "phone") {
        let phone = /^\+\d{8,16}$/;
        let phoneVal = val.replaceAll(" ", ""); //replace all white spaces

        if (!required[ref].required && val && !phone.test(phoneVal)) {
          if (!isNaN(phoneVal.replace("+", ""))) {
            this.message = "";
            result[ref] = true;
          } else {
            this.message = i18n.t("register.phone-international-alert");
            result[ref] = false;
          }
        } else if (!required[ref].required && val && phone.test(phoneVal)) {
          this.message = "";
          result[ref] = true;
        } else {
          this.message = "";
          result[ref] = true;
        }
      }

      store.commit("add", result); //add to store
      this.icon = this.$store.state.validate.validated[ref];
    }
  }
};
</script>
