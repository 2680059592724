<template>
  <div>
    <div class="row">

          <ad-btn :to="'/' + this.$route.params.type + '/offers'" flat color="#00a490" :title="$t('offer.back_to_offers')" prepend-icon="fa-solid fa-arrow-left" />
          <ad-btn-dropdown
            v-if="this.item.actions && (this.item.actions.edit || this.item.actions.cancel)"

            appendIcon="fa-solid fa-caret-down"
            class="text-left"
            :label="$t('offer.edit_menu')"
          >
            <ad-list>
              <!-- Cancel auction -->
              <ad-list-item
                v-if="this.item.actions.cancel"
                clickable
                @click="cancelAuction()"
                class="text-center"
              >
                <font-awesome-icon
                  icon="fa-solid fa-xmark"
                  class="text-center mr-2"
                  style="color: red;"
                />
                <span>{{ $t("auctions.cancel-auction") }}</span>
              </ad-list-item>
              <ad-list-item
                v-if="this.item.actions.edit"
                clickable
                @click="editAuction()"
                class="text-center"
              >
                <font-awesome-icon
                  icon="fa-solid fa-pencil"
                  class="text-center mr-2"
                  style="color: orange;"
                />
                <span>
                  {{ $t("crud.edit") }}
                </span>
              </ad-list-item>
            </ad-list>
          </ad-btn-dropdown>

      <div v-if="this.isAdmin" class="col-md-4 text-right">
        <button
          class="ad-btn-edit mr-2"
          @click="redirectToEdit"
        >
          {{ $t("system.edit") }}
        </button>

        <button
          @click="removeOffer()"
          class="ad-btn-delete"
        >
          {{ $t("system.delete") }}
        </button>
      </div>

    </div>
  <template v-if="this.item.id">
    <h4 class="mt-4 font-weight-bold">
      {{ this.item.name }}
    </h4>

<!--    <p class="mt-4 detail-to-mention" v-html="item.summary">-->
<!--    </p>-->

    <div class="my-4 ad-smaller-text-responsive">
      <!-- 1 -->
<!--      <div class="row ml-2 py-2 pl-3 ad-stats-table">-->
<!--        <div class="mr-5 ad-border-padding-outline">-->
<!--          <font-awesome-icon-->
<!--            icon="fa-solid fa-chart-column" -->
<!--            class="mr-2"-->
<!--          />-->

<!--          <span style="text-decoration:underline;">{{ $t("offer.number_of_views") }}</span> {{ this.item.estimated_traffic }}-->
<!--        </div>-->

<!--        <div class="mr-5 ad-border-padding-outline">-->
<!--          <font-awesome-icon -->
<!--            icon="fa-solid fa-wand-sparkles" -->
<!--            class="mr-2"-->
<!--          />-->

<!--          <span style="text-decoration:underline;">{{ $t("offer.view_price_from") }}</span> {{ this.item.price_per_click }} €-->
<!--        </div>-->

<!--        <div class="">-->
<!--          <font-awesome-icon -->
<!--            icon="fa-regular fa-credit-card" -->
<!--            class="mr-2"-->
<!--          />-->
<!--          {{ $t("offer.price_from") }} {{ (this.item.min_price) ? this.item.min_price.split(".")[0] + " €" : 'N/A' }}-->
<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash; 2 &ndash;&gt;-->
<!--      <div class="row ml-2 py-2 ad-stats-table">-->
<!--        &lt;!&ndash; first line &ndash;&gt;-->
<!--        <div class="col-6 col-md-2">-->
<!--          <font-awesome-icon -->
<!--            icon="fa-regular fa-file-lines" -->
<!--            class="mr-2"-->
<!--          />-->

<!--          {{ $t("advertiser_table.article") }}-->
<!--        </div>-->


<!--      </div>-->

      <!-- 3 -->
      <div class="row ml-2 py-2 ad-stats-table">

        <div v-if="this.item.domain_url" class="col-12 col-md-10">
          <font-awesome-icon
              icon="fa-solid fa-display"
              class="mr-2"
          />

          <a :href="this.item.domain_url" target="_blank" >{{ this.item.domain_url || 'N/A' }}</a>
        </div>

<!--        <div class="col-12 col-md-6 ad-border-padding-outline">-->
<!--          <font-awesome-icon -->
<!--            icon="fa-regular fa-clock" -->
<!--            class="mr-2"-->
<!--          />-->

<!--          <template v-if="this.item.end_date != 'Invalid date'">-->
<!--          {{ $t("offer.offer_end_date") }} <span style="color: rgba(230, 81, 0, 1);"><b>{{ this.item.end_date }} ({{  $t('auctions.days_left', { days: this.item.end_date_diff }) }})</b></span>-->
<!--          </template>-->

<!--          <span v-else >-->
<!--            {{ $t("offer.end_date_empty") }}-->
<!--          </span>-->
<!--        </div>-->

<!--        <div class="col-12 col-md-6">-->
<!--          <font-awesome-icon -->
<!--            icon="fa-regular fa-calendar" -->
<!--            class="mr-2"-->
<!--          />-->

<!--          {{ $t("offer.offer_publish_date") }} {{ this.item.publish_date }}-->
<!--        </div>-->
      </div>

      <!-- 4 -->
      <div class="row ml-2 py-2 ad-stats-table">
        <div class="col-12 col-md-6 ad-border-padding-outline">
          <font-awesome-icon 
            icon="fa-solid fa-shapes" 
            class="mr-2"
          />

          <router-link :to="'/'+this.userMode+'/offers?filter[segment]='+this.item.category.id">
            {{ this.item.category.name }}
          </router-link>
        </div>

        <div class="col-12 col-md-6">
          <div v-if="hashtags.length > 0" class="d-flex">

            <font-awesome-icon 
              icon="fa-solid fa-hashtag" 
              class="mr-2"
            />

            <router-link
                v-for="(hashtag, index) in hashtags" :key="index"
                :to="'/'+userMode+'/offers?filter[tag]='+hashtag.id"
            >
              {{hashtag.word}}<span v-if="hashtags[index +1] !== undefined" style="color:black;">,&nbsp;</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 ad-grid-table">
      <div 
        v-for="(product, index) in item.products" 
        :key="index"
        class="ad-custom-card"
      >
        <p class="ad-custom-title">
          {{ $t("products." + itemByEnum[product.type]) }}
        </p>

        <img 
          :src="'/svg/products/' + itemByEnum[product.type] + '.svg'" 
          width="100%" 
        />

        <small 
          class="d-block pt-3 ad-desc"
        >
          {{ $t("products.text-" + itemByEnum[product.type]) }}
        </small>

        <small class="d-block py-3" style="font-size: 12px; border-bottom: 1px solid rgba(233, 237, 239, 1);">
          {{ $t("offer.views_without_guarantee") }}
        </small>

        <div class="d-flex my-3 font-weight-bold" style="justify-content: space-between;">
          <div>{{ $t("system.price") }}</div>
          <div>{{  parseFloat(product.price).toFixed(2) }} €</div>
        </div>
        
        <button
          v-if="userMode == 'advertiser'"
          class="ad-btn-submit mb-3"
          @click="moveToOrder('/advertiser/order-create/' + item.id + '/' + product.id)"
        >
          {{ $t("offer.order_now") }}
        </button>
      </div>
    </div>

    <ul class="ad-ul-list detail-to-mention">
      <h4>{{ $t("offer.about_article") }}</h4>
      <p class="mt-4" v-html="item.summary">
      </p>
    </ul>

    <ul class="ad-ul-list detail-to-mention">
      <h4>{{ $t("offer.details_to_mention") }}</h4>
        <p class="mt-4" v-html="item.detail">
      </p>
    </ul>

    <div class="ad-container detail-to-mention mb-5">
      <h4>{{ $t("offer.article_promo") }}</h4>
      <p class="mt-4" v-html="item.about_us">
      </p>
    </div>

    <grid-uploader
        :detail-mode="true"
        :value="this.item.attachments"
    />

  </template>
  </div>
</template>

<script>
  import AdBtnDropdown from "@/components/ui/v_2/AdBtnDropdown.vue";
  import AdBtn from "@/components/ui/v_2/AdBtn.vue";
  import AdList from "@/components/ui/v_2/list/AdList.vue";
  import AdListItem from "@/components/ui/v_2/list/AdListItem.vue";
  import crud from "@/store/modules/crud.js";
  import i18n from "@/i18n";
  import moment from 'moment'
  import GridUploader from "@/components/attachments/GridUploader.vue";

  export default {

    data() {
      return {
        item: [],
        hashtags: null,
        itemByEnum: this.$store.state.enumerators.enumerators["product-type"],
        offerId: this.$route.params.id,
        userMode: this.$route.params.type,
        isAdmin: this.$options.filters.isSuperAdmin(
          this.$store.state.auth.user.roles
        )
      }
    },

    components: {
      GridUploader,
      AdBtnDropdown,
      AdList,
      AdListItem,
      AdBtn
    },

    mounted () {
     
       this.getOrder();
    },

    methods: {
      getOrder() {
        let setMode = (this.userMode == 'advertiser') ? 'advertiser' : 'publisher';
        
        crud.get('/auction/' + setMode + '/' + this.offerId).then(response => {
          this.item = response.data.items;

          let now = moment();

          this.item.end_date_diff = moment(this.item.end_date, "YYYY-MM-DD").endOf('day').diff(now, 'days');
          this.item.end_date = moment(this.item.end_date).format('DD.MM.YYYY')

          this.item.start_date = moment(this.item.start_date).locale('sk').format('DD.MM.YYYY')
          this.item.publish_date = moment(this.item.publish_date).locale('sk').format('DD.MM.YYYY')
          this.hashtags = this.item.tags;
        }); 
      },
      moveToOrder(data) {
        this.$router.push({ path: data });
      },
      removeOffer() {
        if (!confirm("Do you want to confirm the action?")) {
          return;
        }

        let self = this;

        crud
          .delete("auction/force/" + this.offerId)
          .then(function() {
            self.$notify({ type: "success", text: i18n.t("success.record-updated") });
            self.$router.push("/admin/offers");
          })
          .catch(() => {
            self.$notify({ type: "error", text: i18n.t("error.oops") });
          });
      },
      redirectToEdit() {
        this.$router.push("/admin/offer/edit/" + this.offerId);
      },
      cancelAuction(message) {
                // Ask confirm
                if (!confirm("Do you want to confirm the action?")) {
                    return;
                }

                crud.post('/auction/publisher/cancel/' + this.$route.params.id, (message) ? { message } : null).then(() => {
                    this.$notify({ type: "success", text: i18n.t("success.record-canceled") });
                    this.$router.push("/publisher/offers");
                });
            },
      editAuction() {
        this.$router.push(`/publisher/offer/edit/${this.offerId}`);
      }  
    },
    
  }
</script>

<style lang="scss" scoped>
  .ad-ul-list{
    padding-left: 24px;
  }

  .ad-stats-table {
    border-top: 1px solid rgba(233, 237, 239, 1);

    &:last-child {
      // border-bottom: 1px solid rgba(233, 237, 239, 1);
    }
  }

  .ad-smaller-text-responsive {
    font-size: 15px; 
    
    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

  .ad-border-padding-outline {
    @media (max-width: 767px) {
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid rgba(233, 237, 239, 1);
    }
  }

  .ad-grid-table {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  .ad-custom-card {
    border: 1px solid rgba(233, 237, 239, 1);
    margin-right: 1rem;
    padding: 0 1rem;
    @media (max-width: 768px) {
      margin-bottom: 0.5em;
    }

    &:last-child {
      margin-right: 0.5rem;
    }

    .ad-desc {
      min-height: 9rem;
    }
  }

  button.ad-btn-submit {
    background: rgba(0, 164, 144, 1);
    color: white;
    width: 100%;
    text-align: center;
    border: 0;
    border-radius: 2px;
    padding: 0.35rem 0;
  }

  .ad-ul-list {
    border-top: 1px solid rgba(233, 237, 239, 1);
    margin-top: 2rem;
    padding-top: 2rem;

    li {
      margin-left: 2rem;
      font-size: 1rem;
    }

    h4 {
      margin-bottom: 1.5rem;
      font-weight: bold;
      font-size: 18px !important;
    }
  }

  .ad-container {
    margin-top: 2rem;
    padding: 1rem 1.5rem;
    background: rgba(0, 0, 0, 0.04);

    p {
      font-size: 1rem;
    }

    h4 {
      margin-bottom: 1.5rem;
      font-weight: bold;
      font-size: 18px !important;
    }
  }

  .ad-btn-delete {
    padding: 0.5rem 1.5rem;
    border: 0;
    border-radius: 4px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    background: rgba(248, 215, 218, 1);
  }

  .ad-btn-edit {
    padding: 0.5rem 1.5rem;
    border: 0;
    border-radius: 4px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    background: rgba(255, 236, 207, 1);
  }

  .ad-custom-title {
    margin-top: .8rem;
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: 600;
    display: flex;
    align-content: center;
    align-items: center;
  }
</style>