import { mapActions } from "vuex";
import crud from "@/store/modules/crud.js";
import router from "@/router/index";

export default {
    data() {
        return {
            loginUser: {
                email: "",
                password: ""
            },
            registerUser: {
                first_name: null,
                last_name: null,
                email: null,
                password: "",
                tos: false
            },
            signature: {
                expires: null,
                string: null
            },
            registerAdditionalData: {
                password_repeat: null
            },
            resetPasswordObj: {
                email: ""
            },
            toResetPasswordObj: {
                email: null,
                token: null,
                password: '',
                password_confirmation: ''
            },
            registerErrors: {
                email: [],
                tos: false
            },
            loginErrors: {
                email:[],
                password:[],
            }
        }
    },
    watch: {
        loginUser: {
            handler() {

                this.loginErrors = {
                    email:[],
                    password:[],
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapActions({
            logIn: "auth/logIn",
            addNotification: "notifications/addNotification",
            forgotPassword: "auth/forgotPassword",
            resetPasswordStore: "auth/resetPassword",
            setView: "view/setUserView"
        }),
        login(validated) {
            this.loginErrors = {
                email:[],
                password:[],
            }
            if(validated) {
                this.logIn(this.loginUser).then(() => {
                    let data =
                        localStorage.default_role !== "null"
                            ? localStorage.default_role
                            : "advertiser";

                    this.setView({
                        status: data,
                        checked: data == "advertiser" ? true : false
                    });
                    // this.$root.$refs.loadMenu.getMenu();
                    router.push("/" + data + "/offers");

                }).catch(err => {
                    console.log(err.response)
                    this.loginErrors = {...this.loginErrors, ...err.response.data.errors}
                    // console.log(err)
                })
            }
        },
        async register(validated) {
            if(this.registerUser.tos === false) {
                this.registerErrors.tos = true
            }
            if(validated) {
                let auth = {}
                await crud.post('auth/register', this.registerUser).then(resp => {
                    auth = resp.data
                }).catch(err => {
                    this.registerErrors = err.data.errors
                    console.log(err)
                })
                await this.$store.dispatch('auth/attempt', {
                    token: 'Bearer ' + auth._token.key,
                    user: auth._user
                })
                window.location.reload()
            }
        },

        async registerInvite(validated) {
            if(this.registerUser.tos === false) {
                this.registerErrors.tos = true
            }
            if(validated) {
                let auth = {}
                await crud.post(
                    this.verificationLink, 
                    {user: this.registerUser}).then(resp => {
                    auth = resp.data
                }).catch(err => {
                    this.registerErrors = err.data.errors
                    console.log(err)
                })
                await this.$store.dispatch('auth/attempt', {
                    token: 'Bearer ' + auth._token.key,
                    user: auth._user
                })
                window.location.reload()
            }
        },
        forgotPasswordMethod(validated) {
            if(validated) {
                this.forgotPassword(this.resetPasswordObj)
            }
        },
        resetPassword(validated) {
            console.log(validated)
            if(validated) {
                this.resetPasswordStore(this.toResetPasswordObj)
            }
        },
        resendVerification() {
            console.log(this.$store.getters["auth/user"])
            crud.get(`auth/verify/${this.$store.getters["auth/user"].id}/resend`).then(() => {
                
            })
        }
    }
}