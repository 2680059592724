<template>
  <div class="ad-list">
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.ad-list {
  margin: 0 !important;
  padding: 0.4rem 0;
  width: 100%;
}
</style>
