<template>
  <CFooter :fixed="false">
    <!-- <div class="mfs-auto">
      <span>addMention &copy; {{ new Date().getFullYear() }} </span>
      <a class="mr-1" href="https://www.dognet.sk/" target="_blank"
        >dognet.sk</a
      >
    </div> -->
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter"
};
</script>
