<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <h1 class="text-left">
          <strong>{{ $t("menu.companies") }}</strong>
        </h1>
      </div>
    </div>

    <dynamic-filter
      :inputs="dynamicFilterInputs"
      v-on:params-updated="processEmit"
    />

    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :tableConfig="{col: 2}"
        :meta="this.table.meta"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import DataViewer from "@/components/ui/DataViewer";
import PreLoader from "@/components/ui/PreLoader";
import DynamicFilter from "@/components/ui/DynamicFilter";
import i18n from "@/i18n";

export default {
  data() {
    return {
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "id",
          translate: "ID",
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "name",
          translate: i18n.t("register.company_name"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "reg_number",
          translate: i18n.t("register.reg_number"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "vat_id",
          translate: i18n.t("register.vat"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "status",
          translate: i18n.t("system.status"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "actions",
          translate: i18n.t("system.actions"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-12"
          },
          buttons: [
            {
              key: "detail",
              link: "/companies/view/",
              identifier: "id",
              translate: i18n.t("company.detail"),
              class: "button bg-secondary text-white mx-1 px-3 px-4 py-2"
            },{
              key: "activity",
              link: "/companies/activity/",
              identifier: "id",
              translate: i18n.t("company.activity"),
              class: "button bg-info text-white mx-1 px-3 px-4 py-2"
            },{
              key: "edit",
              link: "/companies/edit/",
              identifier: "id",
              translate: i18n.t("company.edit"),
              class: "button bg-warning text-white mx-1 px-3 px-4 py-2"
            }
          ]
        }
      ],
      dynamicFilterInputs: [
        {
          name: "name",
          type: "text",
          label: i18n.t("register.company_name"),
          value: null,
          adminOnly: true
        },
        {
          name: "reg_number",
          type: "text",
          label: i18n.t("register.reg_number"),
          value: null,
          adminOnly: true
        },
        {
          name: "vat_id",
          type: "text",
          label: i18n.t("register.vat"),
          value: null,
          adminOnly: true
        },
        {
          name: "status",
          type: "select",
          label: i18n.t("system.status"),
          value: [],
          adminOnly: false
        }
      ]
    };
  },

  components: {
    DataViewer,
    PreLoader,
    DynamicFilter
  },

  mounted() {
    this.loadTable();
  },

  methods: {
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "";
      let filter = this.query.filter ? this.query.filter : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud
        .get("companies" + page + filter + sort)
        .then(function(response) {
          // console.log(  Object.keys(response.data.items[0])  );

          response.data.items.forEach(item => {
            item.status
              ? (item.status = i18n.t(
                  "enum." +
                    self.$store.state.enumerators.enumerators["company-status"][
                      item.status
                    ]
                ))
              : "";
          });

          // Dynamic value
          self.dynamicFilterInputs[3].value = self.selectedOptions;

          self.table = response.data;
          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },
    processEmit(data) {
      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      if (data.filter) {
        this.query.filter = data.filter;

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }
    }
  },

  computed: {
    selectedOptions: function() {
      let enums = Object.entries(
        this.$store.state.enumerators.enumerators["company-status"]
      );

      let arr = [];

      enums.forEach(item => {
        arr.push({
          value: Number([item[0]].toString()),
          name: [item[1]].toString()
        });
      });

      return arr;
    }
  }
};
</script>

<style lang="scss" scoped></style>
