<template>
  <div>
    <div class="row justify-content-center mt-3">
      <div class="input-box col-lg-6">
        <span
          class="input-group-addon d-flex align-items-center"
          role="button"
          @click="showPassword = !showPassword"
        >
          <i
            class="fa"
            :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
            aria-hidden="true"
          ></i>
        </span>
        <input
          :type="[showPassword ? 'text' : 'password']"
          class="input-1 input-2"
          v-model="form.password"
          :value="form.password"
          id="password"
          required
        />
        <label class="input-label pass-label" for="password">
          {{ $t("login.password") }}
        </label>
      </div>
      <div class="input-box col-lg-6">
        <input
          :type="[showPassword ? 'text' : 'password']"
          class="input-1"
          v-model="form.password_confirmation"
          id="password_confirmation"
          :value="form.password_confirmation"
          required
        />
        <label class="input-label" for="password_confirmation">
          {{ $t("system.confirm") }}
          {{ $t("login.password") }}
        </label>
      </div>
    </div>
    <div class="row justify-content-left mt-2 mb-2">
      <button type="button" class="button" @click="generate()">
        {{ $t("register.generate_password") }}
      </button>
    </div>
    <PasswordStrength
      ref="passwordStrength"
      :password-input="form.password"
      :password-confirm-input="form.password_confirmation"
    ></PasswordStrength>
  </div>
</template>

<script>
import PasswordStrength from "@/components/ui/PasswordStrength";

export default {
  name: "password",
  components: {
    PasswordStrength
  },
  props: {
    form: Object,
    type: {
      type: String,
      default: "text"
    },
    size: {
      type: String,
      default: "32"
    },
    characters: {
      type: String,
      default: "a-z,A-Z,0-9,#"
    },
    auto: [String, Boolean],
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showPassword: false
    };
  },
  mounted: function() {
    if (this.auto === "true" || this.auto === 1) {
      this.generate();
    }
  },
  methods: {
    generate() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let pass = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < this.size; i++) {
        pass += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.showPassword = true;
      this.form.password = pass;
      this.form.password_confirmation = pass;
    }
  }
};
</script>
