export default {
  data: function() {
    return {
      ripples: []
    };
  },
  methods: {
    animateRipple: function(e) {
      let el = this.$parent.$refs.rippleItem;
      let pos = el.getBoundingClientRect();

      this.ripples.push({
        x: e.clientX - pos.left,
        y: e.clientY - pos.top,
        show: true
      });
    },
    rippleEnd: function(i) {
      this.ripples[i].show = false;
    }
  }
};
