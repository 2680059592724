//Views Translations
import Translations from "../views/settings/translations_settings/TranslationsIndex.vue";
export default [
  {
    data: {
      admin: [
        {
          path: "/settings/translations",
          meta: {
            label: "menu.translations"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/settings/translations",
              //name: "crud.list",
              component: Translations,
              meta: {
                requiresAuth: true
              }
            }
            /*,
            {
              path: "/websites/view/:id",
              name: i18n.t("menu.websites") + ": " + i18n.t("crud.view"),
              component: WebsiteView,
              meta: {
                requiresAuth: true
              }
            }*/
          ]
        }
      ]
    }
  }
];
