<template>
    <ad-form @submit="process">

        <div v-for="(error, key) in backendErrors" v-bind:key="key" class="error-header col-12">{{$t('validation.'+error)}}</div>

        <div class="container">
            <div class="row">
                <div class="col">
                    <h3>{{ $t("finance.add-credit") }}</h3>
                </div>
            </div>

          <!-- Timeline start -->
          <div class="timeline mr-md-5 mt-4 mb-4">
            <div class="timeline-box position-relative">
              <div class="timeline-dot timeline-dot-active"></div>

              <div class="timeline-dot-line ml-auto"></div>

              <div class="timeline-text mt-md-3">
          <span
              style="font-family: Roboto; font-size: 16px; line-height: 24px;"
              class="d-block font-weight-bold"
          >
            {{ $t("credit.timeline-deposit") }}
          </span>

                <span
                    style="font-family: Roboto; font-size: 14px; font-weight: 400; line-height: 16px;"
                    class="d-block"
                >
            {{ $t("credit.timeline-enter-value") }}
          </span>
              </div>

              <div class="timeline-dot-line-mobile"></div>
            </div>

            <div class="timeline-box position-relative">
              <div class="timeline-dot"></div>

              <div class="timeline-dot-line ml-auto"></div>

              <div class="timeline-text mt-md-3">
          <span
              style="font-family: Roboto; font-size: 16px; line-height: 24px;"
              class="d-block font-weight-bold"
          >
            {{ $t("credit.timeline-invoice") }}
          </span>

                <span
                    style="font-family: Roboto; font-size: 14px; font-weight: 400; line-height: 16px;"
                    class="d-block"
                >
            {{ $t("credit.timeline-invoice-pay") }}
          </span>
              </div>

              <div class="timeline-dot-line-mobile"></div>
            </div>

            <div class="timeline-box">
              <div class="timeline-dot"></div>

              <div class="timeline-text mt-md-3">
          <span
              style="font-family: Roboto; font-size: 16px; line-height: 24px;"
              class="d-block font-weight-bold mt-custom-5"
          >
            {{ $t("credit.timeline-credit") }}
          </span>

                <span
                    style="font-family: Roboto; font-size: 14px; font-weight: 400; line-height: 16px;"
                    class="d-block"
                >
            {{ $t("credit.add-credit") }}
          </span>
              </div>
            </div>
          </div>
          <!-- Timeline end -->

            <!-- Name -->
            <div class="row">
                <div class="col-12 col-md-8 mt-4">
                    <h4>{{ $t("finance.credit-value-label") }}</h4>
                    <ad-input
                        v-model="credit"
                        :rules="[rules.required, rules.float]"
                        :placeholder="$t('finance.credit-value-placeholder')"
                    />
                </div>
            </div>
            <!-- Company Form -->
            <section id="company-form" v-if="!hasCompany">
                <!-- Name -->
                <div class="row">
                    <div class="col-12 col-md-8 mt-4">
                        <h4>{{ $t("finance.billing-details") }}</h4>
                        <ad-input v-model="company.name" :rules="[rules.required]" :placeholder="$t('company.name')" />
                    </div>
                </div>
                <!-- Vat ID, Tax ID, Reg Number -->
                <div class="row">
                    <div class="col-12 col-md-4 mt-4">
                        <ad-input
                            v-model="company.vat_id"
                            :rules="[rules.required]"
                            :placeholder="$t('company.vat_id')"
                        />
                    </div>
                    <div class="col-12 col-md-4 mt-4">
                        <ad-input v-model="company.tax_id" :placeholder="$t('company.tax_id')" />
                    </div>
                    <div class="col-12 col-md-8 mt-4">
                        <ad-input
                            v-model="company.reg_number"
                            :rules="[rules.required]"
                            :placeholder="$t('company.reg_number')"
                        />
                    </div>
                </div>
                <!-- Street -->
                <div class="row">
                    <div class="col-12 col-md-8 mt-4">
                        <ad-input
                            v-model="company.street"
                            :rules="[rules.required]"
                            :placeholder="$t('company.street')"
                        />
                    </div>
                </div>
                <!-- Postal Code -->
                <div class="row">
                    <div class="col-12 col-md-8 mt-4">
                        <ad-input
                            v-model="company.postal_code"
                            :rules="[rules.required]"
                            :placeholder="$t('company.postal_code')"
                        />
                    </div>
                </div>
                <!-- Country -->
                <div class="row">
                    <div class="col-12 col-md-8 mt-4">
                        <ad-select
                            :items="countries"
                            item-value="id"
                            item-label="name"
                            dropdownDirection="top"
                            v-model="company.country"
                            :placeholder="$t('company.country')"
                        />
                    </div>
                </div>
            </section>
            <!-- ------------------------------------------------------------- -->
            <!-- Has Company -->
            <section id="has-company" v-else>
                <!-- Name -->
                <div class="row">
                    <div class="col-12 col-md-8 mt-4 ">
                        <h4>{{ $t("finance.billing-details") }}</h4>
                        <div class="row-delimiter my-2"></div>
                        {{company.name}}
                    </div>
                </div>
                <!-- Vat ID, Tax ID, Reg Number -->
                <div class="row">
                    <div class="col-12 col-md-8 ">
                        <div class="row-delimiter my-2"></div>
                        <div class="d-flex">
                            <span>
                                {{$t('company.vat_id')}} {{company.vat_id}}
                            </span>
                            <span class="mx-auto">
                                {{$t('company.tax_id')}} {{company.tax_id}}
                            </span>
                            <span class="">
                                {{$t('company.reg_number')}} {{company.reg_number}}
                            </span>
                        </div>
                    </div>
                </div>
                 <!-- Street -->
                 <div class="row">
                    <div class="col-12 col-md-8 ">
                        <div class="row-delimiter my-2"></div>
                        {{company.street}}, {{company.city}}, {{$store.state.enumerators.enumerators["country"][company.country]}}
                        <div class="row-delimiter my-2"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-3">
                        <ad-btn flat :title="$t('finance.modify-billing-details')" @click="hasCompany = !hasCompany" style="text-align:left;" />
                    </div>
                </div>
            </section>
            <!-- Credit -->
            <div class="row">
                <div class="col-12 col-md-8 mt-4">
                    <h4>{{ $t("finance.credit-value") }}</h4>
                    <div class="row-delimiter mb-2"></div>
                    <div class="d-flex text-primary-color">
                        <span>{{ $t("finance.credit-value-for-add") }}</span>
                        <span class="ml-auto">{{ creditText }}</span>
                    </div>
                    <div class="row-delimiter mt-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-8 mt-4">
                    <ad-btn style="width: 100%;" :loading="loading" :title="$t('finance.submit-add-credit')" type="submit" />
                </div>
            </div>
        </div>
    </ad-form>
</template>

<script>
import AdInput from "../../../components/ui/v_2/AdInput.vue";
import AdBtn from "../../../components/ui/v_2/AdBtn.vue";
import AdForm from "../../../components/ui/v_2/AdForm.vue";
import AdSelect from "../../../components/ui/v_2/AdSelect.vue";
import finance from "@/mixins/finance.js";
import company from "@/mixins/company.js";
import rules from "@/mixins/rules.js";
import crud from "@/store/modules/crud";

export default {
    components: {
        AdInput,
        AdBtn,
        AdForm,
        AdSelect,
    },
    created() {
        this.getCompany();
    },
    mixins: [company, finance, rules],
    data() {
        return {
            editorToolbar: [["bold"], [{ header: [false, 1, 2, 3, 4, 5, 6] }], [{ list: "bullet" }]],
            loading: false,
            backendErrors: []
        };
    },
    computed: {
        countries: function() {
            const countries = this.$store.state.enumerators.enumerators["country"];
            const countriesKeys = Object.keys(countries);
            let customCountries = [];
            countriesKeys.map((country) => {
                let newCountry = {
                    id: country,
                    name: countries[country],
                };
                customCountries.push(newCountry);
                console.log(countries[country]);
            });
            return customCountries;
        },
    },
    methods: {
        process(validation) {
            if (!validation) {
                return;
            }

          this.loading = true;

          if(!this.hasCompany) {
            crud.put(`companies/${this.company.id}`, this.company)
                .then(() => {
                  this.submitAddCredit();
                })
                .catch(e => {
                  this.form
                  this.loading = false;
                  console.log(e);

                  Object.keys(e.data.errors).forEach(field => {
                    this.backendErrors.push(e.data.errors[field][0]);
                  })
                });
          }else {
            this.submitAddCredit();
          }
        },

        submitAddCredit(){
          this.addCredit().then(() => {
            this.$router.push({path: '/finance/invoice'})
          }).catch((e) => {
            this.loading = false;

            Object.keys(e.data.errors).forEach(field => {
              this.backendErrors.push(e.data.errors[field][0]);
            })
            console.log(e);
          });
        }
    }
};
</script>

<style lang="scss" scoped>
.timeline {
  display: flex;
  justify-content: space-between;

@media (max-width: 768px) {
  flex-direction: column;
}
}

.timeline-box {
  width: 100%;

@media (max-width: 768px) {
  display: flex;
  margin-bottom: 1.5rem;

  .timeline-dot {
    margin-right: 1rem;
  }
}
}

.timeline-dot {
  z-index: 9999 !important;
  width: 1.3rem;
  height: 1.3rem;
  background-color: white;
  border-radius: 50%;
  border: 3px solid rgba(0, 164, 144, 0.12);
}

.timeline-dot-active {
  border: 3px solid rgba(0, 164, 144, 1);
}

.timeline-dot-line {
  z-index: 1 !important;

  height: 4px;
  bottom: 0.75rem;
  background: rgba(0, 164, 144, 0.12);
  width: calc(100% - 1.3rem);
  position: relative;

@media (max-width: 768px) {
  display: none;
}
}

.timeline-dot-line-mobile {
  position: absolute;
  left: 0.525rem;
  top: 1.3rem;
  background: rgba(0, 164, 144, 0.12);
  width: 4px;
  height: 110%;

@media (min-width: 768px) {
  display: none;
}
}

</style>
