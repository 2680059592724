<template>
  <div>
    <div class="ml-3 mb-3">
      <h1 class="text-left">
        <strong>{{ $t("system.orders") }}</strong>
      </h1>
    </div>

    <dynamic-filter
        :inputs="dynamicFilterInputs"
        v-on:params-updated="processEmit"
    />


    <pre-loader
      v-if="
        (performance.loading && performance.success) ||
          (performance.loading && !performance.success)
      "
      :loading="this.performance.loading"
      :success="this.performance.success"
      :errorMessage="this.performance.errorMessage || null"
    />

    <template v-else>
      <DataViewer
        :tableConfig="{col: -1}"
        :headers="this.tableHeaders"
        :items="this.table.items"
        :links="this.table.links"
        :meta="this.table.meta"
        :query="this.query"
        v-on:params-updated="processEmit"
      >
      </DataViewer>
    </template>
  </div>
</template>

<script>
import crud from "@/store/modules/crud.js";
import { mapActions } from "vuex";
import i18n from "@/i18n";
import DataViewer from "@/components/ui/DataViewer";
import PreLoader from "@/components/ui/PreLoader";
import rules from "@/mixins/rules.js";
import helpers from "@/mixins/helpers";
import DynamicFilter from "@/components/ui/DynamicFilter.vue";

export default {
  components: {
    DynamicFilter,
    DataViewer,
    PreLoader
  },

  mixins: [rules],

  data() {
    return {
      selectedFilterOptions: {
        search: null,
        max_price: null,
        min_estimated_traffic: null,
        url: [],
        segment: [],
        published: null
      },
      searchAuctionQuery: null,
      searchAuctionName: null,
      report: {
        modal: false,
        details: {
          what_topic_are_you_interested: "",
          what_minimum_traffic_do_you_require: "",
          price_for_opening_article: "",
          maximum_purchase_price: "",
          other_requirements: ""
        }
      },
      tableFilterEnabled: false,
      query: {
        page: "",
        sort: "",
        filter: ""
      },
      performance: {
        loading: true,
        success: true,
        errorMessage: ""
      },
      table: [],
      tableHeaders: [
        {
          key: "id",
          translate: "ID",
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-3"
          }
        },
        {
          key: "name",
          translate: i18n.t("advertiser_table.article_name"),
          sorter: false,
          style: "width: 150px !important;",
          hint: null,
          href: {
            link: "/admin/order-detail/",
            identifier: "id"
          },
          mobile: {
            class: "col-9"
          }
        },{
          key: "status",
          translate: i18n.t("admin-order.status"),
          sorter: false,
          hint: null,
          mobile: {
            class: "col-9"
          }
        },{
          key: "event",
          translate: i18n.t("admin-order.event"),
          sorter: false,
          hint: null,
          mobile: {
            class: "col-9"
          }
        },{
          key: "activity_created_at",
          translate: i18n.t("admin-order.activity_created_at"),
          sorter: false,
          hint: null,
          mobile: {
            class: "col-9"
          }
        },{
          key: "advertiser",
          translate: i18n.t("advertiser.status"),
          sorter: false,
          style: null,
          hint: null,
          href:{
            link: '/users/view/',
            identifier: 'advertiser_id'
          },
          mobile: {
            class: "col-6"
          }
        },
        {
          key: "publisher",
          translate: i18n.t("publisher.status"),
          sorter: false,
          style: null,
          hint: null,
          href:{
            link: '/users/view/',
            identifier: 'publisher_id'
          },
          mobile: {
            class: "col-6"
          }
        }, {
          key: "created_at",
          translate: i18n.t("admin-order.created_at"),
          sorter: false,
          style: null,
          hint: null,
          mobile: {
            class: "col-6"
          }
        }
      ],
      dynamicFilterInputs: [
        {
          name: "advertiser",
          type: "select2",
          label: i18n.t("admin-order.advertiser"),
          value: []
        },{
          name: "publisher",
          type: "select2",
          label: i18n.t("admin-order.publisher"),
          value: []
        },{
          name: "domain",
          type: "select2",
          label: i18n.t("admin-order.offer-domain"),
          value: []
        },
        {
          name: "name",
          type: "text",
          label: i18n.t("admin-order.offer-name"),
          value: null,
          adminOnly: false
        }
      ]
    };
  },

  mounted() {
    this.loadTable();
    this.adminGetUsers();
    this.getDomains();
  },

  methods: {
    ...mapActions({
      addNotification: "notifications/addNotification"
    }),
    submitReportModal() {
      if (
        this.report.details.what_topic_are_you_interested &&
        this.report.details.what_minimum_traffic_do_you_require &&
        this.report.details.what_minimum_traffic_do_you_require
      ) {
        let self = this;

        let obj = {
          topic: self.report.details.what_topic_are_you_interested,
          minimum_traffic:
            self.report.details.what_minimum_traffic_do_you_require,
          price_per_click: self.report.details.price_for_opening_article,
          price: self.report.details.maximum_purchase_price,
          other: self.report.details.other_requirements
        };

        crud
          .post("wishlist", obj)
          .then(function() {
            self.report.modal = false;

            // Reset data
            self.report.details = {
              what_topic_are_you_interested: "",
              what_minimum_traffic_do_you_require: "",
              price_for_opening_article: "",
              maximum_purchase_price: "",
              other_requirements: ""
            };

            self.addNotification({
              type: "success",
              message: i18n.t("success.record-add")
            });
          })
          .catch(() => {
            self.addNotification({
              type: "danger",
              message: i18n.t("error.oops")
            });
          });
      }
    },
    loadTable() {
      let self = this;

      this.performance = {
        loading: true,
        success: true,
        errorMessage: ""
      };

      // Build query
      let page = this.query.page ? "?page=" + this.query.page : "";
      let filter = this.query.filter ? this.query.filter : "";
      let sort = this.query.sort ? "&sort=" + this.query.sort : "";

      crud
        .get("bid" + page + filter + sort)
        .then(function(response) {
          // console.log(  Object.keys(response.data.items[0])  );

          response.data.items.forEach(item => {
            item.price ? (item.price = item.price.split(".")[0] + "€") : "";
            item.status = i18n.t("enum." + self.$store.state.enumerators.enumerators["bid-status"][item.status]);
            item.created_at = helpers.methods.getDate(item.created_at, 'lll')
            item.domain_name
              ? (item.domain_name = item.domain_name
                  .split("//")[1]
                  .replace("www.", ""))
              : "";
            item.price_per_click
              ? 
            (item.price_per_click = item.price_per_click + "€")
              : 
            "";

            item.advertiser_id = item.advertiser.id;
            item.advertiser = item.advertiser.name + ' (' + item.advertiser.id + ')';

            item.publisher_id = item.publisher.id;
            item.publisher = item.publisher.name + ' (' + item.publisher.id + ')';

            if (item.event !== null) {
              item.event = i18n.t("activity." + self.$store.state.enumerators.enumerators["activity-type"][item.event]);
              item.activity_created_at = helpers.methods.getDate(item.activity_created_at, 'lll');
            }else {
              item.event = i18n.t("activity.na");
            }
            // HTML date
            item.time_left
              ? (item.time_left =
                  item.time_left[0] +
                  '<br><span style="color:rgba(230, 81, 0, 1);font-weight:500;">' +
                  item.time_left[1] +
                  "</span>")
              : "";
          });

          self.table = response.data;
          self.performance.loading = false;
        })
        .catch(error => {
          self.performance.errorMessage = error.statusText; // || 'Internet connection lost'
          self.performance.success = false;
        });
    },
    processEmit(data) {
      console.log(data);

      // Paginate query
      if (data.page) {
        if (data.page == this.table.meta.current_page) return;

        this.query.page = data.page;

        this.loadTable();
      }

      // Sort query
      if (data.sort) {
        if (this.query.sort !== data.sort) {
          this.query.sort = data.sort;
        } else {
          this.query.sort = "-" + this.query.sort;
        }

        this.query.page = 1;

        this.loadTable();
      }

      // Filter query
      if (data.filter) {
        this.query.filter = data.filter;

        this.query.page = 1;

        this.loadTable();
      }

      // Reset
      if (data.reset) {
        this.query.page = 1;

        this.query[data.reset] = "";

        this.loadTable();
      }

      // Emited action
      if (data.action) {
        let self = this;

        self.performance.loading = true;

        crud.post(data.action).then(() => {
          self.loadTable();
        });
      }
    },
    // searchByName() {
    //   if (!this.searchAuctionName) return;

    //   this.selectedFilterOptions.search =
    //     "&filter[search]=" + this.searchAuctionName;

    //   this.loadTable();
    // },
    resetCustomParam(data) {
      this.selectedFilterOptions[data] = "";

      this.loadTable();
    },
    generateFilterQuery() {
      this.query.page = 1;

      let filter = Object.values(this.selectedFilterOptions)
        .join("")
        .toString();

      return filter;
    },
    adminGetUsers() {
      let self = this;
      crud.get("users/select").then(function(response) {
        let arr = [];
        response.data.forEach(item => {
          arr.push({
            label: item.full_name+" ("+item.id+")",
            code: item.id
          });
        });
        self.dynamicFilterInputs[0].value = arr;
        self.dynamicFilterInputs[1].value = arr;
      });
    },

    getDomains() {
      let self = this;

      crud.get("auction/domains/select").then(function(response) {
        let arr = [];

        response.data.forEach(value => {
          arr.push({
            label: value.url.split("//")[1],
            code: value.id
          });
        });
        self.dynamicFilterInputs[2].value = arr;
      });
    },

  }
};
</script>

<style lang="scss" scoped></style>
